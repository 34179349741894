import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-enough-and-too',
  standalone: true,
  imports: [],
  templateUrl: './enough-and-too.component.html',
  styleUrl: './enough-and-too.component.scss',
})
export class EnoughAndTooComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Enough and Too';
  override title = 'Enough And Toot';
}
