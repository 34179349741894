import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-like-and-as',
  standalone: true,
  imports: [],
  templateUrl: './like-and-as.component.html',
  styleUrl: './like-and-as.component.scss',
})
export class LikeAndAsComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Like and As';
  override title = 'Like and As';
}
