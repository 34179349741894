import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-delexical-verbs',
  standalone: true,
  imports: [],
  templateUrl: './delexical-verbs.component.html',
  styleUrl: './delexical-verbs.component.scss',
})
export class DelexicalVerbsComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Delexical Verbs';
  override title = 'Delexical Verbs';
}
