import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-indefinite-pronouns',
  standalone: true,
  imports: [],
  templateUrl: './indefinite-pronouns.component.html',
  styleUrl: './indefinite-pronouns.component.scss',
})
export class IndefinitePronounsComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Pronombres indefinidos';
  override title = 'Pronombres indefinidos';
}
