<div class="past-continuous">
  <p>
    El pasado continuo se usa para describir una acción que
    <strong>estaba</strong> en progreso en un momento específico del pasado.
    También se emplea para describir dos acciones simultáneas o una acción en
    progreso interrumpida por otra.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Usos del Pasado Continuo</h2>
      <ul>
        <li>
          <strong>Acción en progreso en el pasado:</strong> "I
          <strong>was reading</strong> a book at 9 p.m. last night." / "Yo
          <strong>estaba leyendo</strong> un libro a las 9 p.m. anoche."
        </li>
        <li>
          <strong>Dos acciones simultáneas:</strong> "While she
          <strong>was cooking</strong>, he <strong>was setting</strong> the
          table." / "Mientras ella <strong>estaba cocinando</strong>, él
          <strong>estaba poniendo</strong> la mesa."
        </li>
        <li>
          <strong>Acción interrumpida:</strong> "I
          <strong>was watching</strong> TV when the phone rang." / "Yo
          <strong>estaba viendo</strong> televisión cuando sonó el teléfono."
        </li>
      </ul>

      <h2>Formación del Pasado Continuo</h2>
      <p>
        El pasado continuo se forma usando el verbo auxiliar "to be" en pasado
        (was/were) + el verbo principal en forma -ing.
      </p>
      <p>Use <strong>"was"</strong> with singular pronouns (I, he, she, it).</p>
      <p>
        Use <strong>"were"</strong> with plural pronouns (you, we, they) and
        also with "you" in singular.
      </p>
      <table>
        <tr>
          <th>Pronombre</th>
          <th>Auxiliar</th>
          <th>Verbo en -ing</th>
          <th>Ejemplo</th>
        </tr>
        <tr>
          <td>I</td>
          <td>was</td>
          <td>reading</td>
          <td>
            I <strong>was reading</strong> a book. / Yo
            <strong>estaba leyendo</strong> un libro.
          </td>
        </tr>
        <tr>
          <td>You</td>
          <td>were</td>
          <td>playing</td>
          <td>
            You <strong>were playing</strong> soccer. / Tú
            <strong>estabas jugando</strong> al fútbol.
          </td>
        </tr>
        <tr>
          <td>He/She/It</td>
          <td>was</td>
          <td>sleeping</td>
          <td>
            He <strong>was sleeping</strong> on the couch. / Él
            <strong>estaba durmiendo</strong> en el sofá.
          </td>
        </tr>
        <tr>
          <td>We</td>
          <td>were</td>
          <td>studying</td>
          <td>
            We <strong>were studying</strong> for the exam. / Nosotros
            <strong>estábamos estudiando</strong> para el examen.
          </td>
        </tr>
        <tr>
          <td>They</td>
          <td>were</td>
          <td>watching</td>
          <td>
            They <strong>were watching</strong> a movie. / Ellos
            <strong>estaban viendo</strong> una película.
          </td>
        </tr>
      </table>

      <h2>Ejemplos Adicionales</h2>
      <table>
        <tr>
          <th>Frase en Inglés</th>
          <th>Traducción al Español</th>
        </tr>
        <tr>
          <td>She <strong>was dancing</strong> at the party.</td>
          <td>Ella <strong>estaba bailando</strong> en la fiesta.</td>
        </tr>
        <tr>
          <td>They <strong>were traveling</strong> to Spain.</td>
          <td>Ellos <strong>estaban viajando</strong> a España.</td>
        </tr>
        <tr>
          <td>
            We <strong>were having</strong> dinner when the lights went out.
          </td>
          <td>
            Nosotros <strong>estábamos cenando</strong> cuando se apagaron las
            luces.
          </td>
        </tr>
        <tr>
          <td>I <strong>was listening</strong> to music.</td>
          <td>Yo <strong>estaba escuchando</strong> música.</td>
        </tr>
        <tr>
          <td>He <strong>was studying</strong> all night.</td>
          <td>Él <strong>estaba estudiando</strong> toda la noche.</td>
        </tr>
        <tr>
          <td>She <strong>was writing</strong> a letter when I called.</td>
          <td>
            Ella <strong>estaba escribiendo</strong> una carta cuando llamé.
          </td>
        </tr>
        <tr>
          <td>
            We <strong>were playing</strong> cards while waiting for the bus.
          </td>
          <td>
            Nosotros <strong>estábamos jugando</strong> a las cartas mientras
            esperábamos el autobús.
          </td>
        </tr>
        <tr>
          <td>They <strong>were working</strong> on a project together.</td>
          <td>
            Ellos <strong>estaban trabajando</strong> en un proyecto juntos.
          </td>
        </tr>
        <tr>
          <td>I <strong>was painting</strong> my room.</td>
          <td>Yo <strong>estaba pintando</strong> mi habitación.</td>
        </tr>
        <tr>
          <td>She <strong>was talking</strong> on the phone.</td>
          <td>Ella <strong>estaba hablando</strong> por teléfono.</td>
        </tr>
        <tr>
          <td>We <strong>were cleaning</strong> the house.</td>
          <td>Nosotros <strong>estábamos limpiando</strong> la casa.</td>
        </tr>
        <tr>
          <td>He <strong>was cooking</strong> dinner when I arrived.</td>
          <td>Él <strong>estaba cocinando</strong> la cena cuando llegué.</td>
        </tr>
        <tr>
          <td>They <strong>were exercising</strong> in the park.</td>
          <td>
            Ellos <strong>estaban haciendo ejercicio</strong> en el parque.
          </td>
        </tr>
        <tr>
          <td>I <strong>was chatting</strong> with friends online.</td>
          <td>Yo <strong>estaba chateando</strong> con amigos en línea.</td>
        </tr>
        <tr>
          <td>She <strong>was taking</strong> photos at the concert.</td>
          <td>Ella <strong>estaba tomando</strong> fotos en el concierto.</td>
        </tr>
        <tr>
          <td>We <strong>were having</strong> a great time at the beach.</td>
          <td>
            Nosotros <strong>estábamos pasando</strong> un gran momento en la
            playa.
          </td>
        </tr>
        <tr>
          <td>
            He <strong>was fixing</strong> his bike when it started to rain.
          </td>
          <td>
            Él <strong>estaba arreglando</strong> su bicicleta cuando comenzó a
            llover.
          </td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Como hacer preguntas en pasado continuo.</h2>
      <p>
        Para formar preguntas en tiempo pasado continuo, invierta el sujeto y el
        verbo auxiliar "to be" (was/were).
      </p>
      <p>
        Usa <strong>"was"</strong> con pronombres singulares (I, he, she, it).
      </p>
      <p>
        Usa <strong>"were"</strong> con pronombres plurales (you, we, they) y
        también con <strong>"you"</strong> en singular.
      </p>

      <table>
        <thead>
          <tr>
            <th>Question</th>
            <th>Translation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Was I <strong>studying</strong>?</td>
            <td>¿Estaba yo <strong>estudiando</strong>?</td>
          </tr>
          <tr>
            <td>Was he <strong>watching</strong> a movie?</td>
            <td>¿Estaba él <strong>viendo</strong> una película?</td>
          </tr>
          <tr>
            <td>Was she <strong>cooking</strong> dinner?</td>
            <td>¿Estaba ella <strong>cocinando</strong> la cena?</td>
          </tr>
          <tr>
            <td>Were you <strong>playing</strong> soccer?</td>
            <td>¿Estabas tú <strong>jugando</strong> al fútbol?</td>
          </tr>
          <tr>
            <td>Were we <strong>having</strong> a great time?</td>
            <td>¿Estábamos nosotros <strong>pasándolo</strong> bien?</td>
          </tr>
          <tr>
            <td>Were they <strong>studying</strong> together?</td>
            <td>¿Estaban ellos <strong>estudiando</strong> juntos?</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
