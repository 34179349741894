<div class="calendar">
    <div>
    <h2>Días de la Semana</h2>
    <table>
        <thead>
            <tr>
                <th>Inglés</th>
                <th>Español</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Monday</td>
                <td>Lunes</td>
            </tr>
            <tr>
                <td>Tuesday</td>
                <td>Martes</td>
            </tr>
            <tr>
                <td>Wednesday</td>
                <td>Miércoles</td>
            </tr>
            <tr>
                <td>Thursday</td>
                <td>Jueves</td>
            </tr>
            <tr>
                <td>Friday</td>
                <td>Viernes</td>
            </tr>
            <tr>
                <td>Saturday</td>
                <td>Sábado</td>
            </tr>
            <tr>
                <td>Sunday</td>
                <td>Domingo</td>
            </tr>
        </tbody>
    </table>
   </div>
   <div>
        <h2>Meses del Año</h2>
        <table>
            <thead>
                <tr>
                    <th>Inglés</th>
                    <th>Español</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>January</td>
                    <td>Enero</td>
                </tr>
                <tr>
                    <td>February</td>
                    <td>Febrero</td>
                </tr>
                <tr>
                    <td>March</td>
                    <td>Marzo</td>
                </tr>
                <tr>
                    <td>April</td>
                    <td>Abril</td>
                </tr>
                <tr>
                    <td>May</td>
                    <td>Mayo</td>
                </tr>
                <tr>
                    <td>June</td>
                    <td>Junio</td>
                </tr>
                <tr>
                    <td>July</td>
                    <td>Julio</td>
                </tr>
                <tr>
                    <td>August</td>
                    <td>Agosto</td>
                </tr>
                <tr>
                    <td>September</td>
                    <td>Septiembre</td>
                </tr>
                <tr>
                    <td>October</td>
                    <td>Octubre</td>
                </tr>
                <tr>
                    <td>November</td>
                    <td>Noviembre</td>
                </tr>
                <tr>
                    <td>December</td>
                    <td>Diciembre</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <h2>Conceptos Básicos</h2>
        <ul>
            <li><strong>Week:</strong> Semana</li>
            <li><strong>Day:</strong> Día</li>
            <li><strong>Month:</strong> Mes</li>
            <li><strong>Year:</strong> Año</li>
        </ul>
    </div>
</div>