import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-passive-voice',
  standalone: true,
  imports: [],
  templateUrl: './passive-voice.component.html',
  styleUrl: './passive-voice.component.scss',
})
export class PassiveVoiceComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Passive voice';
  override title = 'Passive voice';
}
