<div class="either-vs-neither">
  <p>
    <strong>Either</strong> y <strong>Neither</strong> son usados para expresar
    opciones o elecciones, pero su significado y uso son diferentes.
  </p>
  <p>
    <strong>Either</strong> se utiliza cuando hay dos opciones y se refiere a
    una de ellas, mientras que <strong>Neither</strong> se utiliza para indicar
    que ninguna de las opciones es verdadera o aplicable.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h3>Uso de "Either"</h3>
      <p>
        <strong>Either</strong> se usa para indicar que una de dos cosas es
        posible o aceptable. Generalmente se usa en frases afirmativas o en
        preguntas.
      </p>

      <h3>Ejemplos de "Either":</h3>
      <ul>
        <li>
          "You can <strong>either</strong> go to the beach or stay at home."
          (Puedes <em>ir</em> a la playa o quedarte en casa.)
        </li>
        <li>
          "We will <strong>either</strong> eat Italian food or sushi." (Vamos a
          comer comida italiana o sushi.)
        </li>
        <li>
          "<strong>Either</strong> of the two cars is fine." (<em
            >Cualquiera</em
          >
          de los dos autos está bien.)
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h3>Uso de "Neither"</h3>
      <p>
        <strong>Neither</strong> se usa para expresar que ninguna de las
        opciones o alternativas es aceptable o correcta. Se usa en frases
        negativas y para rechazar dos opciones simultáneamente.
      </p>

      <h3>Ejemplos de "Neither":</h3>
      <ul>
        <li>
          "<strong>Neither</strong> John <strong>nor</strong> Sarah came to the
          party." (Ni John ni Sarah vinieron a la fiesta.)
        </li>
        <li>
          "I have <strong>neither</strong> the time <strong>nor</strong> the
          money to travel." (No tengo ni el tiempo ni el dinero para viajar.)
        </li>
        <li>
          "<strong>Neither</strong> option is acceptable." (Ninguna de las dos
          opciones es aceptable.)
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h3>Comparación: "Either" vs "Neither"</h3>
      <p>
        <strong>Either</strong> se utiliza cuando hay una de dos opciones
        disponibles o cuando se habla de una posibilidad positiva (una de las
        dos opciones es correcta o aceptable). En cambio,
        <strong>Neither</strong> se utiliza para negar ambas opciones, indicando
        que ninguna es correcta o aceptable.
      </p>

      <h4>Resumen de las reglas:</h4>
      <ul>
        <li>
          <strong>Either</strong>: Se usa para una elección entre dos opciones
          positivas o posibles.
        </li>
        <li>
          <strong>Neither</strong>: Se usa para rechazar dos opciones negativas,
          indicando que ninguna es aceptable o posible.
        </li>
      </ul>
    </div>
  </div>
</div>
