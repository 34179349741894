import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ConfiguracionPaso,
  LeccionesInglesService,
  Nivel,
} from '../lecciones/lecciones.-ingles.service';

@Component({
  template: '',
  selector: 'app-leccion-base',
})
export class LeccionBaseComponent implements OnInit, OnDestroy {
  protected descripcionMenu: string;
  protected title: string;

  constructor(private leccionesInglesService: LeccionesInglesService) {}

  ngOnInit(): void {
    const itemsNivel = this.leccionesInglesService.itemsMenu.filter(
      (i) => i.nivel === this.leccionesInglesService.nivel$.value
    );
    let nextItemMenuAnterior = null;
    const index = itemsNivel.findIndex(
      (d) => d.descripcionMenu === this.descripcionMenu
    );
    if (!!itemsNivel[index + 1]) {
      nextItemMenuAnterior = itemsNivel[index + 1];
    } else {
      const itemsNivelRecalculado =
        this.leccionesInglesService.itemsMenu.filter((i) =>
          this.leccionesInglesService.nivel$.value === Nivel.Principiante
            ? i.nivel === Nivel.Intermedio
            : this.leccionesInglesService.nivel$.value === Nivel.Intermedio
            ? i.nivel === Nivel.Avanzado
            : []
        );
      nextItemMenuAnterior = itemsNivelRecalculado[0];
    }

    const anteriorItemMenuAnterior = itemsNivel[index - 1];
    this.leccionesInglesService.configuracionPaso$.next({
      title: this.title,
      urlNext: nextItemMenuAnterior?.path,
      urlPrevious: anteriorItemMenuAnterior?.path,
      nameButtonNext: nextItemMenuAnterior?.descripcionMenu,
      nameButtonPrevious: anteriorItemMenuAnterior?.descripcionMenu,
    } as ConfiguracionPaso);
  }

  ngOnDestroy(): void {
    this.leccionesInglesService.configuracionPaso$.next(null);
  }
}
