<div class="futureContinuous">
  <p>
    El futuro continuo (Future Continuous) se utiliza para describir acciones
    que estarán en progreso en un momento específico del futuro. Se forma con
    "will be" seguido del verbo en gerundio (forma -ing).
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>2. Estructura del Futuro Continuo</h2>
      <ul>
        <li>
          <strong>Afirmación:</strong> Sujeto + will be + verbo + -ing<br />Ejemplo:
          I will be studying.
        </li>
        <li>
          <strong>Negación:</strong> Sujeto + will not be + verbo + -ing<br />Ejemplo:
          I will not be studying.
        </li>
        <li>
          <strong>Pregunta:</strong> Will + sujeto + be + verbo + -ing?<br />Ejemplo:
          Will you be studying?
        </li>
      </ul>

      <h2>3. Ejemplos de Uso</h2>

      <h3>Afirmaciones</h3>
      <table>
        <tr>
          <th>Ejemplo en inglés</th>
          <th>Traducción al español</th>
        </tr>
        <tr>
          <td>I will be <strong>working</strong> tomorrow.</td>
          <td>(Estaré <strong>trabajando</strong> mañana.)</td>
        </tr>
        <tr>
          <td>They will be <strong>traveling</strong> to Italy next week.</td>
          <td>
            (Ellos estarán <strong>viajando</strong> a Italia la próxima
            semana.)
          </td>
        </tr>
        <tr>
          <td>She will be <strong>attending</strong> the meeting.</td>
          <td>(Ella estará <strong>asistiendo</strong> a la reunión.)</td>
        </tr>
        <tr>
          <td>We will be <strong>playing</strong> soccer at 5 PM.</td>
          <td>(Estaremos <strong>jugando</strong> al fútbol a las 5 PM.)</td>
        </tr>
        <tr>
          <td>He will be <strong>cooking</strong> dinner when I arrive.</td>
          <td>
            (Él estará <strong>cocinando</strong> la cena cuando yo llegue.)
          </td>
        </tr>
      </table>

      <h3>Negaciones</h3>
      <table>
        <tr>
          <th>Ejemplo en inglés</th>
          <th>Traducción al español</th>
        </tr>
        <tr>
          <td>I will not be <strong>watching</strong> TV tonight.</td>
          <td>(No estaré <strong>viendo</strong> la televisión esta noche.)</td>
        </tr>
        <tr>
          <td>They will not be <strong>studying</strong> for the exam.</td>
          <td>(No estarán <strong>estudiando</strong> para el examen.)</td>
        </tr>
        <tr>
          <td>She will not be <strong>working</strong> on Sunday.</td>
          <td>(Ella no estará <strong>trabajando</strong> el domingo.)</td>
        </tr>
        <tr>
          <td>We will not be <strong>joining</strong> the party.</td>
          <td>(No estaremos <strong>uniéndonos</strong> a la fiesta.)</td>
        </tr>
        <tr>
          <td>He will not be <strong>driving</strong> to work tomorrow.</td>
          <td>
            (Él no estará <strong>conduciendo</strong> al trabajo mañana.)
          </td>
        </tr>
      </table>

      <h3>Preguntas</h3>
      <table>
        <tr>
          <th>Ejemplo en inglés</th>
          <th>Traducción al español</th>
        </tr>
        <tr>
          <td>Will you be <strong>coming</strong> to the party?</td>
          <td>(¿Vendrás a la fiesta?)</td>
        </tr>
        <tr>
          <td>Will they be <strong>meeting</strong> us at the airport?</td>
          <td>(¿Nos estarán <strong>encontrando</strong> en el aeropuerto?)</td>
        </tr>
        <tr>
          <td>Will she be <strong>attending</strong> the conference?</td>
          <td>(¿Ella estará <strong>asistiendo</strong> a la conferencia?)</td>
        </tr>
        <tr>
          <td>Will we be <strong>finishing</strong> the project on time?</td>
          <td>
            (¿Estaremos <strong>terminando</strong> el proyecto a tiempo?)
          </td>
        </tr>
        <tr>
          <td>Will he be <strong>studying</strong> all night?</td>
          <td>(¿Él estará <strong>estudiando</strong> toda la noche?)</td>
        </tr>
      </table>

      <h2>4. Notas Importantes</h2>
      <ul>
        <li>
          Se utiliza para acciones que estarán en progreso en un momento
          específico en el futuro.
        </li>
        <li>
          Se puede usar para hacer predicciones sobre lo que estará sucediendo
          en el futuro.
        </li>
      </ul>

      <h2>5. Ejemplos Adicionales en Contexto</h2>
      <ul>
        <li>
          <strong>Ejemplo 1:</strong> "At this time tomorrow, I will be flying
          to Paris." <br />(A esta hora mañana, estaré volando a París.)
        </li>
        <li>
          <strong>Ejemplo 2:</strong> "Next year, we will be living in a new
          city." <br />(El próximo año, estaremos viviendo en una nueva ciudad.)
        </li>
      </ul>

      <h2>6. Comparación con el Futuro Simple</h2>
      <table>
        <tr>
          <th>Criterio</th>
          <th>Futuro Simple</th>
          <th>Futuro Continuo</th>
        </tr>
        <tr>
          <td>Estructura</td>
          <td>will + base form</td>
          <td>will be + verbo + -ing</td>
        </tr>
        <tr>
          <td>Uso</td>
          <td>Acciones futuras sin especificidad</td>
          <td>Acciones en progreso en un momento específico</td>
        </tr>
        <tr>
          <td>Ejemplo</td>
          <td>I will study tomorrow.</td>
          <td>I will be studying at 10 AM.</td>
        </tr>
      </table>
    </div>
  </div>
</div>
