<div class="present-perfect-continuous">
  <p>
    El <strong>Present Perfect Continuous</strong>
    es un tiempo verbal en inglés que se usa para hablar de acciones o
    situaciones que comenzaron en el pasado y tienen relevancia en el presente.
    Puede describir algo que aún está en progreso o que recientemente ha
    finalizado, pero cuyos efectos se sienten en el momento actual.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Estructura</h2>
      <h3>Positivo</h3>
      <p><strong>Sujeto + has/have been + verbo en gerundio (-ing)</strong></p>
      <h3>Negativo</h3>
      <p>
        <strong>Sujeto + has/have not been + verbo en gerundio (-ing)</strong>
      </p>
      <h3>Interrogativo</h3>
      <p>
        <strong>Has/Have + sujeto + been + verbo en gerundio (-ing)?</strong>
      </p>
      <h2>2. Principales Usos</h2>
      <table>
        <thead>
          <tr>
            <th>Uso</th>
            <th>Descripción</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              A) Acciones que comenzaron en el pasado y continúan en el presente
            </td>
            <td>
              Se usa para describir actividades que se iniciaron antes y todavía
              están ocurriendo. Indica que la acción aún no se ha terminado.
            </td>
            <td>
              They have been living in this city for years. (Han estado viviendo
              en esta ciudad durante años).
            </td>
          </tr>
          <tr>
            <td>B) Acciones recientes con efectos en el presente</td>
            <td>
              Describe algo que acaba de suceder y que tiene consecuencias o
              efectos en el presente. Aunque la acción puede haber finalizado,
              sus resultados siguen presentes.
            </td>
            <td>
              I have been running, so I’m tired now. (He estado corriendo, así
              que estoy cansado ahora).
            </td>
          </tr>
          <tr>
            <td>C) Enfatizar la duración de la acción</td>
            <td>
              A veces, queremos destacar cuánto tiempo ha durado una actividad.
            </td>
            <td>
              We have been talking for hours. (Hemos estado hablando durante
              horas).
            </td>
          </tr>
        </tbody>
      </table>
      <h2>3. Palabras Clave</h2>
      <p>
        Hay palabras que comúnmente acompañan al
        <strong>Present Perfect Continuous</strong> para expresar duración y
        origen de la acción:
      </p>
      <ul>
        <li>
          <strong>For</strong>: indica duración (ej., for three hours, for a
          long time).
        </li>
        <li>
          <strong>Since</strong>: indica el momento en que comenzó la acción
          (ej., since 8 AM, since last year).
        </li>
        <li>
          <strong>Lately</strong> y <strong>Recently</strong>: señalan que la
          acción ha sido reciente.
        </li>
      </ul>
      <p><strong>Ejemplos:</strong></p>
      <ul>
        <li>
          I have been learning English for two years. (He estado aprendiendo
          inglés por dos años).
        </li>
        <li>
          She has been reading since this morning. (Ha estado leyendo desde esta
          mañana).
        </li>
      </ul>

      <h2>4. Diferencia con el Present Perfect Simple</h2>
      <p>
        El <strong>Present Perfect Simple</strong> y el
        <strong>Present Perfect Continuous</strong> pueden ser similares, pero
        tienen diferencias clave:
      </p>
      <ul>
        <li>
          <strong>Present Perfect Simple</strong>: Enfoca el
          <strong>resultado final</strong> o <strong>hecho</strong> de la
          acción. <br /><strong>Ejemplo:</strong> I have finished my homework.
          (He terminado mi tarea).
        </li>
        <li>
          <strong>Present Perfect Continuous</strong>: Enfoca el
          <strong>proceso</strong>, <strong>duración</strong> o
          <strong>actividad en curso</strong>. <br /><strong>Ejemplo:</strong> I
          have been doing my homework. (He estado haciendo mi tarea, y puede que
          aún no la haya terminado).
        </li>
      </ul>
      <p><strong>Comparación:</strong></p>
      <ul>
        <li>
          She has cleaned the house. (<strong>Present Perfect Simple</strong>):
          Destaca que la limpieza está completa.
        </li>
        <li>
          She has been cleaning the house. (<strong
            >Present Perfect Continuous</strong
          >): Resalta que la acción de limpiar ha durado un tiempo, y quizá aún
          no ha terminado.
        </li>
      </ul>

      <h2>5. Errores Comunes</h2>
      <p>
        Es fácil confundir estos tiempos verbales, así que aquí algunos puntos
        para recordar:
      </p>
      <ul>
        <li>
          <strong>Present Perfect Continuous</strong> describe el
          <strong>proceso o duración</strong> y se usa especialmente cuando la
          acción tiene efectos actuales.
        </li>
        <li>
          <strong>Present Perfect Simple</strong> enfatiza el hecho de que algo
          ya se ha completado y se centra en el <strong>resultado</strong>.
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h3>Ejemplos del Present Perfect Continuous</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo (Inglés)</th>
            <th>Traducción (Español)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>They have been studying</strong> for three hours.</td>
            <td>
              Ellos <strong>han estado estudiando</strong> durante tres horas.
            </td>
          </tr>
          <tr>
            <td><strong>She has not been feeling</strong> well lately.</td>
            <td>
              Ella <strong>no ha estado sintiéndose</strong> bien últimamente.
            </td>
          </tr>
          <tr>
            <td><strong>Have you been working</strong> on the project?</td>
            <td>¿<strong>Has estado trabajando</strong> en el proyecto?</td>
          </tr>
          <tr>
            <td>
              <strong>I have been learning</strong> English for two years.
            </td>
            <td>
              He <strong>estado aprendiendo</strong> inglés durante dos años.
            </td>
          </tr>
          <tr>
            <td><strong>He has not been exercising</strong> regularly.</td>
            <td>
              Él <strong>no ha estado haciendo</strong> ejercicio regularmente.
            </td>
          </tr>
          <tr>
            <td><strong>What have you been doing</strong> all day?</td>
            <td>¿<strong>Qué has estado haciendo</strong> todo el día?</td>
          </tr>
          <tr>
            <td>
              <strong>We have been waiting</strong> for the bus for over an
              hour.
            </td>
            <td>
              Hemos <strong>estado esperando</strong> el autobús durante más de
              una hora.
            </td>
          </tr>
          <tr>
            <td><strong>They have not been visiting</strong> us lately.</td>
            <td>No <strong>nos han estado visitando</strong> últimamente.</td>
          </tr>
          <tr>
            <td><strong>Has she been practicing</strong> for the concert?</td>
            <td>¿<strong>Ha estado practicando</strong> para el concierto?</td>
          </tr>
          <tr>
            <td><strong>I have been reading</strong> that book.</td>
            <td>He <strong>estado leyendo</strong> ese libro.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
