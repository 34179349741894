<div class="past-perfect-continuous">
  <div>
    <p>
      El <strong>past perfect continuous</strong> es un tiempo verbal que se
      utiliza para expresar una acción que comenzó en el pasado y continuó hasta
      un momento específico en el pasado, o que tuvo un efecto en el pasado
      antes de otra acción.
    </p>

    <h2>Estructura</h2>
    <p>La estructura del <strong>past perfect continuous</strong> es:</p>
    <p><strong>Sujeto + had been + verbo en gerundio (-ing)</strong></p>
  </div>
  <div class="section">
    <div class="section-blanco">
      <h2>Usos del Past Perfect Continuous</h2>
      <table>
        <thead>
          <tr>
            <th>Uso</th>
            <th>Descripción</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1. Duración de una Acción en Progreso Antes de Otro Evento</td>
            <td>
              Se utiliza para enfatizar que una acción estaba en curso durante
              un período de tiempo antes de que ocurriera otro evento pasado.
            </td>
            <td class="example">
              Ejemplo:
              <em
                >They had been traveling for hours before they finally reached
                their destination.</em
              >
            </td>
          </tr>
          <tr>
            <td>2. Causa de una Situación Pasada</td>
            <td>
              Se emplea para explicar la causa de una situación en el pasado,
              mostrando cómo una acción previa contribuyó a un estado o evento
              posterior.
            </td>
            <td class="example">
              Ejemplo:
              <em
                >She was upset because she had been waiting for a long time.</em
              >
            </td>
          </tr>
          <tr>
            <td>3. Acciones Repetidas en el Pasado</td>
            <td>
              Se usa para describir acciones que ocurrieron repetidamente en el
              pasado durante un período de tiempo antes de otro evento.
            </td>
            <td class="example">
              Ejemplo:
              <em
                >We had been meeting every week for months before the project
                was completed.</em
              >
            </td>
          </tr>
          <tr>
            <td>4. Eventos que Interrumpieron una Acción en Progreso</td>
            <td>
              Se utiliza para hablar de acciones que fueron interrumpidas por
              otro evento en el pasado.
            </td>
            <td class="example">
              Ejemplo: <em>I had been cooking when the power went out.</em>
            </td>
          </tr>
          <tr>
            <td>5. En Narraciones</td>
            <td>
              Es útil en narraciones para proporcionar contexto sobre lo que
              estaba sucediendo antes de un evento clave.
            </td>
            <td class="example">
              Ejemplo:
              <em
                >The children had been playing outside all day when it started
                to rain.</em
              >
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Ejemplos de Afirmaciones</h3>
      <table border="1" cellpadding="10">
        <thead>
          <tr>
            <th>Afirmación en Past Perfect Continuous</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              She <strong>had been working</strong> at the company for five
              years before she got promoted.
            </td>
            <td>
              Ella había estado trabajando en la empresa durante cinco años
              antes de ser promovida.
            </td>
          </tr>
          <tr>
            <td>
              They <strong>had been waiting</strong> for an hour when the bus
              finally arrived.
            </td>
            <td>
              Habían estado esperando durante una hora cuando finalmente llegó
              el autobús.
            </td>
          </tr>
          <tr>
            <td>
              We <strong>had been studying</strong> for weeks before the exam.
            </td>
            <td>
              Habíamos estado estudiando durante semanas antes del examen.
            </td>
          </tr>
          <tr>
            <td>
              I <strong>had been living</strong> in New York for two years
              before moving to London.
            </td>
            <td>
              Yo había estado viviendo en Nueva York durante dos años antes de
              mudarme a Londres.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Preguntas en Past Perfect Continuous</h2>
      <p>
        Para hacer preguntas en <em>past perfect continuous</em>, usamos la
        estructura:
      </p>
      <p><strong>Had + sujeto + been + verbo en gerundio (-ing)...?</strong></p>
      <p>
        Esta estructura permite preguntar si una acción había estado ocurriendo
        durante un período antes de otro evento pasado.
      </p>

      <h3>Ejemplos de Preguntas</h3>
      <table border="1" cellpadding="10">
        <thead>
          <tr>
            <th>Pregunta en Past Perfect Continuous</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Had</strong> you <strong>been working</strong> on the
              project before I joined?
            </td>
            <td>
              ¿Habías estado trabajando en el proyecto antes de que me uniera?
            </td>
          </tr>
          <tr>
            <td>
              <strong>Had</strong> they <strong>been waiting</strong> for a long
              time when the bus arrived?
            </td>
            <td>
              ¿Habían estado esperando mucho tiempo cuando llegó el autobús?
            </td>
          </tr>
          <tr>
            <td>
              <strong>Had</strong> she <strong>been living</strong> there before
              she moved to another city?
            </td>
            <td>¿Había estado viviendo allí antes de mudarse a otra ciudad?</td>
          </tr>
          <tr>
            <td>
              <strong>Had</strong> we <strong>been studying</strong> together
              before the test?
            </td>
            <td>¿Habíamos estado estudiando juntos antes del examen?</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Negaciones en Past Perfect Continuous</h2>
      <p>
        Para negar en <em>past perfect continuous</em>, usamos la estructura:
      </p>
      <p>
        <strong
          >Sujeto + had not (hadn’t) + been + verbo en gerundio (-ing)</strong
        >
      </p>
      <p>
        Esto indica que una acción no había estado ocurriendo durante un período
        antes de otro evento pasado.
      </p>

      <h3>Ejemplos de Negaciones</h3>
      <table border="1" cellpadding="10">
        <thead>
          <tr>
            <th>Negación en Past Perfect Continuous</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              She <strong>hadn't been working</strong> there for very long when
              she got a promotion.
            </td>
            <td>
              No había estado trabajando allí por mucho tiempo cuando recibió un
              ascenso.
            </td>
          </tr>
          <tr>
            <td>
              They <strong>had not been waiting</strong> for us when we arrived.
            </td>
            <td>No nos habían estado esperando cuando llegamos.</td>
          </tr>
          <tr>
            <td>
              We <strong>hadn't been living</strong> in that city for a year
              before we moved.
            </td>
            <td>
              No habíamos estado viviendo en esa ciudad por un año antes de
              mudarnos.
            </td>
          </tr>
          <tr>
            <td>
              I <strong>had not been studying</strong> very much before the
              exam.
            </td>
            <td>No había estado estudiando mucho antes del examen.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
