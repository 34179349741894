import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-ever-whatever-wherever-whenever',
  standalone: true,
  imports: [],
  templateUrl: './ever-whatever-wherever-whenever.component.html',
  styleUrl: './ever-whatever-wherever-whenever.component.scss',
})
export class EverWhateverWhereverWheneverComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Ever suffix';
  override title = 'Ever suffix use with How, Where, When, Who and What';
}
