import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-say-vs-tell',
  standalone: true,
  imports: [],
  templateUrl: './say-vs-tell.component.html',
  styleUrl: './say-vs-tell.component.scss',
})
export class SayVsTellComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Say vs. Tell';
  override title = 'Say vs. Tell';
}
