<article class="card card--{{ leccionesService.nivel$ | async }}">
  <mat-card
    class="card-tema card-tema--{{ leccionesService.nivel$ | async }}"
    appearance="outlined"
    *ngFor="let tema of temasNivel"
  >
    <a mat-button [attr.href]="tema.path">
      <header>
        <h2 class="card-title">
          {{ tema.descripcionMenu }}
        </h2>
        <h3 class="card-detalle">
          {{ leccionesService.getDetalle(tema.descripcionMenu)?.detalle }}
        </h3>
      </header>
    </a>
  </mat-card>
</article>
