<div class="usedTo">
  <p>
    "<strong>Used to</strong>" se utiliza para hablar sobre hábitos o
    situaciones que eran verdaderos en el pasado, pero que ya no lo son. También
    se utiliza para hablar de acciones que solían ocurrir regularmente en el
    pasado.
  </p>

  <h2>Estructura</h2>
  <p>La estructura básica es la siguiente:</p>
  <ul>
    <li>
      Para afirmaciones:
      <strong>Subject + used to + base form of the verb</strong>.
    </li>
    <li>
      Para negaciones:
      <strong>Subject + did not use to + base form of the verb</strong>.
    </li>
    <li>
      Para preguntas:
      <strong>Did + subject + use to + base form of the verb?</strong>
    </li>
  </ul>

  <h2>Ejemplos</h2>
  <h3>Afirmaciones</h3>
  <p><strong>I used to play soccer.</strong> (Solía jugar al fútbol.)</p>
  <p>
    <strong>She used to live in Paris.</strong> (Ella solía vivir en París.)
  </p>
  <p>
    <strong>They used to go to the beach every summer.</strong> (Ellos solían ir
    a la playa cada verano.)
  </p>

  <h3>Negaciones</h3>
  <p>
    <strong>I did not use to like vegetables.</strong> (No solía gustarme las
    verduras.)
  </p>
  <p>
    <strong>He did not use to study English.</strong> (Él no solía estudiar
    inglés.)
  </p>
  <p>
    <strong>We did not use to travel much.</strong> (No solíamos viajar mucho.)
  </p>

  <h3>Preguntas</h3>
  <p>
    <strong>Did you use to play an instrument?</strong> (¿Solías tocar un
    instrumento?)
  </p>
  <p><strong>Did she use to work here?</strong> (¿Ella solía trabajar aquí?)</p>
  <p>
    <strong>Did they use to have a pet?</strong> (¿Ellos solían tener una
    mascota?)
  </p>

  <h2>Notas Importantes</h2>
  <p>- "Used to" solo se utiliza para hablar del pasado.</p>
  <p>
    - La forma negativa "did not use to" se usa en lugar de "did not used to".
  </p>

  <h2>Ejemplos Adicionales</h2>
  <p>
    <strong>My grandfather used to tell me stories.</strong> (Mi abuelo solía
    contarme historias.)
  </p>
  <p>
    <strong>We used to meet every Friday.</strong> (Solíamos encontrarnos cada
    viernes.)
  </p>
  <p>
    <strong>She did not use to eat meat.</strong> (Ella no solía comer carne.)
  </p>
</div>
