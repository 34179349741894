import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-numeros',
  templateUrl: './numeros.component.html',
  styleUrl: './numeros.component.scss',
})
export class NumerosComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Numeros';
  override title = 'Números en Inglés y su pronunciación';
}
