import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-present-perfect-continuous',
  standalone: true,
  imports: [],
  templateUrl: './present-perfect-continuous.component.html',
  styleUrl: './present-perfect-continuous.component.scss',
})
export class PresentPerfectContinuousComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Present perfect continuous';
  override title = 'Present Perfect Continuous';
}
