import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-preposiciones-de-lugar',
  templateUrl: './preposiciones-de-lugar.component.html',
  styleUrl: './preposiciones-de-lugar.component.scss',
})
export class PreposicionesDeLugarComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Preposiciones de lugar';
  override title = 'Preposiciones de lugar: in, on, at';
}
