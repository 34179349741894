<div class="futureSimple">
  <p>
    El <strong>futuro simple</strong> es un tiempo verbal en inglés que se
    utiliza para hablar sobre acciones o eventos que ocurrirán en el futuro. Se
    forma utilizando el auxiliar "<strong>will</strong>" seguido del verbo en su
    forma base.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Estructura del Futuro Simple</h2>
      <p>La estructura básica del futuro simple es la siguiente:</p>
      <ul>
        <li>
          Para afirmaciones:
          <strong>Subject + will + base form of the verb</strong>.
        </li>
        <li>
          Para negaciones:
          <strong>Subject + will not (won't) + base form of the verb</strong>.
        </li>
        <li>
          Para preguntas:
          <strong>Will + subject + base form of the verb?</strong>.
        </li>
      </ul>

      <h2>Ejemplos de Futuro Simple</h2>

      <h3>Afirmaciones</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I will visit my grandparents.</td>
            <td>(Visitaré a mis abuelos.)</td>
          </tr>
          <tr>
            <td>She will start her new job next week.</td>
            <td>(Ella comenzará su nuevo trabajo la próxima semana.)</td>
          </tr>
          <tr>
            <td>They will travel to Spain in the summer.</td>
            <td>(Ellos viajarán a España en el verano.)</td>
          </tr>
          <tr>
            <td>We will have dinner at 7 PM.</td>
            <td>(Cenaremos a las 7 PM.)</td>
          </tr>
          <tr>
            <td>He will study for the exam.</td>
            <td>(Él estudiará para el examen.)</td>
          </tr>
          <tr>
            <td>It will rain tomorrow.</td>
            <td>(Lloverá mañana.)</td>
          </tr>
          <tr>
            <td>I will call you later.</td>
            <td>(Te llamaré más tarde.)</td>
          </tr>
          <tr>
            <td>She will buy a new car.</td>
            <td>(Ella comprará un coche nuevo.)</td>
          </tr>
        </tbody>
      </table>

      <h3>Negaciones</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I will not (won't) eat dessert.</td>
            <td>(No comeré postre.)</td>
          </tr>
          <tr>
            <td>He will not (won't) attend the meeting.</td>
            <td>(Él no asistirá a la reunión.)</td>
          </tr>
          <tr>
            <td>We will not (won't) go to the party.</td>
            <td>(No iremos a la fiesta.)</td>
          </tr>
          <tr>
            <td>They will not (won't) finish the project on time.</td>
            <td>(No terminarán el proyecto a tiempo.)</td>
          </tr>
          <tr>
            <td>She will not (won't) play soccer this weekend.</td>
            <td>(Ella no jugará al fútbol este fin de semana.)</td>
          </tr>
          <tr>
            <td>It will not (won't) be cold tomorrow.</td>
            <td>(No hará frío mañana.)</td>
          </tr>
          <tr>
            <td>I will not (won't) forget your birthday.</td>
            <td>(No olvidaré tu cumpleaños.)</td>
          </tr>
          <tr>
            <td>He will not (won't) join us for lunch.</td>
            <td>(Él no se unirá a nosotros para el almuerzo.)</td>
          </tr>
        </tbody>
      </table>

      <h3>Preguntas</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Will you go to the concert?</td>
            <td>(¿Irás al concierto?)</td>
          </tr>
          <tr>
            <td>Will she finish her homework?</td>
            <td>(¿Ella terminará su tarea?)</td>
          </tr>
          <tr>
            <td>Will they come to the meeting?</td>
            <td>(¿Ellos vendrán a la reunión?)</td>
          </tr>
          <tr>
            <td>Will we see you tomorrow?</td>
            <td>(¿Te veremos mañana?)</td>
          </tr>
          <tr>
            <td>Will he help us with the project?</td>
            <td>(¿Él nos ayudará con el proyecto?)</td>
          </tr>
          <tr>
            <td>Will it snow this weekend?</td>
            <td>(¿Nevará este fin de semana?)</td>
          </tr>
          <tr>
            <td>Will you join us for dinner?</td>
            <td>(¿Te unirás a nosotros para cenar?)</td>
          </tr>
          <tr>
            <td>Will she travel to New York next year?</td>
            <td>(¿Ella viajará a Nueva York el próximo año?)</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <p>
        La estructura "<strong>going to</strong>" se utiliza para expresar
        intenciones o planes futuros, así como predicciones basadas en evidencia
        actual. Se forma usando la estructura
        <strong>am/is/are + going to + base form of the verb</strong>.
      </p>

      <h2>Estructura de "Going to"</h2>
      <p>La estructura básica es la siguiente:</p>
      <ul>
        <li>
          Para afirmaciones:
          <strong>Subject + am/is/are + going to + base form of the verb</strong
          >.
        </li>
        <li>
          Para negaciones:
          <strong
            >Subject + am/is/are not + going to + base form of the verb</strong
          >.
        </li>
        <li>
          Para preguntas:
          <strong
            >Am/Is/Are + subject + going to + base form of the verb?</strong
          >.
        </li>
      </ul>

      <h2>Ejemplos de "Going to"</h2>

      <h3>Ejemplos de Afirmaciones</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I am going to visit my grandparents.</td>
            <td>(Voy a visitar a mis abuelos.)</td>
          </tr>
          <tr>
            <td>She is going to start her new job next week.</td>
            <td>(Ella va a comenzar su nuevo trabajo la próxima semana.)</td>
          </tr>
          <tr>
            <td>They are going to travel to Spain in the summer.</td>
            <td>(Ellos van a viajar a España en el verano.)</td>
          </tr>
          <tr>
            <td>We are going to have dinner at 7 PM.</td>
            <td>(Vamos a cenar a las 7 PM.)</td>
          </tr>
          <tr>
            <td>He is going to study for the exam.</td>
            <td>(Él va a estudiar para el examen.)</td>
          </tr>
          <tr>
            <td>It is going to rain tomorrow.</td>
            <td>(Va a llover mañana.)</td>
          </tr>
          <tr>
            <td>I am going to call you later.</td>
            <td>(Voy a llamarte más tarde.)</td>
          </tr>
          <tr>
            <td>She is going to buy a new car.</td>
            <td>(Ella va a comprar un coche nuevo.)</td>
          </tr>
        </tbody>
      </table>

      <h3>Ejemplos de Negaciones</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I am not going to eat dessert.</td>
            <td>(No voy a comer postre.)</td>
          </tr>
          <tr>
            <td>He is not going to attend the meeting.</td>
            <td>(Él no va a asistir a la reunión.)</td>
          </tr>
          <tr>
            <td>We are not going to go to the party.</td>
            <td>(No vamos a ir a la fiesta.)</td>
          </tr>
          <tr>
            <td>They are not going to finish the project on time.</td>
            <td>(No van a terminar el proyecto a tiempo.)</td>
          </tr>
          <tr>
            <td>She is not going to play soccer this weekend.</td>
            <td>(Ella no va a jugar al fútbol este fin de semana.)</td>
          </tr>
          <tr>
            <td>It is not going to be cold tomorrow.</td>
            <td>(No va a hacer frío mañana.)</td>
          </tr>
          <tr>
            <td>I am not going to forget your birthday.</td>
            <td>(No voy a olvidar tu cumpleaños.)</td>
          </tr>
          <tr>
            <td>He is not going to join us for lunch.</td>
            <td>(Él no se va a unir a nosotros para el almuerzo.)</td>
          </tr>
        </tbody>
      </table>

      <h3>Ejemplos de Preguntas</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Are you going to go to the concert?</td>
            <td>(¿Vas a ir al concierto?)</td>
          </tr>
          <tr>
            <td>Is she going to finish her homework?</td>
            <td>(¿Ella va a terminar su tarea?)</td>
          </tr>
          <tr>
            <td>Are they going to come to the meeting?</td>
            <td>(¿Ellos van a venir a la reunión?)</td>
          </tr>
          <tr>
            <td>Are we going to see you tomorrow?</td>
            <td>(¿Vamos a verte mañana?)</td>
          </tr>
          <tr>
            <td>Is he going to help us with the project?</td>
            <td>(¿Él va a ayudarnos con el proyecto?)</td>
          </tr>
          <tr>
            <td>Is it going to snow this weekend?</td>
            <td>(¿Va a nevar este fin de semana?)</td>
          </tr>
          <tr>
            <td>Are you going to join us for dinner?</td>
            <td>(¿Vas a unirte a nosotros para cenar?)</td>
          </tr>
          <tr>
            <td>Is she going to travel to New York next year?</td>
            <td>(¿Ella va a viajar a Nueva York el próximo año?)</td>
          </tr>
        </tbody>
      </table>

      <h2>Notas Importantes sobre "Going to"</h2>
      <p>
        - Se utiliza "going to" para planes o intenciones que ya se han tomado
        antes del momento de hablar.
      </p>
      <p>
        - También se usa para hacer predicciones basadas en evidencia actual
        (por ejemplo, "Look at those dark clouds; it is going to rain.").
      </p>

      <h2>Comparación entre "Will" y "Going to"</h2>
      <table>
        <thead>
          <tr>
            <th>Criterio</th>
            <th>Will</th>
            <th>Going to</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Uso</td>
            <td>
              Decisiones espontáneas, promesas, predicciones sin evidencia.
            </td>
            <td>
              Planes e intenciones, predicciones basadas en evidencia actual.
            </td>
          </tr>
          <tr>
            <td>Estructura</td>
            <td>will + base form</td>
            <td>am/is/are going to + base form</td>
          </tr>
          <tr>
            <td>Ejemplo</td>
            <td>I will call you later.</td>
            <td>I am going to call you later.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
