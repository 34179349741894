<div class="relativePronous">
  <p>
    El pronombre relativo es una palabra que conecta una oración principal con
    una cláusula relativa. La cláusula relativa da más información sobre un
    sustantivo o pronombre en la oración principal, y suele usarse para
    describir, identificar o dar contexto adicional.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Los pronombres relativos en inglés y su uso</h2>

      <h3>A. Pronombres relativos básicos</h3>

      <table>
        <tr>
          <th>Pronombre Relativo</th>
          <th>Uso</th>
          <th>Ejemplo</th>
        </tr>
        <tr>
          <td><span>Who</span> (<strong>quien</strong>)</td>
          <td>Se usa para referirse a personas.</td>
          <td>
            The teacher <span>who</span> helped me was very kind.<br /><em
              >La profesora <strong>quien</strong> me ayudó fue muy amable.</em
            >
          </td>
        </tr>
        <tr>
          <td><span>Whom</span> (<strong>a quien</strong>)</td>
          <td>
            Se usa para referirse a personas como objeto, en contextos formales.
          </td>
          <td>
            The friend <span>whom</span> I met yesterday is from Canada.<br /><em
              >La amiga <strong>a quien</strong> conocí ayer es de Canadá.</em
            >
          </td>
        </tr>
        <tr>
          <td><span>Whose</span> (<strong>cuyo/a</strong>)</td>
          <td>Expresa posesión y puede referirse a personas o cosas.</td>
          <td>
            The artist <span>whose</span> paintings are famous lives nearby.<br /><em
              >El artista <strong>cuyas</strong> pinturas son famosas vive
              cerca.</em
            >
          </td>
        </tr>
        <tr>
          <td><span>Which</span> (<strong>el cual, que</strong>)</td>
          <td>Se usa para referirse a animales o cosas.</td>
          <td>
            The book <span>which</span> you gave me is interesting.<br /><em
              >El libro <strong>que</strong> me diste es interesante.</em
            >
          </td>
        </tr>
        <tr>
          <td><span>That</span> (<strong>que</strong>)</td>
          <td>
            Se usa para personas, animales o cosas en cláusulas restrictivas.
          </td>
          <td>
            The house <span>that</span> I want to buy is in the city center.<br /><em
              >La casa <strong>que</strong> quiero comprar está en el
              centro.</em
            >
          </td>
        </tr>
      </table>

      <h3>B. Pronombres relativos adicionales</h3>

      <table>
        <tr>
          <th>Pronombre Relativo</th>
          <th>Uso</th>
          <th>Ejemplo</th>
        </tr>
        <tr>
          <td><span>Where</span> (<strong>donde</strong>)</td>
          <td>Indica un lugar.</td>
          <td>
            This is the town <span>where</span> I grew up.<br /><em
              >Este es el pueblo <strong>donde</strong> crecí.</em
            >
          </td>
        </tr>
        <tr>
          <td><span>When</span> (<strong>cuando</strong>)</td>
          <td>Indica un momento en el tiempo.</td>
          <td>
            I'll never forget the day <span>when</span> we met.<br /><em
              >Nunca olvidaré el día <strong>cuando</strong> nos conocimos.</em
            >
          </td>
        </tr>
        <tr>
          <td><span>Why</span> (<strong>por qué</strong>)</td>
          <td>
            Indica la razón o motivo, generalmente después de "the reason".
          </td>
          <td>
            That’s the reason <span>why</span> I left.<br /><em
              >Esa es la razón <strong>por la cual</strong> me fui.</em
            >
          </td>
        </tr>
      </table>

      <h3>C. Pronombres relativos compuestos</h3>

      <table>
        <tr>
          <th>Pronombre Relativo</th>
          <th>Uso</th>
          <th>Ejemplo</th>
        </tr>
        <tr>
          <td><span>What</span> (<strong>lo que</strong>)</td>
          <td>Significa "la cosa que" o "lo que".</td>
          <td>
            I didn’t understand <span>what</span> he said.<br /><em
              >No entendí <strong>lo que</strong> dijo.</em
            >
          </td>
        </tr>
        <tr>
          <td>
            <span>Whoever</span>
            (<strong>quienquiera</strong>)
          </td>
          <td>Significa "cualquiera que" o "quien sea que".</td>
          <td>
            <span>Whoever</span> wins the race will get a prize.<br /><em
              ><strong>Quienquiera</strong> que gane la carrera recibirá un
              premio.</em
            >
          </td>
        </tr>
        <tr>
          <td>
            <span>Whichever</span>
            (<strong>cualquiera</strong>)
          </td>
          <td>Indica una elección o alternativa.</td>
          <td>
            Take <span>whichever</span> path you find interesting.<br /><em
              >Toma <strong>cualquiera</strong> camino que encuentres
              interesante.</em
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
