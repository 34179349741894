<div class="conditional-sentences">
  <p>
    Los condicionales en inglés se utilizan para expresar situaciones que
    dependen de una condición. Hay cuatro tipos principales de condicionales:
    cero, primero, segundo y tercero. Cada uno tiene su propia estructura y se
    utiliza en diferentes contextos.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Estructura de los Condicionales</h2>
      <table>
        <thead>
          <tr>
            <th>Tipo de Condicional</th>
            <th>Estructura</th>
            <th>Uso</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cero</td>
            <td>If + presente simple, presente simple</td>
            <td>Se utiliza para hechos generales o verdades universales.</td>
          </tr>
          <tr>
            <td>Primero</td>
            <td>If + presente simple, futuro simple (will)</td>
            <td>Se utiliza para situaciones reales o posibles en el futuro.</td>
          </tr>
          <tr>
            <td>Segundo</td>
            <td>If + pasado simple, would + base form</td>
            <td>
              Se utiliza para situaciones hipotéticas o improbables en el
              presente o futuro.
            </td>
          </tr>
          <tr>
            <td>Tercero</td>
            <td>If + pasado perfecto, would have + participio pasado</td>
            <td>
              Se utiliza para situaciones hipotéticas en el pasado que no
              sucedieron.
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Ejemplos</h3>
      <table>
        <thead>
          <tr>
            <th>Oración en Inglés</th>
            <th>Traducción al Español</th>
          </tr>
        </thead>
        <tbody>
          <!-- Cero Condicional -->
          <tr>
            <td>
              If you heat water to 100 degrees, <strong>it boils</strong>.
            </td>
            <td>Si calientas agua a 100 grados, <strong>hierve</strong>.</td>
          </tr>
          <tr>
            <td>If it rains, <strong>the ground gets wet</strong>.</td>
            <td>Si llueve, <strong>el suelo se moja</strong>.</td>
          </tr>

          <!-- Primer Condicional -->
          <tr>
            <td>If it rains tomorrow, <strong>I will stay home</strong>.</td>
            <td>Si llueve mañana, <strong>me quedaré en casa</strong>.</td>
          </tr>
          <tr>
            <td>If you study hard, <strong>you will pass the exam</strong>.</td>
            <td>Si estudias duro, <strong>aprobarás el examen</strong>.</td>
          </tr>

          <!-- Segundo Condicional -->
          <tr>
            <td>
              If I had a million dollars,
              <strong>I would travel the world</strong>.
            </td>
            <td>
              Si tuviera un millón de dólares,
              <strong>viajaría por el mundo</strong>.
            </td>
          </tr>
          <tr>
            <td>If she were here, <strong>she would help us</strong>.</td>
            <td>Si ella estuviera aquí, <strong>nos ayudaría</strong>.</td>
          </tr>

          <!-- Tercero Condicional -->
          <tr>
            <td>
              If I had known about the party,
              <strong>I would have gone</strong>.
            </td>
            <td>
              Si hubiera sabido de la fiesta, <strong>habría ido</strong>.
            </td>
          </tr>
          <tr>
            <td>
              If they had left earlier,
              <strong>they would have caught the train</strong>.
            </td>
            <td>
              Si hubieran salido más temprano,
              <strong>habrían atrapado el tren</strong>.
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Usos</h2>
      <table>
        <thead>
          <tr>
            <th>Uso</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción al Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Para expresar hechos generales.</strong></td>
            <td>If the sun sets, <strong>it gets dark</strong>.</td>
            <td>Si el sol se pone, <strong>se oscurece</strong>.</td>
          </tr>
          <tr>
            <td>
              <strong
                >Para indicar situaciones reales o posibles en el
                futuro.</strong
              >
            </td>
            <td>If it rains, <strong>we will cancel the picnic</strong>.</td>
            <td>Si llueve, <strong>cancelaremos el picnic</strong>.</td>
          </tr>
          <tr>
            <td><strong>Para situaciones hipotéticas.</strong></td>
            <td>If I were you, <strong>I would take the job</strong>.</td>
            <td>Si fuera tú, <strong>aceptaría el trabajo</strong>.</td>
          </tr>
          <tr>
            <td>
              <strong
                >Para hablar de situaciones pasadas que no sucedieron.</strong
              >
            </td>
            <td>
              If they had known,
              <strong>they would have acted differently</strong>.
            </td>
            <td>
              Si hubieran sabido,
              <strong>habrían actuado de manera diferente</strong>.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
