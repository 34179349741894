import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-cuantificadores',
  standalone: true,
  imports: [],
  templateUrl: './cuantificadores.component.html',
  styleUrl: './cuantificadores.component.scss',
})
export class CuantificadoresComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Cuantificadores';
  override title = 'Cuantificadores - “Quantifier”';
}
