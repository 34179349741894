<div class="superlative">
  <p><strong>¿Qué es un superlativo?</strong></p>
  <p>
    En inglés, un superlativo se utiliza para expresar el grado más alto o
    extremo de una cualidad en comparación con un grupo. Este adjetivo o
    adverbio indica que algo es "el más" o "el menos" de todos. Para formar
    superlativos en inglés, a menudo añadimos "-est" a adjetivos cortos o usamos
    "most" con adjetivos más largos.
  </p>

  <div class="section">
    <div class="section-blanco">
      <h2>Reglas para formar superlativos</h2>
      <ol>
        <li>
          <strong>Adjetivos cortos</strong>: Si el adjetivo tiene una sílaba, se
          le añade "-est". Si tiene dos sílabas y termina en "y", se reemplaza
          la "y" por "i" y se le añade "-est".

          <ul>
            <li>
              Ejemplos:
              <ul>
                <li>
                  <em>Tall</em> → <em>Tallest</em> (El/la
                  <strong>más alto/a</strong>)
                </li>
                <li>
                  <em>Happy</em> → <em>Happiest</em> (El/la
                  <strong>más feliz</strong>)
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Adjetivos largos</strong>: Para adjetivos de dos sílabas o
          más, se usa "most" antes del adjetivo.
          <ul>
            <li>
              Ejemplos:
              <ul>
                <li>
                  <em>Beautiful</em> → <em>Most beautiful</em> (El/la
                  <strong>más hermoso/a</strong>)
                </li>
                <li>
                  <em>Expensive</em> → <em>Most expensive</em> (El/la
                  <strong>más caro/a</strong>)
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <strong>Superlativos irregulares</strong>: Algunos adjetivos no siguen
          las reglas generales y cambian completamente de forma en su versión
          superlativa.
          <ul>
            <li>
              Ejemplos:
              <ul>
                <li>
                  <em>Good</em> → <em>Best</em> (El/la <strong>mejor</strong>)
                </li>
                <li>
                  <em>Bad</em> → <em>Worst</em> (El/la <strong>peor</strong>)
                </li>
                <li>
                  <em>Far</em> → <em>Farthest</em> (El/la
                  <strong>más lejos</strong>)
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>

      <h2>Ejemplos en Oraciones</h2>
      <ul>
        <li>
          Adjetivo corto: "Mount Everest is the <em>tallest</em> mountain in the
          world." (El Monte Everest es la montaña <strong>más alta</strong> del
          mundo.)
        </li>
        <li>
          Adjetivo largo: "This movie is the <em>most exciting</em> I've ever
          seen." (Esta película es la <strong>más emocionante</strong> que he
          visto.)
        </li>
        <li>
          Superlativo irregular: "He is the <em>best</em> player on the team."
          (Él es el <strong>mejor</strong> jugador del equipo.)
        </li>
      </ul>

      <h2>Resumen de Ejemplos Comunes</h2>
      <table>
        <thead>
          <tr>
            <th>Adjetivo</th>
            <th>Superlativo</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>small</td>
            <td><strong>smallest</strong></td>
            <td>
              This is the <strong>smallest</strong> room in the house. (Esta es
              la habitación <strong>más pequeña</strong> de la casa.)
            </td>
          </tr>
          <tr>
            <td>tall</td>
            <td><strong>tallest</strong></td>
            <td>
              He is the <strong>tallest</strong> student in the class. (Él es el
              estudiante <strong>más alto</strong> de la clase.)
            </td>
          </tr>
          <tr>
            <td>bright</td>
            <td><strong>brightest</strong></td>
            <td>
              This lamp is the <strong>brightest</strong> in the store. (Esta
              lámpara es la <strong>más brillante</strong> de la tienda.)
            </td>
          </tr>
          <tr>
            <td>easy</td>
            <td><strong>easiest</strong></td>
            <td>
              This is the <strong>easiest</strong> problem to solve. (Este es el
              problema <strong>más fácil</strong> de resolver.)
            </td>
          </tr>
          <tr>
            <td>funny</td>
            <td><strong>funniest</strong></td>
            <td>
              He is the <strong>funniest</strong> person I know. (Él es la
              persona <strong>más divertida</strong> que conozco.)
            </td>
          </tr>
          <tr>
            <td>long</td>
            <td><strong>longest</strong></td>
            <td>
              This is the <strong>longest</strong> river in the country. (Este
              es el río <strong>más largo</strong> del país.)
            </td>
          </tr>
          <tr>
            <td>interesting</td>
            <td><strong>most interesting</strong></td>
            <td>
              This is the <strong>most interesting</strong> book I've read.
              (Este es el libro <strong>más interesante</strong> que he leído.)
            </td>
          </tr>
          <tr>
            <td>comfortable</td>
            <td><strong>most comfortable</strong></td>
            <td>
              This chair is the <strong>most comfortable</strong> one. (Esta
              silla es la <strong>más cómoda</strong>.)
            </td>
          </tr>
          <tr>
            <td>expensive</td>
            <td><strong>most expensive</strong></td>
            <td>
              This car is the <strong>most expensive</strong> in the showroom.
              (Este auto es el <strong>más caro</strong> del salón de
              exhibición.)
            </td>
          </tr>
          <tr>
            <td>beautiful</td>
            <td><strong>most beautiful</strong></td>
            <td>
              She is the <strong>most beautiful</strong> person I know. (Ella es
              la persona <strong>más hermosa</strong> que conozco.)
            </td>
          </tr>
          <tr>
            <td>good</td>
            <td><strong>best</strong></td>
            <td>
              This is the <strong>best</strong> solution. (Esta es la
              <strong>mejor</strong> solución.)
            </td>
          </tr>
          <tr>
            <td>bad</td>
            <td><strong>worst</strong></td>
            <td>
              Today was the <strong>worst</strong> day. (Hoy fue el
              <strong>peor</strong> día.)
            </td>
          </tr>
          <tr>
            <td>far</td>
            <td><strong>farthest/furthest</strong></td>
            <td>
              He traveled the <strong>farthest</strong> distance. (Él viajó la
              <strong>mayor</strong> distancia.)
            </td>
          </tr>
          <tr>
            <td>little</td>
            <td><strong>least</strong></td>
            <td>
              She has the <strong>least</strong> patience. (Ella tiene la
              <strong>menor</strong> paciencia.)
            </td>
          </tr>
          <tr>
            <td>much</td>
            <td><strong>most</strong></td>
            <td>
              This requires the <strong>most</strong> effort. (Esto requiere el
              <strong>mayor</strong> esfuerzo.)
            </td>
          </tr>
          <tr>
            <td>many</td>
            <td><strong>most</strong></td>
            <td>
              She has the <strong>most</strong> books. (Ella tiene la
              <strong>mayor</strong> cantidad de libros.)
            </td>
          </tr>
          <tr>
            <td>old</td>
            <td><strong>oldest/eldest</strong></td>
            <td>
              He is the <strong>oldest</strong> in the family. (Él es el
              <strong>mayor</strong> de la familia.)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2><strong>Ejemplos de Comparativos y Superlativos</strong></h2>

      <table border="1">
        <thead>
          <tr>
            <th>Adjetivo</th>
            <th>Comparativo</th>
            <th>Superlativo</th>
            <th>Ejemplo Comparativo</th>
            <th>Ejemplo Superlativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>small</td>
            <td>smaller</td>
            <td>smallest</td>
            <td>
              This room is <strong>smaller</strong> than the other. (Esta
              habitación es <strong>más pequeña</strong> que la otra.)
            </td>
            <td>
              This is the <strong>smallest</strong> room in the house. (Esta es
              la habitación <strong>más pequeña</strong> de la casa.)
            </td>
          </tr>
          <tr>
            <td>tall</td>
            <td>taller</td>
            <td>tallest</td>
            <td>
              She is <strong>taller</strong> than her sister. (Ella es
              <strong>más alta</strong> que su hermana.)
            </td>
            <td>
              She is the <strong>tallest</strong> in the class. (Ella es la
              <strong>más alta</strong> de la clase.)
            </td>
          </tr>
          <tr>
            <td>bright</td>
            <td>brighter</td>
            <td>brightest</td>
            <td>
              This lamp is <strong>brighter</strong> than that one. (Esta
              lámpara es <strong>más brillante</strong> que esa.)
            </td>
            <td>
              This lamp is the <strong>brightest</strong> in the room. (Esta
              lámpara es la <strong>más brillante</strong> de la habitación.)
            </td>
          </tr>
          <tr>
            <td>easy</td>
            <td>easier</td>
            <td>easiest</td>
            <td>
              This task is <strong>easier</strong> than the previous one. (Esta
              tarea es <strong>más fácil</strong> que la anterior.)
            </td>
            <td>
              This is the <strong>easiest</strong> task. (Esta es la tarea
              <strong>más fácil</strong>.)
            </td>
          </tr>
          <tr>
            <td>interesting</td>
            <td>more interesting</td>
            <td>most interesting</td>
            <td>
              This book is <strong>more interesting</strong> than the other.
              (Este libro es <strong>más interesante</strong> que el otro.)
            </td>
            <td>
              This is the <strong>most interesting</strong> book I've read.
              (Este es el libro <strong>más interesante</strong> que he leído.)
            </td>
          </tr>
          <tr>
            <td>comfortable</td>
            <td>more comfortable</td>
            <td>most comfortable</td>
            <td>
              This chair is <strong>more comfortable</strong> than that one.
              (Esta silla es <strong>más cómoda</strong> que esa.)
            </td>
            <td>
              This is the <strong>most comfortable</strong> chair. (Esta es la
              silla <strong>más cómoda</strong>.)
            </td>
          </tr>
          <tr>
            <td>expensive</td>
            <td>more expensive</td>
            <td>most expensive</td>
            <td>
              This car is <strong>more expensive</strong> than that one. (Este
              auto es <strong>más caro</strong> que ese.)
            </td>
            <td>
              This car is the <strong>most expensive</strong> in the shop. (Este
              auto es el <strong>más caro</strong> de la tienda.)
            </td>
          </tr>
          <tr>
            <td>beautiful</td>
            <td>more beautiful</td>
            <td>most beautiful</td>
            <td>
              This flower is <strong>more beautiful</strong> than the other.
              (Esta flor es <strong>más hermosa</strong> que la otra.)
            </td>
            <td>
              This is the <strong>most beautiful</strong> flower in the garden.
              (Esta es la flor <strong>más hermosa</strong> del jardín.)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
