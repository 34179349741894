import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-verbo-to-do',
  standalone: true,
  imports: [],
  templateUrl: './verbo-to-do.component.html',
  styleUrl: './verbo-to-do.component.scss',
})
export class VerboToDoComponent extends LeccionBaseComponent {
  override descripcionMenu = 'To Do';
  override title = 'Hacer -  “To Do”';
}
