import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-preposiciones-de-estado',
  standalone: true,
  imports: [],
  templateUrl: './preposiciones-de-estado.component.html',
  styleUrl: './preposiciones-de-estado.component.scss',
})
export class PreposicionesDeEstadoComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Preposiciones de estado';
  override title = 'Preposiciones de estado: in, on, at';
}
