<div class="so-vs-such">
  <p>
    Las palabras "so" y "such" se utilizan para intensificar, pero con
    estructuras y significados diferentes. A continuación, te explicaré cómo se
    usan y las reglas que debes seguir para usarlas correctamente.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Uso de "So"</h2>

      <p>
        "So" es un adverbio que se usa para intensificar **adjetivos** y
        **adverbios**. Se emplea para expresar una gran cantidad o grado de
        algo, normalmente para mostrar sorpresa o énfasis.
      </p>

      <h3>Reglas de "So":</h3>
      <ul>
        <li>
          Se utiliza con **adjetivos** y **adverbios** para expresar grado o
          intensidad.
        </li>
        <li>
          La estructura básica es: <strong>so + adjetivo/adverbio</strong>.
        </li>
        <li>
          En ocasiones, se puede usar con la estructura
          <strong>so + adjetivo/adverbio + that</strong> para indicar causa y
          efecto.
        </li>
        <li>Se puede emplear para expresar **sorpresa** o **exageración**.</li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          The weather was <strong>so</strong> hot. (El clima estaba tan
          caluroso.)
        </li>
        <li>She spoke <strong>so</strong> quickly. (Ella habló tan rápido.)</li>
        <li>
          It was <strong>so</strong> late that I couldn't go out. (Era tan tarde
          que no pude salir.)
        </li>
        <li>
          The book was <strong>so</strong> interesting that I finished it in one
          day. (El libro fue tan interesante que lo terminé en un día.)
        </li>
      </ul>

      <h2>2. Uso de "Such"</h2>

      <p>
        "Such" se utiliza para intensificar **sustantivos** o **frases
        nominales** (sustantivo + adjetivo). Su función es hacer que el
        sustantivo sea más impresionante o más notorio. "Such" a menudo expresa
        algo sobresaliente o impresionante.
      </p>

      <h3>Reglas de "Such":</h3>
      <ul>
        <li>
          Se usa con **sustantivos** o **frases nominales** (sustantivo +
          adjetivo).
        </li>
        <li>
          La estructura básica es:
          <strong>such + (adjetivo) + sustantivo</strong>.
        </li>
        <li>
          Se emplea cuando el hablante quiere **enfatizar la magnitud** o la
          **naturaleza extrema** de algo.
        </li>
        <li>
          También se utiliza para mostrar **opiniones críticas** o **juicios
          personales**, cuando el sustantivo es algo negativo o sorprendente.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          It was <strong>such</strong> a beautiful day. (Fue un día tan
          hermoso.)
        </li>
        <li>
          She gave me <strong>such</strong> a wonderful gift. (Ella me dio un
          regalo tan maravilloso.)
        </li>
        <li>
          There were <strong>such</strong> interesting people at the party.
          (Había personas tan interesantes en la fiesta.)
        </li>
        <li>
          It was <strong>such</strong> a terrible mistake. (Fue un error tan
          terrible.)
        </li>
        <li>
          He is <strong>such</strong> a good person. (Él es una persona tan
          buena.)
        </li>
      </ul>

      <h2>3. Diferencias clave entre "So" y "Such"</h2>

      <p>
        Las principales diferencias entre "so" y "such" se basan en las
        estructuras y el tipo de palabras que intensifican:
      </p>

      <h3>1. "So" intensifica adjetivos y adverbios:</h3>
      <ul>
        <li>
          Usamos "so" para modificar **adjetivos** o **adverbios**, que
          describen cualidades o cómo ocurre una acción.
        </li>
        <li>La estructura es <strong>so + adjetivo/adverbio</strong>.</li>
        <li>
          Ejemplo: "She is <strong>so</strong> happy." (Ella está tan feliz.)
        </li>
      </ul>

      <h3>2. "Such" intensifica sustantivos o frases nominales:</h3>
      <ul>
        <li>
          Usamos "such" para intensificar **sustantivos** o **frases nominales**
          (sustantivo + adjetivo).
        </li>
        <li>
          La estructura es <strong>such + (adjetivo) + sustantivo</strong>.
        </li>
        <li>
          Ejemplo: "It was <strong>such</strong> a nice day." (Fue un día tan
          agradable.)
        </li>
      </ul>

      <h2>4. Explicación de los casos específicos de uso de "Such"</h2>

      <p>
        Hay casos donde "such" se utiliza con sustantivos que llevan una
        connotación crítica, negativa o sorprendente. Aquí se explica cómo:
      </p>

      <h3>Such + judgmental noun (tan + sustantivo crítico o negativo):</h3>
      <p>
        Cuando el sustantivo es un **juicio** o tiene una connotación negativa o
        crítica, "such" enfatiza la impresión o sorpresa sobre ese sustantivo.
      </p>

      <ul>
        <li>
          It was <strong>such</strong> a stupid decision! (¡Fue una decisión tan
          estúpida!)
        </li>
        <li>
          He's <strong>such</strong> a liar! (¡Es un mentiroso tan grande!)
        </li>
        <li>
          That was <strong>such</strong> a foolish mistake. (Eso fue un error
          tan tonto.)
        </li>
      </ul>

      <h3>Such + (adjetivo) + sustantivo:</h3>
      <p>
        Usamos esta estructura para enfatizar un sustantivo, resaltando la
        importancia, el tamaño o la naturaleza excepcional de algo.
      </p>

      <ul>
        <li>
          He made <strong>such</strong> a generous donation. (Hizo una donación
          tan generosa.)
        </li>
        <li>
          We saw <strong>such</strong> a great performance. (Vimos una actuación
          tan excelente.)
        </li>
        <li>
          That was <strong>such</strong> a big surprise! (¡Fue una sorpresa tan
          grande!)
        </li>
      </ul>

      <h3>Such + a/an + (sustantivo) (para sustantivos contables):</h3>
      <p>
        Se usa "such" con un artículo "a" o "an" cuando el sustantivo es
        contable y singular, y se quiere enfatizar la magnitud o importancia del
        sustantivo.
      </p>

      <ul>
        <li>
          It was <strong>such</strong> a wonderful experience. (Fue una
          experiencia tan maravillosa.)
        </li>
        <li>
          That’s <strong>such</strong> a funny idea! (¡Esa es una idea tan
          graciosa!)
        </li>
      </ul>

      <h2>5. Uso de "So" + "That" y "Such" + "That"</h2>

      <p>
        Ambas estructuras se usan para mostrar causa y efecto, pero hay
        diferencias clave en su aplicación.
      </p>

      <h3>So + adjetivo/adverbio + that:</h3>
      <p>
        Esta estructura se usa para indicar que algo es tan extremo que causa un
        efecto.
      </p>

      <ul>
        <li>
          The book was <strong>so</strong> good <strong>that</strong> I couldn’t
          put it down. (El libro fue tan bueno que no pude dejarlo.)
        </li>
        <li>
          He ran <strong>so</strong> fast <strong>that</strong> he broke the
          record. (Corrió tan rápido que rompió el récord.)
        </li>
      </ul>

      <h3>Such + (adjetivo) + sustantivo + that:</h3>
      <p>Se usa para mostrar que algo es tan notable que causa un efecto.</p>

      <ul>
        <li>
          It was <strong>such</strong> an interesting movie
          <strong>that</strong> I watched it twice. (Era una película tan
          interesante que la vi dos veces.)
        </li>
        <li>
          They are <strong>such</strong> good friends <strong>that</strong> they
          help each other all the time. (Son tan buenos amigos que se ayudan
          todo el tiempo.)
        </li>
      </ul>
    </div>
  </div>
</div>
