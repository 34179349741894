import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-verbo-to-get',
  standalone: true,
  imports: [],
  templateUrl: './verbo-to-get.component.html',
  styleUrl: './verbo-to-get.component.scss',
})
export class VerboToGetComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Usos del verbo To Get';
  override title = 'Usos del verbo To Get';
}
