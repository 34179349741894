<div class="present-perfect-modal-verbs">
  <p>
    Los <em>modal perfect</em> combinan un verbo modal con el
    <em>present perfect</em> para expresar posibilidad, certeza, deducción o
    consejo en el pasado.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>¿Cuales son y cómo se usan?</h2>
      <table border="1" cellpadding="10">
        <thead>
          <tr>
            <th>Modal Perfect</th>
            <th>Uso</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Might have</strong> / <strong>May have</strong></td>
            <td>
              Se utiliza para expresar una posibilidad en el pasado. Indica que
              algo <em>podría haber ocurrido</em>, pero no estamos seguros. Se
              usa cuando tenemos dudas o no tenemos toda la información sobre si
              realmente pasó.<br /><strong>Ejemplo:</strong> She
              <strong>might have</strong> left already. (Es posible que ella ya
              se haya ido)
            </td>
          </tr>
          <tr>
            <td><strong>Must have</strong></td>
            <td>
              Expresa una deducción sobre algo que probablemente ocurrió en el
              pasado. Usamos <strong>must have</strong> cuando tenemos evidencia
              o motivos fuertes para pensar que algo realmente sucedió.<br /><strong
                >Ejemplo:</strong
              >
              They <strong>must have</strong> forgotten the meeting. (Deben de
              haber olvidado la reunión)
            </td>
          </tr>
          <tr>
            <td><strong>Could have</strong></td>
            <td>
              Indica algo que era posible en el pasado, pero no necesariamente
              ocurrió. Se usa para hablar de oportunidades que existían, o de
              cosas que alguien podía haber hecho pero no hizo.<br /><strong
                >Ejemplo:</strong
              >
              He <strong>could have</strong> won the game. (Él pudo haber ganado
              el juego)
            </td>
          </tr>
          <tr>
            <td><strong>Should have</strong></td>
            <td>
              Se usa para expresar consejo o arrepentimiento sobre el pasado,
              refiriéndose a algo que <em>debería haber sucedido</em> o alguien
              <em>debería haber hecho</em>, pero no lo hizo. También puede
              indicar desaprobación.<br /><strong>Ejemplo:</strong> You
              <strong>should have</strong> called me. (Deberías haberme llamado)
            </td>
          </tr>
          <tr>
            <td><strong>Would have</strong></td>
            <td>
              Se usa para hablar de algo que habría pasado en el pasado bajo
              ciertas condiciones. Indica un deseo o una acción que ocurrió en
              una situación hipotética.<br /><strong>Ejemplo:</strong> I
              <strong>would have</strong> gone if I’d known. (Habría ido si
              hubiera sabido)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h3>Ejemplos de Modal Perfect en Oraciones</h3>
      <table border="1" cellpadding="10">
        <thead>
          <tr>
            <th>Oración</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>She <strong>might have</strong> been at the party.</td>
            <td>Ella podría haber estado en la fiesta.</td>
          </tr>
          <tr>
            <td>He <strong>must have</strong> left already.</td>
            <td>Él debe de haberse ido ya.</td>
          </tr>
          <tr>
            <td>They <strong>could have</strong> won if they tried harder.</td>
            <td>Pudieron haber ganado si se hubieran esforzado más.</td>
          </tr>
          <tr>
            <td>You <strong>should have</strong> called me.</td>
            <td>Deberías haberme llamado.</td>
          </tr>
          <tr>
            <td>I <strong>would have</strong> helped you if you’d asked.</td>
            <td>Te habría ayudado si me hubieras pedido.</td>
          </tr>
          <tr>
            <td>She <strong>may have</strong> forgotten the appointment.</td>
            <td>Puede que haya olvidado la cita.</td>
          </tr>
          <tr>
            <td>They <strong>might have</strong> lost the match.</td>
            <td>Es posible que hayan perdido el partido.</td>
          </tr>
          <tr>
            <td>He <strong>must have</strong> worked late last night.</td>
            <td>Debe de haber trabajado hasta tarde anoche.</td>
          </tr>
          <tr>
            <td>We <strong>could have</strong> arrived earlier.</td>
            <td>Pudimos haber llegado más temprano.</td>
          </tr>
          <tr>
            <td>She <strong>should have</strong> studied harder.</td>
            <td>Ella debería haber estudiado más.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
