import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-modal-verbs-present-perfect',
  standalone: true,
  imports: [],
  templateUrl: './modal-verbs-present-perfect.component.html',
  styleUrl: './modal-verbs-present-perfect.component.scss',
})
export class ModalVerbsPresentPerfectComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Modal perfects';
  override title = 'Modal perfects';
}
