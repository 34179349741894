import { Component } from '@angular/core';
import {
  LeccionesInglesService,
  Nivel,
} from '../lecciones/lecciones.-ingles.service';
import { distinctUntilChanged } from 'rxjs';

@Component({
  templateUrl: './nivel-base.component.html',
  styleUrl: './nivel-base.component.scss',
  selector: 'app-nivel-base',
})
export class NivelBaseComponent {
  nivel: Nivel;
  temasNivel = [];
  constructor(public leccionesService: LeccionesInglesService) {}

  ngOnInit(): void {
    this.leccionesService.nivel$
      .pipe(distinctUntilChanged())
      .subscribe(() => (this.temasNivel = this.leccionesService.menuFiltrado));
  }
}
