<div class="sustantivos">
  <p>
    Uno de los aspectos más importantes cuando aprendes inglés es entender cómo
    se usan los sustantivos, en especial los
    <strong>contables</strong> (countable) e
    <strong>incontables</strong> (uncountable). Este tema puede ser un poco
    confuso porque no siempre coincide con el español. Vamos a descubrir qué son
    y cómo se usan.
  </p>

  <h2>Sustantivos Contables (Countable Nouns)</h2>
  <p>
    Los <strong>sustantivos contables</strong> son aquellos que pueden contarse
    uno por uno. Se refieren a cosas que tienen una forma singular y plural.
    Puedes contar "uno", "dos", "tres", etc.
  </p>

  <h3>Ejemplos</h3>
  <table>
    <thead>
      <tr>
        <th>Español</th>
        <th>Inglés (Singular)</th>
        <th>Inglés (Plural)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Manzana</td>
        <td>Apple</td>
        <td>Apples</td>
      </tr>
      <tr>
        <td>Auto</td>
        <td>Car</td>
        <td>Cars</td>
      </tr>
      <tr>
        <td>Perro</td>
        <td>Dog</td>
        <td>Dogs</td>
      </tr>
    </tbody>
  </table>

  <h3>Reglas Claves</h3>
  <ul>
    <li>
      <strong>Artículos:</strong> Con los sustantivos contables, puedes usar
      artículos como "a" o "an" en singular. Ejemplo: <em>a car, an apple</em>.
    </li>
    <li>
      <strong>Plural:</strong> Para hacer plural, añades <em>-s</em> o
      <em>-es</em> a la mayoría:
      <em>apple → apples, dog → dogs, box → boxes</em>.
    </li>
    <li>
      <strong>Expresiones:</strong> Usa "many" para cantidades grandes:
      <em>I have many books</em>.
    </li>
  </ul>

  <h2>Sustantivos Incontables (Uncountable Nouns)</h2>
  <p>
    Los <strong>sustantivos incontables</strong> se refieren a cosas que no se
    pueden contar individualmente porque son una masa, sustancia o concepto
    abstracto. Estos sustantivos no tienen plural.
  </p>

  <h3>Ejemplos</h3>
  <table>
    <thead>
      <tr>
        <th>Español</th>
        <th>Inglés (Singular)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Agua</td>
        <td>Water</td>
      </tr>
      <tr>
        <td>Leche</td>
        <td>Milk</td>
      </tr>
      <tr>
        <td>Dinero</td>
        <td>Money</td>
      </tr>
      <tr>
        <td>Información</td>
        <td>Information</td>
      </tr>
    </tbody>
  </table>

  <h3>Reglas Claves</h3>
  <ul>
    <li>
      <strong>No Plural:</strong> Los sustantivos incontables no tienen plural,
      por lo tanto, no puedes decir "informations" o "milks".
    </li>
    <li>
      <strong>No 'a' o 'an':</strong> No se usan con artículos como "a" o "an".
      No puedes decir "a water" o "an information".
    </li>
    <li>
      <strong>Expresiones:</strong> Usa palabras como "much", "a lot of" o
      "some" para hablar de cantidades: <em>There is a lot of water</em>.
    </li>
  </ul>

  <h2>Sustantivos que Pueden Ser Contables e Incontables</h2>
  <p>
    Algunos sustantivos pueden ser contables e incontables dependiendo del
    contexto. Esto se debe a que pueden tener un significado general o más
    específico.
  </p>

  <h3>Ejemplos</h3>
  <table>
    <thead>
      <tr>
        <th>Español</th>
        <th>Contable (Singular/Plural)</th>
        <th>Incontable</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Cabello</td>
        <td>There are two hairs in my soup!</td>
        <td>Her hair is beautiful.</td>
      </tr>
      <tr>
        <td>Pollo</td>
        <td>There are three chickens on the farm.</td>
        <td>I like chicken.</td>
      </tr>
    </tbody>
  </table>
</div>
