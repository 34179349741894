<div class="do-vs-make">
  <h1>¿Cuál es la diferencia entre "do" y "make" en inglés?</h1>

  <p>
    Los verbos <em>do</em> y <em>make</em> pueden traducirse como "hacer", pero
    se usan en contextos diferentes. Aquí te explico cuándo usar cada uno.
  </p>

  <div class="section">
    <div class="section-blanco">
      <h2>1. Usar "do"</h2>

      <p>
        <em>Do</em> se utiliza para referirse a actividades, tareas o trabajos,
        especialmente cuando no se especifica el resultado. Es común en
        preguntas, negativas y frases idiomáticas.
      </p>

      <table border="1">
        <tr>
          <th>Ejemplo</th>
          <th>Explicación</th>
        </tr>
        <tr>
          <td>I <strong>do</strong> my homework.</td>
          <td>Hago mi tarea. Aquí, se refiere a la tarea como actividad.</td>
        </tr>
        <tr>
          <td>She <strong>does</strong> her job well.</td>
          <td>Ella hace su trabajo bien. Se refiere al trabajo como tarea.</td>
        </tr>
        <tr>
          <td>What did you <strong>do</strong> yesterday?</td>
          <td>¿Qué hiciste ayer? Pregunta sobre actividades realizadas.</td>
        </tr>
        <tr>
          <td>He <strong>doesn't do</strong> any exercise.</td>
          <td>Él no hace ejercicio. Negativa sobre la actividad.</td>
        </tr>
        <tr>
          <td>We need to <strong>do</strong> some cleaning.</td>
          <td>
            Necesitamos hacer algo de limpieza. Actividad general sin
            especificar resultado.
          </td>
        </tr>
      </table>

      <h2>2. Usar "make"</h2>

      <p>
        <em>Make</em> se utiliza para referirse a la creación o producción de
        algo, o cuando hay un resultado tangible. Implica transformar materiales
        o ideas en algo concreto.
      </p>

      <table border="1">
        <tr>
          <th>Ejemplo</th>
          <th>Explicación</th>
        </tr>
        <tr>
          <td>I <strong>make</strong> breakfast every morning.</td>
          <td>
            Hago el desayuno todas las mañanas. Se refiere a la creación de una
            comida.
          </td>
        </tr>
        <tr>
          <td>She <strong>made</strong> a cake for the party.</td>
          <td>
            Ella hizo un pastel para la fiesta. Se refiere a la creación de un
            objeto.
          </td>
        </tr>
        <tr>
          <td>What did you <strong>make</strong> for dinner?</td>
          <td>
            ¿Qué hiciste para la cena? Pregunta sobre la creación de una comida.
          </td>
        </tr>
        <tr>
          <td>He <strong>makes</strong> a lot of noise.</td>
          <td>Él hace mucho ruido. Aquí se refiere a producir un sonido.</td>
        </tr>
        <tr>
          <td>We need to <strong>make</strong> a decision.</td>
          <td>
            Necesitamos tomar una decisión. Se refiere a la creación de una
            conclusión o resolución.
          </td>
        </tr>
      </table>

      <h2>3. Resumen de Diferencias</h2>

      <table border="1">
        <tr>
          <th>Verbo</th>
          <th>Uso</th>
          <th>Estructura</th>
          <th>Ejemplo</th>
        </tr>
        <tr>
          <td><strong>Do</strong></td>
          <td>
            Referirse a actividades, tareas o trabajos, sin un resultado
            específico.
          </td>
          <td><em>Do + actividad</em></td>
          <td>
            I <strong>do</strong> the laundry. <br />
            She <strong>does</strong> her homework.
          </td>
        </tr>
        <tr>
          <td><strong>Make</strong></td>
          <td>Referirse a la creación o producción de algo tangible.</td>
          <td><em>Make + objeto</em></td>
          <td>
            I <strong>make</strong> a sandwich. <br />
            They <strong>made</strong> a film.
          </td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Explicación sobre "Make" y sus Excepciones</h2>

      <p>
        Como mencionamos anteriormente, en inglés, "make" se usa generalmente
        para referirse a la creación o producción de algo. Sin embargo, hay
        ciertas expresiones que no siguen esta regla general, lo que puede
        resultar confuso para quienes aprenden el idioma. Estas expresiones son
        fijas y se usan comúnmente en la conversación y la escritura.
      </p>

      <p>
        Por ejemplo, "make time" significa encontrar tiempo para hacer algo, y
        "make the bed" significa organizar la cama después de dormir. En estos
        casos, el uso de "make" no implica la creación literal de tiempo o una
        cama, sino que son frases que se han establecido en el uso del idioma.
      </p>

      <h3>Ejemplos Comunes de "Make"</h3>

      <table border="1">
        <tr>
          <th>Expresión</th>
          <th>Significado</th>
        </tr>
        <tr>
          <td>Make time</td>
          <td>Encontrar tiempo para hacer algo.</td>
        </tr>
        <tr>
          <td>Make the bed</td>
          <td>Hacer la cama (organizar la cama después de dormir).</td>
        </tr>
        <tr>
          <td>Make a decision</td>
          <td>Tomar una decisión.</td>
        </tr>
        <tr>
          <td>Make an effort</td>
          <td>Hacer un esfuerzo.</td>
        </tr>
        <tr>
          <td>Make a suggestion</td>
          <td>Hacer una sugerencia.</td>
        </tr>
        <tr>
          <td>Make a promise</td>
          <td>Hacer una promesa.</td>
        </tr>
        <tr>
          <td>Make money</td>
          <td>Hacer dinero (ganar dinero).</td>
        </tr>
        <tr>
          <td>Make a mistake</td>
          <td>Cometer un error.</td>
        </tr>
        <tr>
          <td>Make a phone call</td>
          <td>Hacer una llamada telefónica.</td>
        </tr>
        <tr>
          <td>Make a cake</td>
          <td>Hacer un pastel (preparar un pastel).</td>
        </tr>
        <tr>
          <td>Make friends</td>
          <td>Hacer amigos (establecer amistades).</td>
        </tr>
        <tr>
          <td>Make a deal</td>
          <td>Hacer un trato.</td>
        </tr>
        <tr>
          <td>Make an appointment</td>
          <td>Hacer una cita (formal).</td>
        </tr>
        <tr>
          <td>Make a choice</td>
          <td>Tomar una elección.</td>
        </tr>
        <tr>
          <td>Make an announcement</td>
          <td>Hacer un anuncio.</td>
        </tr>
      </table>
    </div>
  </div>
</div>
