import { Component } from '@angular/core';

@Component({
  selector: 'app-intermedio',
  standalone: true,
  imports: [],
  templateUrl: './intermedio.component.html',
  styleUrl: './intermedio.component.scss'
})
export class IntermedioComponent {

}
