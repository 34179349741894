<div class="adjetives">
  <p>
    Un adjetivo nos dice cómo es o está una cosa, objeto o persona. Describe a
    algo o a alguien. En el diccionario, se identifica así:
    <strong>(adj)</strong>.
  </p>

  <h2>Ejemplos de Adjetivos</h2>
  <table>
    <thead>
      <tr>
        <th>Adjetivo</th>
        <th>Pronunciación</th>
        <th>Traducción</th>
        <th>Uso</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Small</td>
        <td>(smol)</td>
        <td>chico</td>
        <td>Usado para cosas</td>
      </tr>
      <tr>
        <td>Big</td>
        <td>(big)</td>
        <td>grande</td>
        <td>Usado para cosas</td>
      </tr>
      <tr>
        <td>Low</td>
        <td>(lou)</td>
        <td>bajo</td>
        <td>Usado para cosas</td>
      </tr>
      <tr>
        <td>High</td>
        <td>(jaig)</td>
        <td>alto</td>
        <td>Usado para cosas</td>
      </tr>
      <tr>
        <td>Short</td>
        <td>(short)</td>
        <td>bajo/a (personas), corto (cosas)</td>
        <td>Usado para personas y cosas</td>
      </tr>
      <tr>
        <td>Tall</td>
        <td>(tol)</td>
        <td>alto/a</td>
        <td>Usado para personas</td>
      </tr>
      <tr>
        <td>Fat</td>
        <td>(fat)</td>
        <td>gordo</td>
        <td>Para personas y cosas</td>
      </tr>
      <tr>
        <td>Interesting</td>
        <td>(intristing)</td>
        <td>interesante</td>
        <td>Para describir cosas o situaciones</td>
      </tr>
      <tr>
        <td>Blue</td>
        <td>(blu)</td>
        <td>azul</td>
        <td>Color</td>
      </tr>
    </tbody>
  </table>

  <p>
    <strong>Nota:</strong> Los adjetivos no especifican el sexo. Sin embargo,
    algunos pocos sí lo hacen, como en los siguientes ejemplos:
  </p>
  <ul>
    <li>Blond (blond) (rubio) y Blonde (blond) (rubia).</li>
  </ul>

  <h2>Reglas de los Adjetivos</h2>
  <ol>
    <li>
      Siempre van antes del sustantivo (cosa, objeto, persona).
      <ul>
        <li>
          Ejemplo: <em>small chair</em> (silla pequeña), <em>fat dog</em> (perro
          gordo), <em>big table</em> (mesa grande).
        </li>
      </ul>
    </li>
    <li>
      Nunca se pluralizan.
      <ul>
        <li>
          Ejemplo: <em>small chairs</em> (sillas pequeñas),
          <em>fat dogs</em> (perros gordos), <em>big tables</em> (mesas
          grandes).
        </li>
      </ul>
    </li>
    <li>
      En ocasiones, un sustantivo puede usarse como adjetivo.
      <ul>
        <li>Ejemplo: <em>corner shop</em> (tienda de la esquina).</li>
      </ul>
    </li>
  </ol>

  <h2>Sustantivo, Adjetivo o Acción</h2>
  <p>
    En inglés, algunas palabras pueden tener múltiples significados (sustantivo,
    adjetivo o verbo). Es importante memorizar la palabra y su contexto para
    identificar su función.
  </p>

  <table>
    <thead>
      <tr>
        <th>Palabra</th>
        <th>Tipo</th>
        <th>Significado</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Work</td>
        <td>(v) verbo</td>
        <td>trabajar</td>
      </tr>
      <tr>
        <td>Work</td>
        <td>(n) sustantivo</td>
        <td>trabajo</td>
      </tr>
      <tr>
        <td>Workable</td>
        <td>(adj) adjetivo</td>
        <td>manejable, trabajable</td>
      </tr>
      <tr>
        <td>Play</td>
        <td>(v) verbo</td>
        <td>jugar</td>
      </tr>
      <tr>
        <td>Play</td>
        <td>(n) sustantivo</td>
        <td>juego</td>
      </tr>
      <tr>
        <td>Playful</td>
        <td>(adj) adjetivo</td>
        <td>juguetón/juguetona</td>
      </tr>
      <tr>
        <td>Sleep</td>
        <td>(v) verbo</td>
        <td>dormir</td>
      </tr>
      <tr>
        <td>Asleep</td>
        <td>(adj) adjetivo</td>
        <td>dormido/a</td>
      </tr>
      <tr>
        <td>Open</td>
        <td>(v) verbo</td>
        <td>abrir</td>
      </tr>
      <tr>
        <td>Open</td>
        <td>(adj) adjetivo</td>
        <td>abierto/a</td>
      </tr>
      <tr>
        <td>Opening</td>
        <td>(n) sustantivo</td>
        <td>apertura</td>
      </tr>
    </tbody>
  </table>

  <h2>La acción "To Be" con los Adjetivos</h2>
  <p>
    La acción <strong>“to be”</strong> (ser o estar) es muy común seguida de un
    adjetivo. Ejemplos:
  </p>
  <ul>
    <li>I am happy (yo estoy feliz).</li>
    <li>He is tired (él está cansado).</li>
    <li>We are bored (nosotros estamos aburridos).</li>
  </ul>

  <h2>Adjetivos con Terminación "-ed" y "-ing"</h2>
  <p>
    Dependiendo de si la emoción se siente internamente o externamente, se usan
    diferentes terminaciones:
  </p>

  <h3>Adjetivos Terminados en "-ed" (Emociones Internas)</h3>
  <ul>
    <li>I am bored (estoy aburrido).</li>
    <li>You are interested in (tú estás interesado en).</li>
    <li>He feels excited (él se siente emocionado).</li>
    <li>She is tired (ella está cansada).</li>
    <li>We are relaxed (nosotros estamos relajados).</li>
    <li>They are confused (ellos están confundidos).</li>
  </ul>

  <h3>Adjetivos Terminados en "-ing" (Emociones Externas)</h3>
  <ul>
    <li>This book is boring (este libro es aburrido).</li>
    <li>The movie was exciting (la película fue emocionante).</li>
  </ul>
</div>
