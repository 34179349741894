import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-preposiciones-de-tiempo',
  standalone: true,
  imports: [],
  templateUrl: './preposiciones-de-tiempo.component.html',
  styleUrl: './preposiciones-de-tiempo.component.scss',
})
export class PreposicionesDeTiempoComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Preposiciones de tiempo';
  override title = 'Preposiciones de tiempo: in, on, at';
}
