import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrl: './navigation-item.component.scss',
})
export class NavigationItemComponent {
  @Input() title: string;
  @Input() buttonPrevious: string;
  @Input() buttonNext: string;
  @Input() urlPrevious: string;
  @Input() urlNext: string;
}
