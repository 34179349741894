import { NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { AppComponent } from './app/app.component';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LeccionesInglesComponent } from './app/lecciones/lecciones.-ingles.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { NavigationLateralComponent } from './app/navigation/navigation-lateral.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { NavigationButtonComponent } from './app/navigation-button/navigation-button.component';
import { MatCardModule } from '@angular/material/card';
import { AlfabetoComponent } from './app/principiante/alfabeto/alfabeto.component';
import { NavigationItemComponent } from './app/navigation-item/navigation-item.component';
import { LeccionesInglesService } from './app/lecciones/lecciones.-ingles.service';
import { NumerosComponent } from './app/principiante/numeros/numeros.component';
import { NumerosOrdinalesComponent } from './app/principiante/numeros-ordinales/numeros-ordinales.component';
import { ColoresComponent } from './app/principiante/colores/colores.component';
import { VerboToBeComponent } from './app/principiante/verbo-to-be/verbo-to-be.component';
import { PresenteSimpleComponent } from './app/principiante/presente-simple/presente-simple.component';
import { VWhQuestionComponent } from './app/principiante/wh-question/wh-question.component';
import { AdverbiosDeFrecuenciaComponent } from './app/principiante/adverbios-de-frecuencia/adverbios-de-frecuenciacomponent';
import { VerboToHaveComponent } from './app/principiante/verbo-to-have/verbo-to-have.component';
import { CalendarComponent } from './app/principiante/calendar/calendar.component';
import { PronombresComponent } from './app/principiante/pronombres/pronombres.component';
import { SustantivosComponent } from './app/principiante/sustantivos/sustantivos.component';
import { AdjetivosComponent } from './app/principiante/adjetivos/adjetivos.component';
import { DemostrativoComponent } from './app/principiante/demostrativo/demostrativo.component';
import { ArticulosComponent } from './app/principiante/articulos/articulos.component';
import { PreposicionesComponent } from './app/principiante/preposiciones/preposiciones.component';
import { PreposicionesDeTiempoComponent } from './app/principiante/preposiciones-de-tiempo/preposiciones-de-tiempo.component';
import { PreposicionesDeLugarComponent } from './app/principiante/preposiciones-de-lugar/preposiciones-de-lugar.component';
import { PreposicionesDeEstadoComponent } from './app/principiante/preposiciones-de-estado/preposiciones-de-estado.component';
import { SustantivosContablesIncontablesComponent } from './app/principiante/sustantivos-contables-incontables/sustantivos-contables-incontables.component';
import { CuantificadoresComponent } from './app/principiante/cuantificadores/cuantificadores.component';
import { VerbosComponent } from './app/principiante/verbos/verbos.component';
import { VerboToDoComponent } from './app/principiante/verbo-to-do/verbo-to-do.component';
import { VerbosModalesComponent } from './app/principiante/verbos-modales/verbos-modales.component';
import { IndicadorHayComponent } from './app/intermedio/indicador-hay/indicador-hay.component';
import { AdverbiosComponent } from './app/intermedio/adverbios/adverbios.component';
import { IndefinitePronounsComponent } from './app/intermedio/indefinite-pronouns/indefinite-pronouns.component';
import { RelativePronounsComponent } from './app/intermedio/relative-pronouns/relative-pronouns.component';
import { ConjunctionsComponent } from './app/intermedio/conjunctions/conjunctions.component';
import { ComparativeComponent } from './app/intermedio/comparative/comparative.component';
import { SuperlativeComponent } from './app/intermedio/superlative/superlative.component';
import { AdjetivesEdIngComponent } from './app/intermedio/adjetives-ed-ing/adjetives-ed-ing.component';
import { LikeAndAsComponent } from './app/intermedio/like-and-as/like-and-as.component';
import { PastSimpleComponent } from './app/intermedio/past-simple/past-simple.component';
import { UsedToComponent } from './app/intermedio/used-to/used-to.component';
import { FutureSimpleComponent } from './app/intermedio/future-simple/future-simple.component';
import { PresentPerfectComponent } from './app/avanzado/present-perfect/present-perfect.component';
import { PresentPerfectContinuousComponent } from './app/avanzado/present-perfect-continuous/present-perfect-continuous.component';
import { PalabrasClavesComponent } from './app/avanzado/palabras-claves/palabras-claves.component';
import { ModalVerbsPresentPerfectComponent } from './app/avanzado/modal-verbs-present-perfect/modal-verbs-present-perfect.component';
import { PastPerfectComponent } from './app/avanzado/past-perfect/past-perfect.component';
import { PastPerfectContinuousComponent } from './app/avanzado/past-perfect-continuous/past-perfect-continuous.component';
import { FuturePerfectComponent } from './app/avanzado/future-perfect/future-perfect.component';
import { PastContinuousComponent } from './app/intermedio/past-continuous/past-continuous.component';
import { FuturePerfectContinuousComponent } from './app/avanzado/future-perfect-continuos/future-perfect-continuous.component';
import { FutureContinuousComponent } from './app/intermedio/future-continuous/future-continuous.component';
import { ConditionalSentencesComponent } from './app/avanzado/conditional-sentences/conditional-sentences.component';
import { PassiveVoiceComponent } from './app/avanzado/passive-voice/passive-voice.component';
import { DirectAndReportedSpeechComponent } from './app/avanzado/direct-and-reported-speech/direct-and-reported-speech.component';
import { SayVsTellComponent } from './app/avanzado/say-vs-tell/say-vs-tell.component';
import { DoVsMakeComponent } from './app/avanzado/do-vs-make/do-vs-make.component';
import { DelexicalVerbsComponent } from './app/avanzado/delexical-verbs/delexical-verbs.component';
import { LinkingWordsComponent } from './app/avanzado/linking-words/linking-words.component';
import { PhrasalPrepositionalVerbsComponent } from './app/avanzado/phrasal-prepositional-verbs/phrasal-prepositional-verbs.component';
import { VerboToGetComponent } from './app/avanzado/verbo-to-get/verbo-to-get.component';
import { PrefixesAndSuffixesComponent } from './app/avanzado/prefixes-and-suffixes/prefixes-and-suffixes.component';
import { SoVsSuchComponent } from './app/avanzado/so-vs-such/so-vs-such.component';
import { EnoughAndTooComponent } from './app/avanzado/enough-and-too/enough-and-too.component';
import { EvenComponent } from './app/avanzado/even/even.component';
import { ElseComponent } from './app/avanzado/else/else.component';
import { EverWhateverWhereverWheneverComponent } from './app/avanzado/ever-whatever-wherever-whenever/ever-whatever-wherever-whenever.component';
import { NeitherVsEitherComponent } from './app/avanzado/neither-vs-either/neither-vs-either.component';
import { EachVsEveryComponent } from './app/avanzado/each-vs-every/each-vs-every.component';
import { ListaCompletaVerbosIrregularesPasadoSimpleComponent } from './app/principiante/lista-completa-verbos-irregulares-pasado-simple/lista-completa-verbos-irregulares-pasado-simple.component';
import { AvanzadoComponent } from './app/avanzado/avanzado/avanzado.component';
import { IntermedioComponent } from './app/intermedio/intermedio/intermedio.component';
import { NivelBaseComponent } from './app/nivel-base/nivel-base.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InicioComponent } from './app/inicio/inicio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ContactoComponent } from './app/contacto/contacto.component';
import { PrivacidadComponent } from './app/privacidad/privacidad.component';
import { CondicionesComponent } from './app/condiciones/condiciones.component';
import { IndicacionesComponent } from './app/indicaciones/indicaciones.component';
import { FooterComponent } from './app/footer/footer.component';
import { MatOptionModule } from '@angular/material/core';
import { AsyncPipe } from '@angular/common';
import { PrincipianteComponent } from './app/principiante/principiante/principiante.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    NivelBaseComponent,
    AppComponent,
    LeccionesInglesComponent,
    NavigationLateralComponent,
    NavigationButtonComponent,
    NavigationItemComponent,
    AlfabetoComponent,
    NumerosComponent,
    NumerosOrdinalesComponent,
    ColoresComponent,
    VerboToBeComponent,
    AdverbiosDeFrecuenciaComponent,
    VWhQuestionComponent,
    PresenteSimpleComponent,
    VerboToHaveComponent,
    CalendarComponent,
    PronombresComponent,
    SustantivosComponent,
    AdjetivosComponent,
    DemostrativoComponent,
    ArticulosComponent,
    PreposicionesComponent,
    PreposicionesDeLugarComponent,
    InicioComponent,
    IndicacionesComponent,
    FooterComponent,
    VerbosComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      {
        path: '',
        component: InicioComponent,
      },
      {
        path: 'lecciones',
        component: LeccionesInglesComponent,
        children: [
          {
            path: 'principiante/abecedario',
            component: AlfabetoComponent,
          },
          {
            path: 'principiante/numbers',
            component: NumerosComponent,
          },
          {
            path: 'principiante/ordinal-numbers',
            component: NumerosOrdinalesComponent,
          },
          {
            path: 'principiante/colors',
            component: ColoresComponent,
          },
          {
            path: 'principiante/calendar',
            component: CalendarComponent,
          },
          {
            path: 'principiante/verbo-to-be',
            component: VerboToBeComponent,
          },
          {
            path: 'principiante/pronombres',
            component: PronombresComponent,
          },
          {
            path: 'principiante/sustantivos',
            component: SustantivosComponent,
          },
          {
            path: 'principiante/adjetives',
            component: AdjetivosComponent,
          },
          {
            path: 'principiante/wh-question',
            component: VWhQuestionComponent,
          },
          {
            path: 'principiante/simple-present',
            component: PresenteSimpleComponent,
          },
          {
            path: 'principiante/adverbs-of-frecuency',
            component: AdverbiosDeFrecuenciaComponent,
          },
          {
            path: 'principiante/verbo-to-have',
            component: VerboToHaveComponent,
          },
          {
            path: 'principiante/this-that-these-those',
            component: DemostrativoComponent,
          },
          {
            path: 'principiante/the-a-an',
            component: ArticulosComponent,
          },
          {
            path: 'principiante/preposicions-in-on-at',
            component: PreposicionesComponent,
          },
          {
            path: 'principiante/preposicions-of-place-in-on-at',
            component: PreposicionesDeLugarComponent,
          },
          {
            path: 'principiante/preposicions-of-time-in-on-at',
            component: PreposicionesDeTiempoComponent,
          },
          {
            path: 'principiante/preposicions-of-state-in-on-at',
            component: PreposicionesDeEstadoComponent,
          },
          {
            path: 'principiante/countable-and-uncountable-nouns',
            component: SustantivosContablesIncontablesComponent,
          },
          {
            path: 'principiante/quantifier',
            component: CuantificadoresComponent,
          },
          {
            path: 'principiante/verbs',
            component: VerbosComponent,
          },
          {
            path: 'principiante/verbo-to-do',
            component: VerboToDoComponent,
          },
          {
            path: 'principiante/modal-verbs',
            component: VerbosModalesComponent,
          },
          {
            path: 'intermedio/there-is-there-are',
            component: IndicadorHayComponent,
          },
          {
            path: 'intermedio/adverbs',
            component: AdverbiosComponent,
          },
          {
            path: 'intermedio/indefinite-pronouns',
            component: IndefinitePronounsComponent,
          },
          {
            path: 'intermedio/relative-pronouns',
            component: RelativePronounsComponent,
          },
          {
            path: 'intermedio/conjunctions',
            component: ConjunctionsComponent,
          },
          {
            path: 'intermedio/comparative',
            component: ComparativeComponent,
          },

          {
            path: 'intermedio/superlative',
            component: SuperlativeComponent,
          },
          {
            path: 'intermedio/continuous-present',
            component: PresentPerfectContinuousComponent,
          },
          {
            path: 'intermedio/adjectives-that-end-in-ed-and-ing',
            component: AdjetivesEdIngComponent,
          },
          {
            path: 'intermedio/like-and-as',
            component: LikeAndAsComponent,
          },
          {
            path: 'intermedio/past-simple',
            component: PastSimpleComponent,
          },
          {
            path: 'intermedio/past-continuous',
            component: PastContinuousComponent,
          },
          {
            path: 'intermedio/used-to',
            component: UsedToComponent,
          },
          {
            path: 'intermedio/future-simple',
            component: FutureSimpleComponent,
          },
          {
            path: 'intermedio/future-continuous',
            component: FutureContinuousComponent,
          },
          {
            path: 'avanzado/present-perfect',
            component: PresentPerfectComponent,
          },
          {
            path: 'avanzado/present-perfect-continuous',
            component: PresentPerfectContinuousComponent,
          },
          {
            path: 'avanzado/already-just-still-yet-for-since-ago',
            component: PalabrasClavesComponent,
          },
          {
            path: 'avanzado/modal-perfects',
            component: ModalVerbsPresentPerfectComponent,
          },
          {
            path: 'avanzado/past-perfect',
            component: PastPerfectComponent,
          },
          {
            path: 'avanzado/past-perfect-continuous',
            component: PastPerfectContinuousComponent,
          },
          {
            path: 'avanzado/future-perfect',
            component: FuturePerfectComponent,
          },
          {
            path: 'avanzado/future-perfect-continuous',
            component: FuturePerfectContinuousComponent,
          },
          {
            path: 'avanzado/condicional-sentences',
            component: ConditionalSentencesComponent,
          },
          {
            path: 'avanzado/passive-voice',
            component: PassiveVoiceComponent,
          },
          {
            path: 'avanzado/direct-and-reported-speech',
            component: DirectAndReportedSpeechComponent,
          },
          {
            path: 'avanzado/say-vs-tell',
            component: SayVsTellComponent,
          },
          {
            path: 'intermedio/do-vs-make',
            component: DoVsMakeComponent,
          },
          {
            path: 'avanzado/delexical-verbs',
            component: DelexicalVerbsComponent,
          },
          {
            path: 'avanzado/linking-words',
            component: LinkingWordsComponent,
          },
          {
            path: 'avanzado/phrasal-prepositional-verbs',
            component: PhrasalPrepositionalVerbsComponent,
          },
          {
            path: 'avanzado/to-get',
            component: VerboToGetComponent,
          },
          {
            path: 'intermedio/prefixes-and-suffixes',
            component: PrefixesAndSuffixesComponent,
          },
          {
            path: 'intermedio/so-vs-such',
            component: SoVsSuchComponent,
          },
          {
            path: 'intermedio/enough-and-too',
            component: EnoughAndTooComponent,
          },
          {
            path: 'avanzado/even',
            component: EvenComponent,
          },
          {
            path: 'avanzado/else',
            component: ElseComponent,
          },
          {
            path: 'intermedio/ever',
            component: EverWhateverWhereverWheneverComponent,
          },
          {
            path: 'avanzado/neither-vs-either',
            component: NeitherVsEitherComponent,
          },
          {
            path: 'avanzado/each-vs-every',
            component: EachVsEveryComponent,
          },
          {
            path: 'intermedio/verbos-irregulares',
            component: ListaCompletaVerbosIrregularesPasadoSimpleComponent,
          },
          {
            path: 'principiante',
            component: PrincipianteComponent,
          },
          {
            path: 'avanzado',
            component: AvanzadoComponent,
          },
          {
            path: 'intermedio',
            component: IntermedioComponent,
          },
        ],
      },
      {
        path: 'indicaciones',
        component: IndicacionesComponent,
        children: [
          { path: 'contacto', component: ContactoComponent },
          { path: 'politica-de-privacidad', component: PrivacidadComponent },
          { path: 'condiciones-de-uso', component: CondicionesComponent },
        ],
      },
    ]),
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatDividerModule,
    MatCardModule,
    MatTabsModule,
    MatSidenavModule,
    MatExpansionModule,
    MatTooltipModule,
    MatFormFieldModule,
    FormsModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatInputModule,
    AsyncPipe,
    MatOptionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatGridListModule,
  ],
  providers: [LeccionesInglesService, provideClientHydration()],
  bootstrap: [AppComponent],
})
export class AppModule {}
