import { Component } from '@angular/core';

@Component({
  selector: 'app-avanzado',
  standalone: true,
  imports: [],
  templateUrl: './avanzado.component.html',
  styleUrl: './avanzado.component.scss'
})
export class AvanzadoComponent {

}
