<div class="pronombres-container">
    <p>Los pronombres son palabras que se usan en lugar de sustantivos para evitar su repetición. Existen diferentes tipos de pronombres en inglés, como los personales, posesivos, reflexivos, y demostrativos.</p>
    <div class="section">
        <div class="section-blanco">
    <h2>Pronombres Personales</h2>
    <p>Estos pronombres se usan para referirse a las personas o cosas. Se dividen en sujeto y objeto.</p>
    
    <table>
        <thead>
            <tr>
                <th>Pronombre de Sujeto</th>
                <th>Traducción</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>I</td>               
                <td>yo</td>
            </tr>
            <tr>
                <td>you</td>       
                <td>tú</td>
            </tr>
            <tr>
                <td>he</td>    
                <td>él</td>
            </tr>
            <tr>
                <td>she</td>         
                <td>ella</td>
            </tr>
            <tr>
                <td>it</td>        
                <td>eso</td>
            </tr>
            <tr>
                <td>we</td>           
                <td>nosotros/as</td>
            </tr>
            <tr>
                <td>you (plural)</td>         
                <td>ustedes</td>
            </tr>
            <tr>
                <td>they</td>        
                <td>ellos/as</td>
            </tr>
        </tbody>
    </table> 
        <h2>Pronombres de Objeto en Inglés</h2>
        <table>
            <thead>
                <tr>
                    <th>Pronombre de Sujeto</th>
                    <th>Pronombre de Objeto</th>
                    <th>Ejemplo en Inglés</th>
                    <th>Traducción</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>I</td>
                    <td>me</td>
                    <td>He called <strong>me</strong>.</td>
                    <td>Él me llamó.</td>
                </tr>
                <tr>
                    <td>You</td>
                    <td>you</td>
                    <td>I will help <strong>you</strong>.</td>
                    <td>Te ayudaré.</td>
                </tr>
                <tr>
                    <td>He</td>
                    <td>him</td>
                    <td>She likes <strong>him</strong>.</td>
                    <td>A ella le gusta él.</td>
                </tr>
                <tr>
                    <td>She</td>
                    <td>her</td>
                    <td>I saw <strong>her</strong> at the store.</td>
                    <td>La vi en la tienda.</td>
                </tr>
                <tr>
                    <td>It</td>
                    <td>it</td>
                    <td>We found <strong>it</strong>.</td>
                    <td>Lo encontramos.</td>
                </tr>
                <tr>
                    <td>We</td>
                    <td>us</td>
                    <td>They invited <strong>us</strong> to the party.</td>
                    <td>Nos invitaron a la fiesta.</td>
                </tr>
                <tr>
                    <td>They</td>
                    <td>them</td>
                    <td>I gave <strong>them</strong> the keys.</td>
                    <td>Les di las llaves.</td>
                </tr>
            </tbody>
        </table>
   </div> </div>
    <div class="section">
        <div class="section-blanco">
        <h2>Pronombres Posesivos</h2>
        <p>Estos pronombres indican posesión.</p>
        
        <table>
            <thead>
                <tr>
                    <th>Pronombre Posesivo</th>
                    <th>Traducción</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>mine</td>
                    <td>mío/a</td>
                </tr>
                <tr>
                    <td>yours</td>
                    <td>tuyo/a</td>
                </tr>
                <tr>
                    <td>his</td>
                    <td>suyo/a (de él)</td>
                </tr>
                <tr>
                    <td>hers</td>
                    <td>suyo/a (de ella)</td>
                </tr>
                <tr>
                    <td>its</td>
                    <td>suyo/a (de eso)</td>
                </tr>
                <tr>
                    <td>ours</td>
                    <td>nuestro/a</td>
                </tr>
                <tr>
                    <td>theirs</td>
                    <td>suyo/a (de ellos/as)</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="section">
        <div class="section-blanco">
        <h2>Pronombres Reflexivos</h2>
        <p>Se utilizan cuando la acción recae sobre el mismo sujeto.</p>
        
        <table>
            <thead>
                <tr>
                    <th>Pronombre Reflexivo</th>
                    <th>Traducción</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>myself</td>
                    <td>yo mismo/a</td>
                </tr>
                <tr>
                    <td>yourself</td>
                    <td>tú mismo/a</td>
                </tr>
                <tr>
                    <td>himself</td>
                    <td>él mismo</td>
                </tr>
                <tr>
                    <td>herself</td>
                    <td>ella misma</td>
                </tr>
                <tr>
                    <td>itself</td>
                    <td>eso mismo</td>
                </tr>
                <tr>
                    <td>ourselves</td>
                    <td>nosotros mismos</td>
                </tr>
                <tr>
                    <td>yourselves</td>
                    <td>ustedes mismos/as</td>
                </tr>
                <tr>
                    <td>themselves</td>
                    <td>ellos/as mismos/as</td>
                </tr>
            </tbody>
        </table>
    </div>  </div>
</div>