<div class="future-perfect">
  <div>
    <p>
      El <strong>future perfect</strong> es un tiempo verbal que se utiliza para
      describir una acción que estará completa en un momento específico en el
      futuro. Indica que algo habrá sucedido antes de un tiempo o evento futuro.
    </p>

    <h2>Estructura</h2>
    <h3>Positivo</h3>
    <p><strong>Sujeto + will have + verbo en participio pasado</strong></p>

    <h3>Negativo</h3>
    <p><strong>Sujeto + will not have + verbo en participio pasado</strong></p>

    <h3>Interrogativo</h3>
    <p><strong>Will + sujeto + have + verbo en participio pasado?</strong></p>
  </div>

  <div class="section-blanco">
    <h2>Principales Usos del Future Perfect</h2>
    <table>
      <thead>
        <tr>
          <th>Uso</th>
          <th>Descripción</th>
          <th>Ejemplo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>A) Completar acciones en un momento futuro</td>
          <td>
            Se utiliza para indicar que una acción se habrá completado antes de
            un momento o evento específico en el futuro.
          </td>
          <td>
            By next year, I <strong>will have saved</strong> enough money for a
            car. (Para el próximo año,
            <strong>habré ahorrado</strong> suficiente dinero para un coche).
          </td>
        </tr>
        <tr>
          <td>B) Predecir resultados</td>
          <td>
            Se usa para predecir que algo habrá ocurrido o se habrá completado
            en el futuro.
          </td>
          <td>
            She <strong>will have finished</strong> the report by Monday. (Ella
            <strong>habrá terminado</strong> el informe para el lunes).
          </td>
        </tr>
        <tr>
          <td>C) Hablar de planes futuros</td>
          <td>
            Se utiliza para hablar de planes o intenciones que se habrán
            realizado en un futuro determinado.
          </td>
          <td>
            We <strong>will have moved</strong> to a new house by the end of the
            month. (Nosotros <strong>habremos mudado</strong> a una nueva casa
            para fin de mes).
          </td>
        </tr>
        <tr>
          <td>D) Hablar sobre experiencias futuras</td>
          <td>
            Se utiliza para referirse a experiencias que se habrán completado en
            un momento futuro.
          </td>
          <td>
            By the time I turn 30, I <strong>will have traveled</strong> to five
            different countries. (Para cuando cumpla 30,
            <strong>habré viajado</strong> a cinco países diferentes).
          </td>
        </tr>
        <tr>
          <td>E) Hablar sobre cambios futuros</td>
          <td>
            Se usa para hablar de cambios que se habrán realizado en un futuro
            determinado.
          </td>
          <td>
            They <strong>will have renovated</strong> the house by next summer.
            (Ellos <strong>habrán renovado</strong> la casa para el próximo
            verano).
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="section">
    <div class="section-blanco">
      <h3>Ejemplos del Future Perfect</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo (Inglés)</th>
            <th>Traducción (Español)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>I will have finished</strong> my homework by 8 PM.</td>
            <td>Yo <strong>habré terminado</strong> mi tarea para las 8 PM.</td>
          </tr>
          <tr>
            <td>
              <strong>She will not have arrived</strong> by the time we leave.
            </td>
            <td>
              Ella <strong>no habrá llegado</strong> para cuando nos vayamos.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Will they have completed</strong> the project by next
              week?
            </td>
            <td>
              ¿<strong>Habrán completado</strong> el proyecto para la próxima
              semana?
            </td>
          </tr>
          <tr>
            <td>
              <strong>We will have traveled</strong> to three countries by the
              end of this year.
            </td>
            <td>
              Nosotros <strong>habremos viajado</strong> a tres países para el
              final de este año.
            </td>
          </tr>
          <tr>
            <td><strong>He will not have seen</strong> that movie before.</td>
            <td>Él <strong>no habrá visto</strong> esa película antes.</td>
          </tr>
          <tr>
            <td>
              <strong>Will you have finished</strong> your report by tomorrow?
            </td>
            <td>¿<strong>Habrás terminado</strong> tu informe para mañana?</td>
          </tr>
          <tr>
            <td>
              <strong>They will have graduated</strong> by the time we
              celebrate.
            </td>
            <td>
              Ellos <strong>habrán graduado</strong> para cuando celebremos.
            </td>
          </tr>
          <tr>
            <td>
              <strong>I will not have read</strong> the book by next week.
            </td>
            <td>
              No <strong>habré leído</strong> el libro para la próxima semana.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Will she have completed</strong> her studies by the end of
              the year?
            </td>
            <td>
              ¿<strong>Habrá completado</strong> sus estudios para el fin del
              año?
            </td>
          </tr>
          <tr>
            <td><strong>We will have finished</strong> the meeting by 2 PM.</td>
            <td>
              Nosotros <strong>habremos terminado</strong> la reunión para las 2
              PM.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
