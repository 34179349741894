<div class="simplePresent-container">
  <p class="parrafo">
    El <strong>Presente Simple</strong> en inglés es uno de los tiempos verbales
    más básicos y usados en el idioma. Se utiliza principalmente para describir
    acciones habituales, rutinas, hechos generales, verdades universales, y
    situaciones permanentes. A continuación, veremos una explicación detallada
    de cómo se forma, cuándo se utiliza, y los casos especiales que debes tener
    en cuenta.
  </p>

  <div class="section">
    <h2 class="section-title">1. Usos del Presente Simple</h2>
    <div class="section-blanco">
      <h3 class="subtitulo">1.1. Acciones Habituales o Rutinas</h3>
      <p class="parrafo">
        Se usa el presente simple para hablar de acciones que realizamos de
        manera regular o rutinaria.
      </p>
      <p class="parrafo">
        <strong>Ejemplo:</strong><em> I go to the gym every day.</em> (Voy al
        gimnasio todos los días.)
      </p>
    </div>

    <div class="section-blanco">
      <h3 class="subtitulo">1.2. Verdades Universales</h3>
      <p class="parrafo">
        Describe hechos científicos o verdades que siempre son ciertas.
      </p>
      <p class="parrafo">
        <strong>Ejemplo:</strong><em> Water boils at 100°C.</em> (El agua hierve
        a 100°C.)
      </p>
    </div>

    <div class="section-blanco">
      <h3 class="subtitulo">1.3. Situaciones Permanentes</h3>
      <p class="parrafo">
        Se emplea para hablar de situaciones o estados que no cambian fácilmente
        con el tiempo.
      </p>
      <p class="parrafo">
        <strong>Ejemplo:</strong><em> They live in New York.</em> (Ellos viven
        en Nueva York.)
      </p>
    </div>

    <div class="section-blanco">
      <h3 class="subtitulo">1.4. Eventos Programados</h3>
      <p class="parrafo">
        El presente simple se utiliza para hablar de eventos futuros que ocurren
        según un calendario o horario establecido.
      </p>
      <p class="parrafo">
        <strong>Ejemplo:</strong><em> The train leaves at 7 PM.</em> (El tren
        sale a las 7 PM.)
      </p>
    </div>
  </div>

  <div class="section">
    <h2 class="section-title">2. Estructura del Presente Simple</h2>

    <div class="section-blanco">
      <h3 class="subtitulo">Forma Afirmativa</h3>
      <p class="parrafo">
        La forma afirmativa del presente simple sigue esta estructura:
      </p>

      <table class="tabla">
        <thead>
          <tr>
            <th>Sujeto</th>
            <th>Verbo (forma base)</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I</td>
            <td>play</td>
            <td>yo juego</td>
          </tr>
          <tr>
            <td>You</td>
            <td>play</td>
            <td>tú juegas</td>
          </tr>
          <tr>
            <td>He/She/It</td>
            <td>plays</td>
            <td>él/ella/ello juega</td>
          </tr>
          <tr>
            <td>We</td>
            <td>play</td>
            <td>nosotros jugamos</td>
          </tr>
          <tr>
            <td>You</td>
            <td>play</td>
            <td>vosotros jugáis</td>
          </tr>
          <tr>
            <td>They</td>
            <td>play</td>
            <td>ellos/ellas juegan</td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Regla:</strong> En la tercera persona del singular (<em
          >he, she, it</em
        >), se añade una <strong>-s</strong> o <strong>-es</strong> al verbo.
      </p>
    </div>
    <div class="section">
      <h2>3. Casos Especiales en la Tercera Persona del Singular</h2>
      <div class="section-blanco">
        <h3>3.1. Añadir "-es" a ciertos verbos</h3>
        <p>
          Para los verbos que terminan en
          <strong>-o, -sh, -ch, -ss, -x, -z</strong>, se añade
          <strong>-es</strong> en lugar de solo <strong>-s</strong>.
        </p>

        <table>
          <thead>
            <tr>
              <th>Verbo</th>
              <th>3ª Persona Singular</th>
              <th>Traducción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>to go</td>
              <td>he/she/it goes</td>
              <td>él/ella/ello va</td>
            </tr>
            <tr>
              <td>to watch</td>
              <td>he/she/it watches</td>
              <td>él/ella/ello mira</td>
            </tr>
          </tbody>
        </table>

        <h3>3.2. Cambiar "-y" a "-ies"</h3>
        <p>
          Cuando un verbo termina en consonante + <strong>-y</strong>, la
          <strong>-y</strong> cambia a <strong>-ies</strong>.
        </p>

        <table>
          <thead>
            <tr>
              <th>Verbo</th>
              <th>3ª Persona Singular</th>
              <th>Traducción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>to study</td>
              <td>he/she/it studies</td>
              <td>él/ella/ello estudia</td>
            </tr>
            <tr>
              <td>to cry</td>
              <td>he/she/it cries</td>
              <td>él/ella/ello llora</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
