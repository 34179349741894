<div class="the-a-an">
    <p>Los artículos en inglés son palabras que acompañan a los sustantivos. Se usan para especificar si estamos hablando de algo en particular o de algo en general.</p>

    <p>En inglés, los artículos son palabras que se utilizan para definir sustantivos. Existen dos tipos de artículos:</p>
    <ul>
        <li><strong>Artículo definido</strong>: "the"</li>
        <li><strong>Artículos indefinidos</strong>: "a" y "an"</li>
    </ul>
    <div class="section">
        <div class="section-blanco">
    <h2>El Artículo Definido "The"</h2>
    <p>El artículo definido "the" se utiliza para referirse a un sustantivo específico que ya ha sido mencionado o que es conocido por el hablante y el oyente. A diferencia del español, "the" no tiene género ni número; es igual para singular y plural.</p>
    
    <h3>Ejemplos:</h3>
    <ul>
        <li><strong>Singular</strong>: "the apple" (la manzana)</li>
        <li><strong>Plural</strong>: "the books" (los libros)</li>
    </ul>

    <h2>Usos del Artículo Definido "The"</h2>
    <p>Se utiliza "the" en las siguientes situaciones:</p>
    <ul>
        <li><strong>Cuando se menciona algo específico</strong>: 
            <br><em>Example</em>: "I visited <strong>the</strong> museum yesterday." (Visité <strong>el</strong> museo ayer.)
        </li>
        <li><strong>Cuando hay una referencia previa</strong>: 
            <br><em>Example</em>: "I bought a shirt. <strong>The</strong> shirt is blue." (Compré una camisa. <strong>La</strong> camisa es azul.)
        </li>
        <li><strong>Para hablar de cosas únicas</strong>: 
            <br><em>Example</em>: "<strong>The</strong> Earth revolves around <strong>the</strong> sun." (<strong>La</strong> Tierra gira alrededor del <strong>sol</strong>.)
        </li>
        <li><strong>Con nombres geográficos</strong>: 
            <br><em>Example</em>: "He climbed <strong>the</strong> Andes." (Él escaló <strong>los</strong> Andes.)
        </li>
        <li><strong>Con objetos conocidos</strong>: 
            <br><em>Example</em>: "Please turn off <strong>the</strong> light." (Por favor, apaga <strong>la</strong> luz.)
        </li>
    </ul>

    <h2>Cuándo No Usar "The"</h2>
    <p>En inglés, hay situaciones en las que <strong>no</strong> se usa "the":</p>
    <ul>
        <li><strong>Con sustantivos generales</strong>: 
            <br><em>Example</em>: "Cats are playful." (Los gatos son juguetones.)
        </li>
        <li><strong>Con nombres propios</strong>: 
            <br><em>Example</em>: "Michael is a great artist." (Michael es un gran artista.)
        </li>
        <li><strong>Con sustantivos incontables en sentido general</strong>: 
            <br><em>Example</em>: "Rice is a staple food." (El arroz es un alimento básico.)
        </li>
        <li><strong>Con plurales indeterminados</strong>: 
            <br><em>Example</em>: "Students should study hard." (Los estudiantes deben estudiar mucho.)
        </li>
    </ul>
</div>
        <div class="section-blanco">

    <h2>Artículos Indefinidos: "A" y "An"</h2>
    <p> Los artículos indefinidos "a" y "an" se utilizan para referirse a un sustantivo no específico o que se menciona por primera vez. La elección entre "a" y "an" depende del sonido inicial de la palabra que sigue, no de la letra.</p>
    
    <h2>Tabla de Artículos</h2>
    <table>
        <thead>
            <tr>
                <th>Artículo</th>
                <th>Tipo</th>
                <th>Uso</th>
                <th>Ejemplo</th>
                <th>Traducción</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><strong>The</strong></td>
                <td>Definido</td>
                <td>Se usa para referirse a algo específico o conocido, tanto en singular como en plural.</td>
                <td class="example">The dog is barking</td>
                <td>El perro está ladrando.</td>
            </tr>
            <tr>
                <td><strong>The</strong></td>
                <td>Definido</td>
                <td>Con nombres de lugares únicos o específicos (ríos, océanos, montañas).</td>
                <td class="example">The Pacific Ocean</td>
                <td>El océano Pacífico.</td>
            </tr>
            <tr>
                <td><strong>A</strong></td>
                <td>Indefinido</td>
                <td>Se usa antes de palabras que comienzan con sonido consonante.</td>
                <td class="example">A car</td>
                <td>Un coche.</td>
            </tr>
            <tr>
                <td><strong>A</strong></td>
                <td>Indefinido</td>
                <td>Se usa con sustantivos contables en singular.</td>
                <td class="example">A teacher</td>
                <td>Un profesor.</td>
            </tr>
            <tr>
                <td><strong>An</strong></td>
                <td>Indefinido</td>
                <td>Se usa antes de palabras que comienzan con sonido vocal (a, e, i, o, u).</td>
                <td class="example">An apple</td>
                <td>Una manzana.</td>
            </tr>
            <tr>
                <td><strong>An</strong></td>
                <td>Indefinido</td>
                <td>Se usa antes de sustantivos contables en singular.</td>
                <td class="example">An hour</td>
                <td>Una hora.</td>
            </tr>
        </tbody>
    </table>

    <h2>Comparación entre "a" y "an"</h2>
    <p>La diferencia entre <strong>"a"</strong> y <strong>"an"</strong> depende del sonido que inicia la palabra siguiente:</p>
    <ul>
        <li>Usamos <strong>"a"</strong> antes de palabras que comienzan con un sonido consonante, por ejemplo: <strong>"a</strong> dog (un perro).</li>
        <li>Usamos <strong>"an"</strong> antes de palabras que comienzan con un sonido vocal, por ejemplo: <strong>"an"</strong> orange (una naranja).</li>
    </ul>

    <h2>Más Ejemplos</h2>
    <h3>Ejemplos de <strong>"the"</strong>:</h3>
    <ul>
        <li><span class="example">The book is on the table</span> (El libro está sobre la mesa).</li>
        <li><span class="example">I visited the museum</span> (Visité el museo).</li>
        <li><span class="example">The stars are bright tonight</span> (Las estrellas están brillantes esta noche).</li>
    </ul>

    <h3>Ejemplos de <strong>"a"</strong>:</h3>
    <ul>
        <li><span class="example">A car is parked outside</span> (Un coche está estacionado afuera).</li>
        <li><span class="example">I want to eat a sandwich</span> (Quiero comer un sándwich).</li>
        <li><span class="example">She adopted a puppy</span> (Ella adoptó un cachorro).</li>
    </ul>

    <h3>Ejemplos de <strong>"an"</strong>:</h3>
    <ul>
        <li><span class="example">An elephant is big</span> (Un elefante es grande).</li>
        <li><span class="example">He is an artist</span> (Él es un artista).</li>
        <li><span class="example">I need an umbrella</span> (Necesito un paraguas).</li>
    </ul>
</div>
</div>
</div>
