import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-used-to',
  standalone: true,
  imports: [],
  templateUrl: './used-to.component.html',
  styleUrl: './used-to.component.scss',
})
export class UsedToComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Used to';
  override title = 'Used to';
}
