import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-even',
  standalone: true,
  imports: [],
  templateUrl: './even.component.html',
  styleUrl: './even.component.scss',
})
export class EvenComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Even';
  override title = 'Even';
}
