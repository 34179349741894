import { Component } from '@angular/core';

import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-colores',
  templateUrl: './colores.component.html',
  styleUrl: './colores.component.scss',
})
export class ColoresComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Colores';
  override title = 'Colores -  “Colors”';
}
