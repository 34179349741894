import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-so-vs-such',
  standalone: true,
  imports: [],
  templateUrl: './so-vs-such.component.html',
  styleUrl: './so-vs-such.component.scss',
})
export class SoVsSuchComponent extends LeccionBaseComponent {
  override descripcionMenu = 'So vs. such';
  override title = 'So vs. Such';
}
