<div class="each-vs-every">
  <p>
    En inglés, <strong>"each"</strong> y <strong>"every"</strong> se traducen
    como "cada" en español, pero su uso tiene matices diferentes. Ambos se
    utilizan con sustantivos en singular, pero el enfoque y el contexto de uso
    varían. Aquí explicamos cómo y cuándo usar cada uno.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Uso de "Each"</h2>
      <p>
        <strong>"Each"</strong> se usa para enfocarse en los elementos de un
        grupo individualmente, uno por uno.
      </p>

      <table border="1" cellpadding="10">
        <tr>
          <th>Ejemplo en Inglés</th>
          <th>Traducción en Español</th>
        </tr>
        <tr>
          <td>Each student has their own book.</td>
          <td>Cada estudiante tiene su propio libro.</td>
        </tr>
        <tr>
          <td>I spoke to each person at the meeting.</td>
          <td>Hablé con cada persona en la reunión.</td>
        </tr>
        <tr>
          <td>She gave each child a different toy.</td>
          <td>Ella le dio a cada niño un juguete diferente.</td>
        </tr>
        <tr>
          <td>Each piece of furniture in the room was carefully chosen.</td>
          <td>Cada mueble de la habitación fue cuidadosamente elegido.</td>
        </tr>
        <tr>
          <td>Each day brings new opportunities.</td>
          <td>Cada día trae nuevas oportunidades.</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Uso de "Every"</h2>
      <p>
        <strong>"Every"</strong> se utiliza para referirse al grupo en su
        totalidad y para hablar de frecuencia o algo común a todos los elementos
        del grupo.
      </p>

      <table border="1" cellpadding="10">
        <tr>
          <th>Ejemplo en Inglés</th>
          <th>Traducción en Español</th>
        </tr>
        <tr>
          <td>Every student in the class passed the test.</td>
          <td>Todos los estudiantes de la clase aprobaron el examen.</td>
        </tr>
        <tr>
          <td>He goes to the gym every morning.</td>
          <td>Él va al gimnasio cada mañana.</td>
        </tr>
        <tr>
          <td>Every country has its own culture.</td>
          <td>Cada país tiene su propia cultura.</td>
        </tr>
        <tr>
          <td>She checks her email every hour.</td>
          <td>Ella revisa su correo electrónico cada hora.</td>
        </tr>
        <tr>
          <td>Every house on the street has a garden.</td>
          <td>Cada casa en la calle tiene un jardín.</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Comparación Rápida</h2>
      <p>
        Aunque ambos términos significan "cada", su uso cambia dependiendo del
        contexto:
      </p>

      <table border="1" cellpadding="10">
        <tr>
          <th>Each</th>
          <th>Every</th>
        </tr>
        <tr>
          <td>
            Se enfoca en los elementos individualmente, uno por uno.<br />Ejemplo:
            Each member is responsible for their part.
          </td>
          <td>
            Se enfoca en el grupo completo, en su totalidad.<br />Ejemplo: Every
            member must be present at the meeting.
          </td>
        </tr>
        <tr>
          <td>
            Puede usarse como pronombre o adjetivo.<br />Ejemplo: I bought three
            books, and each was interesting.
          </td>
          <td>
            Sólo se usa como adjetivo.<br />Ejemplo: Every day is a new chance.
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
