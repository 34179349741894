<div class="thereIsThereAre">
  <div class="section">
    <div class="section-blanco">
      <h2>Introducción a "There is" y "There are"</h2>

      <p>
        En inglés, usamos las expresiones <strong>"there is"</strong> y
        <strong>"there are"</strong> para indicar que algo
        <strong>existe</strong> o está <strong>presente</strong> en un lugar
        determinado. Ambas expresiones se traducen al español como
        <strong>"hay"</strong>, pero se utilizan de manera diferente dependiendo
        de si el sustantivo es singular o plural.
      </p>
    </div>
    <div class="section-blanco">
      <h2>Uso de "There is"</h2>
      <p>
        <strong>"There is"</strong> se usa cuando hablamos de una sola cosa
        (singular) o de algo incontable. Aquí tienes algunos ejemplos:
      </p>

      <h2>Estructura de "There is"</h2>
      <ul>
        <li>
          <strong>Afirmativo:</strong> There is + sustantivo
          singular/incontable.
          <ul>
            <li>Ejemplo: There is a book on the table.</li>
          </ul>
        </li>
        <li>
          <strong>Negativo:</strong> There isn’t + sustantivo
          singular/incontable.
          <ul>
            <li>Ejemplo: There isn’t a computer in the room.</li>
          </ul>
        </li>
      </ul>

      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>There is</strong> a cat on the roof.</td>
            <td><strong>Hay</strong> un gato en el techo.</td>
          </tr>
          <tr>
            <td><strong>There is</strong> some water in the bottle.</td>
            <td><strong>Hay</strong> agua en la botella.</td>
          </tr>
          <tr>
            <td><strong>There is</strong> a book on the table.</td>
            <td><strong>Hay</strong> un libro sobre la mesa.</td>
          </tr>
          <tr>
            <td><strong>There is</strong> sugar on the table.</td>
            <td><strong>Hay</strong> azúcar en la mesa.</td>
          </tr>
          <tr>
            <td><strong>There is</strong> a new student in class.</td>
            <td><strong>Hay</strong> un nuevo estudiante en clase.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Uso de "There are"</h2>
      <p>
        <strong>"There are"</strong> se usa para hablar de más de una cosa
        (plural). Aquí tienes algunos ejemplos:
      </p>

      <h2>Estructura de "There are"</h2>
      <ul>
        <li>
          <strong>Afirmativo:</strong> There are + sustantivo plural.
          <ul>
            <li>Ejemplo: There are five chairs in the room.</li>
          </ul>
        </li>
        <li>
          <strong>Negativo:</strong> There aren’t + sustantivo plural.
          <ul>
            <li>Ejemplo: There aren’t any books on the shelf.</li>
          </ul>
        </li>
      </ul>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>There are</strong> two cats on the roof.</td>
            <td><strong>Hay</strong> dos gatos en el techo.</td>
          </tr>
          <tr>
            <td><strong>There are</strong> many cars on the street.</td>
            <td><strong>Hay</strong> muchos autos en la calle.</td>
          </tr>
          <tr>
            <td><strong>There are</strong> some chairs in the living room.</td>
            <td><strong>Hay</strong> algunas sillas en la sala.</td>
          </tr>
          <tr>
            <td><strong>There are</strong> ten students in the classroom.</td>
            <td><strong>Hay</strong> diez estudiantes en el salón.</td>
          </tr>
          <tr>
            <td><strong>There are</strong> several dogs in the park.</td>
            <td><strong>Hay</strong> varios perros en el parque.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Diferencias clave</h2>
      <p>En resumen, la diferencia principal entre ambas expresiones es:</p>
      <ul>
        <li>
          <strong>"There is"</strong> se usa con sustantivos
          <strong>singulares</strong> o <strong>incontables</strong>.
          <ul>
            <li>Ejemplo: There is a pen on the desk.</li>
          </ul>
        </li>
        <li>
          <strong>"There are"</strong> se usa con sustantivos
          <strong>plurales</strong>.
          <ul>
            <li>Ejemplo: There are three pens on the desk.</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h2>Cómo hacer preguntas con "There is" y "There are"</h2>

      <p>
        En inglés, para preguntar si algo existe o está presente en un lugar,
        usamos <strong>"there is"</strong> o <strong>"there are"</strong>, pero
        el truco está en cambiar el orden de las palabras. Al formular una
        pregunta, el verbo "to be" (is/are) se coloca antes del sujeto.
      </p>

      <h2>Pregunta con "There is"</h2>
      <p>
        <strong>"Is there"</strong> se usa para hacer preguntas sobre una sola
        cosa o algo incontable. La estructura básica es:
      </p>
      <p><strong>Is there + sustantivo singular o incontable?</strong></p>
      <p>
        Esta forma se usa para preguntar si existe algo que no se puede contar
        en unidades específicas (agua, dinero, tiempo) o cuando preguntamos por
        una sola cosa. Por ejemplo:
      </p>

      <table>
        <thead>
          <tr>
            <th>Pregunta en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Is there</strong> a bank nearby?</td>
            <td>¿<strong>Hay</strong> un banco cerca?</td>
          </tr>
          <tr>
            <td><strong>Is there</strong> any water left?</td>
            <td>¿<strong>Queda</strong> algo de agua?</td>
          </tr>
          <tr>
            <td><strong>Is there</strong> a park in this town?</td>
            <td>¿<strong>Hay</strong> un parque en este pueblo?</td>
          </tr>
          <tr>
            <td><strong>Is there</strong> a book on the table?</td>
            <td>¿<strong>Hay</strong> un libro sobre la mesa?</td>
          </tr>
          <tr>
            <td><strong>Is there</strong> any sugar in the kitchen?</td>
            <td>¿<strong>Hay</strong> azúcar en la cocina?</td>
          </tr>
        </tbody>
      </table>

      <h2>Pregunta con "There are"</h2>
      <p>
        <strong>"Are there "</strong> se usa para hacer preguntas sobre más de
        una cosa (plural). La estructura básica es:
      </p>
      <p><strong>Are there + sustantivo plural?</strong></p>
      <p>
        Se usa cuando quieres preguntar sobre la existencia o presencia de
        varias cosas o personas. Aquí tienes algunos ejemplos:
      </p>

      <table>
        <thead>
          <tr>
            <th>Pregunta en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Are there</strong> any people at the party?</td>
            <td>¿<strong>Hay</strong> gente en la fiesta?</td>
          </tr>
          <tr>
            <td><strong>Are there</strong> two cars in the garage?</td>
            <td>¿<strong>Hay</strong> dos autos en el garaje?</td>
          </tr>
          <tr>
            <td><strong>Are there</strong> any chairs available?</td>
            <td>¿<strong>Hay</strong> sillas disponibles?</td>
          </tr>
          <tr>
            <td><strong>Are there</strong> many students in the classroom?</td>
            <td>¿<strong>Hay</strong> muchos estudiantes en el aula?</td>
          </tr>
          <tr>
            <td><strong>Are there</strong> any restaurants nearby?</td>
            <td>¿<strong>Hay</strong> restaurantes cerca?</td>
          </tr>
        </tbody>
      </table>

      <h2>Uso de la contracción "there's"</h2>
      <p>
        En inglés, <strong>"there's"</strong> es la forma contraída de
        <strong>"there is"</strong> y se usa cuando nos referimos a una sola
        cosa o algo incontable.
      </p>

      <h3>Ejemplos de "there's"</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Yes, <strong>there's</strong> a bank nearby.</td>
            <td>Sí, <strong>hay</strong> un banco cerca.</td>
          </tr>
          <tr>
            <td>No, <strong>there's</strong> not any milk in the fridge.</td>
            <td>No, no <strong>hay</strong> leche en el refrigerador.</td>
          </tr>
          <tr>
            <td>
              Is there a computer in the office? Yes,
              <strong>there's</strong> one.
            </td>
            <td>
              ¿<strong>Hay</strong> una computadora en la oficina? Sí,
              <strong>hay</strong> una.
            </td>
          </tr>
          <tr>
            <td>No, <strong>there's</strong> not a problem.</td>
            <td>No, no <strong>hay</strong> un problema.</td>
          </tr>
          <tr>
            <td><strong>There's</strong> a lot of homework to do.</td>
            <td><strong>Hay</strong> mucha tarea por hacer.</td>
          </tr>
        </tbody>
      </table>

      <h3>Cuándo no usar "there's"</h3>
      <p>
        La contracción <strong>"there's"</strong> no se utiliza en contextos
        plurales. Para plural, se debe usar <strong>"there are"</strong> en su
        forma completa. Ejemplos:
      </p>

      <h3>Ejemplos sin contracción "there are"</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Yes, <strong>there are</strong> two banks nearby.</td>
            <td>Sí, <strong>hay</strong> dos bancos cerca.</td>
          </tr>
          <tr>
            <td>
              No, <strong>there aren't</strong> any computers in the office.
            </td>
            <td>No, no <strong>hay</strong> computadoras en la oficina.</td>
          </tr>
          <tr>
            <td>
              Are there many people at the party? Yes,
              <strong>there are</strong>.
            </td>
            <td>
              ¿<strong>Hay</strong> mucha gente en la fiesta? Sí,
              <strong>hay</strong>.
            </td>
          </tr>
          <tr>
            <td>No, <strong>there aren't</strong> any problems.</td>
            <td>No, no <strong>hay</strong> problemas.</td>
          </tr>
          <tr>
            <td>
              Are there any restaurants nearby? Yes, <strong>there are</strong>.
            </td>
            <td>
              ¿<strong>Hay</strong> restaurantes cerca? Sí,
              <strong>hay</strong>.
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Cómo responder a las preguntas</h2>
      <p>
        Cuando respondemos a las preguntas formuladas con
        <strong>"there is"</strong> y <strong>"there are"</strong>, utilizamos
        la misma estructura del verbo "to be". Las respuestas pueden ser
        afirmativas o negativas:
      </p>

      <h3>Respuestas afirmativas</h3>
      <ul>
        <li><strong>Yes, there is.</strong> (para singular o incontable)</li>
        <li><strong>Yes, there are.</strong> (para plural)</li>
      </ul>

      <h3>Respuestas negativas</h3>
      <ul>
        <li><strong>No, there isn't.</strong> (para singular o incontable)</li>
        <li><strong>No, there aren't.</strong> (para plural)</li>
      </ul>

      <h2>Ejemplos de respuestas</h2>
      <ul>
        <li>
          <strong>Is there</strong> a computer in the office? -
          <em>No, <strong>there isn't</strong>.</em>
        </li>
        <li>
          <strong>Are there</strong> any books on the shelf? -
          <em>Yes, <strong>there are</strong>.</em>
        </li>
        <li>
          <strong>Is there</strong> any milk in the fridge? -
          <em>No, <strong>there isn't</strong>.</em>
        </li>
        <li>
          <strong>Are there</strong> two chairs in the room? -
          <em>Yes, <strong>there are</strong>.</em>
        </li>
      </ul>
    </div>
  </div>
</div>
