import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-past-simple',
  standalone: true,
  imports: [],
  templateUrl: './past-simple.component.html',
  styleUrl: './past-simple.component.scss',
})
export class PastSimpleComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Pasado simple';
  override title = 'Pasado simple - "Past simple"';
}
