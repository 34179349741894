
    <div class="numerosContainer">
    <div> 
        <p>Para contar en <strong>inglés</strong>, primero debes dominar los <strong>números del 1 al 20</strong> . Aprender a escribir y pronunciar estos números básicos te facilitará avanzar hacia números más grandes, incluso hasta 1000. Aunque memorizar los primeros 20 puede ser complicado, es esencial.</p>
        <p>Aquí te presentamos la <strong>escritura</strong> y <strong>pronunciación</strong> de cada número. Es importante que te concentres en cada sonido, ya que más adelante, los usarás como base para contar números más grandes. ¡<strong>Comencemos</strong>!</p>
        <div class="section">
        <div class="section-blanco">
        <h2 class="numeros-title">Números del 1 - 20</h2>
        <p>Los números del <strong>1 al 10</strong> son relativamente fáciles de aprender.</p>
        <p>Sin embargo, del <strong>11 al 20</strong> las cosas se complican un poco, ya que son <strong>números compuestos</strong> y sus pronunciaciones involucran más sonidos. Por ejemplo, al decir el número <strong>14</strong>, pronunciarías algo como <em>“for-TIN”</em>.</p>
        <table>
            <thead>
                <tr>
                    <th>Número</th>
                    <th>Escritura en inglés</th>
                    <th>Pronunciación</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="numeros-cell">1</td>
                    <td class="numeros-cell">One</td>
                    <td class="numeros-cell">/uan/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">2</td>
                    <td class="numeros-cell">Two</td>
                    <td class="numeros-cell">/chu/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">3</td>
                    <td class="numeros-cell">Three</td>
                    <td class="numeros-cell">/three/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">4</td>
                    <td class="numeros-cell">Four</td>
                    <td class="numeros-cell">/fohr/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">5</td>
                    <td class="numeros-cell">Five</td>
                    <td class="numeros-cell">/faiv/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">6</td>
                    <td class="numeros-cell">Six</td>
                    <td class="numeros-cell">/siks/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">7</td>
                    <td class="numeros-cell">Seven</td>
                    <td class="numeros-cell">/se-ven/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">8</td>
                    <td class="numeros-cell">Eight</td>
                    <td class="numeros-cell">/ayt/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">9</td>
                    <td class="numeros-cell">Nine</td>
                    <td class="numeros-cell">/nain/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">10</td>
                    <td class="numeros-cell">Ten</td>
                    <td class="numeros-cell">/ten/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">11</td>
                    <td class="important">Eleven</td>
                    <td class="important">/i-LE-ven/</td>
                </tr>

                <tr>
                    <td class="numeros-cell">12</td>
                    <td class="important">Twelve</td>
                    <td class="important">/tuel-v/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">13</td>
                    <td class="numeros-cell">Thirteen</td>
                    <td class="numeros-cell">/th’r-TIN/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">14</td>
                    <td class="numeros-cell">Fourteen</td>
                    <td class="numeros-cell">/for-TIN/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">15</td>
                    <td class="numeros-cell">Fifteen</td>
                    <td class="numeros-cell">/fif-TIN/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">16</td>
                    <td class="numeros-cell">Sixteen</td>
                    <td class="numeros-cell">/siks-TIN/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">17</td>
                    <td class="numeros-cell">Seventeen</td>
                    <td class="numeros-cell">/se-vin-TIN/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">18</td>
                    <td class="numeros-cell">Eighteen</td>
                    <td class="numeros-cell">/ay[T]-TIN/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">19</td>
                    <td class="numeros-cell">Nineteen</td>
                    <td class="numeros-cell">/nain-TIN/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">20</td>
                    <td class="highlight">Twenty</td>
                    <td class="highlight">/Tuen-[t]i/</td>
                </tr>
            </tbody>
        </table></div></div>
    </div>
    <div class="section">
        <div class="section-blanco">
        <h2  class="numeros-title">Números del 21 - 99</h2>
        <table>
            <thead>
                <tr>
                    <th>Número</th>
                    <th>Escritura</th>
                    <th>Pronunciación</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="numeros-cell">21</td>
                    <td class="numeros-cell">Twenty-One</td>
                    <td class="numeros-cell">/tuen-ti-uan/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">22</td>
                    <td class="numeros-cell">Twenty-Two</td>
                    <td class="numeros-cell">/tuen-ti-TU/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">23</td>
                    <td class="numeros-cell">Twenty-Three</td>
                    <td class="numeros-cell">/tuen-ti-THRi/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">24</td>
                    <td class="numeros-cell">Twenty-Four</td>
                    <td class="numeros-cell">/tuen-ti-FOR/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">25</td>
                    <td class="numeros-cell">Twenty-Five</td>
                    <td class="numeros-cell">/tuen-ti-FAIV/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">30</td>
                    <td class="numeros-cell">Thirty</td>
                    <td class="numeros-cell">/TH’R-di/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">40</td>
                    <td class="numeros-cell">Forty</td>
                    <td class="numeros-cell">/FOHR-di/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">50</td>
                    <td class="numeros-cell">Fifty</td>
                    <td class="numeros-cell">/FIHF-di/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">60</td>
                    <td class="numeros-cell">Sixty</td>
                    <td class="numeros-cell">/SIKS-di/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">70</td>
                    <td class="numeros-cell">Seventy</td>
                    <td class="numeros-cell">/SEH-vihn-di/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">80</td>
                    <td class="numeros-cell">Eighty</td>
                    <td class="numeros-cell">/AY-di/</td>
                </tr>
                <tr>
                    <td class="numeros-cell">90</td>
                    <td class="numeros-cell">Ninety</td>
                    <td class="numeros-cell">/NAIN-di/</td>
                </tr>
            </tbody>
        </table></div>
    </div>
    <div class="section">
        <div class="section-blanco">
        <h2 class="numeros-title"> Números 100 - 1000</h2>
        <p>Ahora, veamos cómo se pronuncian los números más grandes.</p>
        <p>Se pone como ejemplos algunos pocos ya que los demas siguen la misma regla.</p>
        <p>es decir, numero + Hundred o Thousand respecticamente dependiendo si son cien(Hundred) o mil(Thousand)</p>
            <table>
                <thead>
                    <tr>
                        <th>Número</th>
                        <th>Escritura</th>
                        <th>Pronunciación</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="numeros-cell">100</td>
                        <td class="numeros-cell">One Hundred</td>
                        <td class="numeros-cell">/uan-HUN-dʒrih[d]/ suena como: uan ján-dred</td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">200</td>
                        <td class="numeros-cell">Two Hundred</td>
                        <td class="numeros-cell">/tu-HUN-dʒrih[d] suena como: chu ján-dred </td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">500</td>
                        <td class="numeros-cell">Five Hundred</td>
                        <td class="numeros-cell">/faiv-HUN-dʒrih[d]/  suena como: faiv ján-dred </td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">1000</td>
                        <td class="numeros-cell">One Thousand</td>
                        <td class="numeros-cell">/uan-thauz(e)nd/  suena como: uan tháu-zand </td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">4000</td>
                        <td class="numeros-cell">Four Thousand</td>
                        <td class="numeros-cell">/uan-thauz(e)nd/  suena como: for tháu-zand </td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">5000</td>
                        <td class="numeros-cell">Nine Thousand</td>
                        <td class="numeros-cell">/uan-thauz(e)nd/  suena como: nain tháu-zand </td>
                    </tr>
                </tbody>
            </table>
        <div>
            <span>Aquí tienes algunos ejemplos con decenas y unidades</span>
            <table>
                <thead>
                    <tr>
                        <th>Número</th>
                        <th>Escritura</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="numeros-cell">120</td>
                        <td class="numeros-cell"><span>One Hundred Twenty</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">245</td>
                        <td class="numeros-cell"><span>Two Hundred Forty-Five</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">379</td>
                        <td class="numeros-cell"><span>Three Hundred Seventy-Nine</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">410</td>
                        <td class="numeros-cell"><span>Four Hundred Ten</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">512</td>
                        <td class="numeros-cell"><span>Five Hundred Twelve</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">678</td>
                        <td class="numeros-cell"><span>Six Hundred Seventy-Eight</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">785</td>
                        <td class="numeros-cell"><span>Seven Hundred Eighty-Five</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">892</td>
                        <td class="numeros-cell"><span>Eight Hundred Ninety-Two</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">999</td>
                        <td class="numeros-cell"><span>Nine Hundred Ninety-Nine</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">1234</td>
                        <td class="numeros-cell"><span>One Thousand Two Hundred Thirty-Four</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">2345</td>
                        <td class="numeros-cell"><span>Two Thousand Three Hundred Forty-Five</span></td>
                    </tr>
                    <tr>
                        <td class="numeros-cell">3456</td>
                        <td class="numeros-cell"><span>Three Thousand Four Hundred Fifty-Six</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
