import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export class ConfiguracionPaso {
  title: string;
  nameButtonPrevious: string;
  nameButtonNext: string;
  urlPrevious: string;
  urlNext: string;
}
interface ItemMenuDetalle {
  descripcionMenu: string;
  detalle: string;
}

@Injectable({ providedIn: 'root' })
export class LeccionesInglesService {
  configuracionPaso$ = new BehaviorSubject<ConfiguracionPaso>(null);
  showItem$ = new BehaviorSubject<boolean>(false);
  nivel$ = new BehaviorSubject<string>(Nivel.Principiante);
  lastNivelActivo: Nivel = Nivel.Principiante;

  itemsMenu: {
    path: string;
    descripcionMenu: string;
    nivel: Nivel;
    isChildOf: string;
  }[] = [
    {
      path: '/lecciones/principiante/abecedario',
      descripcionMenu: 'Abecedario',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/numbers',
      descripcionMenu: 'Numeros',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/ordinal-numbers',
      descripcionMenu: 'Numeros Ordinales',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/colors',
      descripcionMenu: 'Colores',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/calendar',
      descripcionMenu: 'Calendario',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/pronombres',
      descripcionMenu: 'Pronombres',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/sustantivos',
      descripcionMenu: 'Sustantivos',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/simple-present',
      descripcionMenu: 'Presente Simple',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/wh-question',
      descripcionMenu: 'Preguntas Informativas',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/verbs',
      descripcionMenu: 'Verbos en Inglés',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/verbo-to-be',
      descripcionMenu: 'To Be',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/verbo-to-do',
      descripcionMenu: 'To Do',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/adverbs-of-frecuency',
      descripcionMenu: 'Adverbios de Frecuencia',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/adjetives',
      descripcionMenu: 'Adjetivos',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/verbo-to-have',
      descripcionMenu: 'To-Have',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/this-that-these-those',
      descripcionMenu: 'This - These - That - Those',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },

    {
      path: '/lecciones/principiante/the-a-an',
      descripcionMenu: 'Artículos: The, A, An',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/preposicions-in-on-at',
      descripcionMenu: 'IN-ON-AT Preposicions',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/preposicions-of-place-in-on-at',
      descripcionMenu: 'Preposiciones de lugar',
      nivel: Nivel.Principiante,
      isChildOf: 'IN-ON-AT Preposicions',
    },
    {
      path: '/lecciones/principiante/preposicions-of-time-in-on-at',
      descripcionMenu: 'Preposiciones de tiempo',
      nivel: Nivel.Principiante,
      isChildOf: 'IN-ON-AT Preposicions',
    },
    {
      path: '/lecciones/principiante/preposicions-of-state-in-on-at',
      descripcionMenu: 'Preposiciones de estado',
      nivel: Nivel.Principiante,
      isChildOf: 'IN-ON-AT Preposicions',
    },
    {
      path: '/lecciones/principiante/countable-and-uncountable-nouns',
      descripcionMenu: 'Contables/Incontables',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/quantifier',
      descripcionMenu: 'Cuantificadores',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/principiante/modal-verbs',
      descripcionMenu: 'Verbos modales en Inglés',
      nivel: Nivel.Principiante,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/there-is-there-are',
      descripcionMenu: 'There Is - There Are',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/adverbs',
      descripcionMenu: 'Adverbios - Adverbs',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/indefinite-pronouns',
      descripcionMenu: 'Pronombres indefinidos',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/relative-pronouns',
      descripcionMenu: 'Pronombres relativos',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/conjunctions',
      descripcionMenu: 'Conjunciones',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/comparative',
      descripcionMenu: 'Adjetivos Comparativos',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/superlative',
      descripcionMenu: 'Superlative',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/adjectives-that-end-in-ed-and-ing',
      descripcionMenu: 'Adjetivos: uso “ED Y ING“',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/like-and-as',
      descripcionMenu: 'Like and As',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/continuous-present',
      descripcionMenu: 'Presente continuo',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/past-simple',
      descripcionMenu: 'Pasado simple',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/verbos-irregulares',
      descripcionMenu: 'Lista verbos Irregulares',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/past-continuous',
      descripcionMenu: 'Pasado continuo',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/used-to',
      descripcionMenu: 'Used to',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/future-simple',
      descripcionMenu: 'Futuro simple',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/future-continuous',
      descripcionMenu: 'Futuro continuo',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/present-perfect',
      descripcionMenu: 'Present perfect',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/present-perfect-continuous',
      descripcionMenu: 'Present perfect continuous',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/already-just-still-yet-for-since-ago',
      descripcionMenu: 'Use of Already, Just, Still...',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/modal-perfects',
      descripcionMenu: 'Modal perfects',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/past-perfect',
      descripcionMenu: 'Past-perfect',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/past-perfect-continuous',
      descripcionMenu: 'Past perfect continuous',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/future-perfect',
      descripcionMenu: 'Future perfect',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/future-perfect-continuous',
      descripcionMenu: 'Future perfect continuous',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/condicional-sentences',
      descripcionMenu: 'Condicional sentences',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/passive-voice',
      descripcionMenu: 'Passive voice',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/direct-and-reported-speech',
      descripcionMenu: 'Direct and reported speech',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/say-vs-tell',
      descripcionMenu: 'Say vs. Tell',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/do-vs-make',
      descripcionMenu: 'Do vs. Make',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/delexical-verbs',
      descripcionMenu: 'Delexical Verbs',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/linking-words',
      descripcionMenu: 'Linking words',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/phrasal-prepositional-verbs',
      descripcionMenu: 'Phrasal/prepositional verbs',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/to-get',
      descripcionMenu: 'Usos del verbo To Get',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/prefixes-and-suffixes',
      descripcionMenu: 'Prefixes and suffixes',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/so-vs-such',
      descripcionMenu: 'So vs. such',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/enough-and-too',
      descripcionMenu: 'Enough and Too',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/even',
      descripcionMenu: 'Even',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/else',
      descripcionMenu: 'Else',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/intermedio/ever',
      descripcionMenu: 'Ever suffix',
      nivel: Nivel.Intermedio,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/neither-vs-either',
      descripcionMenu: 'Neither vs. Either',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
    {
      path: '/lecciones/avanzado/each-vs-every',
      descripcionMenu: 'Each vs. Every',
      nivel: Nivel.Avanzado,
      isChildOf: '',
    },
  ];

  itemsMenuConDetalle: ItemMenuDetalle[] = [
    {
      descripcionMenu: 'Abecedario',
      detalle: 'Introducción a las letras del abecedario en inglés.',
    },
    {
      descripcionMenu: 'Numeros',
      detalle: 'Aprende a contar en inglés, desde el 1 hasta miles.',
    },
    {
      descripcionMenu: 'Numeros Ordinales',
      detalle:
        'Cómo usar los números ordinales para indicar posición o secuencia.',
    },
    {
      descripcionMenu: 'Colores',
      detalle: 'Colores básicos en inglés y su pronunciación.',
    },
    {
      descripcionMenu: 'Calendario',
      detalle: 'Aprender los días de la semana, meses.',
    },
    {
      descripcionMenu: 'Pronombres',
      detalle: 'Estudio de pronombres personales, posesivos y reflexivos.',
    },
    {
      descripcionMenu: 'Sustantivos',
      detalle: 'Tipos de sustantivos y su plural.',
    },
    {
      descripcionMenu: 'Presente Simple',
      detalle:
        'Uso y reglas del presente simple en oraciones afirmativas, negativas e interrogativas.',
    },
    {
      descripcionMenu: 'Preguntas Informativas',
      detalle:
        'Cómo hacer preguntas con palabras como “what”, “where”, “why” y más.',
    },
    {
      descripcionMenu: 'Verbos en Inglés',
      detalle:
        'Introduccion de los verbos más comunes en inglés y sus formas. Varbos Auxiliares, verbos modales, verbos compuestos y verbos transitivos.',
    },
    {
      descripcionMenu: 'To Be',
      detalle: 'Uso del verbo “to be” en diferentes tiempos y formas.',
    },
    {
      descripcionMenu: 'To Do',
      detalle: 'Uso del verbo “to do” en diferentes tiempos y formas.',
    },
    {
      descripcionMenu: 'Adverbios de Frecuencia',
      detalle:
        'Cómo y cuándo usar adverbios de frecuencia como “always”, “never”, etc.',
    },
    {
      descripcionMenu: 'Adjetivos',
      detalle: 'Cómo describir personas, lugares y cosas utilizando adjetivos.',
    },
    {
      descripcionMenu: 'To-Have',
      detalle:
        'El uso del verbo “to have” en contexto de posesión y otras aplicaciones.',
    },
    {
      descripcionMenu: 'This - These - That - Those',
      detalle: 'Diferencia entre los pronombres y determinantes demostrativos.',
    },
    {
      descripcionMenu: 'Artículos: The, A, An',
      detalle: 'Uso de los artículos definidos e indefinidos en inglés.',
    },
    {
      descripcionMenu: 'IN-ON-AT Preposicions',
      detalle:
        'Cómo y cuándo usar las preposiciones de lugar “in”, “on” y “at”.',
    },
    {
      descripcionMenu: 'Preposiciones de lugar',
      detalle:
        'Detalles sobre cómo usar preposiciones para ubicar objetos en espacio.',
    },
    {
      descripcionMenu: 'Preposiciones de tiempo',
      detalle: 'El uso de preposiciones para hablar de momentos en el tiempo.',
    },
    {
      descripcionMenu: 'Preposiciones de estado',
      detalle:
        'Cómo usar las preposiciones para describir el estado de las cosas.',
    },
    {
      descripcionMenu: 'Contables/Incontables',
      detalle:
        'Diferencia entre sustantivos contables e incontables en inglés.',
    },
    {
      descripcionMenu: 'Cuantificadores',
      detalle: 'Uso de cuantificadores para describir cantidades.',
    },
    {
      descripcionMenu: 'Verbos modales en Inglés',
      detalle: 'Estudio de verbos modales como “can”, “must”, “should” y más.',
    },
    {
      descripcionMenu: 'There Is - There Are',
      detalle: 'Cómo usar estas expresiones para hablar de existencia.',
    },
    {
      descripcionMenu: 'Adverbios - Adverbs',
      detalle: 'Tipos de adverbios en inglés y cómo usarlos.',
    },
    {
      descripcionMenu: 'Pronombres indefinidos',
      detalle:
        'Pronombres que no especifican una persona o cosa en particular.',
    },
    {
      descripcionMenu: 'Pronombres relativos',
      detalle: 'Uso de pronombres como “who”, “which”, “that” para unir ideas.',
    },
    {
      descripcionMenu: 'Conjunciones',
      detalle: 'Conectores para unir oraciones y expresiones.',
    },
    {
      descripcionMenu: 'Adjetivos Comparativos',
      detalle: 'Cómo comparar dos o más cosas usando adjetivos comparativos.',
    },
    {
      descripcionMenu: 'Superlative',
      detalle:
        'Cómo expresar el grado más alto de una cualidad con superlativos.',
    },
    {
      descripcionMenu: 'Presente continuo',
      detalle: 'Uso del presente continuo para hablar de acciones en progreso.',
    },
    {
      descripcionMenu: 'Adjetivos: uso “ED Y ING“',
      detalle:
        'Cómo usar adjetivos con las terminaciones “ed” y “ing” correctamente.',
    },
    {
      descripcionMenu: 'Like and As',
      detalle:
        'Diferencia entre “like” y “as” para comparaciones y expresiones.',
    },
    {
      descripcionMenu: 'Pasado simple',
      detalle:
        'Uso de los verbos en su forma pasada para hablar de eventos ya ocurridos.',
    },
    {
      descripcionMenu: 'Lista verbos Irregulares',
      detalle: 'Una lista de los verbos irregulares más comunes en inglés.',
    },
    {
      descripcionMenu: 'Pasado continuo',
      detalle: 'Cómo se forma y cuándo usar el pasado continuo.',
    },
    {
      descripcionMenu: 'Used to',
      detalle:
        'Uso de “used to” para hablar de hábitos pasados que ya no ocurren.',
    },
    {
      descripcionMenu: 'Futuro simple',
      detalle:
        'Cómo usar el futuro simple para expresar acciones que sucederán.',
    },
    {
      descripcionMenu: 'Futuro continuo',
      detalle:
        'Uso del futuro continuo para hablar de acciones futuras en progreso.',
    },
    {
      descripcionMenu: 'Present perfect',
      detalle:
        'Uso del present perfect para hablar de acciones que tienen relevancia en el presente.',
    },
    {
      descripcionMenu: 'Present perfect continuous',
      detalle:
        'Cómo usar el present perfect continuous para enfatizar la duración de una acción.',
    },
    {
      descripcionMenu: 'Use of Already, Just, Still...',
      detalle: 'Explicación de cómo usar estos términos en el present perfect.',
    },
    {
      descripcionMenu: 'Modal perfects',
      detalle:
        'Uso de los modales con el perfect para hablar de probabilidad o deducción.',
    },
    {
      descripcionMenu: 'Past-perfect',
      detalle:
        'Cómo usar el past perfect para hablar de eventos previos a otros eventos en el pasado.',
    },
    {
      descripcionMenu: 'Past perfect continuous',
      detalle:
        'Uso del past perfect continuous para describir acciones continuas en el pasado.',
    },
    {
      descripcionMenu: 'Future perfect',
      detalle:
        'Uso del future perfect para hablar de acciones que habrán terminado en el futuro.',
    },
    {
      descripcionMenu: 'Future perfect continuous',
      detalle:
        'Cómo usar el future perfect continuous para hablar de acciones continuas que terminarán en el futuro.',
    },
    {
      descripcionMenu: 'Condicional sentences',
      detalle:
        'Uso de las oraciones condicionales para expresar situaciones hipotéticas.',
    },
    {
      descripcionMenu: 'Passive voice',
      detalle:
        'Cómo usar la voz pasiva para enfatizar la acción en lugar de la persona que la realiza.',
    },
    {
      descripcionMenu: 'Direct and reported speech',
      detalle: 'Diferencia entre el discurso directo e indirecto.',
    },
    {
      descripcionMenu: 'Say vs. Tell',
      detalle: 'Uso de “say” y “tell” en el contexto de comunicación.',
    },
    {
      descripcionMenu: 'Do vs. Make',
      detalle: 'Diferencia entre los verbos “do” y “make” en inglés.',
    },
    {
      descripcionMenu: 'Delexical Verbs',
      detalle:
        'Verbos que pierden su significado en combinación con otros sustantivos.',
    },
    {
      descripcionMenu: 'Linking words',
      detalle:
        'Palabras y frases que se usan para enlazar ideas y mejorar la coherencia en el discurso.',
    },
    {
      descripcionMenu: 'Phrasal/prepositional verbs',
      detalle:
        'Estudio de verbos compuestos con preposiciones para expresar acciones específicas.',
    },
    {
      descripcionMenu: 'Usos del verbo To Get',
      detalle: 'Diferentes maneras de usar el verbo “get” en inglés.',
    },
    {
      descripcionMenu: 'Prefixes and suffixes',
      detalle:
        'Cómo los prefijos y sufijos cambian el significado de las palabras en inglés.',
    },
    {
      descripcionMenu: 'So vs. such',
      detalle: 'Diferencia entre el uso de “so” y “such” para enfatizar.',
    },
    {
      descripcionMenu: 'Practical English Expressions',
      detalle: 'Expresiones útiles y cotidianas en inglés.',
    },
    {
      descripcionMenu: 'Enough and Too',
      detalle:
        'En esta clase, aprenderás a usar "enough" y "too". Ambas palabras se utilizan para indicar cantidad o grado, pero se emplean de manera diferente. Te explico cómo utilizarlas correctamente y cómo puedes distinguir su uso en distintas situaciones.',
    },
    {
      descripcionMenu: 'Even',
      detalle:
        'Aprenderás a usar "even" y sus combinaciones comunes, como "even though", "even if" y "even when", que añaden énfasis o expresan condiciones y contradicciones. Exploraremos sus distintos significados y ejemplos de uso en contextos cotidianos.',
    },
    {
      descripcionMenu: 'Else',
      detalle:
        'En esta clase, aprenderás el uso de "else" en inglés, una palabra que añade contraste o alternativa en preguntas y afirmaciones. Exploraremos expresiones comunes como "Who else is coming?" (¿Quién más viene?) y "Nobody else was there." (No había nadie más). Verás cómo "else" se combina con palabras como "someone" y "anything" para expresar opciones adicionales, con ejemplos y ejercicios para que practiques su uso.',
    },
    {
      descripcionMenu: 'Ever suffix',
      detalle:
        'En esta clase, aprenderás a usar "ever" y sus combinaciones, como en "whenever" o "whoever". "Ever" añade énfasis y puede expresar frecuencia, posibilidad o alcance. Descubriremos cómo este sufijo cambia el sentido de las palabras y se usa en preguntas y frases comunes en inglés.',
    },
    {
      descripcionMenu: 'Neither vs. Either',
      detalle:
        'En esta clase, aprenderás la diferencia entre "neither" y "either". Ambos términos se utilizan para expresar opciones o exclusiones, pero tienen significados opuestos.',
    },
    {
      descripcionMenu: 'Each vs. Every',
      detalle:
        'En esta clase, aprenderás la diferencia entre "each" y "every", dos palabras que suelen confundirse. Aunque ambas se usan para hablar de grupos, "each" se enfoca en los individuos dentro del grupo, mientras que "every" implica totalidad. Veremos cómo y cuándo emplear cada una en distintos contextos y ejemplos.',
    },
    {
      descripcionMenu: 'Principiante',
      detalle:
        'En este nivel, encontraras temas y conceptos básicos del idioma inglés como el alfabeto, los números y los colores. Estas lecciones están diseñadas para aquellos que recién comienzan a aprender inglés.',
    },
    {
      descripcionMenu: 'Avanzado',
      detalle:
        'En este nivel, encontraras temas como los tiempos perfectos, las oraciones condicionales y el uso avanzado de modales.',
    },
    {
      descripcionMenu: 'Intermedio',
      detalle:
        '"En este nivel, encontraras temas como los pronombres relativos, la voz pasiva y las conjunciones, ademas Aprenderás cómo formar oraciones más complejas en tiempo como pasado, pasado continuo, futuro y futuro continuo.',
    },
  ];

  get menuFiltrado() {
    return this.itemsMenu.filter((i) => i.nivel === this.nivel$.value);
  }
  getDetalle(menu) {
    return this.itemsMenuConDetalle.find(
      (i) => i.descripcionMenu?.toLowerCase() === menu?.toLowerCase()
    );
  }
}

export enum Nivel {
  Principiante = 'principiante',
  Intermedio = 'intermedio',
  Avanzado = 'avanzado',
  Ejercicio = 'ejercicio',
}
