import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-each-vs-every',
  standalone: true,
  imports: [],
  templateUrl: './each-vs-every.component.html',
  styleUrl: './each-vs-every.component.scss',
})
export class EachVsEveryComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Each vs. Every';
  override title = 'Each vs. Every';
}
