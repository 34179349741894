
<div class="preposicions">
    <p>Para expresar dónde se encuentra algo o alguien, es fundamental conocer el uso de las preposiciones de lugar. En inglés, las preposiciones <strong>in</strong>, <strong>on</strong>, y <strong>at</strong> tienen usos específicos que pueden ser confusos, especialmente para hablantes de español.</p>

    <table>
        <tr>
            <th>Preposición</th>
            <th>Significado</th>
            <th>Uso Común</th>
        </tr>
        <tr>
            <td><strong>in</strong></td>
            <td>en, dentro</td>
            <td>Se usa para lugares cerrados o definidos.</td>
        </tr>
        <tr>
            <td><strong>on</strong></td>
            <td>sobre, en</td>
            <td>Se usa para superficies.</td>
        </tr>
        <tr>
            <td><strong>at</strong></td>
            <td>a, en</td>
            <td>Se usa para puntos específicos o eventos.</td>
        </tr>
    </table>

    <div class="section"> 
        <div class="section-blanco">
        <h2>1. Uso de <strong>in</strong></h2>
        <p>La preposición <strong>in</strong> se utiliza para indicar que algo está dentro de un espacio o en un área delimitada.</p>

        <h3>Ejemplos de <strong>in</strong></h3>
        <ul>
            <li><span class="example">The cat is in the box.</span> (El gato está en la caja).</li>
            <li><span class="example">There are many books in the library.</span> (Hay muchos libros en la biblioteca).</li>
        </ul>

        <h3>Consejos para recordar <strong>in</strong></h3>
        <div class="tip">
            <strong>Tip:</strong> Recuerda que <strong>in</strong> se refiere a "dentro de" algo. Siempre piensa en un espacio cerrado:
            <li><span class="example"> cuando hables de lugares con limites como paises usaras In: In England(En inglaterra).</span></li>
            <li><span class="example"> Si esta" dentro de" un vehiculo y si no te podes parar en él usaras In: In car (Dentro del auto/en el auto)</span></li>
            <li><span class="example"> Si esta "dentro de" un edificio  y no especifica donde usaras In: in the building(En el edificio)</span></li>

        </div>
    </div>
    <div class="section-blanco">
        <h2>2. Uso de <strong>on</strong></h2>
        <p>La preposición <strong>on</strong> se usa para indicar que algo está sobre una superficie.</p>

        <h3>Ejemplos de <strong>on</strong></h3>
        <ul>
            <li><span class="example">The picture is on the wall.</span> (La imagen está en la pared).</li>
            <li><span class="example">I left my phone on the table.</span> (Dejé mi teléfono sobre la mesa).</li>
        </ul>

        <h3>Consejos para recordar <strong>on</strong></h3>
        <div class="tip">
            <strong>Tip:</strong> Piensa en <strong>on</strong> como "encima de" o "sobre". Se usa para todo lo que está en contacto con una superficie.
            <li><span class="example"> Si esta dentro de un vehiculo y SI te podes parar en él usaras on: on bus (en el bus)</span></li>
            <li><span class="example"> Si esta "dentro de" un edificio y  "especifica" en que piso usaras on: On the second floor(En el segundo piso)</span></li>
        </div>
    </div>
    <div class="section-blanco">

        <h2>3. Uso de <strong>at</strong></h2>
        <p>La preposición <strong>at</strong> se utiliza para referirse a un lugar específico, como un punto de encuentro o una dirección.</p>

        <h3>Ejemplos de <strong>at</strong></h3>
        <ul>
            <li><span class="example">I am at the bus stop.</span> (Estoy en la parada de autobús).</li>
            <li><span class="example">She is at the gym.</span> (Ella está en el gimnasio).</li>
        </ul>

        <h3>Consejos para recordar <strong>at</strong></h3>
        <div class="tip">
            <strong>Tip:</strong> Recuerda que <strong>at</strong> se usa para lugares específicos o eventos. Piensa en él como "en un punto exacto".
        </div>
    </div>
    <div class="section-blanco">
    <h2>Resumen de Usos</h2>
    <p>Las preposiciones <strong>in</strong>, <strong>on</strong>, y <strong>at</strong> son esenciales para indicar la ubicación en inglés. Aquí hay una breve comparación:</p>
    <ul>
        <li><strong>In</strong> se usa para espacios cerrados.</li>
        <li><strong>On</strong> se usa para superficies.</li>
        <li><strong>At</strong> se usa para puntos específicos.</li>
    </ul>


    <h3>Ejemplos de uso de In, At y On</h3>
    <table>
        <tr>
            <th>Preposición</th>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>The cat is in the box.</td>
            <td>El gato está en la caja.</td>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>She is in the kitchen.</td>
            <td>Ella está en la cocina.</td>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>We live in New York.</td>
            <td>Vivimos en Nueva York.</td>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>The car is in the garage.</td>
            <td>El coche está en el garaje.</td>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>The milk is in the fridge.</td>
            <td>La leche está en el refrigerador.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>He is at the office.</td>
            <td>Él está en la oficina.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>They are at the park.</td>
            <td>Ellos están en el parque.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>I will meet you at the restaurant.</td>
            <td>Te encontraré en el restaurante.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>She is waiting at the bus stop.</td>
            <td>Ella está esperando en la parada del autobús.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>We arrived at the airport.</td>
            <td>Llegamos al aeropuerto.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>The book is on the table.</td>
            <td>El libro está sobre la mesa.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>She put the picture on the wall.</td>
            <td>Ella puso la foto en la pared.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>The dog is on the couch.</td>
            <td>El perro está en el sofá.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>There is a stain on the carpet.</td>
            <td>Hay una mancha en la alfombra.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>The picture is on the fridge.</td>
            <td>La foto está en el refrigerador.</td>
        </tr>
    </table>
</div></div>