<div class="past-perfect">
  <div>
    <p>
      El <em>past perfect</em> se forma con <strong>had </strong>("AUXILIAR")
      <strong>+ participio pasado</strong> y se usa para describir una acción
      completada antes de otra acción o momento específico en el pasado. Nos
      ayuda a mostrar el orden de los eventos en el pasado.
    </p>
    <p>
      Estructura:
      <strong>Sujeto + had + participio pasado del verbo principal</strong>
    </p>
    <p>
      Ejemplo: She <strong>had</strong> finished her work before he arrived.
    </p>
  </div>
  <div class="section">
    <div class="section-blanco">
      <h2>¿Cuándo se usa el Past Perfect?</h2>
      <table border="1" cellpadding="10">
        <thead>
          <tr>
            <th>Uso del Past Perfect</th>
            <th>Descripción</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Acción completada antes de otra en el pasado</td>
            <td>
              Describe una acción que ocurrió y se completó antes de otra acción
              en el pasado. Se usa para clarificar cuál de las dos acciones
              sucedió primero.
            </td>
            <td>
              When I arrived, they <strong>had finished</strong> eating. (Cuando
              llegué, ellos ya habían terminado de comer)
            </td>
          </tr>
          <tr>
            <td>Expresar causa y efecto en el pasado</td>
            <td>
              Explica el motivo de una situación en el pasado al mostrar una
              acción anterior que causó la situación presente en el contexto.
            </td>
            <td>
              She was tired because she <strong>had worked</strong> all day.
              (Estaba cansada porque había trabajado todo el día)
            </td>
          </tr>
          <tr>
            <td>Para indicar que algo no sucedió</td>
            <td>
              El <strong>past perfect</strong> puede usarse para mostrar que una
              acción no ocurrió antes de otra acción en el pasado.
            </td>
            <td>
              I arrived late because I <strong>hadn't set</strong> my alarm.
              (Llegué tarde porque no había puesto mi alarma)
            </td>
          </tr>
          <tr>
            <td>En oraciones condicionales de tipo III</td>
            <td>
              Se usa en la parte de la condición para expresar que algo
              <em>habría pasado</em> si una condición anterior hubiera sido
              diferente.
            </td>
            <td>
              If they <strong>had prepared</strong> better, they would have won.
              (Si se hubieran preparado mejor, habrían ganado)
            </td>
          </tr>
          <tr>
            <td>
              Con "just" para indicar una acción recién terminada en el pasado
            </td>
            <td>
              Al usar "just" con el <strong>past perfect</strong>, se destaca
              que una acción había terminado justo antes de otro momento en el
              pasado.
            </td>
            <td>
              They <strong>had just left</strong> when we arrived. (Acababan de
              irse cuando llegamos)
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Ejemplos de Oraciones en Past Perfect</h3>
      <table border="1" cellpadding="10">
        <thead>
          <tr>
            <th>Oración</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              He <strong>had finished</strong> his homework before going out.
            </td>
            <td>Él había terminado su tarea antes de salir.</td>
          </tr>
          <tr>
            <td>They <strong>had met</strong> before the party started.</td>
            <td>Se habían conocido antes de que comenzara la fiesta.</td>
          </tr>
          <tr>
            <td>
              We <strong>hadn't seen</strong> each other since high school.
            </td>
            <td>No nos habíamos visto desde la secundaria.</td>
          </tr>
          <tr>
            <td>
              By the time she arrived, the train <strong>had left</strong>.
            </td>
            <td>Para cuando ella llegó, el tren ya se había ido.</td>
          </tr>
          <tr>
            <td>She <strong>had hoped</strong> to see him at the event.</td>
            <td>Ella había esperado verlo en el evento.</td>
          </tr>
          <tr>
            <td>
              They <strong>had decided</strong> to leave before the rain
              started.
            </td>
            <td>Habían decidido irse antes de que comenzara a llover.</td>
          </tr>
          <tr>
            <td>
              I <strong>hadn't realized</strong> the meeting was canceled.
            </td>
            <td>
              No me había dado cuenta de que la reunión había sido cancelada.
            </td>
          </tr>
          <tr>
            <td>
              She <strong>had never seen</strong> the ocean before that day.
            </td>
            <td>Nunca había visto el océano antes de ese día.</td>
          </tr>
          <tr>
            <td>We <strong>had already eaten</strong> when he arrived.</td>
            <td>Ya habíamos comido cuando él llegó.</td>
          </tr>
          <tr>
            <td>
              After they <strong>had left</strong>, I found the missing keys.
            </td>
            <td>Después de que se fueron, encontré las llaves perdidas.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>2. Negaciones en Past Perfect</h2>
      <p>Para negar en <em>past perfect</em>, usamos la estructura:</p>
      <p><strong>Sujeto + had not (hadn’t) + participio pasado</strong></p>
      <p>
        Esto indica que una acción no se había completado antes de otro evento
        pasado.
      </p>

      <h3>Ejemplos de Negaciones</h3>
      <table border="1" cellpadding="10">
        <thead>
          <tr>
            <th>Negación en Past Perfect</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              She <strong>hadn't finished</strong> her homework when I called.
            </td>
            <td>Ella no había terminado su tarea cuando llamé.</td>
          </tr>
          <tr>
            <td>They <strong>had not left</strong> by the time we arrived.</td>
            <td>No se habían ido cuando llegamos.</td>
          </tr>
          <tr>
            <td>We <strong>hadn't met</strong> him before the event.</td>
            <td>No lo habíamos conocido antes del evento.</td>
          </tr>
          <tr>
            <td>
              I <strong>had not seen</strong> that movie before yesterday.
            </td>
            <td>No había visto esa película antes de ayer.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>1. Preguntas en Past Perfect</h2>
      <p>
        Para hacer preguntas en <em>past perfect</em>, usamos la estructura:
      </p>
      <p><strong>Had + sujeto + participio pasado...?</strong></p>
      <p>
        Esta estructura permite preguntar si una acción se completó antes de
        otra en el pasado.
      </p>

      <h3>Ejemplos de Preguntas</h3>
      <table border="1" cellpadding="10">
        <thead>
          <tr>
            <th>Pregunta en Past Perfect</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Had</strong> you <strong>finished</strong> your homework
              before the class?
            </td>
            <td>¿Habías terminado tu tarea antes de la clase?</td>
          </tr>
          <tr>
            <td>
              <strong>Had</strong> they <strong>left</strong> when you arrived?
            </td>
            <td>¿Se habían ido cuando llegaste?</td>
          </tr>
          <tr>
            <td>
              <strong>Had</strong> she <strong>seen</strong> that movie before?
            </td>
            <td>¿Había visto ella esa película antes?</td>
          </tr>
          <tr>
            <td>
              <strong>Had</strong> we <strong>met</strong> before the meeting?
            </td>
            <td>¿Nos habíamos conocido antes de la reunión?</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
