import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-adjetivos',
  templateUrl: './adjetivos.component.html',
  styleUrl: './adjetivos.component.scss',
})
export class AdjetivosComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Adjetivos';
  override title = 'Adjetivos - “Adjectives”';
}
