import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-presente-simple',
  templateUrl: './presente-simple.component.html',
  styleUrl: './presente-simple.component.scss',
})
export class PresenteSimpleComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Presente Simple';
  override title = 'Presente Simple - “Simple Present”';
}
