import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, inject, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
} from 'rxjs';
import {
  LeccionesInglesService,
  Nivel,
} from '../lecciones/lecciones.-ingles.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrl: './inicio.component.scss',
})
export class InicioComponent implements OnInit {
  itemsPrincipiante = [];
  itemsIntermedio = [];
  itemsAvanzado = [];
  isSmall$ = new BehaviorSubject<boolean>(true);
  private breakpointObserver = inject(BreakpointObserver);
  constructor(public leccionesService: LeccionesInglesService) {}

  ngOnInit(): void {
    this.itemsPrincipiante = this.leccionesService.itemsMenu.filter(
      (i) => i.nivel === Nivel.Principiante
    );
    this.itemsIntermedio = this.leccionesService.itemsMenu.filter(
      (i) => i.nivel === Nivel.Intermedio
    );
    this.itemsAvanzado = this.leccionesService.itemsMenu.filter(
      (i) => i.nivel === Nivel.Avanzado
    );

    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(
        map((result) => result.matches),
        shareReplay(),
        distinctUntilChanged()
      )
      .subscribe((isSmall) => this.isSmall$.next(isSmall));
  }
}
