import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-future-simple',
  standalone: true,
  imports: [],
  templateUrl: './future-simple.component.html',
  styleUrl: './future-simple.component.scss',
})
export class FutureSimpleComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Futuro simple';
  override title = 'Futuro simple - "Future simple"';
}
