import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-past-perfect',
  standalone: true,
  imports: [],
  templateUrl: './past-perfect.component.html',
  styleUrl: './past-perfect.component.scss',
})
export class PastPerfectComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Past-perfect';
  override title = 'Past perfect';
}
