<div class="comparative">
  <h2>¿Qué son los comparativos?</h2>
  <p>
    Los comparativos son adjetivos modificados que utilizamos para comparar dos
    personas, lugares, cosas o ideas, La estructura básica es:
    <em
      ><strong
        >(Sujeto) + verbo + adjetivo comparativo + than + (objeto de
        comparación)</strong
      ></em
    >. es como decir: X mas alto que Y, X es mejor que Y, X es mas hermoso que
    Y.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Formación de los comparativos</h2>
      <p>La estructura básica es:</p>
      <p>
        <strong
          >(Sujeto) + verbo + adjetivo comparativo + than + (objeto de
          comparación)</strong
        >
      </p>

      <h3>Tipos de comparativos</h3>

      <h4>1. Comparativos de adjetivos cortos (1 sílaba)</h4>
      <p>
        Para adjetivos de una sílaba, añadimos "-er". Ejemplo: <em>tall</em> →
        <strong>taller</strong> (más alto).
      </p>
      <table border="1">
        <thead>
          <tr>
            <th>Adjetivo</th>
            <th>Comparativo</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>tall (alto)</td>
            <td><strong>taller</strong></td>
            <td>
              My brother is <strong>taller</strong> than me. (Mi hermano es
              <strong>más alto</strong> que yo.)
            </td>
          </tr>
          <tr>
            <td>fast (rápido)</td>
            <td><strong>faster</strong></td>
            <td>
              She runs <strong>faster</strong> than I do. (Ella corre
              <strong>más rápido</strong> que yo.)
            </td>
          </tr>
          <tr>
            <td>small (pequeño)</td>
            <td><strong>smaller</strong></td>
            <td>
              This car is <strong>smaller</strong> than the other one. (Este
              coche es <strong>más pequeño</strong> que el otro.)
            </td>
          </tr>
          <tr>
            <td>old (mayor)</td>
            <td><strong>older</strong></td>
            <td>
              My sister is <strong>older</strong> than me. (Mi hermana es
              <strong>mayor</strong> que yo.)
            </td>
          </tr>
          <tr>
            <td>cold (frío)</td>
            <td><strong>colder</strong></td>
            <td>
              Today is <strong>colder</strong> than yesterday. (Hoy está
              <strong>más frío</strong> que ayer.)
            </td>
          </tr>
          <tr>
            <td>big (grande)</td>
            <td><strong>bigger</strong></td>
            <td>
              This house is <strong>bigger</strong> than mine. (Esta casa es
              <strong>más grande</strong> que la mía.)
            </td>
          </tr>
          <tr>
            <td>hot (cálido)</td>
            <td><strong>hotter</strong></td>
            <td>
              The weather is <strong>hotter</strong> in summer. (El clima es
              <strong>más cálido</strong> en verano.)
            </td>
          </tr>
          <tr>
            <td>long (largo)</td>
            <td><strong>longer</strong></td>
            <td>
              This bridge is <strong>longer</strong> than the other one. (Este
              puente es <strong>más largo</strong> que el otro.)
            </td>
          </tr>
          <tr>
            <td>cheap (barato)</td>
            <td><strong>cheaper</strong></td>
            <td>
              This product is <strong>cheaper</strong> online. (Este producto es
              <strong>más barato</strong> en línea.)
            </td>
          </tr>
          <tr>
            <td>high (alto)</td>
            <td><strong>higher</strong></td>
            <td>
              The mountain is <strong>higher</strong> than the hill. (La montaña
              es <strong>más alta</strong> que la colina.)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h4>2. Comparativos de adjetivos de 2 sílabas que terminan en -y</h4>
      <p>
        Cambiamos la "y" por "i" y añadimos "-er". Ejemplo: <em>happy</em> →
        <strong>happier</strong> (más feliz).
      </p>
      <table border="1">
        <thead>
          <tr>
            <th>Adjetivo</th>
            <th>Comparativo</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>happy (feliz)</td>
            <td><strong>happier</strong></td>
            <td>
              I'm <strong>happier</strong> now than before. (Ahora estoy
              <strong>más feliz</strong> que antes.)
            </td>
          </tr>
          <tr>
            <td>busy (ocupado)</td>
            <td><strong>busier</strong></td>
            <td>
              She's <strong>busier</strong> than ever. (Ella está
              <strong>más ocupada</strong> que nunca.)
            </td>
          </tr>
          <tr>
            <td>funny (gracioso)</td>
            <td><strong>funnier</strong></td>
            <td>
              He is <strong>funnier</strong> than his brother. (Él es
              <strong>más gracioso</strong> que su hermano.)
            </td>
          </tr>
          <tr>
            <td>crazy (loco)</td>
            <td><strong>crazier</strong></td>
            <td>
              This movie is <strong>crazier</strong> than the last one. (Esta
              película es <strong>más loca</strong> que la anterior.)
            </td>
          </tr>
          <tr>
            <td>easy (fácil)</td>
            <td><strong>easier</strong></td>
            <td>
              This exercise is <strong>easier</strong> than the previous one.
              (Este ejercicio es <strong>más fácil</strong> que el anterior.)
            </td>
          </tr>
          <tr>
            <td>lazy (perezoso)</td>
            <td><strong>lazier</strong></td>
            <td>
              He's <strong>lazier</strong> than his sister. (Él es
              <strong>más perezoso</strong> que su hermana.)
            </td>
          </tr>
          <tr>
            <td>dirty (sucio)</td>
            <td><strong>dirtier</strong></td>
            <td>
              This room is <strong>dirtier</strong> than the other. (Esta
              habitación está <strong>más sucia</strong> que la otra.)
            </td>
          </tr>
          <tr>
            <td>pretty (bonito)</td>
            <td><strong>prettier</strong></td>
            <td>
              Her dress is <strong>prettier</strong> than mine. (Su vestido es
              <strong>más bonito</strong> que el mío.)
            </td>
          </tr>
          <tr>
            <td>cloudy (nublado)</td>
            <td><strong>cloudier</strong></td>
            <td>
              Today is <strong>cloudier</strong> than yesterday. (Hoy está
              <strong>más nublado</strong> que ayer.)
            </td>
          </tr>
          <tr>
            <td>messy (desordenado)</td>
            <td><strong>messier</strong></td>
            <td>
              His desk is <strong>messier</strong> than mine. (Su escritorio
              está <strong>más desordenado</strong> que el mío.)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h4>3. Comparativos de adjetivos largos (2 o más sílabas)</h4>
      <p>
        Para adjetivos de 2+ sílabas (no terminados en -y), usamos "more" antes
        del adjetivo. Ejemplo: <em>beautiful</em> →
        <strong>more beautiful</strong> (más hermoso).
      </p>
      <table border="1">
        <thead>
          <tr>
            <th>Adjetivo</th>
            <th>Comparativo</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>beautiful (hermoso)</td>
            <td><strong>more beautiful</strong></td>
            <td>
              This place is <strong>more beautiful</strong> than the other.
              (Este lugar es <strong>más hermoso</strong> que el otro.)
            </td>
          </tr>
          <tr>
            <td>expensive (caro)</td>
            <td><strong>more expensive</strong></td>
            <td>
              This item is <strong>more expensive</strong> than that one. (Este
              artículo es <strong>más caro</strong> que ese.)
            </td>
          </tr>
          <tr>
            <td>comfortable (cómodo)</td>
            <td><strong>more comfortable</strong></td>
            <td>
              This chair is <strong>more comfortable</strong> than that one.
              (Esta silla es <strong>más cómoda</strong> que esa.)
            </td>
          </tr>
          <tr>
            <td>interesting (interesante)</td>
            <td><strong>more interesting</strong></td>
            <td>
              This book is <strong>more interesting</strong> than the last.
              (Este libro es <strong>más interesante</strong> que el último.)
            </td>
          </tr>
          <tr>
            <td>difficult (difícil)</td>
            <td><strong>more difficult</strong></td>
            <td>
              This exam is <strong>more difficult</strong> than before. (Este
              examen es <strong>más difícil</strong> que antes.)
            </td>
          </tr>
          <tr>
            <td>popular (popular)</td>
            <td><strong>more popular</strong></td>
            <td>
              That song is <strong>more popular</strong> now. (Esa canción es
              <strong>más popular</strong> ahora.)
            </td>
          </tr>
          <tr>
            <td>famous (famoso)</td>
            <td><strong>more famous</strong></td>
            <td>
              She is <strong>more famous</strong> than her sister. (Ella es
              <strong>más famosa</strong> que su hermana.)
            </td>
          </tr>
          <tr>
            <td>intelligent (inteligente)</td>
            <td><strong>more intelligent</strong></td>
            <td>
              He's <strong>more intelligent</strong> than his friends. (Él es
              <strong>más inteligente</strong> que sus amigos.)
            </td>
          </tr>
          <tr>
            <td>valuable (valioso)</td>
            <td><strong>more valuable</strong></td>
            <td>
              This item is <strong>more valuable</strong> than that. (Este
              artículo es <strong>más valioso</strong> que ese.)
            </td>
          </tr>
          <tr>
            <td>powerful (potente)</td>
            <td><strong>more powerful</strong></td>
            <td>
              This computer is <strong>more powerful</strong> than the old one.
              (Este ordenador es <strong>más potente</strong> que el anterior.)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h4>4. Comparativos irregulares</h4>
      <p>
        <strong>Comparativos irregulares:</strong> Son aquellos adjetivos que no
        siguen las reglas generales de formación de comparativos. En lugar de
        agregar "er" o usar "more", estos adjetivos cambian su forma para
        expresar una comparación.
      </p>

      <table border="1">
        <thead>
          <tr>
            <th>Adjetivo</th>
            <th>Comparativo</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>good (bueno)</td>
            <td><strong>better (mejor)</strong></td>
            <td>
              This restaurant is <strong>better</strong> than that one. (Este
              restaurante es <strong>mejor</strong> que ese.)
            </td>
          </tr>
          <tr>
            <td>bad (malo)</td>
            <td><strong>worse (peor)</strong></td>
            <td>
              His health is <strong>worse</strong> this year. (Su salud es
              <strong>peor</strong> este año.)
            </td>
          </tr>
          <tr>
            <td>far (lejos)</td>
            <td><strong>farther (más lejos)</strong></td>
            <td>
              She lives <strong>farther</strong> away than me. (Ella vive
              <strong>más lejos</strong> que yo.)
            </td>
          </tr>
          <tr>
            <td>little (poco)</td>
            <td><strong>less (menos)</strong></td>
            <td>
              We have <strong>less</strong> time today than yesterday. (Tenemos
              <strong>menos</strong> tiempo hoy que ayer.)
            </td>
          </tr>
          <tr>
            <td>much (mucho)</td>
            <td><strong>more (más)</strong></td>
            <td>
              There is <strong>more</strong> information on this page. (Hay
              <strong>más</strong> información en esta página.)
            </td>
          </tr>
          <tr>
            <td>many (muchos)</td>
            <td><strong>more (más)</strong></td>
            <td>
              There are <strong>more</strong> options available. (Hay
              <strong>más</strong> opciones disponibles.)
            </td>
          </tr>
          <tr>
            <td>old (viejo)</td>
            <td>
              <strong>older (mayor)</strong>/<strong>elder (mayor)</strong>
            </td>
            <td>
              My <strong>elder</strong> sister lives abroad. (Mi hermana
              <strong>mayor</strong> vive en el extranjero.)
            </td>
          </tr>
          <tr>
            <td>near (cerca)</td>
            <td><strong>nearer (más cerca)</strong></td>
            <td>
              We are <strong>nearer</strong> to our destination. (Estamos
              <strong>más cerca</strong> de nuestro destino.)
            </td>
          </tr>
          <tr>
            <td>well (bien)</td>
            <td><strong>better (mejor)</strong></td>
            <td>
              He performs <strong>better</strong> than his peers. (Él se
              desempeña <strong>mejor</strong> que sus compañeros.)
            </td>
          </tr>
          <tr>
            <td>badly (mal)</td>
            <td><strong>worse (peor)</strong></td>
            <td>
              She sings <strong>worse</strong> than her brother. (Ella canta
              <strong>peor</strong> que su hermano.)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
