<!DOCTYPE html>
<html lang="es">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta
      name="description"
      content="Accede a nuestra plataforma gratuita para aprender inglés desde cero. Cursos, ejercicios y recursos diseñados para mejorar tu nivel de inglés rápidamente."
    />
    <meta
      name="keywords"
      content="aprender inglés, curso de inglés online, recursos gratuitos para aprender inglés"
    />
    <meta
      name="google-site-verification"
      content="TLNN50u0CxdDp-6-oHLoe1giJyBwfXrIZDB4V3RLmf4"
    />
    <meta name="author" content="Glube" />
    <title>Curso de inglés online</title>
    <link rel="canonical" href="https://www.curso-de-ingles-online.com/" />
  </head>
  <body class="body" [ngClass]="{ 'body--small': isSmall$.value }">
    <h1>Aprende Inglés</h1>
    <p class="label">
      Domina el idioma desde cero hasta un nivel avanzado, plataforma hecha solo
      para aprender.
    </p>
    <div></div>
    <div class="container" [ngClass]="{ 'container--small': isSmall$.value }">
      <div
        class="level level-principiante"
        [ngClass]="{ 'level--small': isSmall$.value }"
      >
        <h2 class="level-title">Nivel Principiante</h2>
        <div
          class="level-text"
          [ngClass]="{ 'level-text--small': isSmall$.value }"
        >
          Perfecto para aquellos que están comenzando a aprender inglés. En este
          nivel, encontraras temas y conceptos básicos del idioma inglés como el
          alfabeto, los números y los colores. Estas lecciones están diseñadas
          para aquellos que recién comienzan a aprender inglés.
        </div>
        <a
          class="button-principal"
          [ngClass]="{ 'button-principal--small': isSmall$.value }"
          href="lecciones/principiante"
          >Ir a temas de nivel.</a
        >
      </div>
      <div
        class="level level-intermedio"
        [ngClass]="{ 'level--small': isSmall$.value }"
      >
        <h2 class="level-title">Nivel Intermedio</h2>
        <div
          class="level-text"
          [ngClass]="{ 'level-text--small': isSmall$.value }"
        >
          Ideal para quienes tienen conocimientos básicos y quieren mejorar. En
          este nivel, encontraras temas como los pronombres relativos, la voz
          pasiva y las conjunciones, ademas Aprenderás cómo formar oraciones más
          complejas en tiempo como pasado, pasado continuo, futuro y futuro
          continuo.
        </div>
        <a
          class="button-principal"
          [ngClass]="{ 'button-principal--small': isSmall$.value }"
          href="lecciones/intermedio"
          >Ir a temas de nivel.</a
        >
      </div>
      <div
        class="level level-avanzado"
        [ngClass]="{ 'level--small': isSmall$ | async }"
      >
        <h2 class="level-title">Nivel Avanzado</h2>
        <div
          class="level-text"
          [ngClass]="{ 'level-text--small': isSmall$.value }"
        >
          Diseñado para estudiantes avanzados que buscan perfeccionar su inglés.
          En este nivel, encontraras temas como los tiempos perfectos, las
          oraciones condicionales y el uso avanzado de modales.
        </div>
        <a
          class="button-principal"
          [ngClass]="{ 'button-principal--small': isSmall$.value }"
          href="lecciones/avanzado"
          >Ir a temas de nivel.</a
        >
      </div>
    </div>
  </body>
  <app-footer class="footer"></app-footer>
</html>
