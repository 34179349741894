import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LeccionesInglesService } from './lecciones/lecciones.-ingles.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  titleApp = 'curso-de-ingles-online';
  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    private leccionesInglesService: LeccionesInglesService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const partesUrl = this.router.url.split('/');
        const lastSection = partesUrl[partesUrl.length - 1];
        if (partesUrl.includes('lecciones')) {
          const menu = this.leccionesInglesService.itemsMenu.find((item) => {
            const partesUrlItem = item.path.split('/');
            const lastSectioItem = partesUrlItem[partesUrlItem.length - 1];
            return lastSectioItem === lastSection;
          });
          const description = this.leccionesInglesService.getDetalle(
            !!menu ? menu.descripcionMenu : lastSection
          );
          if (!!description) {
            this.title.setTitle(description.descripcionMenu);
            this.meta.updateTag({
              name: 'description',
              content: description.detalle,
            });
          }
        } else if (!partesUrl.includes('indicaciones')) {
          this.title.setTitle('Curso de Ingles Online');
          this.meta.updateTag({
            name: 'description',
            content:
              'Accede a nuestra plataforma gratuita para aprender inglés desde cero. Cursos, ejercicios y recursos diseñados para mejorar tu nivel de inglés rápidamente.',
          });
        }
      }
    });
  }
}
