import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-adjetives-ed-ing',
  standalone: true,
  imports: [],
  templateUrl: './adjetives-ed-ing.component.html',
  styleUrl: './adjetives-ed-ing.component.scss',
})
export class AdjetivesEdIngComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Adjetivos: uso “ED Y ING“';
  override title = 'Adjetivos terminados en “ED Y ING“';
}
