import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-comparative',
  standalone: true,
  imports: [],
  templateUrl: './comparative.component.html',
  styleUrl: './comparative.component.scss',
})
export class ComparativeComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Adjetivos Comparativos';
  override title = 'Adjetivos Comparativos - “Comparative”';
}
