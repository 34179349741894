<div class="direct-and-reported-speech">
  <p>
    Cuando hablamos en inglés, a veces queremos citar exactamente lo que alguien
    dijo (*Direct Speech*) o queremos decirlo de forma indirecta (*Reported
    Speech*). Aquí te explico cómo funciona cada uno y cómo pasar de uno a otro.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Direct Speech (Estilo Directo)</h2>

      <p>
        En el *Direct Speech*, repetimos las palabras exactas que alguien dijo,
        usando comillas. Es útil cuando queremos ser precisos o citar
        exactamente lo que se dijo.
      </p>

      <p><strong>Ejemplo de Direct Speech:</strong></p>
      <p><em>John said, "I am going to the store."</em></p>

      <h2>Reported Speech (Estilo Indirecto)</h2>

      <p>
        En el *Reported Speech*, no usamos las palabras exactas de la persona,
        sino que adaptamos lo que dijo para expresarlo de forma indirecta.
        Cambiamos algunos elementos, como los tiempos verbales, los pronombres,
        y a veces otras palabras, dependiendo de la situación.
      </p>

      <p><strong>Ejemplo de Reported Speech:</strong></p>
      <p><em>John said that he was going to the store.</em></p>

      <h2>Pasos para Convertir Direct Speech a Reported Speech:</h2>

      <p>
        A continuación, explico los pasos para pasar del estilo directo al
        indirecto. Vamos a usar el mismo ejemplo:
      </p>
      <p>Oración en Direct Speech: <em>"I am going to the store."</em></p>
      <p>Oración en Reported Speech: <em>he was going to the store.</em></p>

      <p>
        <strong>1. Cambia el tiempo verbal</strong>: Normalmente, cuando pasamos
        de estilo directo a indirecto, debemos retroceder un tiempo verbal. Esto
        significa que:
      </p>
      <ul>
        <li>
          Present simple (<em>am, is, are</em>) pasa a pasado simple (<em
            >was, were</em
          >).
        </li>
        <li>
          Present continuous (<em>is going</em>) pasa a pasado continuo (<em
            >was going</em
          >).
        </li>
        <li>
          Pasado simple (<em>went</em>) pasa a pasado perfecto (<em>had gone</em
          >).
        </li>
      </ul>

      <p>
        En el ejemplo, "<em>I am going</em>" en presente simple se convierte en
        "<em>he was going</em>" en pasado simple.
      </p>

      <p>
        <strong>2. Cambia los pronombres</strong>: Cambia los pronombres para
        reflejar correctamente quién está hablando y a quién se refiere la
        oración. En el ejemplo, cambiamos "<em>I</em>" (yo) por "<em>he</em>"
        (él), porque ya no es John quien está hablando directamente.
      </p>

      <p>
        <strong>3. Cambia algunas palabras clave si es necesario</strong>:
        Algunas palabras cambian cuando pasamos a estilo indirecto:
      </p>
      <ul>
        <li><em>Now</em> pasa a <em>then</em>.</li>
        <li><em>Today</em> pasa a <em>that day</em>.</li>
        <li><em>Tomorrow</em> pasa a <em>the next day</em>.</li>
        <li><em>Yesterday</em> pasa a <em>the day before</em>.</li>
      </ul>

      <p>
        Estas palabras de tiempo cambian solo si estamos hablando en un momento
        diferente. En el ejemplo, si John dijo
        <em>"I am going to the store today"</em>, al pasar a Reported Speech se
        diría <em>"he was going to the store that day"</em>.
      </p>

      <h2>Resumen de Cambios:</h2>

      <p>Para pasar de Direct Speech a Reported Speech:</p>
      <ul>
        <li>
          <strong>Cambia el tiempo verbal</strong> (retrocede un tiempo en la
          mayoría de los casos).
        </li>
        <li>
          <strong>Cambia los pronombres</strong> para reflejar a quién se
          refiere.
        </li>
        <li>
          <strong>Cambia palabras clave de tiempo</strong> si es necesario (por
          ejemplo, <em>now</em> a <em>then</em>).
        </li>
      </ul>

      <h3>Ejemplo Completo:</h3>

      <p>
        Oración en Direct Speech:
        <em>Mary said, "I will call you tomorrow."</em>
      </p>
      <p>
        Oración en Reported Speech:
        <em>Mary said that she would call me the next day.</em>
      </p>

      <p>Observa que:</p>
      <ul>
        <li>
          <em>will</em> cambió a <em>would</em> (tiempo futuro a condicional).
        </li>
        <li><em>I</em> cambió a <em>she</em> (cambio de pronombre).</li>
        <li>
          <em>Tomorrow</em> cambió a <em>the next day</em> (cambio de palabra de
          tiempo).
        </li>
      </ul>
    </div>
  </div>
</div>
