<div class="prefixes-and-suffixes">
  <p>
    Los <strong>prefijos</strong> y <strong>sufijos</strong> son partes que se
    añaden al inicio o al final de una palabra para cambiar su significado o
    función gramatical. A continuación, encontrarás una lista de los prefijos y
    sufijos más comunes en inglés, junto con ejemplos y explicaciones.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Prefixes (Prefijos)</h2>
      <p>
        Los prefijos se adjuntan al principio de una palabra para cambiar su
        significado.
      </p>

      <h3>Ejemplos de Prefijos</h3>
      <table border="1">
        <thead>
          <tr>
            <th>Palabra base</th>
            <th>Palabra con prefijo</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>regular (adj.)</td>
            <td><strong>ir</strong>regular (adj.)</td>
            <td>Regular → Irregular</td>
          </tr>
          <tr>
            <td>understand (v.)</td>
            <td><strong>mis</strong>understand (v.)</td>
            <td>Entender → Malentender</td>
          </tr>
          <tr>
            <td>active (adj.)</td>
            <td><strong>in</strong>active (adj.)</td>
            <td>Activo → Inactivo</td>
          </tr>
          <tr>
            <td>likely (adj.)</td>
            <td><strong>un</strong>likely (adj.)</td>
            <td>Probable → Improbable</td>
          </tr>
          <tr>
            <td>visible (adj.)</td>
            <td><strong>in</strong>visible (adj.)</td>
            <td>Visible → Invisible</td>
          </tr>
          <tr>
            <td>appropriate (adj.)</td>
            <td><strong>in</strong>appropriate (adj.)</td>
            <td>Adecuado → Inadecuado</td>
          </tr>
          <tr>
            <td>place (v.)</td>
            <td><strong>re</strong>place (v.)</td>
            <td>Colocar → Reemplazar</td>
          </tr>
          <tr>
            <td>do (v.)</td>
            <td><strong>re</strong>do (v.)</td>
            <td>Hacer → Hacer de nuevo</td>
          </tr>
          <tr>
            <td>direct (adj.)</td>
            <td><strong>indirect</strong> (adj.)</td>
            <td>Directo → Indirecto</td>
          </tr>
          <tr>
            <td>appear (v.)</td>
            <td><strong>dis</strong>appear (v.)</td>
            <td>Aparecer → Desaparecer</td>
          </tr>
          <tr>
            <td>known (adj.)</td>
            <td><strong>un</strong>known (adj.)</td>
            <td>Conocido → Desconocido</td>
          </tr>
          <tr>
            <td>happy (adj.)</td>
            <td><strong>un</strong>happy (adj.)</td>
            <td>Feliz → Infeliz</td>
          </tr>
          <tr>
            <td>visible (adj.)</td>
            <td><strong>in</strong>visible (adj.)</td>
            <td>Visible → Invisible</td>
          </tr>
        </tbody>
      </table>

      <h2>Suffixes (Sufijos)</h2>
      <p>
        Los sufijos se adjuntan al final de una palabra para crear una nueva
        palabra o para cambiar la función de la palabra.
      </p>

      <h3>Common Suffixes (Sufijos comunes)</h3>
      <table border="1">
        <thead>
          <tr>
            <th>Sufijo</th>
            <th>Significado</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>-acy</strong></td>
            <td>Estado o calidad de</td>
            <td>
              <strong>accuracy</strong> (exactitud),
              <strong>privacy</strong> (privacidad),
              <strong>democracy</strong> (democracia)
            </td>
          </tr>
          <tr>
            <td><strong>-al</strong></td>
            <td>Acto o proceso</td>
            <td>
              <strong>refusal</strong> (rechazo),
              <strong>arrival</strong> (llegada),
              <strong>approval</strong> (aprobación)
            </td>
          </tr>
          <tr>
            <td><strong>-ance</strong>, <strong>-ence</strong></td>
            <td>Estado o calidad de</td>
            <td>
              <strong>absence</strong> (ausencia),
              <strong>performance</strong> (rendimiento),
              <strong>reference</strong> (referencia)
            </td>
          </tr>
          <tr>
            <td><strong>-dom</strong></td>
            <td>Lugar o estado de ser</td>
            <td>
              <strong>kingdom</strong> (reino),
              <strong>freedom</strong> (libertad),
              <strong>wisdom</strong> (sabiduría)
            </td>
          </tr>
          <tr>
            <td><strong>-er</strong>, <strong>-or</strong></td>
            <td>Alguien que, uno que</td>
            <td>
              <strong>writer</strong> (escritor),
              <strong>conductor</strong> (conductor),
              <strong>developer</strong> (desarrollador)
            </td>
          </tr>
          <tr>
            <td><strong>-ism</strong></td>
            <td>Doctrina, creencia</td>
            <td>
              <strong>realism</strong> (realismo),
              <strong>nationalism</strong> (nacionalismo),
              <strong>capitalism</strong> (capitalismo)
            </td>
          </tr>
          <tr>
            <td><strong>-ist</strong></td>
            <td>Alguien que, uno que</td>
            <td>
              <strong>feminist</strong> (feminista),
              <strong>artist</strong> (artista),
              <strong>socialist</strong> (socialista)
            </td>
          </tr>
          <tr>
            <td><strong>-ity</strong>, <strong>-ty</strong></td>
            <td>Calidad de</td>
            <td>
              <strong>creativity</strong> (creatividad),
              <strong>reliability</strong> (fiabilidad),
              <strong>activity</strong> (actividad)
            </td>
          </tr>
          <tr>
            <td><strong>-ment</strong></td>
            <td>Condición de</td>
            <td>
              <strong>development</strong> (desarrollo),
              <strong>achievement</strong> (logro),
              <strong>entertainment</strong> (entretenimiento)
            </td>
          </tr>
          <tr>
            <td><strong>-ness</strong></td>
            <td>Estado de ser</td>
            <td>
              <strong>kindness</strong> (amabilidad),
              <strong>darkness</strong> (oscuridad),
              <strong>happiness</strong> (felicidad)
            </td>
          </tr>
          <tr>
            <td><strong>-ship</strong></td>
            <td>Posición</td>
            <td>
              <strong>friendship</strong> (amistad),
              <strong>leadership</strong> (liderazgo),
              <strong>relationship</strong> (relación)
            </td>
          </tr>
          <tr>
            <td><strong>-sion</strong>, <strong>-tion</strong></td>
            <td>Estado de ser</td>
            <td>
              <strong>celebration</strong> (celebración),
              <strong>education</strong> (educación),
              <strong>action</strong> (acción)
            </td>
          </tr>
          <tr>
            <td><strong>-ate</strong></td>
            <td>Convertirse en, hacerse, volverse</td>
            <td>
              <strong>activate</strong> (activar),
              <strong>negotiate</strong> (negociar),
              <strong>celebrate</strong> (celebrar)
            </td>
          </tr>
          <tr>
            <td><strong>-en</strong></td>
            <td>Convertirse en, hacerse, volverse</td>
            <td>
              <strong>shorten</strong> (acortar),
              <strong>widen</strong> (ensanchar),
              <strong>hasten</strong> (acelerar)
            </td>
          </tr>
          <tr>
            <td><strong>-ify</strong>, <strong>-fy</strong></td>
            <td>Convertirse en, hacerse, volverse</td>
            <td>
              <strong>clarify</strong> (aclarar),
              <strong>justify</strong> (justificar),
              <strong>magnify</strong> (ampliar)
            </td>
          </tr>
          <tr>
            <td><strong>-ize</strong>, <strong>-ise</strong></td>
            <td>Convertirse en, hacerse, volverse</td>
            <td>
              <strong>realize</strong> (darse cuenta),
              <strong>organize</strong> (organizar),
              <strong>recognize</strong> (reconocer)
            </td>
          </tr>
          <tr>
            <td><strong>-able</strong>, <strong>-ible</strong></td>
            <td>Capaz de</td>
            <td>
              <strong>tolerable</strong> (tolerable),
              <strong>visible</strong> (visible),
              <strong>predictable</strong> (predecible)
            </td>
          </tr>
          <tr>
            <td><strong>-ful</strong></td>
            <td>Notable para</td>
            <td>
              <strong>careful</strong> (cuidadoso),
              <strong>successful</strong> (exitoso),
              <strong>wonderful</strong> (maravilloso)
            </td>
          </tr>
          <tr>
            <td><strong>-ic</strong>, <strong>-ical</strong></td>
            <td>Relativo a</td>
            <td>
              <strong>historical</strong> (histórico),
              <strong>musical</strong> (musical),
              <strong>physical</strong> (físico)
            </td>
          </tr>
          <tr>
            <td><strong>-ious</strong>, <strong>-ous</strong></td>
            <td>Caracterizado por</td>
            <td>
              <strong>famous</strong> (famoso),
              <strong>dangerous</strong> (peligroso),
              <strong>nervous</strong> (nervioso)
            </td>
          </tr>
          <tr>
            <td><strong>-ish</strong></td>
            <td>Tener la calidad de</td>
            <td>
              <strong>childish</strong> (infantil),
              <strong>foolish</strong> (necio),
              <strong>greenish</strong> (verdoso)
            </td>
          </tr>
          <tr>
            <td><strong>-ive</strong></td>
            <td>Tener la calidad de</td>
            <td>
              <strong>aggressive</strong> (agresivo),
              <strong>productive</strong> (productivo),
              <strong>creative</strong> (creativo)
            </td>
          </tr>
          <tr>
            <td><strong>-less</strong></td>
            <td>Sin</td>
            <td>
              <strong>careless</strong> (despreocupado),
              <strong>hopeless</strong> (sin esperanza),
              <strong>useless</strong> (inútil)
            </td>
          </tr>
          <tr>
            <td><strong>-y</strong></td>
            <td>Caracterizado por</td>
            <td>
              <strong>greedy</strong> (codicioso),
              <strong>messy</strong> (desordenado),
              <strong>happy</strong> (feliz)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
