import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-verbos',
  templateUrl: './verbos.component.html',
  styleUrl: './verbos.component.scss',
})
export class VerbosComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Verbos en Inglés';
  override title = 'Introduccion a Verbos en Inglés';
}
