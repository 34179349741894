<div class="delexical-verbs">
  <p>
    Los <strong>verbos delexicales</strong> son verbos que, por sí solos, tienen
    un significado muy general y a menudo no son muy informativos. En su uso
    cotidiano, dependen de otros elementos, como sustantivos o adjetivos, para
    proporcionar un significado específico. Delexical verbs son verbos comunes
    como “have”, “take”, “make” o “give”.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Have</h2>
      <p>
        <strong>“Have”</strong> indica posesión, pero cuando se utiliza en
        estructuras delexicales, el sentido cambia. Podemos usar “have” cuando
        hablamos de:
      </p>
      <ul>
        <li><strong>Comidas:</strong> como en "have breakfast" (desayunar).</li>
        <li>
          <strong>Conversaciones:</strong> como en "have a conversation" (tener
          una conversación).
        </li>
        <li>
          <strong>Desacuerdos:</strong> como en "have a disagreement" (tener un
          desacuerdo).
        </li>
        <li><strong>Lavados:</strong> como en "have a wash" (lavarse).</li>
        <li>
          <strong>Descansos:</strong> como en "have a break" (tomar un
          descanso).
        </li>
      </ul>

      <h2>2. Take</h2>
      <p>
        <strong>“Take”</strong> se refiere a la acción de tomar, y su
        significado varía en diferentes contextos. Usamos “take” cuando hablamos
        de:
      </p>
      <ul>
        <li>
          <strong>Acciones:</strong> como en "take a shower" (tomar una ducha).
        </li>
        <li>
          <strong>Viajes:</strong> como en "take a trip" (hacer un viaje).
        </li>
        <li>
          <strong>Descansos:</strong> como en "take a break" (tomar un
          descanso).
        </li>
        <li><strong>Notas:</strong> como en "take notes" (tomar apuntes).</li>
        <li>
          <strong>Medicina:</strong> como en "take medicine" (tomar medicina).
        </li>
      </ul>

      <h2>3. Make</h2>
      <p>
        <strong>“Make”</strong> implica la creación de algo. Este verbo se
        utiliza en diversas combinaciones que indican acciones creativas o
        decisionales. Usamos “make” en situaciones como:
      </p>
      <ul>
        <li>
          <strong>Decisiones:</strong> como en "make a decision" (tomar una
          decisión).
        </li>
        <li>
          <strong>Comida:</strong> como en "make a cake" (hacer un pastel).
        </li>
        <li>
          <strong>Errores:</strong> como en "make a mistake" (cometer un error).
        </li>
        <li><strong>Planes:</strong> como en "make plans" (hacer planes).</li>
        <li><strong>Ruido:</strong> como en "make noise" (hacer ruido).</li>
      </ul>

      <h2>4. Give</h2>
      <p>
        <strong>“Give”</strong> se refiere a la acción de dar y se usa en
        combinaciones que describen la oferta de algo. Usamos “give” en
        contextos como:
      </p>
      <ul>
        <li>
          <strong>Presentaciones:</strong> como en "give a presentation" (hacer
          una presentación).
        </li>
        <li><strong>Consejos:</strong> como en "give advice" (dar consejo).</li>
        <li>
          <strong>Ayudar:</strong> como en "give a hand" (dar una mano, ayudar).
        </li>
        <li>
          <strong>Regalos:</strong> como en "give a gift" (dar un regalo).
        </li>
        <li>
          <strong>Informes:</strong> como en "give a report" (dar un informe).
        </li>
      </ul>

      <h3>Ejemplos de Verbos Delexicales</h3>
      <table border="1">
        <tr>
          <th>Verbo</th>
          <th>Ejemplo de Uso</th>
          <th>Significado Específico</th>
        </tr>
        <tr>
          <td>Do</td>
          <td>Do the laundry</td>
          <td>Hacer la colada (lavar la ropa).</td>
        </tr>
        <tr>
          <td>Have</td>
          <td>Have a bath</td>
          <td>Tener un baño (tomar un baño).</td>
        </tr>
        <tr>
          <td>Take</td>
          <td>Take a break</td>
          <td>Tomar un descanso.</td>
        </tr>
        <tr>
          <td>Make</td>
          <td>Make a decision</td>
          <td>Tomar una decisión.</td>
        </tr>
        <tr>
          <td>Have</td>
          <td>Have a party</td>
          <td>Tener una fiesta.</td>
        </tr>
        <tr>
          <td>Take</td>
          <td>Take a picture</td>
          <td>Tomar una foto.</td>
        </tr>
        <tr>
          <td>Do</td>
          <td>Do homework</td>
          <td>Hacer la tarea.</td>
        </tr>
        <tr>
          <td>Have</td>
          <td>Have a meeting</td>
          <td>Tener una reunión.</td>
        </tr>
        <tr>
          <td>Take</td>
          <td>Take a seat</td>
          <td>Tomar asiento.</td>
        </tr>
        <tr>
          <td>Make</td>
          <td>Make a mistake</td>
          <td>Cometer un error.</td>
        </tr>
      </table>
    </div>
  </div>
</div>
