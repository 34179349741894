<div class="present-perfect">
  <p>
    El Present Perfect en inglés es un tiempo verbal que conecta el pasado con
    el presente.
  </p>
  <ul>
    <li><strong>Acciones pasadas</strong> con relevancia en el presente.</li>
    <li>
      <strong>Experiencias de vida</strong> (sin mencionar cuándo ocurrieron).
    </li>
    <li>
      <strong>Acciones que comenzaron en el pasado</strong> y continúan en el
      presente.
    </li>
  </ul>
  <div class="section">
    <div class="section-blanco">
      <h2>2. Estructura del <strong>Present Perfect</strong></h2>

      <h3>Forma afirmativa:</h3>
      <p><strong>Sujeto + have/has + participio pasado</strong></p>
      <p>Ejemplos:</p>
      <ul>
        <li>
          <strong>I have eaten</strong> sushi before.
          <em>(He comido sushi antes)</em>
        </li>
        <li>
          <strong>She has visited</strong> Paris three times.
          <em>(Ella ha visitado París tres veces)</em>
        </li>
      </ul>

      <h3>Forma negativa:</h3>
      <p><strong>Sujeto + have/has + not + participio pasado</strong></p>
      <p>Ejemplos:</p>
      <ul>
        <li>
          <strong>They haven’t finished</strong> their homework.
          <em>(No han terminado su tarea)</em>
        </li>
        <li>
          <strong>He hasn’t called</strong> me. <em>(Él no me ha llamado)</em>
        </li>
      </ul>

      <p>
        Como se ve en los ejemplos y como hemos mencionado, el uso del verbo
        debe ir en pasado participio, si desea repasar como formar los verbos en
        pasado puede hacerlo desde
        <a href="curso-de-ingles-online.com/lecciones/intermedio/past-simple">
          aqui
        </a>
      </p>

      <h3>Forma interrogativa:</h3>
      <p><strong>Have/Has + sujeto + participio pasado?</strong></p>
      <p>Ejemplos:</p>
      <ul>
        <li>
          <strong>Have you ever traveled</strong> to Italy?
          <em>(¿Alguna vez has viajado a Italia?)</em>
        </li>
        <li>
          <strong>Has she met</strong> him before?
          <em>(¿Ella lo ha conocido antes?)</em>
        </li>
      </ul>

      <h2>Usos del <strong>Present Perfect</strong> en Inglés</h2>

      <!-- Uso 1 -->
      <h3>1. Cambio a lo largo del tiempo</h3>
      <p>
        Usamos el <strong>Present Perfect</strong> para describir un cambio que
        ha ocurrido con el tiempo.
      </p>
      <div class="example">
        <p><strong>Ejemplo en inglés:</strong> "My English has improved."</p>
        <p><strong>Traducción:</strong> Mi inglés ha mejorado.</p>
      </div>

      <!-- Uso 2 -->
      <h3>2. Experiencias de vida</h3>
      <p>
        Describe experiencias de vida sin especificar el momento exacto. Se
        enfoca en el hecho de que sucedió.
      </p>
      <div class="example">
        <p><strong>Ejemplo en inglés:</strong> "I have traveled to Italy."</p>
        <p><strong>Traducción:</strong> He viajado a Italia.</p>
      </div>

      <!-- Uso 3 -->
      <h3>3. Acciones recientes que afectan el presente</h3>
      <p>
        Expresa una acción que ocurrió en un pasado reciente y que es relevante
        para el momento actual.
      </p>
      <div class="example">
        <p>
          <strong>Ejemplo en inglés:</strong> "She has just finished her
          homework."
        </p>
        <p><strong>Traducción:</strong> Ella acaba de terminar su tarea.</p>
      </div>

      <!-- Uso 4 -->
      <h3>
        4. Situaciones que comenzaron en el pasado y continúan en el presente
      </h3>
      <p>
        Utilizamos el <strong>Present Perfect</strong> para acciones o estados
        que empezaron en el pasado y siguen siendo válidos, usando palabras como
        "for" y "since".
      </p>
      <div class="example">
        <p>
          <strong>Ejemplo en inglés:</strong> "They have lived in this city for
          five years."
        </p>
        <p>
          <strong>Traducción:</strong> Han vivido en esta ciudad por cinco años.
        </p>
      </div>

      <!-- Uso 5 -->
      <h3>
        5. Acciones en diferentes momentos en el pasado con posibilidad de
        repetición
      </h3>
      <p>
        Usamos el <strong>Present Perfect</strong> para acciones que ocurrieron
        varias veces y podrían repetirse en el futuro.
      </p>
      <div class="example">
        <p>
          <strong>Ejemplo en inglés:</strong> "I have visited Paris three
          times."
        </p>
        <p><strong>Traducción:</strong> He visitado París tres veces.</p>
      </div>

      <h2>4. Palabras clave del <strong>Present Perfect</strong></h2>
      <p>
        Las siguientes palabras se usan frecuentemente con el
        <strong>Present Perfect</strong>:
      </p>
      <ul>
        <li>
          <strong>Just</strong> (acaba de) –
          <strong>I have just eaten.</strong> <em>(Acabo de comer)</em>
        </li>
        <li>
          <strong>Already</strong> (ya) –
          <strong>They have already seen that movie.</strong>
          <em>(Ellos ya han visto esa película)</em>
        </li>
        <li>
          <strong>Yet</strong> (todavía/aún, en preguntas o negativas) –
          <strong>Have you done your homework yet?</strong>
          <em>(¿Has hecho tu tarea ya?)</em>
        </li>
        <li>
          <strong>Ever</strong> (alguna vez, en preguntas) –
          <strong>Have you ever traveled alone?</strong>
          <em>(¿Alguna vez has viajado solo?)</em>
        </li>
        <li>
          <strong>Never</strong> (nunca) –
          <strong>She has never been to Mexico.</strong>
          <em>(Ella nunca ha estado en México)</em>
        </li>
        <li>
          <strong>For</strong> y <strong>since</strong>:
          <ul>
            <li>
              <strong>For</strong> (por una cantidad de tiempo) –
              <strong>I have worked here for five years.</strong>
              <em>(He trabajado aquí por cinco años)</em>
            </li>
            <li>
              <strong>Since</strong> (desde un punto específico en el tiempo) –
              <strong>He has lived here since 2010.</strong>
              <em>(Él ha vivido aquí desde 2010)</em>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h3>Ejemplos de Present Perfect</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en Inglés</th>
            <th>Traducción en Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>I have traveled</strong> to Japan.</td>
            <td>He viajado a Japón.</td>
          </tr>
          <tr>
            <td><strong>She has eaten</strong> sushi before.</td>
            <td>Ella ha comido sushi antes.</td>
          </tr>
          <tr>
            <td><strong>We have finished</strong> our homework.</td>
            <td>Hemos terminado nuestra tarea.</td>
          </tr>
          <tr>
            <td><strong>They have never visited</strong> Paris.</td>
            <td>Ellos nunca han visitado París.</td>
          </tr>
          <tr>
            <td><strong>He has just called</strong> his mother.</td>
            <td>Él acaba de llamar a su madre.</td>
          </tr>
          <tr>
            <td><strong>Have you ever seen</strong> a dolphin?</td>
            <td>¿Alguna vez has visto un delfín?</td>
          </tr>
          <tr>
            <td><strong>I have known</strong> her since childhood.</td>
            <td>La conozco desde la infancia.</td>
          </tr>
          <tr>
            <td><strong>They have already completed</strong> the project.</td>
            <td>Ellos ya han completado el proyecto.</td>
          </tr>
          <tr>
            <td><strong>We have been</strong> here for two hours.</td>
            <td>Hemos estado aquí por dos horas.</td>
          </tr>
          <tr>
            <td><strong>She has never tried</strong> skiing.</td>
            <td>Ella nunca ha probado el esquí.</td>
          </tr>
          <tr>
            <td><strong>You haven’t answered</strong> my question yet.</td>
            <td>No has respondido a mi pregunta aún.</td>
          </tr>
          <tr>
            <td><strong>He has broken</strong> his leg.</td>
            <td>Él se ha roto la pierna.</td>
          </tr>
          <tr>
            <td><strong>Have they visited</strong> New York?</td>
            <td>¿Han visitado Nueva York?</td>
          </tr>
          <tr>
            <td><strong>I have read</strong> that book.</td>
            <td>He leído ese libro.</td>
          </tr>
          <tr>
            <td><strong>We have worked</strong> together for five years.</td>
            <td>Hemos trabajado juntos por cinco años.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Diferencias entre Present Perfect y Past Simple</h2>
      <p>
        Muchas veces suele confundirse el Past simple con el present perfect,
        por ende mencionamos algunas caracteristicas de ambos tiempos para poder
        diferenciarlos. Hay que tener muy en cuenta que el
        <strong>Present Perfect</strong> se utiliza para conectar acciones
        pasadas con el presente, sin especificar el momento exacto en que
        ocurrieron, mientras que el <strong>Past Simple</strong> se emplea para
        acciones que ocurrieron en un momento específico del pasado y no tienen
        relación directa con el presente.
      </p>
      <table>
        <tr>
          <th>Característica</th>
          <th>Present Perfect</th>
          <th>Past Simple</th>
        </tr>
        <tr>
          <td><strong>Uso principal</strong></td>
          <td>
            Acciones pasadas con relevancia en el presente. El tiempo exacto no
            importa.
          </td>
          <td>Acciones completadas en un momento específico del pasado.</td>
        </tr>
        <tr>
          <td><strong>Conexión con el presente</strong></td>
          <td>Sí, tiene efecto o relevancia ahora.</td>
          <td>No, es solo un evento del pasado.</td>
        </tr>
        <tr>
          <td><strong>Ejemplos de palabras clave</strong></td>
          <td>Already, just, yet, ever, never</td>
          <td>Yesterday, last year, in 2015, ago</td>
        </tr>
      </table>

      <h2>Ejemplos</h2>
      <table>
        <tr>
          <th>Present Perfect</th>
          <th>Past Simple</th>
        </tr>
        <tr>
          <td>
            "They have just arrived." (Acaban de llegar; es relevante ahora)
          </td>
          <td>"They arrived yesterday." (Llegaron ayer; es un hecho pasado)</td>
        </tr>
        <tr>
          <td>
            "She has never been to Paris." (Nunca ha estado en París en su vida)
          </td>
          <td>
            "She went to Paris last year." (Fue a París el año pasado, momento
            específico)
          </td>
        </tr>
        <tr>
          <td>
            "I have already finished my work." (Ya he terminado, y está listo
            ahora)
          </td>
          <td>
            "I finished my work an hour ago." (Terminé hace una hora, en el
            pasado)
          </td>
        </tr>
        <tr>
          <td>"Have you ever tried sushi?" (¿Alguna vez has probado sushi?)</td>
          <td>"I tried sushi last month." (Probé sushi el mes pasado)</td>
        </tr>
      </table>
    </div>
  </div>
</div>
