import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-future-continuous',
  standalone: true,
  imports: [],
  templateUrl: './future-continuous.component.html',
  styleUrl: './future-continuous.component.scss',
})
export class FutureContinuousComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Futuro continuo';
  override title = 'Futuro continuo - "Future continuous"';
}
