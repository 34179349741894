<div class="todo">
  <p>
    El verbo <strong>"to do"</strong> es fundamental en inglés, ya que puede
    funcionar tanto como verbo principal como verbo auxiliar. Aquí nos
    enfocaremos en su uso en presente simple, que es un tiempo verbal que se
    utiliza para describir acciones habituales, rutinas y hechos generales. El
    verbo to-do en inglés tiene varios significados y usos.
    <strong
      >Indica realizar o ejecutar una acción, cumplir con una obligación,
      practicar o participar en actividades</strong
    >, y también se utiliza como verbo auxiliar para formar preguntas y
    negaciones en presente simple.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h1 class="titulo-principal">
        Uso de verbo To do - uso de "do" y "does"
      </h1>
      <p class="parrafo">
        "Do" y "does" son formas del verbo
        <strong>"to do,"</strong>. La elección entre <strong>"do"</strong> y
        <strong>"does"</strong> depende del sujeto de la oración;
        <strong>"do"</strong> se usa con los sujetos
        <strong>"I," "you," "we,"</strong> y <strong>"they,"</strong> mientras
        que <strong>"does"</strong> se usa con <strong>"he," "she,"</strong> e
        <strong>"it."</strong>
      </p>
    </div>
    <div class="section-blanco">
      <h2>1. Uso del Presente Simple</h2>
      <p><strong>El presente simple</strong> se usa para:</p>
      <ul>
        <li>Describir acciones habituales o rutinarias.</li>
        <li>Hablar de hechos generales o verdades universales.</li>
        <li>Indicar estados permanentes.</li>
        <li>Hablar sobre situaciones actuales que no cambian.</li>
      </ul>
    </div>
    <div class="section-blanco">
      <h2>2. Como Verbo Principal</h2>
      <p>
        <strong>Significado:</strong> Como verbo principal, "to do" indica
        realizar una acción o actividad.
      </p>

      <h3>Ejemplos:</h3>
      <table>
        <tr>
          <th>Ejemplo en Inglés</th>
          <th>Traducción en Español</th>
        </tr>
        <tr>
          <td>I do my homework every day.</td>
          <td>Hago mi tarea todos los días.</td>
        </tr>
        <tr>
          <td>They do their shopping on Saturdays.</td>
          <td>Ellos hacen sus compras los sábados.</td>
        </tr>
        <tr>
          <td>She does exercise in the morning.</td>
          <td>Ella hace ejercicio por la mañana.</td>
        </tr>
        <tr>
          <td>We do our best to help.</td>
          <td>Hacemos nuestro mejor esfuerzo para ayudar.</td>
        </tr>
        <tr>
          <td>He does his work carefully.</td>
          <td>Él hace su trabajo cuidadosamente.</td>
        </tr>
        <tr>
          <td>They do a lot of reading.</td>
          <td>Ellos hacen mucha lectura.</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>3. Como Verbo Auxiliar</h2>
      <h3 class="subtitulo">Forma Negativa</h3>
      <p>
        Para hacer oraciones negativas en presente simple, se usa
        <strong>do not (don’t)</strong> o
        <strong>does not (doesn’t)</strong> seguido del verbo en su forma base:
      </p>

      <table>
        <thead>
          <tr>
            <th>Sujeto</th>
            <th>Auxiliar (Do/Does)</th>
            <th>Negación</th>
            <th>Verbo (forma base)</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I</td>
            <td>do</td>
            <td>not</td>
            <td>play</td>
            <td>yo no juego</td>
          </tr>
          <tr>
            <td>You</td>
            <td>do</td>
            <td>not</td>
            <td>play</td>
            <td>tú no juegas</td>
          </tr>
          <tr>
            <td>He/She/It</td>
            <td>does</td>
            <td>not</td>
            <td>play</td>
            <td>él/ella/ello no juega</td>
          </tr>
          <tr>
            <td>We</td>
            <td>do</td>
            <td>not</td>
            <td>play</td>
            <td>nosotros no jugamos</td>
          </tr>
          <tr>
            <td>You</td>
            <td>do</td>
            <td>not</td>
            <td>play</td>
            <td>vosotros no jugáis</td>
          </tr>
          <tr>
            <td>They</td>
            <td>do</td>
            <td>not</td>
            <td>play</td>
            <td>ellos no juegan</td>
          </tr>
        </tbody>
      </table>

      <p>
        <strong>Regla:</strong> Con <em>he, she, it</em>, usamos
        <strong>does not (doesn't)</strong> y no se añade <strong>-s</strong> al
        verbo.
      </p>

      <h2>Ejemplos: Negaciones y Afirmaciones con "do" y "does"</h2>
      <table border="1">
        <thead>
          <tr>
            <th>#</th>
            <th>Negación/Afirmación</th>
            <th>Traducción</th>
            <th>Uso de "do" o "does"</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>I do not (don't) like broccoli.</td>
            <td>No me gusta el brócoli.</td>
            <td>Se usa "do" porque el sujeto es "I".</td>
          </tr>
          <tr>
            <td>2</td>
            <td>You do not (don't) have any money.</td>
            <td>No tienes dinero.</td>
            <td>Se usa "do" porque el sujeto es "you".</td>
          </tr>
          <tr>
            <td>3</td>
            <td>We do not (don't) go to that restaurant.</td>
            <td>No vamos a ese restaurante.</td>
            <td>Se usa "do" porque el sujeto es "we".</td>
          </tr>
          <tr>
            <td>4</td>
            <td>They do not (don't) want to leave yet.</td>
            <td>Ellos no quieren irse todavía.</td>
            <td>Se usa "do" porque el sujeto es "they".</td>
          </tr>
          <tr>
            <td>5</td>
            <td>He does not (doesn't) play basketball.</td>
            <td>Él no juega al baloncesto.</td>
            <td>Se usa "does" porque el sujeto es "he".</td>
          </tr>
          <tr>
            <td>6</td>
            <td>She does not (doesn't) like chocolate.</td>
            <td>A ella no le gusta el chocolate.</td>
            <td>Se usa "does" porque el sujeto es "she".</td>
          </tr>
          <tr>
            <td>7</td>
            <td>It does not (doesn't) matter.</td>
            <td>No importa.</td>
            <td>Se usa "does" porque el sujeto es "it".</td>
          </tr>
          <tr>
            <td>8</td>
            <td>I do not (don't) understand the question.</td>
            <td>No entiendo la pregunta.</td>
            <td>Se usa "do" porque el sujeto es "I".</td>
          </tr>
          <tr>
            <td>9</td>
            <td>You do not (don't) need to worry.</td>
            <td>No necesitas preocuparte.</td>
            <td>Se usa "do" porque el sujeto es "you".</td>
          </tr>
          <tr>
            <td>10</td>
            <td>They do not (don't) have a dog.</td>
            <td>Ellos no tienen un perro.</td>
            <td>Se usa "do" porque el sujeto es "they".</td>
          </tr>
        </tbody>
      </table>

      <h3 class="subtitulo">Forma Interrogativa</h3>
      <p>
        Como mencionamos anteriormente, para hacer preguntas en presente simple,
        usamos el auxiliar <strong>do</strong> o <strong>does</strong> al inicio
        de la oración, seguido del sujeto y el verbo en su forma base:
      </p>

      <table>
        <thead>
          <tr>
            <th>Auxiliar (Do/Does)</th>
            <th>Sujeto</th>
            <th>Verbo (forma base). Ej Play</th>
            <th>Pregunta en inglés</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Do</td>
            <td>I</td>
            <td>play</td>
            <td>Do I play?</td>
            <td>¿yo juego?</td>
          </tr>
          <tr>
            <td>Do</td>
            <td>you</td>
            <td>play</td>
            <td>Do you play?</td>
            <td>¿tú juegas?</td>
          </tr>
          <tr>
            <td>Does</td>
            <td>he/she/it</td>
            <td>play</td>
            <td>Does he/she/it play?</td>
            <td>¿él/ella/ello juega?</td>
          </tr>
          <tr>
            <td>Do</td>
            <td>we</td>
            <td>play</td>
            <td>Do we play?</td>
            <td>¿nosotros jugamos?</td>
          </tr>
          <tr>
            <td>Do</td>
            <td>you</td>
            <td>play</td>
            <td>Do you play?</td>
            <td>¿vosotros jugáis?</td>
          </tr>
          <tr>
            <td>Do</td>
            <td>they</td>
            <td>play</td>
            <td>Do they play?</td>
            <td>¿ellos juegan?</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2 class="titulo-seccion">Ejemplos: Preguntas con "do" y "does"</h2>
      <table border="1">
        <thead>
          <tr>
            <th>#</th>
            <th>Pregunta</th>
            <th>Traducción</th>
            <th>Uso de "do" o "does"</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Do you like pizza?</td>
            <td>¿Te gusta la pizza?</td>
            <td>Se usa "do" porque el sujeto es "you".</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Do they play football?</td>
            <td>¿Ellos juegan al fútbol?</td>
            <td>Se usa "do" porque el sujeto es "they".</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Do we need to study for the exam?</td>
            <td>¿Necesitamos estudiar para el examen?</td>
            <td>Se usa "do" porque el sujeto es "we".</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Do I have to go now?</td>
            <td>¿Tengo que irme ahora?</td>
            <td>Se usa "do" porque el sujeto es "I".</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Do you speak Spanish?</td>
            <td>¿Hablas español?</td>
            <td>Se usa "do" porque el sujeto es "you".</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Does he work here?</td>
            <td>¿Él trabaja aquí?</td>
            <td>Se usa "does" porque el sujeto es "he".</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Does she have a car?</td>
            <td>¿Ella tiene un coche?</td>
            <td>Se usa "does" porque el sujeto es "she".</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Does it rain often?</td>
            <td>¿Llueve a menudo?</td>
            <td>Se usa "does" porque el sujeto es "it".</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Do you want to join us?</td>
            <td>¿Quieres unirte a nosotros?</td>
            <td>Se usa "do" porque el sujeto es "you".</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Do they enjoy watching movies?</td>
            <td>¿Ellos disfrutan ver películas?</td>
            <td>Se usa "do" porque el sujeto es "they".</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>4. Enfasis</h2>
      <p>
        El verbo "to do" se utiliza para enfatizar la acción en oraciones
        afirmativas. Al añadir "do" o "does" se refuerza la afirmación.
      </p>

      <h3>Ejemplos de Enfasis:</h3>
      <table>
        <tr>
          <th>Ejemplo en Inglés</th>
          <th>Traducción en Español</th>
        </tr>
        <tr>
          <td>I do want to see that movie!</td>
          <td>¡De verdad quiero ver esa película!</td>
        </tr>
        <tr>
          <td>She does know the answer.</td>
          <td>Ella sí sabe la respuesta.</td>
        </tr>
        <tr>
          <td>They do enjoy playing soccer.</td>
          <td>Ellos realmente disfrutan jugar al fútbol.</td>
        </tr>
      </table>
    </div>
  </div>
</div>
