import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-linking-words',
  standalone: true,
  imports: [],
  templateUrl: './linking-words.component.html',
  styleUrl: './linking-words.component.scss',
})
export class LinkingWordsComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Linking words';
  override title = 'Linking words';
}
