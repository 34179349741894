<div class="passive-voice">
  <p>
    La <strong>voz pasiva</strong> es una manera de expresar una acción en la
    que el <strong>enfoque está en el objeto</strong> de la acción, en lugar del
    sujeto. A veces, no es tan importante quién hizo algo, sino qué se hizo. Por
    ejemplo, si quieres hablar de un cuadro famoso, lo importante no siempre es
    mencionar quién lo pintó, sino que el cuadro fue pintado.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Estructura de la voz pasiva</h2>

      <p>Para formar la voz pasiva en inglés, sigue esta fórmula básica:</p>

      <blockquote>
        <strong>Sujeto pasivo</strong> + <strong>verbo "to be"</strong> (en el
        tiempo correcto) + <strong>participio pasado</strong> del verbo
        principal
      </blockquote>
      <div>
        <h2>Transformación de Oración Activa a Pasiva en Inglés</h2>

        <p>
          Para convertir una oración activa en pasiva, sigue estos pasos. Vamos
          a usar un ejemplo para que se entienda mejor:
        </p>

        <h3>Ejemplo:</h3>
        <p>
          Oración activa: <em>The chef prepared the meal.</em> <br />
          Oración pasiva: <em>The meal was prepared by the chef.</em>
        </p>

        <h3>Pasos para Transformar de Activa a Pasiva:</h3>

        <p>
          <strong>1. Cambia el objeto al principio</strong>: Mira la oración
          activa y encuentra el objeto, o sea, la cosa o persona que recibe la
          acción. En el ejemplo, el objeto es <strong>"the meal"</strong> (la
          comida). En la pasiva, ponemos este objeto al principio de la oración.
        </p>

        <p>Entonces, comenzamos la oración pasiva con "<em>The meal</em>".</p>

        <p>
          <strong>2. Cambia el verbo</strong>: Ahora, cambia el verbo de la
          oración. Usamos el verbo "<em>to be</em>" (en el tiempo correcto) y
          ponemos el verbo principal en participio pasado (la forma de verbo
          como “prepared”).
        </p>

        <p>
          En nuestro ejemplo, el verbo "<em>prepared</em>" está en pasado, así
          que usamos "<em>was</em>" (la forma pasada de "to be") y luego
          agregamos "<em>prepared</em>".
        </p>

        <p>
          Hasta ahora, la oración queda así: "<em>The meal was prepared</em>".
        </p>

        <p>
          <strong
            >3. Mueve el sujeto original al final (si quieres decir quién lo
            hizo)</strong
          >: En la oración activa, el sujeto es "<em>The chef</em>" (el chef).
          Ahora, este sujeto va al final, pero ya no es el centro de atención,
          sino quien hizo la acción.
        </p>

        <p>Si queremos incluir al chef, lo ponemos al final de la oración.</p>

        <p>
          <strong>4. Usa "by" para decir quién hizo la acción</strong>: Si es
          importante decir quién realizó la acción, lo ponemos al final,
          precedido por "<em>by</em>". Esto indica que "<em>the chef</em>" es
          quien preparó la comida.
        </p>

        <p>
          Así, la oración completa en voz pasiva es: "<strong
            >The meal was prepared by the chef.</strong
          >"
        </p>

        <h3>¿Por qué usar la voz pasiva?</h3>
        <p>
          En la voz pasiva, nos enfocamos en la acción o en el resultado (la
          comida que fue preparada), en lugar de en quien hizo la acción. Esto
          es útil cuando queremos destacar lo que pasó, no necesariamente quién
          lo hizo.
        </p>

        <h3>Resumen:</h3>

        <p>Para pasar de activa a pasiva:</p>

        <ul>
          <li>
            1. Encuentra el objeto de la oración activa y ponlo al inicio en la
            oración pasiva.
          </li>
          <li>
            2. Cambia el verbo para usar "<em>to be</em>" en el tiempo correcto,
            y pon el verbo principal en participio pasado.
          </li>
          <li>
            3. Si quieres mencionar quién hizo la acción, pon el sujeto original
            al final, precedido por "<em>by</em>".
          </li>
        </ul>
      </div>

      <h2>Diferencias clave entre voz activa y pasiva</h2>

      <table border="1">
        <tr>
          <th>Voz Activa</th>
          <th>Voz Pasiva</th>
        </tr>
        <tr>
          <td>Da más importancia al sujeto (quién realiza la acción).</td>
          <td>
            Da más importancia a la acción o al objeto de la acción. Se puede
            usar si no sabes o no quieres decir quién realizó la acción.
          </td>
        </tr>
        <tr>
          <td>
            Ejemplo: <em>The company launched a new product.</em> (La empresa
            lanzó un nuevo producto.)
          </td>
          <td>
            Ejemplo: <em>A new product was launched by the company.</em> (Un
            nuevo producto fue lanzado por la empresa.)
          </td>
        </tr>
        <tr>
          <td colspan="2">
            A veces, ni siquiera es necesario mencionar al agente (quién lo
            hizo):
            <em>A new product was launched.</em> (Se lanzó un nuevo producto.)
          </td>
        </tr>
      </table>

      <h2>Cómo usar la voz pasiva en diferentes tiempos</h2>

      <table border="1">
        <tr>
          <th>Tiempo</th>
          <th>Activa</th>
          <th>Pasiva</th>
        </tr>
        <tr>
          <td>Presente simple</td>
          <td><em>She writes a letter.</em></td>
          <td><em>A letter is written by her.</em></td>
        </tr>
        <tr>
          <td>Pasado simple</td>
          <td><em>She wrote a letter.</em></td>
          <td><em>A letter was written by her.</em></td>
        </tr>
        <tr>
          <td>Futuro simple</td>
          <td><em>She will write a letter.</em></td>
          <td><em>A letter will be written by her.</em></td>
        </tr>
        <tr>
          <td>Presente perfecto</td>
          <td><em>She has written a letter.</em></td>
          <td><em>A letter has been written by her.</em></td>
        </tr>
        <tr>
          <td>Pasado perfecto</td>
          <td><em>She had written a letter.</em></td>
          <td><em>A letter had been written by her.</em></td>
        </tr>
      </table>

      <h2>Cuándo usar la voz pasiva</h2>

      <table border="1">
        <tr>
          <th>Caso</th>
          <th>Ejemplo</th>
        </tr>
        <tr>
          <td>Cuando el agente no es importante o es obvio.</td>
          <td>
            <em>The roads were cleaned.</em> (Las calles fueron limpiadas.)
          </td>
        </tr>
        <tr>
          <td>Cuando el agente es desconocido.</td>
          <td><em>The window was broken.</em> (La ventana fue rota.)</td>
        </tr>
        <tr>
          <td>Cuando queremos sonar más formales o impersonales.</td>
          <td><em>Mistakes were made.</em> (Se cometieron errores.)</td>
        </tr>
      </table>
    </div>
  </div>
</div>
