
<div class="colors">
  <p>En esta seccion aprenderemos algunos <strong>colores en ingles</strong>, ademas incluiremos como decir que un color es claro u oscuro</p>
  <p> En inglés, para decir "oscuro" o "claro" en relación con un color, se utilizan las palabras <strong>dark</strong> (oscuro) y <strong>light</strong> (claro) antes del color. Esto no se limita solo al azul;</p>
  <p> por ejemplo, se puede decir "dark green" (verde oscuro) o "light red" (rojo claro).</p>
  <div class="section">
  <div class="section-blanco">
    <h2>Colores</h2>

  <table border="1" cellpadding="10">
      <thead>
          <tr>
              <th>Color (Español)</th>
              <th>Color (Inglés)</th>
              <th>Pronunciación (Inglés)</th>
          </tr>
      </thead>
      <tbody>
          <tr>
              <td>Rojo</td>
              <td>Red</td>
              <td>Red (/rɛd/)</td>
          </tr>
          <tr>
              <td>Azul</td>
              <td>Blue</td>
              <td>Blue (/bluː/)</td>
          </tr>
          <tr>
              <td>Azul Oscuro</td>
              <td>Dark Blue</td>
              <td>Dark Blue (/dɑːrk bluː/)</td>
          </tr>
          <tr>
              <td>Celeste</td>
              <td>Sky Blue</td>
              <td>Sky Blue (/skaɪ bluː/)</td>
          </tr>
          <tr>
              <td>Amarillo</td>
              <td>Yellow</td>
              <td>Yellow (/ˈjɛloʊ/)</td>
          </tr>
          <tr>
              <td>Verde</td>
              <td>Green</td>
              <td>Green (/ɡriːn/)</td>
          </tr>
          <tr>
              <td>Verde Oscuro</td>
              <td>Dark Green</td>
              <td>Dark Green (/dɑːrk ɡriːn/)</td>
          </tr>
          <tr>
              <td>Verde Claro</td>
              <td>Light Green</td>
              <td>Light Green (/laɪt ɡriːn/)</td>
          </tr>
          <tr>
              <td>Naranja</td>
              <td>Orange</td>
              <td>Orange (/ˈɔːrɪndʒ/)</td>
          </tr>
          <tr>
              <td>Morado</td>
              <td>Purple</td>
              <td>Purple (/ˈpɜːrpəl/)</td>
          </tr>
          <tr>
              <td>Blanco</td>
              <td>White</td>
              <td>White (/waɪt/)</td>
          </tr>
          <tr>
              <td>Negro</td>
              <td>Black</td>
              <td>Black (/blæk/)</td>
          </tr>
          <tr>
              <td>Gris Oscuro</td>
              <td>Dark Gray</td>
              <td>Dark Gray (/dɑːrk ɡreɪ/)</td>
          </tr>
          <tr>
              <td>Gris Claro</td>
              <td>Light Gray</td>
              <td>Light Gray (/laɪt ɡreɪ/)</td>
          </tr>
          <tr>
              <td>Rosa</td>
              <td>Pink</td>
              <td>Pink (/pɪŋk/)</td>
          </tr>
          <tr>
              <td>Rosa Claro</td>
              <td>Light Pink</td>
              <td>Light Pink (/laɪt pɪŋk/)</td>
          </tr>
          <tr>
              <td>Marrón</td>
              <td>Brown</td>
              <td>Brown (/braʊn/)</td>
          </tr>
          <tr>
              <td>Violeta</td>
              <td>Violet</td>
              <td>Violet (/ˈvaɪəlɪt/)</td>
          </tr>
      </tbody>
  </table>

  <h2>10 Ejemplos de Uso de Colores</h2>
  <ul>
      <li>Mi coche es <strong>azul oscuro</strong>. (My car is <strong>dark blue</strong>.)</li>
      <li>La camisa es de color <strong>blanco</strong>. (The shirt is <strong>white</strong>.)</li>
      <li>El sol se ve <strong>amarillo</strong> en la mañana. (The sun looks <strong>yellow</strong> in the morning.)</li>
      <li>Compré una bicicleta <strong>verde claro</strong>. (I bought a <strong>light green</strong> bike.)</li>
      <li>Me encanta el color <strong>naranja</strong> en las flores. (I love the <strong>orange</strong> color in flowers.)</li>
      <li>Las paredes de mi cuarto son <strong>gris claro</strong>. (The walls of my room are <strong>light gray</strong>.)</li>
      <li>El vestido es <strong>rosa claro</strong>. (The dress is <strong>light pink</strong>.)</li>
      <li>El gato tiene pelaje <strong>negro</strong>. (The cat has <strong>black</strong> fur.)</li>
      <li>Su chaqueta es <strong>azul claro</strong>. (Her jacket is <strong>light blue</strong>.)</li>
      <li>Prefiero los pantalones de color <strong>marrón oscuro</strong>. (I prefer <strong>dark brown</strong> pants.)</li>
      <li>Mi mochila es de un <strong>verde oscuro</strong>. (My backpack is a <strong>dark green</strong>.)</li>
  </ul>

</div>
</div></div>