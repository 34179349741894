import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-sustantivos-contables-incontables',
  standalone: true,
  imports: [],
  templateUrl: './sustantivos-contables-incontables.component.html',
  styleUrl: './sustantivos-contables-incontables.component.scss',
})
export class SustantivosContablesIncontablesComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Contables/Incontables';
  override title = '“Countable and uncountable Nouns”';
}
