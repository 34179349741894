<div class="say-vs-tell">
  <p>
    Los verbos <em>say</em> y <em>tell</em> significan "decir", pero se usan de
    forma diferente según la estructura de la oración y el contexto. Aquí te
    explico cuándo usar cada uno.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Usar "say"</h2>

      <p>
        <em>Say</em> se utiliza para reportar lo que alguien dijo, pero no
        incluye a la persona a quien se dirige el mensaje (a menos que usemos
        <em>to</em>). Es ideal cuando simplemente queremos mencionar las
        palabras de alguien.
      </p>

      <table border="1">
        <tr>
          <th>Ejemplo</th>
          <th>Explicación</th>
        </tr>
        <tr>
          <td>She <strong>said</strong>, "I'm tired."</td>
          <td>
            Ella dijo "Estoy cansada". Aquí, <em>say</em> simplemente reporta
            las palabras.
          </td>
        </tr>
        <tr>
          <td>He <strong>said to</strong> me, "See you tomorrow."</td>
          <td>
            Él me dijo "Nos vemos mañana". Usamos <em>say to</em> cuando
            especificamos a quién se dirige.
          </td>
        </tr>
      </table>

      <h2>2. Usar "tell"</h2>

      <p>
        <em>Tell</em> se usa cuando queremos mencionar quién recibió la
        información. A diferencia de <em>say</em>, con <em>tell</em> siempre
        debemos incluir a una persona después del verbo.
      </p>

      <table border="1">
        <tr>
          <th>Ejemplo</th>
          <th>Explicación</th>
        </tr>
        <tr>
          <td>She <strong>told</strong> me, "I'm tired."</td>
          <td>
            Ella me dijo "Estoy cansada". Aquí, <em>me</em> es la persona a
            quien se dirige la información.
          </td>
        </tr>
        <tr>
          <td>He <strong>told</strong> them the news.</td>
          <td>
            Él les contó las noticias. Con <em>tell</em>, es obligatorio decir a
            quién se dirige (en este caso, <em>them</em>).
          </td>
        </tr>
      </table>

      <h2>3. Resumen de Diferencias</h2>

      <table border="1">
        <tr>
          <th>Verbo</th>
          <th>Uso</th>
          <th>Estructura</th>
          <th>Ejemplo</th>
        </tr>
        <tr>
          <td><strong>Say</strong></td>
          <td>
            Reportar palabras, sin especificar siempre a quién se dirigen.
          </td>
          <td><em>Say + frase</em> o <em>Say to + persona + frase</em></td>
          <td>
            She <strong>said</strong> "Hello." <br />
            He <strong>said to</strong> her, "Goodbye."
          </td>
        </tr>
        <tr>
          <td><strong>Tell</strong></td>
          <td>Comunicar algo a alguien directamente.</td>
          <td>
            <em>Tell + persona + frase</em> o <em>Tell + persona + objeto</em>
          </td>
          <td>
            She <strong>told</strong> me she was leaving. <br />
            He <strong>told</strong> us the truth.
          </td>
        </tr>
      </table>

      <h2>4. Notas importantes:</h2>

      <p>
        <strong>Tell</strong> también se usa con ciertos objetos específicos,
        sin necesidad de mencionar una persona:
      </p>
      <ul>
        <li>Tell the truth (decir la verdad)</li>
        <li>Tell a lie (decir una mentira)</li>
        <li>Tell a story (contar una historia)</li>
        <li>Tell the time (decir la hora)</li>
      </ul>

      <h3>Ejemplos</h3>

      <table border="1">
        <tr>
          <th>Ejemplo (Say)</th>
          <th>Ejemplo (Tell)</th>
        </tr>
        <tr>
          <td>They <strong>said</strong> "It's raining outside."</td>
          <td>She <strong>told</strong> me that it was raining outside.</td>
        </tr>
        <tr>
          <td>He <strong>said</strong>, "I will help you."</td>
          <td>He <strong>told</strong> her he would help her.</td>
        </tr>
        <tr>
          <td>Maria <strong>said</strong> she loved ice cream.</td>
          <td>Maria <strong>told</strong> her friends she loved ice cream.</td>
        </tr>
        <tr>
          <td>The teacher <strong>said</strong> we have a test tomorrow.</td>
          <td>The teacher <strong>told</strong> us we have a test tomorrow.</td>
        </tr>
        <tr>
          <td>John <strong>said</strong>, "I forgot my homework."</td>
          <td>John <strong>told</strong> me he forgot his homework.</td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div class="banner"></div>
