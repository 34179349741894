import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-wh-question',
  templateUrl: './wh-question.component.html',
  styleUrl: './wh-question.component.scss',
})
export class VWhQuestionComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Preguntas Informativas';
  override title = 'Preguntas Informativas - “WH - Question Words”';
}
