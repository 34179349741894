import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-conditional-sentences',
  standalone: true,
  imports: [],
  templateUrl: './conditional-sentences.component.html',
  styleUrl: './conditional-sentences.component.scss',
})
export class ConditionalSentencesComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Condicional sentences';
  override title = 'Condicional sentences';
}
