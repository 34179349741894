import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-verbo-to-have',
  templateUrl: './verbo-to-have.component.html',
  styleUrl: './verbo-to-have.component.scss',
})
export class VerboToHaveComponent extends LeccionBaseComponent {
  override descripcionMenu = 'To-Have';
  override title = 'Tener -  “To Have”';
}
