import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-verbos-modales',
  standalone: true,
  imports: [],
  templateUrl: './verbos-modales.component.html',
  styleUrl: './verbos-modales.component.scss',
})
export class VerbosModalesComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Verbos modales en Inglés';
  override title = 'Verbos Modales “Modal verbs”';
}
