<div class="linking-words">
  <p>
    Las Linking Words, o palabras de enlace, son palabras o frases que se usan
    para conectar ideas, frases y oraciones en inglés. Ayudan a que tu discurso
    sea más coherente y fluido. Vamos a ver algunos tipos de palabras de enlace
    comunes y cómo usarlas.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Linking Words para Adición</h2>
      <p>Estas palabras se utilizan para añadir información.</p>
      <ul>
        <li><b>And</b> - Y</li>
        <li><b>Also</b> - También</li>
        <li><b>Too</b> - También (al final de la oración)</li>
        <li><b>In addition</b> - Además</li>
        <li><b>Moreover</b> - Además</li>
        <li><b>Furthermore</b> - Además (en un contexto más formal)</li>
        <li><b>Besides</b> - Además (cuando se añade algo no relacionado)</li>
        <li><b>As well as</b> - Así como</li>
      </ul>

      <h2>Linking Words para Contraste</h2>
      <p>
        Estas palabras se usan para mostrar una idea opuesta o un contraste.
      </p>
      <ul>
        <li><b>But</b> - Pero</li>
        <li><b>However</b> - Sin embargo</li>
        <li><b>Although</b> - Aunque</li>
        <li><b>On the other hand</b> - Por otro lado</li>
        <li><b>Nevertheless</b> - No obstante</li>
        <li><b>Yet</b> - Sin embargo</li>
      </ul>

      <h2>Linking Words para Causa y Efecto</h2>
      <p>Estas palabras explican la razón o el resultado de algo.</p>
      <ul>
        <li><b>Because</b> - Porque</li>
        <li><b>So</b> - Entonces, Así que</li>
        <li><b>Therefore</b> - Por lo tanto</li>
        <li><b>Since</b> - Ya que</li>
        <li><b>As a result</b> - Como resultado</li>
      </ul>

      <h2>Linking Words para Ejemplos</h2>
      <p>Estas palabras se usan para dar ejemplos específicos.</p>
      <ul>
        <li><b>For example</b> - Por ejemplo</li>
        <li><b>For instance</b> - Por ejemplo</li>
        <li><b>Such as</b> - Como</li>
        <li><b>Like</b> - Como</li>
      </ul>

      <h3>Ejemplos de Linking Words</h3>

      <table border="1" cellpadding="8">
        <tr>
          <th>Tipo</th>
          <th>Linking Word</th>
          <th>Ejemplo</th>
          <th>Traducción al Español</th>
        </tr>

        <!-- Linking Words para Adición -->
        <tr>
          <td>Adición</td>
          <td><strong>And</strong></td>
          <td>I like coffee <strong>and</strong> tea.</td>
          <td>Me gusta el café <strong>y</strong> el té.</td>
        </tr>
        <tr>
          <td>Adición</td>
          <td><strong>Also</strong></td>
          <td>She is smart, <strong>also</strong> kind.</td>
          <td>Ella es inteligente, <strong>además</strong> amable.</td>
        </tr>
        <tr>
          <td>Adición</td>
          <td><strong>Moreover</strong></td>
          <td>He is rich; <strong>moreover</strong>, he is famous.</td>
          <td>Él es rico; <strong>además</strong>, es famoso.</td>
        </tr>

        <!-- Linking Words para Contraste -->
        <tr>
          <td>Contraste</td>
          <td><strong>But</strong></td>
          <td>It's sunny, <strong>but</strong> cold.</td>
          <td>Está soleado, <strong>pero</strong> hace frío.</td>
        </tr>
        <tr>
          <td>Contraste</td>
          <td><strong>However</strong></td>
          <td>
            She failed the test. <strong>However</strong>, she tried her best.
          </td>
          <td>
            Ella falló el examen. <strong>Sin embargo</strong>, hizo su mejor
            esfuerzo.
          </td>
        </tr>
        <tr>
          <td>Contraste</td>
          <td><strong>Although</strong></td>
          <td><strong>Although</strong> he was tired, he kept working.</td>
          <td><strong>Aunque</strong> estaba cansado, siguió trabajando.</td>
        </tr>

        <!-- Linking Words para Causa y Efecto -->
        <tr>
          <td>Causa y Efecto</td>
          <td><strong>Because</strong></td>
          <td>She left <strong>because</strong> she was tired.</td>
          <td>Ella se fue <strong>porque</strong> estaba cansada.</td>
        </tr>
        <tr>
          <td>Causa y Efecto</td>
          <td><strong>Therefore</strong></td>
          <td>He didn’t study; <strong>therefore</strong>, he failed.</td>
          <td>No estudió; <strong>por lo tanto</strong>, falló.</td>
        </tr>
        <tr>
          <td>Causa y Efecto</td>
          <td><strong>Since</strong></td>
          <td><strong>Since</strong> it’s raining, we’ll stay inside.</td>
          <td>
            <strong>Ya que</strong> está lloviendo, nos quedaremos adentro.
          </td>
        </tr>

        <!-- Linking Words para Ejemplos -->
        <tr>
          <td>Ejemplos</td>
          <td><strong>For example</strong></td>
          <td>
            Many animals, <strong>for example</strong>, lions and tigers, are
            carnivores.
          </td>
          <td>
            Muchos animales, <strong>por ejemplo</strong>, leones y tigres, son
            carnívoros.
          </td>
        </tr>
        <tr>
          <td>Ejemplos</td>
          <td><strong>Such as</strong></td>
          <td>He enjoys outdoor activities <strong>such as</strong> hiking.</td>
          <td>
            Le gustan actividades al aire libre <strong>como</strong> el
            senderismo.
          </td>
        </tr>

        <!-- Linking Words para Tiempo -->
        <tr>
          <td>Tiempo</td>
          <td><strong>Before</strong></td>
          <td>I will call you <strong>before</strong> I leave.</td>
          <td>Te llamaré <strong>antes</strong> de irme.</td>
        </tr>
        <tr>
          <td>Tiempo</td>
          <td><strong>After</strong></td>
          <td>Let's eat <strong>after</strong> the movie.</td>
          <td>Comamos <strong>después</strong> de la película.</td>
        </tr>
        <tr>
          <td>Tiempo</td>
          <td><strong>When</strong></td>
          <td>Call me <strong>when</strong> you arrive.</td>
          <td>Llámame <strong>cuando</strong> llegues.</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Linking Words para Secuenciación de Ideas</h2>

      <p>
        Cuando queremos presentar una secuencia de ideas, podemos utilizar las
        siguientes palabras para estructurar nuestros pensamientos:
      </p>
      <ul>
        <li>
          <strong>Firstly, Secondly… Lastly:</strong> Estas palabras se utilizan
          al principio de una frase y siempre van seguidas de una coma. Se
          traducen como "en primer lugar", "en segundo lugar" y "por último".
        </li>
        <li>
          <strong>The following:</strong> Esta expresión suele aparecer al
          principio de una oración, aunque también puede estar dentro de la
          oración. Se traduce como "el/los siguiente/s".
        </li>
        <li>
          <strong
            >In conclusion, To conclude, In summary, In short/brief:</strong
          >
          Estas palabras se emplean al comienzo de un párrafo para resumir ideas
          y van seguidas de una coma. Se traducen como "en conclusión", "para
          concluir", "en resumen".
        </li>
      </ul>

      <h3>Ejemplos de Linking Words para Secuenciación de Ideas</h3>

      <table border="1" cellpadding="8">
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Linking Word</th>
            <th>Ejemplo</th>
            <th>Traducción al Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Secuencia</td>
            <td>Firstly, Secondly, Lastly</td>
            <td>
              <strong>Firstly</strong>, we need to prepare the ingredients.
              <strong>Secondly</strong>, we should chop the vegetables.
              <strong>Lastly</strong>, cook everything in a large pan.
            </td>
            <td>
              En primer lugar, necesitamos preparar los ingredientes. En segundo
              lugar, debemos picar las verduras. Por último, cocinamos todo en
              una sartén grande.
            </td>
          </tr>
          <tr>
            <td>Secuencia</td>
            <td>The following</td>
            <td>
              <strong>The following</strong> steps are essential for completing
              the project: research, planning, and execution.
            </td>
            <td>
              Los siguientes pasos son esenciales para completar el proyecto:
              investigación, planificación y ejecución.
            </td>
          </tr>
          <tr>
            <td>Secuencia</td>
            <td>The following</td>
            <td>
              After the meeting, <strong>the following</strong> actions should
              be taken: send the report, schedule a follow-up, and finalize the
              budget.
            </td>
            <td>
              Después de la reunión, se deben tomar las siguientes acciones:
              enviar el informe, programar un seguimiento y finalizar el
              presupuesto.
            </td>
          </tr>
          <tr>
            <td>Resumen</td>
            <td>In conclusion</td>
            <td>
              <strong>In conclusion</strong>, the new policy will benefit both
              employees and customers.
            </td>
            <td>
              En conclusión, la nueva política beneficiará tanto a los empleados
              como a los clientes.
            </td>
          </tr>
          <tr>
            <td>Resumen</td>
            <td>To conclude</td>
            <td>
              <strong>To conclude</strong>, we need to focus on reducing our
              carbon footprint for a sustainable future.
            </td>
            <td>
              Para concluir, debemos centrarnos en reducir nuestra huella de
              carbono para un futuro sostenible.
            </td>
          </tr>
          <tr>
            <td>Resumen</td>
            <td>In summary</td>
            <td>
              <strong>In summary</strong>, adopting renewable energy sources is
              the best way to combat climate change.
            </td>
            <td>
              En resumen, adoptar fuentes de energía renovables es la mejor
              manera de combatir el cambio climático.
            </td>
          </tr>
          <tr>
            <td>Resumen</td>
            <td>In short</td>
            <td>
              <strong>In short</strong>, we must prioritize environmental
              conservation to ensure a better world for future generations.
            </td>
            <td>
              En resumen, debemos priorizar la conservación del medio ambiente
              para asegurar un mejor mundo para las futuras generaciones.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
