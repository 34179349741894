import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-verbo-to-be',
  templateUrl: './verbo-to-be.component.html',
  styleUrl: './verbo-to-be.component.scss',
})
export class VerboToBeComponent extends LeccionBaseComponent {
  override descripcionMenu = 'To Be';
  override title = 'Ser/Estar -  “To Be”';
}
