<div class="ever">
  <p>
    En esta clase, aprenderás a usar expresiones como <strong>whatever</strong>,
    <strong>wherever</strong>, <strong>whenever</strong>, entre otras, en
    inglés. Estas expresiones se utilizan para expresar una variedad de
    situaciones que no están limitadas por circunstancias específicas. A
    continuación, exploraremos el uso de estas palabras en diferentes contextos.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Uso de "Whatever" (Lo que sea)</h2>

      <p>
        <strong>Whatever</strong> es una palabra que se usa para referirse a
        cualquier cosa, sin importar cuál sea. Se utiliza para indicar que no
        importa cuál sea la elección o la decisión, ya que todos los resultados
        son igualmente aceptables.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Whatever</strong> se usa para expresar indiferencia o para
          indicar que no hay restricciones sobre lo que se menciona.
        </li>
        <li>Puede ser un pronombre o un adjetivo.</li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          I'll eat <strong>whatever</strong> you make for dinner. (Comeré lo que
          sea que prepares para la cena.)
        </li>
        <li>
          <strong>Whatever</strong> happens, I’ll be here to help. (Lo que sea
          que pase, estaré aquí para ayudar.)
        </li>
        <li>
          Take <strong>whatever</strong> you need. (Toma lo que sea que
          necesites.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>whatever</strong> se refiere a cualquier cosa
        o situación sin importar cuál.
      </p>
    </div>
    <div class="section-blanco">
      <h2>2. Uso de "Wherever" (Donde sea)</h2>

      <p>
        <strong>Wherever</strong> se usa para hablar de cualquier lugar, sin
        importar cuál. Indica que no hay restricción geográfica o de ubicación
        en el contexto de la oración.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Wherever</strong> se usa para expresar que algo puede ocurrir
          en cualquier lugar o en todos los lugares posibles.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          We can go <strong>wherever</strong> you want. (Podemos ir donde sea
          que quieras.)
        </li>
        <li>
          She’ll follow you <strong>wherever</strong> you go. (Ella te seguirá
          donde sea que vayas.)
        </li>
        <li>
          We will be happy <strong>wherever</strong> we live. (Seremos felices
          donde sea que vivamos.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>wherever</strong> se refiere a cualquier
        lugar, sin importar cuál se elija.
      </p>
    </div>
    <div class="section-blanco">
      <h2>3. Uso de "Whenever" (Cuando sea)</h2>

      <p>
        <strong>Whenever</strong> se utiliza para hablar de cualquier momento,
        sin importar cuándo ocurra. Es útil cuando no importa el tiempo en el
        que algo suceda.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Whenever</strong> se usa para indicar que no importa cuándo
          ocurra algo.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          You can call me <strong>whenever</strong> you want. (Puedes llamarme
          cuando sea que quieras.)
        </li>
        <li>
          <strong>Whenever</strong> I see her, she is smiling. (Cuando sea que
          la vea, ella está sonriendo.)
        </li>
        <li>
          I’ll be ready <strong>whenever</strong> you arrive. (Estaré listo
          cuando sea que llegues.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>whenever</strong> se refiere a cualquier
        momento o instante, sin importar cuándo.
      </p>
    </div>
    <div class="section-blanco">
      <h2>4. Uso de "Whoever" (Quien sea)</h2>

      <p>
        <strong>Whoever</strong> se utiliza para hablar de cualquier persona,
        sin importar quién sea. Se usa para referirse a una persona que no está
        especificada o que podría ser cualquier persona en un grupo.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Whoever</strong> se usa para referirse a cualquier persona,
          sin especificar quién.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          <strong>Whoever</strong> is in charge will make the final decision.
          (Quien sea que esté a cargo tomará la decisión final.)
        </li>
        <li>
          <strong>Whoever</strong> wants to join, feel free to do so. (Quien sea
          que quiera unirse, siéntase libre de hacerlo.)
        </li>
        <li>
          <strong>Whoever</strong> finds my keys will get a reward. (Quien sea
          que encuentre mis llaves recibirá una recompensa.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>whoever</strong> se refiere a cualquier
        persona que sea relevante para la situación.
      </p>
    </div>
    <div class="section-blanco">
      <h2>5. Uso de "Whatever" en frases negativas</h2>

      <p>
        <strong>Whatever</strong> también puede usarse en frases negativas para
        enfatizar que no importa lo que se haga, ya que no tendrá un impacto
        positivo o esperado.
      </p>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          <strong>Whatever</strong> you say won’t change my mind. (Lo que sea
          que digas no cambiará mi opinión.)
        </li>
        <li>
          I don’t care <strong>whatever</strong> happens. (No me importa lo que
          sea que pase.)
        </li>
      </ul>

      <p>
        En estos casos, <strong>whatever</strong> refuerza la idea de que,
        aunque ocurra algo, no afectará el resultado o la decisión tomada.
      </p>
    </div>
    <div class="section-blanco">
      <h2>6. Uso de "However" (Como sea, De cualquier manera)</h2>

      <p>
        <strong>However</strong> se usa para expresar una idea que introduce una
        contradicción, un contraste o una forma alternativa de hacer algo. Puede
        usarse para sugerir que no importa cómo se haga algo, siempre se
        alcanzará el mismo resultado.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>However</strong> se usa para señalar que, independientemente
          de cómo ocurra algo, el resultado será el mismo o se verá de la misma
          manera.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          We will finish the project <strong>however</strong> we can.
          (Terminaremos el proyecto como sea que podamos.)
        </li>
        <li>
          <strong>However</strong> you decide to do it, we will support you.
          (Como sea que decidas hacerlo, te apoyaremos.)
        </li>
        <li>
          He will arrive <strong>however</strong> late it is. (Él llegará,
          aunque sea tarde.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>however</strong> se utiliza para mostrar que,
        sin importar el método o la forma, la acción se completará o se
        ejecutará.
      </p>
    </div>
  </div>
</div>
