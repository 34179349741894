import { Component } from '@angular/core';

@Component({
  selector: 'app-principiante',
  standalone: true,
  imports: [],
  templateUrl: './principiante.component.html',
  styleUrl: './principiante.component.scss',
})
export class PrincipianteComponent {}
