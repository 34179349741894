<div class="usedTo">
  <p>
    "<strong>Used to</strong>" es una expresión que se utiliza en inglés para
    hablar sobre hábitos, rutinas o situaciones que eran ciertas en el pasado,
    pero que ya no son así. Se refiere a acciones que solían ocurrir
    regularmente o a estados que solían ser verdaderos, pero que han cambiado
    con el tiempo.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Uso de "Used to"</h2>
      <p>Se usa principalmente en las siguientes situaciones:</p>
      <ul>
        <li>
          <strong>Hábitos pasados:</strong> Se utiliza para describir
          actividades que se hacían regularmente en el pasado. Por ejemplo:
          <strong>I used to play basketball every Saturday.</strong> (Solía
          jugar baloncesto cada sábado).
        </li>
        <li>
          <strong>Situaciones pasadas:</strong> Se refiere a situaciones o
          condiciones que eran ciertas en el pasado pero que ya no lo son. Por
          ejemplo: <strong>He used to live in London.</strong> (Él solía vivir
          en Londres).
        </li>
        <li>
          <strong>Comparaciones:</strong> Se puede usar para comparar lo que era
          habitual en el pasado con la situación actual. Por ejemplo:
          <strong>People used to smoke more in the past.</strong> (La gente
          solía fumar más en el pasado).
        </li>
      </ul>

      <h2>Estructura de "Used to"</h2>
      <p>La estructura de "used to" es bastante sencilla:</p>
      <ul>
        <li>
          Para afirmaciones:
          <strong>Subject + used to + base form of the verb</strong>.
        </li>
        <li>
          Para negaciones:
          <strong>Subject + did not use to + base form of the verb</strong>.
        </li>
        <li>
          Para preguntas:
          <strong>Did + subject + use to + base form of the verb?</strong>.
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h2>Ejemplos de Uso</h2>

      <h3>Afirmaciones</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I used to play soccer.</td>
            <td>(Solía jugar al fútbol.)</td>
          </tr>
          <tr>
            <td>She used to live in Paris.</td>
            <td>(Ella solía vivir en París.)</td>
          </tr>
          <tr>
            <td>They used to go to the beach every summer.</td>
            <td>(Ellos solían ir a la playa cada verano.)</td>
          </tr>
          <tr>
            <td>We used to have picnics in the park.</td>
            <td>(Solíamos hacer picnics en el parque.)</td>
          </tr>
          <tr>
            <td>He used to ride his bike to school.</td>
            <td>(Él solía ir en bicicleta a la escuela.)</td>
          </tr>
          <tr>
            <td>My grandparents used to tell stories.</td>
            <td>(Mis abuelos solían contar historias.)</td>
          </tr>
          <tr>
            <td>I used to watch cartoons on Saturday mornings.</td>
            <td>(Solía ver dibujos animados los sábados por la mañana.)</td>
          </tr>
          <tr>
            <td>They used to visit their relatives every holiday.</td>
            <td>(Ellos solían visitar a sus parientes cada vacaciones.)</td>
          </tr>
        </tbody>
      </table>

      <h3>Negaciones</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I did not use to like vegetables.</td>
            <td>(No solía gustarme las verduras.)</td>
          </tr>
          <tr>
            <td>He did not use to study English.</td>
            <td>(Él no solía estudiar inglés.)</td>
          </tr>
          <tr>
            <td>We did not use to travel much.</td>
            <td>(No solíamos viajar mucho.)</td>
          </tr>
          <tr>
            <td>She did not use to play video games.</td>
            <td>(Ella no solía jugar videojuegos.)</td>
          </tr>
          <tr>
            <td>They did not use to eat spicy food.</td>
            <td>(Ellos no solían comer comida picante.)</td>
          </tr>
          <tr>
            <td>I did not use to enjoy horror movies.</td>
            <td>(No solía disfrutar las películas de terror.)</td>
          </tr>
          <tr>
            <td>We did not use to go out on weekdays.</td>
            <td>(No solíamos salir entre semana.)</td>
          </tr>
          <tr>
            <td>He did not use to wake up early.</td>
            <td>(Él no solía levantarse temprano.)</td>
          </tr>
        </tbody>
      </table>

      <h3>Preguntas</h3>
      <table>
        <thead>
          <tr>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Did you use to play an instrument?</td>
            <td>(¿Solías tocar un instrumento?)</td>
          </tr>
          <tr>
            <td>Did she use to work here?</td>
            <td>(¿Ella solía trabajar aquí?)</td>
          </tr>
          <tr>
            <td>Did they use to have a pet?</td>
            <td>(¿Ellos solían tener una mascota?)</td>
          </tr>
          <tr>
            <td>Did you use to swim in the lake?</td>
            <td>(¿Solías nadar en el lago?)</td>
          </tr>
          <tr>
            <td>Did he use to play for that team?</td>
            <td>(¿Él solía jugar en ese equipo?)</td>
          </tr>
          <tr>
            <td>Did your parents use to travel a lot?</td>
            <td>(¿Tus padres solían viajar mucho?)</td>
          </tr>
          <tr>
            <td>Did we use to go camping together?</td>
            <td>(¿Solíamos ir de campamento juntos?)</td>
          </tr>
          <tr>
            <td>Did she use to enjoy painting?</td>
            <td>(¿Ella solía disfrutar pintar?)</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
