import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-present-perfect',
  standalone: true,
  imports: [],
  templateUrl: './present-perfect.component.html',
  styleUrl: './present-perfect.component.scss',
})
export class PresentPerfectComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Present perfect';
  override title = 'Present Perfect';
}
