import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-indicador-hay',
  standalone: true,
  imports: [],
  templateUrl: './indicador-hay.component.html',
  styleUrl: './indicador-hay.component.scss',
})
export class IndicadorHayComponent extends LeccionBaseComponent {
  override descripcionMenu = 'There Is - There Are';
  override title = 'Hay - "There is" y "There are"';
}
