import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-past-continuous',
  standalone: true,
  imports: [],
  templateUrl: './past-continuous.component.html',
  styleUrl: './past-continuous.component.scss',
})
export class PastContinuousComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Pasado continuo';
  override title = 'Pasado continuo - "Past continuous"';
}
