import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-articulos',
  templateUrl: './articulos.component.html',
  styleUrl: './articulos.component.scss',
})
export class ArticulosComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Artículos: The, A, An';
  override title = 'Artículos en Inglés: The, A, An';
}
