import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-adverbios-de-frecuencia',
  templateUrl: './adverbios-de-frecuencia.component.html',
  styleUrl: './adverbios-de-frecuencia.component.scss',
})
export class AdverbiosDeFrecuenciaComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Adverbios de Frecuencia';
  override title = 'Adverbios de frecuencia';
}
