<div class="tohave-container">
  <p>
    El verbo "have" significa "tener". Puede usarse para expresar posesión,
    obligación y para formar tiempos compuestos. A continuación, se detalla su
    uso con ejemplos en diferentes tiempos y contextos.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Usar "Have" para expresar posesión</h2>
      <p>
        El verbo "have" se utiliza para hablar de lo que alguien posee o tiene.
        Para las terceras personas del singular (he, she, it), "have" cambia a
        <strong>"has"</strong>.
      </p>

      <table>
        <thead>
          <tr>
            <th>Persona</th>
            <th>Ejemplo Afirmativo</th>
            <th>Ejemplo Negativo</th>
            <th>Ejemplo Interrogativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I</td>
            <td>I <strong>have</strong> a car. (Tengo un auto)</td>
            <td>I <strong>don’t have</strong> a car. (No tengo un auto)</td>
            <td>Do I <strong>have</strong> a car? (¿Tengo un auto?)</td>
          </tr>
          <tr>
            <td>You</td>
            <td>You <strong>have</strong> a bike. (Tienes una bicicleta)</td>
            <td>
              You <strong>don’t have</strong> a bike. (No tienes una bicicleta)
            </td>
            <td>
              Do you <strong>have</strong> a bike? (¿Tienes una bicicleta?)
            </td>
          </tr>
          <tr>
            <td>He/She/It</td>
            <td>She <strong>has</strong> a dog. (Ella tiene un perro)</td>
            <td>
              She <strong>doesn’t have</strong> a dog. (Ella no tiene un perro)
            </td>
            <td>
              Does she <strong>have</strong> a dog? (¿Ella tiene un perro?)
            </td>
          </tr>
          <tr>
            <td>We</td>
            <td>We <strong>have</strong> a house. (Tenemos una casa)</td>
            <td>
              We <strong>don’t have</strong> a house. (No tenemos una casa)
            </td>
            <td>Do we <strong>have</strong> a house? (¿Tenemos una casa?)</td>
          </tr>
          <tr>
            <td>They</td>
            <td>
              They <strong>have</strong> a new job. (Ellos tienen un nuevo
              trabajo)
            </td>
            <td>
              They <strong>don’t have</strong> a new job. (Ellos no tienen un
              nuevo trabajo)
            </td>
            <td>
              Do they <strong>have</strong> a new job? (¿Ellos tienen un nuevo
              trabajo?)
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Negación en Tercera Persona del Singular en Presente Simple</h2>
      <p>
        En inglés, la negación en tercera persona del singular se forma de la
        siguiente manera:
      </p>
      <ol>
        <li>
          <strong>Uso del auxiliar "does":</strong> Se utiliza "does" para
          indicar que estamos hablando de la tercera persona del singular (he,
          she, it).
        </li>
        <li>
          <strong>Forma base del verbo:</strong> El verbo principal se mantiene
          en su forma base. Por ejemplo, en lugar de "has", se usa "have".
        </li>
        <li>
          <strong>Estructura de la negación:</strong> La estructura es:
          <em>Sujeto + "does not" (o "doesn't") + verbo en forma base</em>.
          Ejemplo: <strong>"She does not (doesn't) like ice cream."</strong> (A
          ella no le gusta el helado).
        </li>
      </ol>
      <p>
        Por estos motivos tenemos en la tabla al negar una horacion en tercera
        persona del singular tenemos algo como esto: She doesn’t have a dog.
        (Ella no tiene un perro),como vemos, se usa doesn't have y no doesn't
        has ya que se debe usar have en su forma base.
      </p>
    </div>
    <div class="section-blanco">
      <h2>2. Usar "Have" para expresar obligación</h2>
      <p>
        Para expresar obligación, se usa la estructura **"have to"**. Es
        equivalente a "must", pero "have to" se puede conjugar en varios
        tiempos.
      </p>

      <table>
        <thead>
          <tr>
            <th>Persona</th>
            <th>Ejemplo Afirmativo</th>
            <th>Ejemplo Negativo</th>
            <th>Ejemplo Interrogativo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I</td>
            <td>I <strong>have to</strong> work. (Tengo que trabajar)</td>
            <td>
              I <strong>don’t have to</strong> work. (No tengo que trabajar)
            </td>
            <td>Do I <strong>have to</strong> work? (¿Tengo que trabajar?)</td>
          </tr>
          <tr>
            <td>He/She</td>
            <td>He <strong>has to</strong> study. (Él tiene que estudiar)</td>
            <td>
              He <strong>doesn’t have to</strong> study. (Él no tiene que
              estudiar)
            </td>
            <td>
              Does he <strong>have to</strong> study? (¿Él tiene que estudiar?)
            </td>
          </tr>
          <tr>
            <td>We</td>
            <td>
              We <strong>have to</strong> go now. (Tenemos que irnos ahora)
            </td>
            <td>
              We <strong>don’t have to</strong> go now. (No tenemos que irnos
              ahora)
            </td>
            <td>
              Do we <strong>have to</strong> go now? (¿Tenemos que irnos ahora?)
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Preguntas con "to have" y traducción</h2>
      <table>
        <thead>
          <tr>
            <th>Persona</th>
            <th>Inglés</th>
            <th>Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I</td>
            <td>Do I have enough money?</td>
            <td>¿Tengo suficiente dinero?</td>
          </tr>
          <tr>
            <td>You</td>
            <td>Do you have any siblings?</td>
            <td>¿Tienes hermanos?</td>
          </tr>
          <tr>
            <td>He</td>
            <td>Does he have a car?</td>
            <td>¿Él tiene un auto?</td>
          </tr>
          <tr>
            <td>She</td>
            <td>Does she have time to study?</td>
            <td>¿Ella tiene tiempo para estudiar?</td>
          </tr>
          <tr>
            <td>We</td>
            <td>Do we have tickets for the movie?</td>
            <td>¿Tenemos boletos para la película?</td>
          </tr>
          <tr>
            <td>They</td>
            <td>Do they have a house?</td>
            <td>¿Ellos tienen una casa?</td>
          </tr>
        </tbody>
      </table>

      <h2>Respuestas con "to have" y "has", traducción</h2>
      <table>
        <thead>
          <tr>
            <th>Persona</th>
            <th>Inglés</th>
            <th>Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I</td>
            <td>No, I don’t have any siblings.</td>
            <td>No, no tengo hermanos.</td>
          </tr>
          <tr>
            <td>You</td>
            <td>Yes, you have enough money.</td>
            <td>Sí, tienes suficiente dinero.</td>
          </tr>
          <tr>
            <td>He</td>
            <td>Yes, he has a car.</td>
            <td>Sí, él tiene un auto.</td>
          </tr>
          <tr>
            <td>She</td>
            <td>No, she doesn’t have time to study.</td>
            <td>No, ella no tiene tiempo para estudiar.</td>
          </tr>
          <tr>
            <td>We</td>
            <td>Yes, we have tickets for the movie.</td>
            <td>Sí, tenemos boletos para la película.</td>
          </tr>
          <tr>
            <td>They</td>
            <td>No, they don’t have a house.</td>
            <td>No, ellos no tienen una casa.</td>
          </tr>
        </tbody>
      </table>

      <h2>Afirmaciones con "to have" y "has", traducción</h2>
      <table>
        <thead>
          <tr>
            <th>Persona</th>
            <th>Inglés</th>
            <th>Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I</td>
            <td>I have a new job.</td>
            <td>Yo tengo un nuevo trabajo.</td>
          </tr>
          <tr>
            <td>You</td>
            <td>You have a beautiful home.</td>
            <td>Tienes una hermosa casa.</td>
          </tr>
          <tr>
            <td>He</td>
            <td>He has a good idea.</td>
            <td>Él tiene una buena idea.</td>
          </tr>
          <tr>
            <td>She</td>
            <td>She has a lot of friends.</td>
            <td>Ella tiene muchos amigos.</td>
          </tr>
          <tr>
            <td>We</td>
            <td>We have a meeting tomorrow.</td>
            <td>Nosotros tenemos una reunión mañana.</td>
          </tr>
          <tr>
            <td>They</td>
            <td>They have a dog.</td>
            <td>Ellos tienen un perro.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
