<div class="adverbios-de-frecuencia">
  <h2>¿Qué son los adverbios de frecuencia?</h2>
  <p>
    Los adverbios de frecuencia son palabras que indican con qué regularidad se
    lleva a cabo una acción. Nos ayudan a describir hábitos, rutinas y acciones
    que ocurren en diferentes intervalos de tiempo. Son esenciales para dar
    contexto a nuestras oraciones, permitiendo que los hablantes comprendan la
    frecuencia de las acciones.
  </p>

  <h2>Frecuencia de Uso</h2>
  <p>
    Los adverbios de frecuencia se clasifican generalmente en seis categorías,
    según la frecuencia que indican:
  </p>

  <table>
    <thead>
      <tr>
        <th>Adverbio</th>
        <th>Frecuencia</th>
        <th>Significado</th>
        <th>Ejemplo</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><strong>Always</strong></td>
        <td>100%</td>
        <td>Siempre</td>
        <td>
          I always drink coffee in the morning. (Siempre bebo café por la
          mañana.)
        </td>
      </tr>
      <tr>
        <td><strong>Usually</strong></td>
        <td>95%</td>
        <td>Usualmente</td>
        <td>
          She usually goes to the gym after work. (Ella suele ir al gimnasio
          después del trabajo.)
        </td>
      </tr>
      <tr>
        <td><strong>Often</strong></td>
        <td>60%</td>
        <td>A menudo</td>
        <td>
          We often eat out on weekends. (A menudo comemos fuera los fines de
          semana.)
        </td>
      </tr>
      <tr>
        <td><strong>Sometimes</strong></td>
        <td>40%</td>
        <td>A veces</td>
        <td>
          They sometimes watch movies at home. (A veces ven películas en casa.)
        </td>
      </tr>
      <tr>
        <td><strong>Rarely</strong></td>
        <td>20%</td>
        <td>Rara vez</td>
        <td>I rarely go to parties. (Rara vez voy a fiestas.)</td>
      </tr>
      <tr>
        <td><strong>Never</strong></td>
        <td>0%</td>
        <td>Nunca</td>
        <td>He never eats breakfast. (Él nunca desayuna.)</td>
      </tr>
    </tbody>
  </table>

  <h2>Colocación en Oraciones</h2>
  <p>
    Generalmente, los adverbios de frecuencia se colocan antes del verbo
    principal. Sin embargo, si el verbo es "to be", el adverbio se coloca
    después:
  </p>
  <ul>
    <li>She <strong>always</strong> wakes up early.</li>
    <li>He is <strong>never</strong> late.</li>
  </ul>

  <h2>Importancia de los Adverbios de Frecuencia</h2>
  <p>
    Los adverbios de frecuencia son importantes en la comunicación porque nos
    permiten ser más precisos sobre nuestras actividades diarias y hábitos.
    Ayudan a construir una imagen clara de nuestras rutinas y a establecer
    expectativas en conversaciones.
  </p>
</div>
