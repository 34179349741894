<div class="navigationItem">
  <app-navigation-lateral>
    <div class="navigationItem-containerContent">
      <app-navigation-item
        *ngIf="leccionesService.showItem$ | async"
        [title]="leccionesService.configuracionPaso$.value?.title"
        [urlPrevious]="leccionesService.configuracionPaso$.value?.urlPrevious"
        [urlNext]="leccionesService.configuracionPaso$.value?.urlNext"
        [buttonPrevious]="
          leccionesService.configuracionPaso$.value?.nameButtonPrevious
        "
        [buttonNext]="leccionesService.configuracionPaso$.value?.nameButtonNext"
      >
        <router-outlet> </router-outlet>
      </app-navigation-item>
      <app-nivel-base *ngIf="!(leccionesService.showItem$ | async)">
        <router-outlet> </router-outlet>
      </app-nivel-base>
    </div>
    <app-footer></app-footer>
  </app-navigation-lateral>
</div>
