<div class="past-simple">
  <section>
    <h2>¿Qué es el Pasado Simple?</h2>
    <p>
      El pasado simple en inglés es un tiempo verbal que se utiliza para hablar
      sobre <strong>acciones completadas en el pasado</strong>, ya sea en un
      momento específico o en una secuencia de eventos. Este tiempo se usa
      cuando queremos mencionar eventos que ya ocurrieron y no tienen relación
      directa con el presente.
    </p>

    <p>Por ejemplo, usamos el pasado simple para:</p>
    <ul>
      <li>
        Hablar de algo que ocurrió en un momento específico del pasado. Ejemplo:
        "I <strong>visited</strong> my grandmother last weekend." (Visité a mi
        abuela el fin de semana pasado).
      </li>
      <li>
        Describir una secuencia de eventos. Ejemplo: "She
        <strong>got up</strong>, <strong>had breakfast</strong>, and
        <strong>left</strong> for work." (Se levantó, desayunó y se fue al
        trabajo).
      </li>
      <li>
        Mencionar hechos o situaciones del pasado que ya no son verdaderos.
        Ejemplo: "He <strong>lived</strong> in Spain when he was young." (Él
        vivió en España cuando era joven).
      </li>
    </ul>
  </section>
  <div class="section">
    <div class="section-blanco">
      <section>
        <h2>Formación del Pasado Simple</h2>
        <h3>Afirmativa</h3>
        <p>
          En las oraciones afirmativas, el pasado simple de los
          <strong>verbos regulares</strong> se forma añadiendo "<em>-ed</em>" al
          final del verbo.
        </p>
        <p>
          <strong>Estructura:</strong> Sujeto + Verbo en pasado + Complemento
        </p>

        <table border="1" cellpadding="5">
          <tr>
            <th>Ejemplo en Inglés</th>
            <th>Traducción</th>
          </tr>
          <tr>
            <td>I <strong>worked</strong> yesterday.</td>
            <td>Yo trabajé ayer.</td>
          </tr>
          <tr>
            <td>She <strong>played</strong> soccer last weekend.</td>
            <td>Ella jugó fútbol el fin de semana pasado.</td>
          </tr>
        </table>

        <h3>Negativa</h3>
        <p>
          Para hacer oraciones negativas, utilizamos "did not" o "didn't"
          seguido del verbo en su forma base.
        </p>
        <p>
          <strong>Estructura:</strong> Sujeto + did not (didn’t) + Verbo en
          infinitivo + Complemento
        </p>

        <table border="1" cellpadding="5">
          <tr>
            <th>Ejemplo en Inglés</th>
            <th>Traducción</th>
          </tr>
          <tr>
            <td>I <strong>didn't work</strong> yesterday.</td>
            <td>No trabajé ayer.</td>
          </tr>
          <tr>
            <td>She <strong>didn't play</strong> soccer last weekend.</td>
            <td>Ella no jugó fútbol el fin de semana pasado.</td>
          </tr>
        </table>

        <h3>Interrogativa</h3>
        <p>
          Para hacer preguntas, colocamos "Did" al inicio de la oración, seguido
          del sujeto y el verbo en su forma base.
        </p>
        <p>
          <strong>Estructura:</strong> Did + Sujeto + Verbo en infinitivo +
          Complemento + ?
        </p>

        <table border="1" cellpadding="5">
          <tr>
            <th>Ejemplo en Inglés</th>
            <th>Traducción</th>
          </tr>
          <tr>
            <td><strong>Did</strong> you <strong>work</strong> yesterday?</td>
            <td>¿Trabajaste ayer?</td>
          </tr>
          <tr>
            <td>
              <strong>Did</strong> she <strong>play</strong> soccer last
              weekend?
            </td>
            <td>¿Jugó ella fútbol el fin de semana pasado?</td>
          </tr>
        </table>
      </section>

      <section>
        <h2>Verbos Regulares e Irregulares en Pasado Simple</h2>

        <h3>Verbos Regulares</h3>
        <p>
          Para formar el pasado simple de los verbos regulares, añadimos
          "<em>-ed</em>" al final del verbo en su forma base.
        </p>

        <table border="1" cellpadding="5">
          <tr>
            <th>Verbo en Presente</th>
            <th>Verbo en Pasado Simple</th>
            <th>Traducción</th>
          </tr>
          <tr>
            <td>Play</td>
            <td>Played</td>
            <td>Jugar</td>
          </tr>
          <tr>
            <td>Work</td>
            <td>Worked</td>
            <td>Trabajar</td>
          </tr>
          <tr>
            <td>Study</td>
            <td>Studied</td>
            <td>Estudiar</td>
          </tr>
          <tr>
            <td>Live</td>
            <td>Lived</td>
            <td>Vivir</td>
          </tr>
          <tr>
            <td>Dance</td>
            <td>Danced</td>
            <td>Bailar</td>
          </tr>
        </table>

        <h3>Verbos Irregulares</h3>
        <p>
          Los verbos irregulares no siguen una regla específica para formar el
          pasado simple. A continuación, tienes una tabla con algunos de los
          verbos irregulares más comunes y sus formas en pasado simple.
          Para ver la lista completa dirijase al menu -> Lista completa de verbos irregulares.
        </p>

        <table border="1" cellpadding="5">
          <tr>
            <th>Verbo en Presente</th>
            <th>Verbo en Pasado Simple</th>
            <th>Traducción</th>
          </tr>
          <tr>
            <td>Be</td>
            <td>Was/Were</td>
            <td>Ser/Estar</td>
          </tr>
          <tr>
            <td>Begin</td>
            <td>Began</td>
            <td>Empezar</td>
          </tr>
          <tr>
            <td>Break</td>
            <td>Broke</td>
            <td>Romper</td>
          </tr>
          <tr>
            <td>Bring</td>
            <td>Brought</td>
            <td>Traer</td>
          </tr>
          <tr>
            <td>Buy</td>
            <td>Bought</td>
            <td>Comprar</td>
          </tr>
          <tr>
            <td>Do</td>
            <td>Did</td>
            <td>Hacer</td>
          </tr>
          <tr>
            <td>Eat</td>
            <td>Ate</td>
            <td>Comer</td>
          </tr>
          <tr>
            <td>Go</td>
            <td>Went</td>
            <td>Ir</td>
          </tr>
          <tr>
            <td>Have</td>
            <td>Had</td>
            <td>Tener</td>
          </tr>
          <tr>
            <td>See</td>
            <td>Saw</td>
            <td>Ver</td>
          </tr>
          <tr>
            <td>Take</td>
            <td>Took</td>
            <td>Tomar</td>
          </tr>
          <tr>
            <td>Write</td>
            <td>Wrote</td>
            <td>Escribir</td>
          </tr>
        </table>
      </section>
    </div>
  </div>
</div>
