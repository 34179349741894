import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-lista-completa-verbos-irregulares-pasado-simple',
  standalone: true,
  imports: [],
  templateUrl:
    './lista-completa-verbos-irregulares-pasado-simple.component.html',
  styleUrl: './lista-completa-verbos-irregulares-pasado-simple.component.scss',
})
export class ListaCompletaVerbosIrregularesPasadoSimpleComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Lista verbos Irregulares';
  override title = 'Lista verbos Irregulares';
}
