<div class="verbs">
  <p>
    Los <strong>verbos modales</strong> son verbos auxiliares que se utilizan
    para expresar posibilidad, capacidad, permiso, obligación y otros matices en
    la comunicación. Estos verbos no tienen un significado propio y siempre se
    usan junto con otro verbo en su forma base.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Estructura de Oraciones con Verbos Modales</h2>
      <p>
        <strong>Sujeto + modal + verbo en forma base + complemento.</strong>
      </p>
    </div>
    <div class="section-blanco">
      <h2>Verbos Modales Comunes</h2>

      <table>
        <thead>
          <tr>
            <th>Verbo Modal</th>
            <th>Significado</th>
            <th colspan="2">Ejemplos</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>Inglés</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="modal">Can</td>
            <td>Expresa habilidad o posibilidad.</td>
            <td>
              <ul>
                <li>"I can swim."</li>
                <li>"Can you help me?"</li>
                <li>"She can speak three languages."</li>
                <li>"They can come to the party."</li>
                <li>"He can play the guitar."</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>(Puedo nadar.)</li>
                <li>(¿Puedes ayudarme?)</li>
                <li>(Ella puede hablar tres idiomas.)</li>
                <li>(Ellos pueden venir a la fiesta.)</li>
                <li>(Él puede tocar la guitarra.)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="modal">Could</td>
            <td>
              Pasado de "can" y se usa para hacer solicitudes corteses o
              expresar habilidad en el pasado.
            </td>
            <td>
              <ul>
                <li>"I could read when I was five."</li>
                <li>"Could you please pass the salt?"</li>
                <li>"She could play the piano as a child."</li>
                <li>"They could travel more last year."</li>
                <li>"Could you help me with my homework?"</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>(Podía leer cuando tenía cinco años.)</li>
                <li>(¿Podrías pasarme la sal, por favor?)</li>
                <li>(Ella podía tocar el piano cuando era niña.)</li>
                <li>(Ellos podían viajar más el año pasado.)</li>
                <li>(¿Podrías ayudarme con mi tarea?)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="modal">May</td>
            <td>Expresa permiso o posibilidad.</td>
            <td>
              <ul>
                <li>"May I leave early?"</li>
                <li>"It may rain tomorrow."</li>
                <li>"You may take a break."</li>
                <li>"She may join us later."</li>
                <li>"We may go to the movies tonight."</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>(¿Puedo irme temprano?)</li>
                <li>(Puede que llueva mañana.)</li>
                <li>(Puedes tomar un descanso.)</li>
                <li>(Ella puede unirse a nosotros más tarde.)</li>
                <li>(Podemos ir al cine esta noche.)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="modal">Might</td>
            <td>Expresa una posibilidad más débil que "may".</td>
            <td>
              <ul>
                <li>"I might go to the party."</li>
                <li>"She might join us for dinner."</li>
                <li>"They might come to visit this weekend."</li>
                <li>"We might see a movie later."</li>
                <li>"He might be late."</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>(Podría ir a la fiesta.)</li>
                <li>(Ella podría unirse a nosotros para la cena.)</li>
                <li>(Ellos podrían venir a visitar este fin de semana.)</li>
                <li>(Podríamos ver una película más tarde.)</li>
                <li>(Él podría llegar tarde.)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="modal">Must</td>
            <td>Expresa obligación o necesidad.</td>
            <td>
              <ul>
                <li>"You must wear a seatbelt."</li>
                <li>"I must finish my homework."</li>
                <li>"She must attend the meeting."</li>
                <li>"They must pay their bills on time."</li>
                <li>"He must be at least 18 to vote."</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>(Debes usar el cinturón de seguridad.)</li>
                <li>(Debo terminar mi tarea.)</li>
                <li>(Ella debe asistir a la reunión.)</li>
                <li>(Ellos deben pagar sus cuentas a tiempo.)</li>
                <li>(Él debe tener al menos 18 años para votar.)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="modal">Should</td>
            <td>Da consejos o recomendaciones.</td>
            <td>
              <ul>
                <li>"You should see a doctor."</li>
                <li>"She should study harder."</li>
                <li>"They should try the new restaurant."</li>
                <li>"We should leave early to avoid traffic."</li>
                <li>"He should call his parents."</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>(Deberías ver a un médico.)</li>
                <li>(Ella debería estudiar más.)</li>
                <li>(Ellos deberían probar el nuevo restaurante.)</li>
                <li>(Deberíamos salir temprano para evitar el tráfico.)</li>
                <li>(Él debería llamar a sus padres.)</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
