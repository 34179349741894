<div class="preposicions">
  <p>
    Las preposiciones <strong>in</strong>, <strong>on</strong> y
    <strong>at</strong> también se utilizan para describir estados o
    condiciones. A continuación, te explicamos cómo utilizarlas correctamente en
    este contexto, junto con algunos tips para facilitar su uso.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. <strong>In</strong> - Estado dentro de una condición</h2>
      <p>
        La preposición <strong>in</strong> se usa para describir un estado o
        condición en la que alguien o algo se encuentra dentro de un contexto
        más abstracto. La idea es que la persona o cosa está "envuelta" en esa
        situación o estado.
      </p>
      <ul>
        <li><strong>In pain</strong>: Estar en un estado de dolor.</li>
        <li><strong>In trouble</strong>: Estar en problemas.</li>
        <li><strong>In love</strong>: Estar enamorado.</li>
        <li><strong>In good health</strong>: Estar en buen estado de salud.</li>
        <li><strong>In a hurry</strong>: Estar apurado.</li>
      </ul>
      <div class="tips">
        <strong>Tip:</strong> Utiliza <strong>in</strong> cuando estés
        describiendo una condición o estado en el que "te encuentras dentro de".
        Piensa en estar dentro de algo emocional o mental.
      </div>
    </div>
    <div class="section-blanco">
      <h2>
        2. <strong>On</strong> - Estado en proceso o en una superficie emocional
      </h2>
      <p>
        La preposición <strong>on</strong> se usa para indicar que alguien o
        algo está en un estado, condición o proceso que implica una acción
        continua o repetida, como si estuviera "sobre" un camino o un plan.
      </p>
      <ul>
        <li>
          <strong>On fire</strong>: Estar emocionalmente o físicamente
          encendido.
        </li>
        <li><strong>On duty</strong>: Estar de servicio o en turno.</li>
        <li><strong>On edge</strong>: Estar nervioso o inquieto.</li>
        <li><strong>On time</strong>: Llegar a tiempo.</li>
        <li><strong>On hold</strong>: Algo que está en espera.</li>
      </ul>
      <div class="tips">
        <strong>Tip:</strong> Utiliza <strong>on</strong> para describir estados
        o situaciones que ocurren "sobre" una situación o cuando algo está en
        proceso. También puede usarse para describir condiciones de tiempo como
        "on time".
      </div>
    </div>
    <div class="section-blanco">
      <h2>3. <strong>At</strong> - Estado en un punto específico</h2>
      <p>
        La preposición <strong>at</strong> at se usa para indicar un estado
        relacionado con una actividad, condición o situación, generalmente más
        puntual o enfocada en un aspecto específico de la persona o cosa. o un
        momento particular de una condición o circunstancia.
      </p>
      <ul>
        <li><strong>At peace</strong>: Estar en un estado de paz.</li>
        <li><strong>At risk</strong>: Estar en peligro.</li>
        <li><strong>At fault</strong>: Ser el culpable o responsable.</li>
        <li><strong>At your best</strong>: Estar en tu mejor momento.</li>
        <li><strong>At a loss</strong>: Estar sin saber qué hacer.</li>
      </ul>
      <div class="tips">
        <strong>Tip:</strong> Utiliza <strong>at</strong> cuando describas un
        estado en un punto específico o circunstancia concreta. Generalmente se
        usa para condiciones puntuales.
      </div>
    </div>
    <div class="section-blanco">
      <h2>Ejemplos Preposiciones de Estado: In, At, On</h2>

      <table>
        <thead>
          <tr>
            <th>Preposición</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción en Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>In</strong></td>
            <td>He is <strong>in</strong> love</td>
            <td>Él está <strong>enamorado</strong></td>
          </tr>
          <tr>
            <td><strong>In</strong></td>
            <td>She is <strong>in</strong> a good mood</td>
            <td>Ella está <strong>de buen humor</strong></td>
          </tr>
          <tr>
            <td><strong>In</strong></td>
            <td>They are <strong>in</strong> trouble</td>
            <td>Ellos están <strong>en problemas</strong></td>
          </tr>
          <tr>
            <td><strong>In</strong></td>
            <td>He is <strong>in</strong> pain</td>
            <td>Él está <strong>con dolor</strong></td>
          </tr>
          <tr>
            <td><strong>In</strong></td>
            <td>She is <strong>in</strong> danger</td>
            <td>Ella está <strong>en peligro</strong></td>
          </tr>
          <tr>
            <td><strong>At</strong></td>
            <td>He is <strong>at</strong> peace</td>
            <td>Él está <strong>en paz</strong></td>
          </tr>
          <tr>
            <td><strong>At</strong></td>
            <td>She is <strong>at</strong> risk</td>
            <td>Ella está <strong>en riesgo</strong></td>
          </tr>
          <tr>
            <td><strong>At</strong></td>
            <td>They are <strong>at</strong> fault</td>
            <td>Ellos tienen <strong>la culpa</strong></td>
          </tr>
          <tr>
            <td><strong>At</strong></td>
            <td>She felt <strong>at</strong> ease</td>
            <td>Ella se sintió <strong>a gusto</strong></td>
          </tr>
          <tr>
            <td><strong>On</strong></td>
            <td>She is <strong>on</strong> a diet</td>
            <td>Ella está <strong>a dieta</strong></td>
          </tr>
          <tr>
            <td><strong>On</strong></td>
            <td>He is <strong>on</strong> the phone</td>
            <td>Él está <strong>al teléfono</strong></td>
          </tr>
          <tr>
            <td><strong>On</strong></td>
            <td>The team is <strong>on</strong> fire</td>
            <td>El equipo está <strong>en su mejor momento</strong></td>
          </tr>
          <tr>
            <td><strong>On</strong></td>
            <td>He is <strong>on</strong> duty</td>
            <td>Él está <strong>de servicio</strong></td>
          </tr>
          <tr>
            <td><strong>On</strong></td>
            <td>She is <strong>on</strong> a break</td>
            <td>Ella está <strong>en un descanso</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
