import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-numeros-ordinales',
  templateUrl: './numeros-ordinales.component.html',
  styleUrl: './numeros-ordinales.component.scss',
})
export class NumerosOrdinalesComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Numeros Ordinales';
  override title = 'Números ordinales  -   “ordinal numbers”';
}
