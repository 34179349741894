<div class="verbos">
  <p>
    En inglés, los verbos son la parte de la oración más complicada. Existen
    diferentes tipos de verbos que cumplen distintas funciones en la oración.
    Aquí exploraremos algunos de los tipos más comunes: los verbos auxiliares,
    los verbos modales, los verbos compuestos y otros.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Verbos Auxiliares</h2>
      <p>
        Los <strong>verbos auxiliares</strong> son verbos que ayudan al verbo
        principal a expresar tiempo, voz o modo. No tienen un significado
        completo por sí mismos y se utilizan junto con el verbo principal.
      </p>
      <ul>
        <li>
          <strong>Ejemplos Comunes:</strong> <em>be</em>, <em>have</em>,
          <em>do</em>
        </li>
      </ul>

      <table>
        <thead>
          <tr>
            <th>Verbo Auxiliar</th>
            <th>Uso</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>to be</strong></td>
            <td>Indica estado o acción continua</td>
            <td>
              She <strong>is</strong> happy. (Ella <strong>está</strong> feliz.)
            </td>
          </tr>
          <tr>
            <td><strong>to have</strong></td>
            <td>Indica posesión o experiencia</td>
            <td>
              They <strong>have</strong> finished. (Ellos
              <strong>han</strong> terminado.)
            </td>
          </tr>
          <tr>
            <td><strong>to do</strong></td>
            <td>Se usa en preguntas y negaciones</td>
            <td>
              Do you <strong>like</strong> pizza? (¿Te <strong>gusta</strong> la
              pizza?)
            </td>
          </tr>
        </tbody>
      </table>

      <span
        >Veremos estos tres verbos más comunes en los siguientes
        apartados.</span
      >
    </div>
    <div class="section-blanco">
      <h2>2. Verbos Modales</h2>
      <p>
        Los <strong>verbos modales</strong> son un tipo de verbo auxiliar que
        expresa modalidad, como posibilidad, capacidad, permiso, prohibición y
        obligación. Estos verbos no cambian de forma según la persona.
      </p>
      <ul>
        <li>
          <strong>Ejemplos Comunes:</strong> <em>can</em>, <em>could</em>,
          <em>may</em>, <em>might</em>, <em>must</em>, <em>should</em>
        </li>
      </ul>
      <h3>Uso</h3>
      <p>Se utilizan para expresar:</p>
      <ul>
        <li>
          <strong>Posibilidad:</strong>
          <ul>
            <li>
              Ejemplo: <em>It might rain tomorrow.</em> (Podría llover mañana).
            </li>
          </ul>
        </li>
        <li>
          <strong>Capacidad:</strong>
          <ul>
            <li>Ejemplo: <em>I can swim.</em> (Puedo nadar).</li>
          </ul>
        </li>
        <li>
          <strong>Permiso:</strong>
          <ul>
            <li>
              Ejemplo: <em>May I leave early?</em> (¿Puedo irme temprano?).
            </li>
          </ul>
        </li>
        <li>
          <strong>Obligación:</strong>
          <ul>
            <li>
              Ejemplo: <em>You must wear a seatbelt.</em> (Debes usar el
              cinturón de seguridad).
            </li>
          </ul>
        </li>
      </ul>
      <span>Veremos más sobre verbos modales en los siguientes apartados.</span>
    </div>
    <div class="section-blanco">
      <h2>3. Verbos Compuestos</h2>
      <p>
        Los <strong>verbos compuestos</strong> son combinaciones de un verbo y
        una preposición o un adverbio que crean un nuevo significado. Se conocen
        comúnmente como <em>phrasal verbs</em>.
      </p>
      <ul>
        <li>
          <strong>Ejemplos Comunes:</strong> <em>give up</em>,
          <em>take off</em>,
          <em>look after</em>
        </li>
      </ul>
      <h3>Uso</h3>
      <p>
        El significado de un verbo compuesto a menudo no se puede deducir del
        significado de las palabras individuales. Aquí hay algunos ejemplos:
      </p>
      <table>
        <tr>
          <th>Verbo Compuesto</th>
          <th>Significado</th>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td>Give up</td>
          <td>Rendir o dejar de intentar</td>
          <td>I will not give up on my dreams.</td>
          <td>No renunciaré a mis sueños.</td>
        </tr>
        <tr>
          <td>Take off</td>
          <td>Despegar (un avión) o quitar (ropa)</td>
          <td>The plane will take off at 5 PM.</td>
          <td>El avión despegará a las 5 PM.</td>
        </tr>
        <tr>
          <td>Look after</td>
          <td>Cuidar de alguien o algo</td>
          <td>She looks after her elderly parents.</td>
          <td>Ella cuida de sus padres ancianos.</td>
        </tr>
        <tr>
          <td>Run into</td>
          <td>Encontrarse accidentalmente con alguien</td>
          <td>I ran into an old friend at the mall.</td>
          <td>Me encontré con un viejo amigo en el centro comercial.</td>
        </tr>
        <tr>
          <td>Set up</td>
          <td>Establecer o organizar</td>
          <td>They set up a new business.</td>
          <td>Establecieron un nuevo negocio.</td>
        </tr>
      </table>

      <table>
        <tr>
          <th>Verbo Compuesto</th>
          <th>Palabra 1</th>
          <th>Significado Palabra 1</th>
          <th>Palabra 2</th>
          <th>Significado Palabra 2</th>
          <th>Significado Combinado</th>
        </tr>
        <tr>
          <td>Give up</td>
          <td>Give</td>
          <td>Dar</td>
          <td>Up</td>
          <td>Arriba</td>
          <td>Rendir o dejar de intentar</td>
        </tr>
        <tr>
          <td>Take off</td>
          <td>Take</td>
          <td>Tomar</td>
          <td>Off</td>
          <td>Fuera</td>
          <td>Despegar (un avión) o quitar (ropa)</td>
        </tr>
        <tr>
          <td>Look after</td>
          <td>Look</td>
          <td>Mirar</td>
          <td>After</td>
          <td>Después</td>
          <td>Cuidar de alguien o algo</td>
        </tr>
        <tr>
          <td>Run into</td>
          <td>Run</td>
          <td>Correr</td>
          <td>Into</td>
          <td>Dentro</td>
          <td>Encontrarse accidentalmente con alguien</td>
        </tr>
        <tr>
          <td>Set up</td>
          <td>Set</td>
          <td>Establecer</td>
          <td>Up</td>
          <td>Arriba</td>
          <td>Establecer o organizar</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>4. Verbos Transitivos e Intransitivos</h2>
      <p>
        Los <strong>verbos transitivos</strong> requieren un objeto directo para
        completar su significado, mientras que los
        <strong>verbos intransitivos</strong> no lo necesitan.
      </p>
      <table>
        <tr>
          <th>Verbo</th>
          <th>Tipo</th>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td>Eat</td>
          <td>Transitivo</td>
          <td>She eats an apple.</td>
          <td>Ella come una manzana.</td>
        </tr>
        <tr>
          <td>Sleep</td>
          <td>Intransitivo</td>
          <td>He sleeps well.</td>
          <td>Él duerme bien.</td>
        </tr>
        <tr>
          <td>Write</td>
          <td>Transitivo</td>
          <td>I write a letter.</td>
          <td>Escribo una carta.</td>
        </tr>
        <tr>
          <td>Run</td>
          <td>Intransitivo</td>
          <td>They run every morning.</td>
          <td>Ellos corren cada mañana.</td>
        </tr>
      </table>
    </div>
  </div>
</div>
