import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LeccionesInglesService } from './lecciones.-ingles.service';

@Component({
  selector: 'app-lecciones-ingles',
  templateUrl: './lecciones-ingles.component.html',
  styleUrl: './lecciones-ingles.component.scss',
})
export class LeccionesInglesComponent {
  constructor(
    public router: Router,
    public leccionesService: LeccionesInglesService
  ) {}

  showItem = false;
  onShowItem(showItem) {
    this.showItem = showItem;
  }
  goHome() {
    this.router.navigate(['']);
  }

  goNext() {
    this.router.navigate(['/profile']);
  }
}
