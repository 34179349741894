<div class="adjetives-ending-ed-ing">
  <p>
    Los adjetivos en inglés que terminan en <strong>"-ed"</strong> y
    <strong>"-ing"</strong> pueden causar confusión, ya que ambos tienen
    funciones distintas y se usan en contextos diferentes. A continuación se
    explica cuándo y cómo usar cada uno.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Reglas y Uso</h2>
      <ul>
        <li>
          <strong>Adjetivos terminados en "-ed":</strong> Estos adjetivos
          describen cómo se siente una persona. Suelen expresar emociones o
          estados de ánimo y se utilizan cuando alguien experimenta una
          sensación o sentimiento.
        </li>
        <ul>
          <li>Ejemplo: "I am <strong>bored</strong>." (Estoy aburrido/a)</li>
        </ul>

        <li>
          <strong>Adjetivos terminados en "-ing":</strong> Estos adjetivos
          describen características de una situación, cosa o persona, y expresan
          la causa del sentimiento.
        </li>
        <ul>
          <li>
            Ejemplo: "This movie is <strong>boring</strong>." (Esta película es
            aburrida)
          </li>
        </ul>
      </ul>

      <h2>Tabla de Ejemplos</h2>
      <table>
        <thead>
          <tr>
            <th>Adjetivo "-ed"</th>
            <th>Adjetivo "-ing"</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción en Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>bored</td>
            <td>boring</td>
            <td>
              I am <strong>bored</strong> because the movie is
              <strong>boring</strong>.
            </td>
            <td>
              Estoy <strong>aburrido</strong> porque la película es
              <strong>aburrida</strong>.
            </td>
          </tr>
          <tr>
            <td>interested</td>
            <td>interesting</td>
            <td>
              She is <strong>interested</strong> in the book because it is
              <strong>interesting</strong>.
            </td>
            <td>
              Ella está <strong>interesada</strong> en el libro porque es
              <strong>interesante</strong>.
            </td>
          </tr>
          <tr>
            <td>tired</td>
            <td>tiring</td>
            <td>
              We are <strong>tired</strong> because the work was
              <strong>tiring</strong>.
            </td>
            <td>
              Estamos <strong>cansados</strong> porque el trabajo fue
              <strong>agotador</strong>.
            </td>
          </tr>
          <tr>
            <td>excited</td>
            <td>exciting</td>
            <td>
              The children are <strong>excited</strong> because the game is
              <strong>exciting</strong>.
            </td>
            <td>
              Los niños están <strong>emocionados</strong> porque el juego es
              <strong>emocionante</strong>.
            </td>
          </tr>
          <tr>
            <td>frustrated</td>
            <td>frustrating</td>
            <td>
              He felt <strong>frustrated</strong> after trying the
              <strong>frustrating</strong> puzzle.
            </td>
            <td>
              Él se sintió <strong>frustrado</strong> después de intentar
              resolver el <strong>rompecabezas frustrante</strong>.
            </td>
          </tr>
          <tr>
            <td>amazed</td>
            <td>amazing</td>
            <td>
              I was <strong>amazed</strong> by the
              <strong>amazing</strong> performance.
            </td>
            <td>
              Estaba <strong>asombrado</strong> por la
              <strong>asombrosa</strong> actuación.
            </td>
          </tr>
          <tr>
            <td>confused</td>
            <td>confusing</td>
            <td>
              The instructions were <strong>confusing</strong>, so I felt
              <strong>confused</strong>.
            </td>
            <td>
              Las instrucciones eran <strong>confusas</strong>, así que me sentí
              <strong>confundido</strong>.
            </td>
          </tr>
          <tr>
            <td>annoyed</td>
            <td>annoying</td>
            <td>
              She was <strong>annoyed</strong> by the
              <strong>annoying</strong> noise.
            </td>
            <td>
              Ella estaba <strong>molesta</strong> por el
              <strong>ruido molesto</strong>.
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th>Adjetivo "-ed"</th>
            <th>Traducción</th>
            <th>Adjetivo "-ing"</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>alarmed</td>
            <td>alarmado</td>
            <td>alarming</td>
            <td>alarmante</td>
          </tr>
          <tr>
            <td>astonished</td>
            <td>asombrado</td>
            <td>astonishing</td>
            <td>asombroso</td>
          </tr>
          <tr>
            <td>annoyed</td>
            <td>molesto</td>
            <td>annoying</td>
            <td>molesto</td>
          </tr>
          <tr>
            <td>bored</td>
            <td>aburrido</td>
            <td>boring</td>
            <td>aburrido</td>
          </tr>
          <tr>
            <td>challenged</td>
            <td>desafiado</td>
            <td>challenging</td>
            <td>desafiante</td>
          </tr>
          <tr>
            <td>comforted</td>
            <td>consolado</td>
            <td>comforting</td>
            <td>consolador</td>
          </tr>
          <tr>
            <td>confused</td>
            <td>confuso</td>
            <td>confusing</td>
            <td>confuso</td>
          </tr>
          <tr>
            <td>disgusted</td>
            <td>asqueado</td>
            <td>disgusting</td>
            <td>asqueroso</td>
          </tr>
          <tr>
            <td>excited</td>
            <td>emocionado</td>
            <td>exciting</td>
            <td>emocionante</td>
          </tr>
          <tr>
            <td>frightened</td>
            <td>asustado</td>
            <td>frightening</td>
            <td>espantoso</td>
          </tr>
          <tr>
            <td>inspired</td>
            <td>inspirado</td>
            <td>inspiring</td>
            <td>inspirador</td>
          </tr>
          <tr>
            <td>moved</td>
            <td>conmovido</td>
            <td>moving</td>
            <td>conmovedor</td>
          </tr>
          <tr>
            <td>overwhelmed</td>
            <td>abrumado</td>
            <td>overwhelming</td>
            <td>abrumador</td>
          </tr>
          <tr>
            <td>relaxed</td>
            <td>relajado</td>
            <td>relaxing</td>
            <td>relajante</td>
          </tr>
          <tr>
            <td>surprised</td>
            <td>sorprendido</td>
            <td>surprising</td>
            <td>sorprendente</td>
          </tr>
          <tr>
            <td>terrified</td>
            <td>aterrado</td>
            <td>terrifying</td>
            <td>aterrador</td>
          </tr>
          <tr>
            <td>tired</td>
            <td>cansado</td>
            <td>tiring</td>
            <td>agotador</td>
          </tr>
          <tr>
            <td>worried</td>
            <td>preocupado</td>
            <td>worrying</td>
            <td>preocupante</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
