import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-future-perfect-continuous',
  standalone: true,
  imports: [],
  templateUrl: './future-perfect-continuous.component.html',
  styleUrl: './future-perfect-continuous.component.scss',
})
export class FuturePerfectContinuousComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Future perfect continuous';
  override title = 'Future perfect continuous';
}
