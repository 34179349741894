import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import {
  LeccionesInglesService,
  Nivel,
} from '../lecciones/lecciones.-ingles.service';
import { FormControl } from '@angular/forms';
interface ItemMenuNivel {
  path: string;
  descripcionMenu: string;
  nivel: Nivel;
  isChildOf: string;
}

@Component({
  selector: 'app-navigation-lateral',
  templateUrl: './navigation-lateral.component.html',
  styleUrl: './navigation-lateral.component.scss',
})
export class NavigationLateralComponent implements OnInit {
  @Output() showItem: EventEmitter<boolean>;
  itemsMenuPorNivel: ItemMenuNivel[] = [];
  currentPath: string;
  private breakpointObserver = inject(BreakpointObserver);
  ButtonMenuType = Nivel;
  buttonMenuActivado: Nivel = Nivel.Principiante;

  pathActivo: string;
  showBar = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private router: Router,
    public leccionesService: LeccionesInglesService
  ) {
    // Suscribirse a los cambios de ruta
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const url = this.router.url.split('/')[1];
        if (!url) {
          return;
        }
        if (this.router.url.split('/')[1] == 'indicaciones') {
          this.showBar = false;
          let menuActivado = this.router.url.split('/')[2]
            ? this.router.url.split('/')[2]
            : 'contacto';
          this.buttonMenuActivado = null;
          this.router.navigate(['/indicaciones/' + menuActivado]);
        } else {
          this.showBar = true;

          this.pathActivo = this.router.url;
          let menuActivado = this.router.url.split('/')[2]
            ? this.router.url.split('/')[2]
            : 'principiante';
          this.setActive(menuActivado, this.router.url.split('/')[3]);
        }
      });
  }
  goHome() {
    this.router.navigate(['/']);
    this.leccionesService.nivel$.next(null);
  }

  goTo(buttonMenuActivado) {
    this.router.navigate(['/lecciones/' + buttonMenuActivado]);
  }

  setActive(buttonMenuActivado: any, clase?: string) {
    this.leccionesService.nivel$.next(buttonMenuActivado);
    if (!clase) {
      this.leccionesService.showItem$.next(false);
      this.router.navigate(['/lecciones/' + buttonMenuActivado]);
    } else {
      this.leccionesService.showItem$.next(true);
    }
    this.leccionesService.lastNivelActivo = buttonMenuActivado;
    this.buttonMenuActivado = buttonMenuActivado;
    this.itemsMenuPorNivel = this.leccionesService.menuFiltrado;
  }
  goLecciones(buttonMenuActivado: any) {
    this.router.navigate(['/lecciones/' + buttonMenuActivado]);
  }
  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  ngOnInit(): void {
    this.itemsMenuPorNivel = this.leccionesService.menuFiltrado;
  }
}
