<div class="tobe">
  <p>
    El verbo "to be" en presente simple tiene varios significados y usos. Se
    utiliza para indicar la existencia o presencia de algo, describe estados o
    condiciones, y se usa para hablar de características o identidades. En
    presente simple, tiene tres formas: "am," "is," y "are." "Am" se usa con
    "I," "is" se usa con sujetos en singular como "he," "she," o "it," y "are"
    se usa con sujetos en plural "we" "they",o con "you." En resumen, "to be" en
    presente simple es esencial para describir estados y características.
  </p>

  <div class="section">
    <div class="section-blanco">
      <h2>El Verbo "To Be"</h2>

      <h2>Conjugación del Verbo "To Be" en Forma Positiva</h2>
      <table>
        <thead>
          <tr>
            <th>Pronombre / Sujeto</th>
            <th>Verbo "To Be"</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I</td>
            <td>am</td>
            <td>Yo soy/estoy</td>
          </tr>
          <tr>
            <td>You</td>
            <td>are</td>
            <td>Tú eres/estás</td>
          </tr>
          <tr>
            <td>He</td>
            <td>is</td>
            <td>Él es/está</td>
          </tr>
          <tr>
            <td>She</td>
            <td>is</td>
            <td>Ella es/está</td>
          </tr>
          <tr>
            <td>It</td>
            <td>is</td>
            <td>Eso es/está</td>
          </tr>
          <tr>
            <td>We</td>
            <td>are</td>
            <td>Nosotros somos/estamos</td>
          </tr>
          <tr>
            <td>You (plural)</td>
            <td>are</td>
            <td>Ustedes son/están</td>
          </tr>
          <tr>
            <td>They</td>
            <td>are</td>
            <td>Ellos son/están</td>
          </tr>
        </tbody>
      </table>

      <mat-card class="tobe-example">
        <mat-card-header>
          <mat-card-title
            ><strong>
              Estructura de las Oraciones Afirmativas</strong
            ></mat-card-title
          >
        </mat-card-header>
        <mat-card-content>
          <div>
            <p>
              La estructura de una oración afirmativa con el verbo "to be" es la
              siguiente:
            </p>
            <p><strong>Sujeto + Verbo "to be" + Complemento</strong></p>

            <table>
              <thead>
                <tr>
                  <th>Ejemplo en Inglés</th>
                  <th>Traducción en Español</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>I am a student.</td>
                  <td>Yo soy un estudiante.</td>
                </tr>
                <tr>
                  <td>He is a doctor.</td>
                  <td>Él es un doctor.</td>
                </tr>
                <tr>
                  <td>We are friends.</td>
                  <td>Nosotros somos amigos.</td>
                </tr>
                <tr>
                  <td>They are at the park.</td>
                  <td>Ellos están en el parque.</td>
                </tr>
                <tr>
                  <td>She is a good singer.</td>
                  <td>Ella es una buena cantante.</td>
                </tr>
                <tr>
                  <td>It is a beautiful day.</td>
                  <td>Es un día hermoso.</td>
                </tr>
                <tr>
                  <td>You are very kind.</td>
                  <td>Tú eres muy amable.</td>
                </tr>
                <tr>
                  <td>My name is John.</td>
                  <td>Mi nombre es John.</td>
                </tr>
                <tr>
                  <td>You are my friend.</td>
                  <td>Tú eres mi amigo.</td>
                </tr>
                <tr>
                  <td>We are from Argentina.</td>
                  <td>Somos de Argentina.</td>
                </tr>
                <tr>
                  <td>The dog is in the garden.</td>
                  <td>El perro está en el jardín.</td>
                </tr>
                <tr>
                  <td>She is very happy today.</td>
                  <td>Ella está muy feliz hoy.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="section-blanco">
      <h2>Contracciones</h2>
      <p>
        En inglés hablado y escrito informal, es común usar contracciones con el
        verbo "to be". Aquí están las contracciones más comunes:
      </p>
      <table>
        <thead>
          <tr>
            <th>Forma Completa</th>
            <th>Forma Contraída</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I am</td>
            <td>I'm</td>
            <td>Yo soy/estoy</td>
          </tr>
          <tr>
            <td>You are</td>
            <td>You're</td>
            <td>Tú eres/estás</td>
          </tr>
          <tr>
            <td>He is</td>
            <td>He's</td>
            <td>Él es/está</td>
          </tr>
          <tr>
            <td>She is</td>
            <td>She's</td>
            <td>Ella es/está</td>
          </tr>
          <tr>
            <td>It is</td>
            <td>It's</td>
            <td>Eso es/está</td>
          </tr>
          <tr>
            <td>We are</td>
            <td>We're</td>
            <td>Nosotros somos/estamos</td>
          </tr>
          <tr>
            <td>You are (plural)</td>
            <td>You're</td>
            <td>Ustedes son/están</td>
          </tr>
          <tr>
            <td>They are</td>
            <td>They're</td>
            <td>Ellos son/están</td>
          </tr>
        </tbody>

        <p>
          Como vimos, las contracciones son formas abreviadas de las palabras.
          Aquí tienes algunos ejemplos:
        </p>

        <ul>
          <li><strong>I'm a student.</strong> (Yo soy un estudiante)</li>
          <li><strong>He's a doctor.</strong> (Él es un doctor)</li>
          <li><strong>We're friends.</strong> (Nosotros somos amigos)</li>
          <li>
            <strong>They're at the park.</strong> (Ellos están en el parque)
          </li>
          <li>
            <strong>She's a good singer.</strong> (Ella es una buena cantante)
          </li>
          <li><strong>It's a beautiful day.</strong> (Es un día hermoso)</li>
          <li><strong>You're very kind.</strong> (Tú eres muy amable)</li>
          <li><strong>My name's John.</strong> (Mi nombre es John)</li>
          <li><strong>You're my friend.</strong> (Tú eres mi amigo)</li>
          <li><strong>We're from Argentina.</strong> (Somos de Argentina)</li>
          <li>
            <strong>The dog's in the garden.</strong> (El perro está en el
            jardín)
          </li>
          <li>
            <strong>She's very happy today.</strong> (Ella está muy feliz hoy)
          </li>
        </ul>
      </table>

      <table border="1" cellpadding="5" cellspacing="0">
        <thead>
          <tr>
            <th>Pronombre personal</th>
            <th>Verbo "To be"</th>
            <th>Contracción</th>
            <th>Traducción</th>
            <th>Persona a la que corresponde</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>I</td>
            <td>am</td>
            <td>I'm</td>
            <td>Yo soy o estoy</td>
            <td>Primera persona del singular</td>
          </tr>
          <tr>
            <td>You</td>
            <td>are</td>
            <td>You're</td>
            <td>Tú eres o estás</td>
            <td>Segunda persona del singular</td>
          </tr>
          <tr>
            <td>She</td>
            <td>is</td>
            <td>She's</td>
            <td>Ella es o está</td>
            <td>Tercera persona del singular</td>
          </tr>
          <tr>
            <td>He</td>
            <td>is</td>
            <td>He's</td>
            <td>Él es o está</td>
            <td>Tercera persona del singular</td>
          </tr>
          <tr>
            <td>It</td>
            <td>is</td>
            <td>It's</td>
            <td>Ello es o está</td>
            <td>Tercera persona del singular</td>
          </tr>
          <tr>
            <td>We</td>
            <td>are</td>
            <td>We're</td>
            <td>Nosotros somos o estamos</td>
            <td>Primera persona del plural</td>
          </tr>
          <tr>
            <td>You</td>
            <td>are</td>
            <td>You're</td>
            <td>Ustedes son o están</td>
            <td>Segunda persona del plural</td>
          </tr>
          <tr>
            <td>They</td>
            <td>are</td>
            <td>They're</td>
            <td>Ellos son o están</td>
            <td>Tercera persona del plural</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>¿Cómo hacer preguntas con el verbo "to be"?</h2>
      <p>
        Para hacer preguntas con el verbo <strong>"to be"</strong> en inglés,
        simplemente debes invertir el orden del sujeto y el verbo. Es decir,
        pones primero el verbo <em>"to be"</em> (am, is, are), luego el sujeto,
        y finalmente el complemento.
      </p>

      <h2>1. Estructura de las preguntas</h2>
      <p>
        Las preguntas en inglés con el verbo "to be" siguen esta estructura:
      </p>
      <p><strong>Verbo "to be" + Sujeto + Complemento</strong></p>
      <p>Aquí tienes algunos ejemplos:</p>

      <table>
        <tr>
          <th>Pregunta en inglés</th>
          <th>Pregunta en español</th>
        </tr>
        <tr>
          <td>Are you a student?</td>
          <td>¿Eres tú un estudiante?</td>
        </tr>
        <tr>
          <td>Is she happy?</td>
          <td>¿Está ella feliz?</td>
        </tr>
        <tr>
          <td>Are we at home?</td>
          <td>¿Estamos en casa?</td>
        </tr>
        <tr>
          <td>Is it cold?</td>
          <td>¿Está frío?</td>
        </tr>
        <tr>
          <td>Are they teachers?</td>
          <td>¿Son ellos profesores?</td>
        </tr>
        <tr>
          <td>Is he a doctor?</td>
          <td>¿Es él un doctor?</td>
        </tr>
        <tr>
          <td>Am I your friend?</td>
          <td>¿Soy yo tu amigo?</td>
        </tr>
        <tr>
          <td>Are we ready?</td>
          <td>¿Estamos listos?</td>
        </tr>
        <tr>
          <td>Is this your book?</td>
          <td>¿Es este tu libro?</td>
        </tr>
        <tr>
          <td>Are you coming with us?</td>
          <td>¿Vienes tú con nosotros?</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>2. Respuestas cortas en inglés</h2>
      <p class="section-contenido">
        Las respuestas cortas se forman con el verbo "to be" seguido del
        pronombre personal correspondiente. Existen dos tipos de respuestas:
        afirmativas y negativas.
      </p>
      <h3>Respuestas afirmativas</h3>
      <p>Para responder afirmativamente, utilizamos:</p>
      <p><strong>Yes + pronombre personal + verbo "to be".</strong></p>
      <p>Ejemplos:</p>

      <table>
        <tr>
          <th>Pregunta</th>
          <th>Respuesta corta afirmativa</th>
        </tr>
        <tr>
          <td>Are you a student?</td>
          <td>Yes, I am.</td>
        </tr>
        <tr>
          <td>Is she happy?</td>
          <td>Yes, she is.</td>
        </tr>
        <tr>
          <td>Are we at home?</td>
          <td>Yes, we are.</td>
        </tr>
        <tr>
          <td>Is it cold?</td>
          <td>Yes, it is.</td>
        </tr>
        <tr>
          <td>Are they teachers?</td>
          <td>Yes, they are.</td>
        </tr>
        <tr>
          <td>Is he a doctor?</td>
          <td>Yes, he is.</td>
        </tr>
        <tr>
          <td>Am I your friend?</td>
          <td>Yes, you are.</td>
        </tr>
        <tr>
          <td>Are we ready?</td>
          <td>Yes, we are.</td>
        </tr>
        <tr>
          <td>Is this your book?</td>
          <td>Yes, it is.</td>
        </tr>
        <tr>
          <td>Are you coming with us?</td>
          <td>Yes, I am.</td>
        </tr>
      </table>

      <h3>Respuestas negativas</h3>
      <p>Para responder negativamente, utilizamos:</p>
      <p>
        <strong
          >No + pronombre personal + verbo "to be" en negativo
          (contracción).</strong
        >
      </p>
      <p>Ejemplos:</p>

      <table>
        <tr>
          <th>Pregunta</th>
          <th>Respuesta corta negativa</th>
        </tr>
        <tr>
          <td>Are you a student?</td>
          <td>No, I'm not.</td>
        </tr>
        <tr>
          <td>Is she happy?</td>
          <td>No, she isn't.</td>
        </tr>
        <tr>
          <td>Are we at home?</td>
          <td>No, we aren't.</td>
        </tr>
        <tr>
          <td>Is it cold?</td>
          <td>No, it isn't.</td>
        </tr>
        <tr>
          <td>Are they teachers?</td>
          <td>No, they aren't.</td>
        </tr>
        <tr>
          <td>Is he a doctor?</td>
          <td>No, he isn't.</td>
        </tr>
        <tr>
          <td>Am I your friend?</td>
          <td>No, you aren't.</td>
        </tr>
        <tr>
          <td>Are we ready?</td>
          <td>No, we aren't.</td>
        </tr>
        <tr>
          <td>Is this your book?</td>
          <td>No, it isn't.</td>
        </tr>
        <tr>
          <td>Are you coming with us?</td>
          <td>No, I'm not.</td>
        </tr>
      </table>
    </div>
  </div>

  <div class="section-blanco">
    <h2>Ser/Estar - “To Be” - Forma Negativa</h2>
    <p>
      La forma negativa del verbo "to be" se usa para decir que algo no es de
      una manera, no está en un lugar o no tiene una característica.
    </p>
    <p>
      El verbo "to be" en forma negativa se forma con la estructura:
      <strong>Sujeto + verbo "to be" + not + complemento</strong>.
    </p>
    <p>
      Se pueden usar las contracciones para hacer las oraciones más naturales.
    </p>

    <h2>Formas negativas del verbo "to be":</h2>

    <table>
      <thead>
        <tr>
          <th>Pronombre personal</th>
          <th>Verbo "To be"</th>
          <th>Contracción negativa</th>
          <th>Traducción</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>I</td>
          <td>am not</td>
          <td>(No hay contracción)</td>
          <td>Yo no soy/estoy</td>
        </tr>
        <tr>
          <td>You</td>
          <td>are not</td>
          <td>aren't</td>
          <td>Tú no eres/estás</td>
        </tr>
        <tr>
          <td>She</td>
          <td>is not</td>
          <td>isn't</td>
          <td>Ella no es/está</td>
        </tr>
        <tr>
          <td>He</td>
          <td>is not</td>
          <td>isn't</td>
          <td>Él no es/está</td>
        </tr>
        <tr>
          <td>It</td>
          <td>is not</td>
          <td>isn't</td>
          <td>Ello no es/está</td>
        </tr>
        <tr>
          <td>We</td>
          <td>are not</td>
          <td>aren't</td>
          <td>Nosotros no somos/estamos</td>
        </tr>
        <tr>
          <td>You (plural)</td>
          <td>are not</td>
          <td>aren't</td>
          <td>Ustedes no son/están</td>
        </tr>
        <tr>
          <td>They</td>
          <td>are not</td>
          <td>aren't</td>
          <td>Ellos no son/están</td>
        </tr>
      </tbody>
    </table>

    <h2>Ejemplos:</h2>
    <ul>
      <li>
        <strong>Sin contracción:</strong>
        <ul>
          <li>I am not a teacher. (Yo no soy profesor.)</li>
          <li>She is not at home. (Ella no está en casa.)</li>
          <li>They are not friends. (Ellos no son amigos.)</li>
        </ul>
      </li>
      <li>
        <strong>Con contracción:</strong>
        <ul>
          <li>You aren't busy. (Tú no estás ocupado.)</li>
          <li>He isn't my brother. (Él no es mi hermano.)</li>
          <li>We aren't tired. (Nosotros no estamos cansados.)</li>
        </ul>
      </li>
    </ul>

    <h2>Resumen:</h2>
    <p>
      El verbo "to be" en negativo se forma agregando
      <strong>not</strong> después del verbo. Con frecuencia se usan las
      contracciones, como <strong>isn't</strong> y <strong>aren't</strong>, para
      sonar más natural.
    </p>
  </div>
</div>
