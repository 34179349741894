import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-preposiciones',
  templateUrl: './preposiciones.component.html',
  styleUrl: './preposiciones.component.scss',
})
export class PreposicionesComponent extends LeccionBaseComponent {
  override descripcionMenu = 'IN-ON-AT Preposicions';
  override title = 'Preposiciones: in, on, at';
}
