<div>
  <div class="navigationItem-navigationContent">
    <app-navigation-button
      [text]="buttonPrevious"
      [icon]="'arrow_back'"
      [url]="urlPrevious"
      class="navigationButton navigationButton-previous"
    ></app-navigation-button>
    <app-navigation-button
      class="navigationButton navigationButton-next"
      *ngIf="urlNext"
      [text]="buttonNext"
      [url]="urlNext"
      [icon]="'arrow_forward'"
    ></app-navigation-button>
  </div>

  <div class="navigationItem-title">
    <h1 class="title">{{ title }}</h1>
  </div>

  <div class="navigationItem-content">
    <ng-content> </ng-content>
  </div>
</div>
