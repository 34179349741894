import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-relative-pronouns',
  standalone: true,
  imports: [],
  templateUrl: './relative-pronouns.component.html',
  styleUrl: './relative-pronouns.component.scss',
})
export class RelativePronounsComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Pronombres relativos';
  override title = 'Pronombres relativos';
}
