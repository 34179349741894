<div class="privacy-policy">
  <div class="textos">
    <h1>Políticas de Privacidad</h1>
    <p>Última actualización: 17 de noviembre de 2024</p>

    <section>
      <h2>Introducción</h2>
      <p>
        Bienvenido a <strong>Cursos de Inglés online</strong>. En este sitio web
        respetamos tu privacidad al 100%. Nuestro propósito es ofrecer contenido
        educativo sobre el aprendizaje del idioma inglés de manera clara,
        accesible y sin recopilar datos directamente de los usuarios.
      </p>
    </section>

    <!--  <section>
      <h2>Google AdSense</h2>
      <p>
        Este sitio utiliza <strong>Google AdSense</strong> para mostrar
        anuncios. Google puede recopilar información como la dirección IP, el
        tipo de navegador, datos demográficos y de intereses de los usuarios
        mediante cookies o tecnologías similares.
      </p>
      <p>
        Estas cookies permiten a Google personalizar los anuncios que ves en
        función de tus intereses y analizar el rendimiento de la publicidad.
        Para más información, visita la
        <a
          href="https://policies.google.com/technologies/partner-sites"
          target="_blank"
          rel="noopener"
        >
          Política de Privacidad de Google</a
        >.
      </p>
    </section>-->

    <section>
      <h2>Gestión de Cookies</h2>
      <p>Este sitio no utliza cookies</p>
      <!--  <p>
        Los usuarios pueden optar por desactivar la personalización de anuncios
        accediendo a
        <a
          href="https://adssettings.google.com/"
          target="_blank"
          rel="noopener"
        >
          Configuración de anuncios de Google</a
        >. También puedes bloquear o gestionar cookies desde la configuración de
        tu navegador.
      </p>
      -->
    </section>

    <section>
      <h2>Propósito Educativo</h2>
      <p>
        El contenido de este sitio tiene un propósito estrictamente educativo.
        No solicitamos información ni interactuamos con servicios externos que
        puedan recopilar datos
        <!--, excepto por el uso de Google AdSense para la
        visualización de anuncios -->.
      </p>
    </section>

    <section>
      <h2>Sin Enlaces a Otros Sitios</h2>
      <p>
        Todo el contenido disponible en
        <strong>Cursos de Inglés online</strong> es creado por nosotros y no
        incluye enlaces a sitios externos
        <!--, salvo las referencias mencionadas a
        servicios de Google-->.
      </p>
    </section>

    <section>
      <h2>Actualizaciones</h2>
      <p>
        Estas políticas podrían actualizarse en el futuro para reflejar cambios
        en nuestro sitio web o para cumplir con nuevas regulaciones. En caso de
        cambios significativos, se informará mediante un aviso destacado en esta
        página.
      </p>
    </section>

    <section>
      <h2>Contacto</h2>
      <p>
        Si tienes preguntas o comentarios sobre estas políticas, no dudes en
        contactarnos a través de nuestra página de contacto.
      </p>
    </section>
  </div>
</div>
