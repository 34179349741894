import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-prefixes-and-suffixes',
  standalone: true,
  imports: [],
  templateUrl: './prefixes-and-suffixes.component.html',
  styleUrl: './prefixes-and-suffixes.component.scss',
})
export class PrefixesAndSuffixesComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Prefixes and suffixes';
  override title = 'Prefixes and suffixes';
}
