import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-palabras-claves',
  standalone: true,
  imports: [],
  templateUrl: './palabras-claves.component.html',
  styleUrl: './palabras-claves.component.scss',
})
export class PalabrasClavesComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Use of Already, Just, Still...';
  override title = 'Use of Already, Just, Still, Yet, For, Since y Ago';
}
