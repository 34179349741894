<div class="leccionAlfabeto">
  <span class="explicacion">
    El abecedario en inglés consta de 26 letras. Estas letras se dividen en 5
    vocales y 21 consonantes. La correcta pronunciación de cada letra es
    fundamental para quienes están aprendiendo a hablar y escribir en inglés, ya
    que es clave para dominar el idioma y poder comunicarse de manera efectiva
    con los demás.
  </span>
  <h2 class="explicacion">
    A continuacion, se detalla cada una de ellas con su pronunciacion
  </h2>

  <div class="card-container">
    <mat-card *ngFor="let item of letters" class="card">
      <mat-card-content>
        <mat-card-title>{{ item.letter }}</mat-card-title>
        <mat-card-subtitle class="pronunciacion">{{
          item.pronunciacion
        }}</mat-card-subtitle>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="section-blanco">
    <h2>Ejemplos de Deletreo (Examples of Spelling)</h2>
    <table>
      <thead>
        <tr>
          <th>Word (Palabra)</th>
          <th>Spelling (Letras)</th>
          <th>Pronunciation (Pronunciacion)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="word">Dog</td>
          <td class="letters">D - O - G</td>
          <td class="pronunciation">Di - Ou - Yi</td>
        </tr>
        <tr>
          <td class="word">Cat</td>
          <td class="letters">C - A - T</td>
          <td class="pronunciation">Ci - Ei - Ti</td>
        </tr>
        <tr>
          <td class="word">Lucas</td>
          <td class="letters">L - U - C - A - S</td>
          <td class="pronunciation">El - Iu - Ci - Ei - Es</td>
        </tr>
        <tr>
          <td class="word">Buenos</td>
          <td class="letters">B - U - E - N - O - S</td>
          <td class="pronunciation">Bi - Iu - I - En - Ou - Es</td>
        </tr>
        <tr>
          <td class="word">Apple</td>
          <td class="letters">A - P - P - L - E</td>
          <td class="pronunciation">Ei - Pi - Pi - El - I</td>
        </tr>
        <tr>
          <td class="word">Happy</td>
          <td class="letters">H - A - P - P - Y</td>
          <td class="pronunciation">Eich - Ei - Pi - Pi - Uai</td>
        </tr>
        <tr>
          <td class="word">School</td>
          <td class="letters">S - C - H - O - O - L</td>
          <td class="pronunciation">Es - Ci - Eich - Ou - Ou - El</td>
        </tr>
        <tr>
          <td class="word">Friend</td>
          <td class="letters">F - R - I - E - N - D</td>
          <td class="pronunciation">Ef - Ar - Ai - I - En - Di</td>
        </tr>
        <tr>
          <td class="word">Window</td>
          <td class="letters">W - I - N - D - O - W</td>
          <td class="pronunciation">Dabliu - Ai - En - Di - Ou - Dabliu</td>
        </tr>
        <tr>
          <td class="word">Orange</td>
          <td class="letters">O - R - A - N - G - E</td>
          <td class="pronunciation">Ou - Ar - Ei - En - Yi - I</td>
        </tr>
        <tr>
          <td class="word">Table</td>
          <td class="letters">T - A - B - L - E</td>
          <td class="pronunciation">Ti - Ei - Bi - El - I</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
