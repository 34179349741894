import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-demostrativo',
  templateUrl: './demostrativo.component.html',
  styleUrl: './demostrativo.component.scss',
})
export class DemostrativoComponent extends LeccionBaseComponent {
  override descripcionMenu = 'This - These - That - Those';
  override title = 'Demostrativos - uso de This/These/That/Those';
}
