import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-phrasal-prepositional-verbs',
  standalone: true,
  imports: [],
  templateUrl: './phrasal-prepositional-verbs.component.html',
  styleUrl: './phrasal-prepositional-verbs.component.scss',
})
export class PhrasalPrepositionalVerbsComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Phrasal/prepositional verbs';
  override title = 'Phrasal and prepositional verbs';
}
