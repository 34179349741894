<div class="conjunctions">
  <p>
    Las conjunciones en inglés son palabras que unen palabras, frases u
    oraciones. Existen tres tipos principales: conjunciones coordinantes,
    conjunciones subordinantes y conjunciones correlativas. A continuación, se
    explican estos tipos y se muestran ejemplos de cada uno.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Conjunciones Coordinantes (Coordinating Conjunctions)</h2>
      <p>
        Estas conjunciones unen palabras, frases u oraciones independientes. Son
        las más comunes y se pueden recordar con el acrónimo
        <strong>FANBOYS</strong>: For, And, Nor, But, Or, Yet, So.
      </p>

      <table>
        <tr>
          <th>Conjunción</th>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td><strong>and</strong> (y)</td>
          <td>
            She can read Spanish, <strong>and</strong> she can also speak
            French.
          </td>
          <td>
            Ella puede leer español, <strong>y</strong> también puede hablar
            francés.
          </td>
        </tr>
        <tr>
          <td><strong>but</strong> (pero)</td>
          <td>I wanted to go out, <strong>but</strong> it started raining.</td>
          <td>Quería salir, <strong>pero</strong> comenzó a llover.</td>
        </tr>
        <tr>
          <td><strong>or</strong> (o)</td>
          <td>Do you want tea <strong>or</strong> coffee?</td>
          <td>¿Quieres té <strong>o</strong> café?</td>
        </tr>
        <tr>
          <td><strong>so</strong> (así que)</td>
          <td>It was late, <strong>so</strong> I went to bed.</td>
          <td>Era tarde, <strong>así que</strong> me fui a la cama.</td>
        </tr>
        <tr>
          <td><strong>nor</strong> (ni)</td>
          <td>
            He doesn’t like ice cream, <strong>nor</strong> does he like cake.
          </td>
          <td>
            No le gusta el helado, <strong>ni</strong> le gusta el pastel.
          </td>
        </tr>
        <tr>
          <td><strong>yet</strong> (sin embargo)</td>
          <td>He’s rich, <strong>yet</strong> he’s not happy.</td>
          <td>Él es rico, <strong>sin embargo</strong>, no es feliz.</td>
        </tr>
        <tr>
          <td><strong>for</strong> (porque)</td>
          <td>I stayed home, <strong>for</strong> I was feeling ill.</td>
          <td>Me quedé en casa, <strong>porque</strong> me sentía mal.</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>2. Conjunciones Subordinantes (Subordinating Conjunctions)</h2>
      <p>
        Estas conjunciones introducen una oración subordinada, que depende de la
        oración principal. Indican relaciones de causa, tiempo, condición, etc.
      </p>

      <table>
        <tr>
          <th>Conjunción</th>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td><strong>because</strong> (porque)</td>
          <td>
            She stayed at home <strong>because</strong> she was feeling sick.
          </td>
          <td>
            Ella se quedó en casa <strong>porque</strong> se sentía enferma.
          </td>
        </tr>
        <tr>
          <td><strong>although</strong> (aunque)</td>
          <td><strong>Although</strong> it was cold, we went for a walk.</td>
          <td><strong>Aunque</strong> hacía frío, salimos a caminar.</td>
        </tr>
        <tr>
          <td><strong>if</strong> (si)</td>
          <td>He will help you <strong>if</strong> you ask nicely.</td>
          <td>Él te ayudará <strong>si</strong> pides amablemente.</td>
        </tr>
        <tr>
          <td><strong>while</strong> (mientras)</td>
          <td>I read a book <strong>while</strong> she watched TV.</td>
          <td>Leí un libro <strong>mientras</strong> ella veía la tele.</td>
        </tr>
        <tr>
          <td><strong>since</strong> (ya que)</td>
          <td>We haven’t seen them <strong>since</strong> they moved away.</td>
          <td>No los hemos visto <strong>desde que</strong> se mudaron.</td>
        </tr>
        <tr>
          <td><strong>when</strong> (cuando)</td>
          <td>Call me <strong>when</strong> you arrive.</td>
          <td>Llámame <strong>cuando</strong> llegues.</td>
        </tr>
        <tr>
          <td><strong>as</strong> (como)</td>
          <td>He smiled <strong>as</strong> he spoke.</td>
          <td>Él sonrió <strong>mientras</strong> hablaba.</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>3. Conjunciones Correlativas (Correlative Conjunctions)</h2>
      <p>
        Estas conjunciones trabajan en pares y conectan ideas que están
        relacionadas entre sí. Algunos pares de conjunciones correlativas
        comunes son: both...and, either...or, neither...nor, not only...but
        also, whether...or.
      </p>

      <table>
        <tr>
          <th>Conjunción</th>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td><strong>both...and</strong> (tanto...como)</td>
          <td>
            <strong>Both</strong> my brother <strong>and</strong> my sister are
            doctors.
          </td>
          <td>
            <strong>Tanto</strong> mi hermano <strong>como</strong> mi hermana
            son doctores.
          </td>
        </tr>
        <tr>
          <td><strong>either...or</strong> (o...o)</td>
          <td>
            You can have <strong>either</strong> tea <strong>or</strong> coffee.
          </td>
          <td>Puedes tener <strong>o</strong> té <strong>o</strong> café.</td>
        </tr>
        <tr>
          <td><strong>neither...nor</strong> (ni...ni)</td>
          <td>
            She speaks <strong>neither</strong> French
            <strong>nor</strong> Spanish.
          </td>
          <td>
            Ella no habla <strong>ni</strong> francés
            <strong>ni</strong> español.
          </td>
        </tr>
        <tr>
          <td><strong>not only...but also</strong> (no solo...sino también)</td>
          <td>
            She is <strong>not only</strong> smart
            <strong>but also</strong> kind.
          </td>
          <td>
            Ella es <strong>no solo</strong> inteligente
            <strong>sino también</strong> amable.
          </td>
        </tr>
        <tr>
          <td><strong>whether...or</strong> (si...o)</td>
          <td>
            I don't know <strong>whether</strong> to go
            <strong>or</strong> stay.
          </td>
          <td>No sé <strong>si</strong> ir <strong>o</strong> quedarme.</td>
        </tr>
        <tr>
          <td><strong>as...as</strong> (tan...como)</td>
          <td>
            She is <strong>as</strong> tall <strong>as</strong> her brother.
          </td>
          <td>
            Ella es <strong>tan</strong> alta <strong>como</strong> su hermano.
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
