import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-future-perfect',
  standalone: true,
  imports: [],
  templateUrl: './future-perfect.component.html',
  styleUrl: './future-perfect.component.scss',
})
export class FuturePerfectComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Future perfect';
  override title = 'Future Perfect';
}
