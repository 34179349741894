<div class="else">
  <p>
    En esta clase, aprenderás a usar <strong>else</strong>, una palabra que en
    inglés se utiliza en diversas expresiones, tanto en preguntas como en
    afirmaciones. <strong>Else</strong> se utiliza para referirse a una cosa o
    persona diferente, en contraste con la que ya se ha mencionado. A
    continuación, exploraremos los usos más comunes de "else" en diferentes
    contextos.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Uso de "Else" en Preguntas y Negaciones</h2>

      <p>
        <strong>Else</strong> se utiliza a menudo en preguntas y oraciones
        negativas para hablar de alternativas o de algo diferente de lo
        mencionado previamente. En general, "else" se refiere a otra persona,
        cosa, lugar o situación distinta.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          Se usa en oraciones interrogativas o negativas para referirse a algo
          distinto o adicional.
        </li>
        <li>
          En preguntas, se usa para ofrecer una opción o para preguntar por algo
          adicional.
        </li>
        <li>
          En negaciones, indica que no hay nada o nadie más disponible o
          relevante.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          Is there <strong>anyone else</strong> coming to the party? (¿Hay
          alguien más viniendo a la fiesta?)
        </li>
        <li>
          We don't have <strong>anything else</strong> to discuss. (No tenemos
          nada más que discutir.)
        </li>
        <li>
          Would you like <strong>anything else</strong> for dinner? (¿Te
          gustaría algo más para la cena?)
        </li>
        <li>
          Is there <strong>anywhere else</strong> we can go? (¿Hay otro lugar
          adonde podamos ir?)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>else</strong> se usa para referirse a algo o
        alguien diferente, ya sea en un contexto de oferta, pregunta o negación.
      </p>
    </div>
    <div class="section-blanco">
      <h2>2. Uso de "Someone Else" (Alguien Más)</h2>

      <p>
        Cuando se usa <strong>someone else</strong>, se hace referencia a una
        persona diferente, distinta a la que se mencionó anteriormente o que se
        espera.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Someone else</strong> se utiliza para hablar de una persona
          distinta a la que se ha mencionado o a la que se espera en una
          situación.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          <strong>Someone else</strong> will answer the phone. (Alguien más
          contestará el teléfono.)
        </li>
        <li>
          I don't want to talk to you, I want to speak with
          <strong>someone else</strong>. (No quiero hablar contigo, quiero
          hablar con otra persona.)
        </li>
        <li>
          If you don't help me, I'll ask <strong>someone else</strong>. (Si no
          me ayudas, le pediré a otra persona.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>someone else</strong> se refiere a una
        persona distinta de la mencionada previamente o esperada.
      </p>
    </div>
    <div class="section-blanco">
      <h2>3. Uso de "Something Else" (Algo Más)</h2>

      <p>
        <strong>Something else</strong> se usa para hablar de una cosa diferente
        o adicional que no ha sido mencionada previamente. Es muy común en
        conversaciones sobre opciones, cambios o alternativas.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Something else</strong> se refiere a otra cosa que no ha sido
          mencionada, o que es distinta a lo esperado o mencionado.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          Do you want <strong>something else</strong> to eat? (¿Quieres algo más
          para comer?)
        </li>
        <li>
          I think we should try <strong>something else</strong>. (Creo que
          deberíamos intentar otra cosa.)
        </li>
        <li>
          If this doesn't work, let's try <strong>something else</strong>. (Si
          esto no funciona, probemos con otra cosa.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>something else</strong> se refiere a una cosa
        distinta de la que se está hablando o que se ha mencionado previamente.
      </p>
    </div>
    <div class="section-blanco">
      <h2>4. Uso de "Anyone Else" (¿Alguien Más?)</h2>

      <p>
        <strong>Anyone else</strong> es utilizado para referirse a otra persona
        dentro de un grupo, o para preguntar si hay más personas involucradas.
        Es una forma común de hablar sobre una alternativa de personas.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Anyone else</strong> se usa para preguntar si hay otras
          personas involucradas o si alguien más está disponible.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          Is there <strong>anyone else</strong> who can help? (¿Hay alguien más
          que pueda ayudar?)
        </li>
        <li>
          Would <strong>anyone else</strong> like to join us? (¿A alguien más le
          gustaría unirse a nosotros?)
        </li>
        <li>
          I don't want to talk to anyone else. (No quiero hablar con nadie más.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>anyone else</strong> se usa para hablar de
        una persona diferente a la mencionada o disponible en una situación.
      </p>
    </div>
    <div class="section-blanco">
      <h2>5. Uso de "Nothing Else" (Nada Más)</h2>

      <p>
        <strong>Nothing else</strong> se utiliza para decir que no hay nada
        adicional o diferente, indicando que no hay más cosas por agregar o
        discutir.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Nothing else</strong> se usa para expresar que no hay nada
          adicional o relevante en la conversación o situación.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          We have <strong>nothing else</strong> to talk about. (No tenemos nada
          más de qué hablar.)
        </li>
        <li>
          There's <strong>nothing else</strong> in the box. (No hay nada más en
          la caja.)
        </li>
        <li>
          I've done everything, there's <strong>nothing else</strong> to do. (He
          hecho todo, no hay nada más por hacer.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>nothing else</strong> se usa para indicar que
        no hay más cosas que considerar o que añadir.
      </p>
    </div>
    <div class="section-blanco">
      <h2>6. Uso de "Anywhere Else" (En Otro Lugar)</h2>

      <p>
        <strong>Anywhere else</strong> se usa para hablar de un lugar distinto
        al mencionado o considerado previamente. Indica que se está buscando una
        alternativa de ubicación.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Anywhere else</strong> se refiere a un lugar distinto, ya sea
          mencionado anteriormente o que se está considerando como una opción
          alternativa.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          Is there <strong>anywhere else</strong> we can go? (¿Hay otro lugar
          adonde podamos ir?)
        </li>
        <li>
          He didn’t want to go anywhere else. (No quería ir a ningún otro
          lugar.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>anywhere else</strong> se usa para referirse
        a un lugar diferente del mencionado o considerado previamente.
      </p>
    </div>
  </div>
</div>
