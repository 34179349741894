<div class="numerosContainer">
  <div>
    <p>
      Los números cardinales o números "comunes" que viste en la sección
      anterior <strong>NÚMEROS (numbers)</strong> son números que indican
      cantidad, pero los <strong>números ordinales (ordinal numbers)</strong> se
      utilizan para indicar la posición de algo o distribución.
    </p>
    <p>
      Se detalla a continuacion un listado de
      <strong>ordinal numbers</strong> hasta la posicion 20th, debajo de la
      sección unos ejemplos de como podrias utilizarlos
    </p>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title
          ><strong
            >Aclaraciones que te ayudaran a aprenderlos mas rapido:</strong
          ></mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <div>
          <p>
            Para los primeros números ordinales (primero, segundo, tercero), se
            usan de forma especial.
          </p>
          <p>
            <span class="highlight">Primero (1st)</span> se dice "First" en
            inglés.
          </p>
          <p>
            <span class="highlight">Segundo (2nd)</span> se dice "Second" en
            inglés.
          </p>
          <p>
            <span class="highlight">Tercero (3rd)</span> se dice "Third" en
            inglés.
          </p>
          <p>
            Estos son únicos y no siguen un patrón, por eso se tratan como casos
            especiales.
          </p>
          <p>
            -------------------------------------------------------------------------------------------------------
          </p>
          <p>
            Para los números ordinales después del tercero (cuarto, quinto,
            sexto, etc.), se usa un patrón regular.
          </p>
          <p>
            Estos números siguen la regla de añadir
            <span class="highlight">-th</span> al número comun.
          </p>
          <p>
            <span class="highlight">Cuarto (4th)</span> se dice "Fourth" en
            inglés.
          </p>
          <p>
            <span class="highlight">Quinto (5th)</span> se dice "Fifth" en
            inglés.
          </p>
          <p>
            <span class="highlight">Sexto (6th)</span> se dice "Sixth" en
            inglés.
          </p>
          <p>
            <span class="highlight">Séptimo (7th)</span> se dice "Seventh" en
            inglés.
          </p>
        </div>
      </mat-card-content>
    </mat-card>
    <table>
      <thead>
        <tr>
          <th>Número</th>
          <th>Escritura</th>
          <th>Pronunciación</th>
          <th>Cómo se dice en Español</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="numerosOrdinales-cell">1st</td>
          <td class="numerosOrdinales-cell">First</td>
          <td class="numerosOrdinales-cell">/fɜrst/ suena como: férst</td>
          <td class="numerosOrdinales-cell">Primero</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">2nd</td>
          <td class="numerosOrdinales-cell">Second</td>
          <td class="numerosOrdinales-cell">/ˈsɛkənd/ suena como: sék-ənd</td>
          <td class="numerosOrdinales-cell">Segundo</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">3rd</td>
          <td class="numerosOrdinales-cell">Third</td>
          <td class="numerosOrdinales-cell">/θɜrd/ suena como: zerd</td>
          <td class="numerosOrdinales-cell">Tercero</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">4th</td>
          <td class="numerosOrdinales-cell">Fourth</td>
          <td class="numerosOrdinales-cell">/fɔrθ/ suena como: fòrtθ</td>
          <td class="numerosOrdinales-cell">Cuarto</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">5th</td>
          <td class="numerosOrdinales-cell">Fifth</td>
          <td class="numerosOrdinales-cell">/fɪfθ/ suena como: fiftθ</td>
          <td class="numerosOrdinales-cell">Quinto</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">6th</td>
          <td class="numerosOrdinales-cell">Sixth</td>
          <td class="numerosOrdinales-cell">/sɪksth/ suena como: siksθ</td>
          <td class="numerosOrdinales-cell">Sexto</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">7th</td>
          <td class="numerosOrdinales-cell">Seventh</td>
          <td class="numerosOrdinales-cell">/ˈsɛvənθ/ suena como: sév-ənθ</td>
          <td class="numerosOrdinales-cell">Séptimo</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">8th</td>
          <td class="numerosOrdinales-cell">Eighth</td>
          <td class="numerosOrdinales-cell">/eɪtθ/ suena como: eitθ</td>
          <td class="numerosOrdinales-cell">Octavo</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">9th</td>
          <td class="numerosOrdinales-cell">Ninth</td>
          <td class="numerosOrdinales-cell">/naɪnθ/ suena como: naintθ</td>
          <td class="numerosOrdinales-cell">Noveno</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">10th</td>
          <td class="numerosOrdinales-cell">Tenth</td>
          <td class="numerosOrdinales-cell">/tɛnθ/ suena como: téntθ</td>
          <td class="numerosOrdinales-cell">Décimo</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">11th</td>
          <td class="numerosOrdinales-cell">Eleventh</td>
          <td class="numerosOrdinales-cell">
            /ɪˈlɛvənθ/ suena como: i-lév-ənθ
          </td>
          <td class="numerosOrdinales-cell">Undécimo</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">12th</td>
          <td class="numerosOrdinales-cell">Twelfth</td>
          <td class="numerosOrdinales-cell">/twɛlfθ/ suena como: tuélfθ</td>
          <td class="numerosOrdinales-cell">Duodécimo</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">13th</td>
          <td class="numerosOrdinales-cell">Thirteenth</td>
          <td class="numerosOrdinales-cell">
            /ˈθɜrˌtinθ/ suena como: zér-tínθ
          </td>
          <td class="numerosOrdinales-cell">Decimotercero</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">14th</td>
          <td class="numerosOrdinales-cell">Fourteenth</td>
          <td class="numerosOrdinales-cell">/ˈfɔrˌtinθ/ suena como: fòrtínθ</td>
          <td class="numerosOrdinales-cell">Decimocuarto</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">15th</td>
          <td class="numerosOrdinales-cell">Fifteenth</td>
          <td class="numerosOrdinales-cell">/ˈfɪfˌtinθ/ suena como: fiftínθ</td>
          <td class="numerosOrdinales-cell">Decimoquinto</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">16th</td>
          <td class="numerosOrdinales-cell">Sixteenth</td>
          <td class="numerosOrdinales-cell">
            /ˈsɪksˌtinθ/ suena como: siks-tínθ
          </td>
          <td class="numerosOrdinales-cell">Decimosexto</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">17th</td>
          <td class="numerosOrdinales-cell">Seventeenth</td>
          <td class="numerosOrdinales-cell">
            /ˌsɛvənˈtinθ/ suena como: sév-ən-tínθ
          </td>
          <td class="numerosOrdinales-cell">Decimoséptimo</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">18th</td>
          <td class="numerosOrdinales-cell">Eighteenth</td>
          <td class="numerosOrdinales-cell">/ˌeɪˈtinθ/ suena como: eit-tínθ</td>
          <td class="numerosOrdinales-cell">Decimoctavo</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">19th</td>
          <td class="numerosOrdinales-cell">Nineteenth</td>
          <td class="numerosOrdinales-cell">
            /ˌnaɪnˈtinθ/ suena como: naint-tínθ
          </td>
          <td class="numerosOrdinales-cell">Decimonoveno</td>
        </tr>
        <tr>
          <td class="numerosOrdinales-cell">20th</td>
          <td class="numerosOrdinales-cell">Twentieth</td>
          <td class="numerosOrdinales-cell">
            /ˈtwɛntiəθ/ suena como: tuénti-eth
          </td>
          <td class="numerosOrdinales-cell">Vigésimo</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <h2>Ejemplos de Números Ordinales en Inglés y su Traducción</h2>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>First</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "This is my
          <strong>first</strong> attempt at baking a cake."
        </p>
        <p>
          <strong>En Español:</strong> "Este es mi primer intento de hacer un
          pastel."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Second</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "She finished in
          <strong>second</strong> place in the competition."
        </p>
        <p>
          <strong>En Español:</strong> "Ella terminó en segundo lugar en la
          competencia."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Third</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>third</strong> chapter of
          the book is very interesting."
        </p>
        <p>
          <strong>En Español:</strong> "El tercer capítulo del libro es muy
          interesante."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Fourth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>fourth</strong> of July is a
          national holiday in the United States."
        </p>
        <p>
          <strong>En Español:</strong> "El cuatro de julio es un día festivo
          nacional en los Estados Unidos."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Fifth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "My birthday is on the
          <strong>fifth</strong> of March."
        </p>
        <p>
          <strong>En Español:</strong> "Mi cumpleaños es el cinco de marzo."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Sixth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>sixth</strong> day of the
          week is Saturday."
        </p>
        <p>
          <strong>En Español:</strong> "El sexto día de la semana es sábado."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Seventh</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>seventh</strong> book in the
          series was released last year."
        </p>
        <p>
          <strong>En Español:</strong> "El séptimo libro de la serie se publicó
          el año pasado."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Eighth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "We will meet on the
          <strong>eighth</strong> of August."
        </p>
        <p>
          <strong>En Español:</strong> "Nos encontraremos el ocho de agosto."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Ninth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "He was the <strong>ninth</strong> person
          to arrive at the party."
        </p>
        <p>
          <strong>En Español:</strong> "Él fue la
          <strong>novena</strong> persona en llegar a la fiesta."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Tenth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "She celebrated her
          <strong>tenth</strong> birthday with a big party."
        </p>
        <p>
          <strong>En Español:</strong> "Ella celebró su
          <strong>décimo</strong> cumpleaños con una gran fiesta."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Eleventh</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>eleventh</strong> hour is
          often associated with a last-minute effort."
        </p>
        <p>
          <strong>En Español:</strong> "La <strong>undécima</strong> hora a
          menudo se asocia con un esfuerzo de última hora."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Twelfth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>twelfth</strong> student to
          finish the exam was very quick."
        </p>
        <p>
          <strong>En Español:</strong> "El <strong>duodécimo</strong> estudiante
          en terminar el examen fue muy rápido."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Thirteenth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "Friday the
          <strong>thirteenth</strong> is considered an unlucky day."
        </p>
        <p>
          <strong>En Español:</strong> "El <strong>viernes trece</strong> se
          considera un día de mala suerte."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Fourteenth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>fourteenth</strong> century
          saw significant changes in Europe."
        </p>
        <p>
          <strong>En Español:</strong> "El <strong>siglo XIV</strong> vio
          cambios significativos en Europa."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Fifteenth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>fifteenth</strong> amendment
          granted voting rights to women."
        </p>
        <p>
          <strong>En Español:</strong> "La
          <strong>enmienda decimoquinta</strong> otorgó el derecho al voto a las
          mujeres."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Sixteenth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "Her sweet <strong>sixteen</strong> party
          was a huge success."
        </p>
        <p>
          <strong>En Español:</strong> "Su fiesta de los
          <strong>dieciséis</strong> años fue un gran éxito."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Seventeenth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>seventeenth</strong> letter
          of the alphabet is Q."
        </p>
        <p>
          <strong>En Español:</strong> "La <strong>decimoséptima</strong> letra
          del alfabeto es la Q."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Eighteenth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "She turned
          <strong>eighteen</strong> last week."
        </p>
        <p>
          <strong>En Español:</strong> "Ella cumplió
          <strong>dieciocho</strong> años la semana pasada."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Nineteenth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>nineteenth</strong> century
          was a time of great industrial progress."
        </p>
        <p>
          <strong>En Español:</strong> "El <strong>siglo XIX</strong> fue una
          época de gran progreso industrial."
        </p>
      </mat-card-content>
    </mat-card>

    <mat-card class="numerosOrdinales-example">
      <mat-card-header>
        <mat-card-title><strong>Twentieth</strong></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p>
          <strong>In English:</strong> "The <strong>twentieth</strong> century
          brought many technological advancements."
        </p>
        <p>
          <strong>En Español:</strong> "El <strong>siglo XX</strong> trajo
          muchos avances tecnológicos."
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
