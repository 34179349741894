<div class="indefinitePronous">
  <p>
    Los pronombres indefinidos se utilizan para referirse a personas, cosas,
    lugares o cantidades de manera no específica. A continuación se presentan
    los pronombres indefinidos organizados por tipos, junto con ejemplos y sus
    traducciones al español.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Pronombres que se refieren a personas:</h2>
      <table>
        <thead>
          <tr>
            <th>Pronombre Indefinido</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción al Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Someone</strong> (alguien)</td>
            <td><strong>Someone</strong> is outside.</td>
            <td><strong>Alguien</strong> está afuera.</td>
          </tr>
          <tr>
            <td><strong>Anyone</strong> (cualquiera, alguien)</td>
            <td>Can <strong>anyone</strong> help me?</td>
            <td>¿Puede <strong>alguien</strong> ayudarme?</td>
          </tr>
          <tr>
            <td><strong>Everyone</strong> (todos)</td>
            <td><strong>Everyone</strong> was happy.</td>
            <td><strong>Todos</strong> estaban felices.</td>
          </tr>
          <tr>
            <td><strong>No one</strong> (nadie)</td>
            <td><strong>No one</strong> answered the phone.</td>
            <td><strong>Nadie</strong> respondió el teléfono.</td>
          </tr>
          <tr>
            <td><strong>Somebody</strong> (alguien)</td>
            <td><strong>Somebody</strong> left their jacket.</td>
            <td><strong>Alguien</strong> dejó su chaqueta.</td>
          </tr>
          <tr>
            <td><strong>Anybody</strong> (cualquiera, alguien)</td>
            <td>Does <strong>anybody</strong> have a pen?</td>
            <td>¿Tiene <strong>alguien</strong> un bolígrafo?</td>
          </tr>
          <tr>
            <td><strong>Everybody</strong> (todos)</td>
            <td><strong>Everybody</strong> loves ice cream.</td>
            <td><strong>A todos</strong> les encanta el helado.</td>
          </tr>
          <tr>
            <td><strong>Nobody</strong> (nadie)</td>
            <td><strong>Nobody</strong> came to the meeting.</td>
            <td><strong>Nadie</strong> vino a la reunión.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>2. Pronombres que se refieren a cosas:</h2>
      <table>
        <thead>
          <tr>
            <th>Pronombre Indefinido</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción al Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Something</strong> (algo)</td>
            <td>I bought <strong>something</strong> for you.</td>
            <td>Compré <strong>algo</strong> para ti.</td>
          </tr>
          <tr>
            <td><strong>Anything</strong> (cualquier cosa, algo)</td>
            <td>Do you need <strong>anything</strong> from the store?</td>
            <td>¿Necesitas <strong>algo</strong> de la tienda?</td>
          </tr>
          <tr>
            <td><strong>Everything</strong> (todo)</td>
            <td><strong>Everything</strong> is fine.</td>
            <td><strong>Todo</strong> está bien.</td>
          </tr>
          <tr>
            <td><strong>Nothing</strong> (nada)</td>
            <td>There’s <strong>nothing</strong> to worry about.</td>
            <td>No hay <strong>nada</strong> de qué preocuparse.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>3. Pronombres que se refieren a lugares:</h2>
      <table>
        <thead>
          <tr>
            <th>Pronombre Indefinido</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción al Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Somewhere</strong> (algún lugar)</td>
            <td>I left my keys <strong>somewhere</strong> in the house.</td>
            <td>Dejé mis llaves en <strong>algún lugar</strong> de la casa.</td>
          </tr>
          <tr>
            <td><strong>Anywhere</strong> (cualquier lugar)</td>
            <td>We can go <strong>anywhere</strong> you want.</td>
            <td>Podemos ir a <strong>cualquier lugar</strong> que quieras.</td>
          </tr>
          <tr>
            <td><strong>Everywhere</strong> (en todas partes)</td>
            <td>I've looked <strong>everywhere</strong> for my phone.</td>
            <td>He buscado en <strong>todas partes</strong> mi teléfono.</td>
          </tr>
          <tr>
            <td><strong>Nowhere</strong> (en ningún lugar)</td>
            <td>There’s <strong>nowhere</strong> to sit.</td>
            <td>No hay <strong>ningún lugar</strong> para sentarse.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>4. Pronombres que se refieren a cantidad:</h2>
      <table>
        <thead>
          <tr>
            <th>Pronombre Indefinido</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción al Español</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>All</strong> (todo/todos)</td>
            <td><strong>All</strong> of them are here.</td>
            <td><strong>Todos</strong> están aquí.</td>
          </tr>
          <tr>
            <td><strong>None</strong> (ninguno)</td>
            <td><strong>None</strong> of the books are mine.</td>
            <td><strong>Ninguno</strong> de los libros es mío.</td>
          </tr>
          <tr>
            <td><strong>Many</strong> (muchos)</td>
            <td><strong>Many</strong> people like to travel.</td>
            <td><strong>A muchas</strong> personas les gusta viajar.</td>
          </tr>
          <tr>
            <td><strong>Few</strong> (pocos)</td>
            <td><strong>Few</strong> students passed the test.</td>
            <td><strong>Pocos</strong> estudiantes aprobaron el examen.</td>
          </tr>
          <tr>
            <td><strong>Several</strong> (varios)</td>
            <td><strong>Several</strong> of my friends are coming.</td>
            <td><strong>Varios</strong> de mis amigos vienen.</td>
          </tr>
          <tr>
            <td><strong>Some</strong> (algunos)</td>
            <td><strong>Some</strong> people don't like coffee.</td>
            <td><strong>A algunas</strong> personas no les gusta el café.</td>
          </tr>
          <tr>
            <td><strong>Any</strong> (alguno, cualquiera)</td>
            <td>Do you have <strong>any</strong> money left?</td>
            <td>¿Te queda <strong>algo</strong> de dinero?</td>
          </tr>
          <tr>
            <td><strong>Both</strong> (ambos)</td>
            <td><strong>Both</strong> of them are teachers.</td>
            <td><strong>Ambos</strong> son profesores.</td>
          </tr>
          <tr>
            <td><strong>Either</strong> (cualquiera de los dos)</td>
            <td><strong>Either</strong> option is fine.</td>
            <td><strong>Cualquiera</strong> de las dos opciones está bien.</td>
          </tr>
          <tr>
            <td><strong>Neither</strong> (ninguno de los dos)</td>
            <td><strong>Neither</strong> choice is wrong.</td>
            <td><strong>Ninguna</strong> de las elecciones es incorrecta.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
