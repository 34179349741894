import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-pronombres',
  templateUrl: './pronombres.component.html',
  styleUrl: './pronombres.component.scss',
})
export class PronombresComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Pronombres';
  override title = 'Pronombres';
}
