import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-neither-vs-either',
  standalone: true,
  imports: [],
  templateUrl: './neither-vs-either.component.html',
  styleUrl: './neither-vs-either.component.scss',
})
export class NeitherVsEitherComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Neither vs. Either';
  override title = 'Neither vs. Either';
}
