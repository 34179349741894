<div class="to-get">
  <p>
    El verbo "<strong>to get</strong>" es uno de los más usados en inglés y
    tiene muchos significados según el contexto. A continuación, encontrarás los
    usos más comunes de este verbo junto con ejemplos y explicaciones.
  </p>
  <div class="section">
    <div class="section-blanco">
      <p>
        <strong>Nota:</strong> "<strong>to get</strong>" es un verbo irregular.
        Su forma en pasado y participio pasado es "<strong>got</strong>".
      </p>

      <h2>“To get” + Objeto Directo</h2>
      <p>
        Cuando "<strong>to get</strong>" se usa con un objeto directo, puede
        tener diferentes significados como recibir, obtener, comprar, ganar,
        cobrar, o traer.
      </p>

      <h3>To Receive (Recibir)</h3>
      <p>Este uso indica que alguien ha recibido algo.</p>
      <table border="1">
        <tr>
          <td>
            <strong>Did you get</strong> my message this morning? (¿Recibiste mi
            mensaje esta mañana?)
          </td>
        </tr>
        <tr>
          <td>
            <strong>I got</strong> the package I was waiting for. (Recibí el
            paquete que estaba esperando.)
          </td>
        </tr>
      </table>

      <h3>To Obtain (Conseguir/Obtener)</h3>
      <p>Utilizado para hablar de algo que hemos conseguido o alcanzado.</p>
      <table border="1">
        <tr>
          <td>
            <strong>Emma got</strong> a scholarship for college. (Emma consiguió
            una beca para la universidad.)
          </td>
        </tr>
        <tr>
          <td>
            <strong>We got</strong> tickets to the concert! (¡Conseguimos
            boletos para el concierto!)
          </td>
        </tr>
      </table>

      <h3>To Buy (Comprar)</h3>
      <p>Este uso se refiere a comprar algo.</p>
      <table border="1">
        <tr>
          <td>
            <strong>Did you get</strong> the groceries I asked for? (¿Compraste
            los víveres que pedí?)
          </td>
        </tr>
        <tr>
          <td>
            <strong>She got</strong> a new laptop for her studies. (Compró una
            laptop nueva para sus estudios.)
          </td>
        </tr>
      </table>

      <h3>To Earn/Charge (Ganar/Cobrar)</h3>
      <p>
        Este significado se usa para hablar de cuánto se gana o cobra por algo.
      </p>
      <table border="1">
        <tr>
          <td>
            <strong>How much do you get</strong> for each sale? (¿Cuánto cobras
            por cada venta?)
          </td>
        </tr>
        <tr>
          <td>
            <strong>She gets</strong> a good salary at her new job. (Gana un
            buen sueldo en su nuevo trabajo.)
          </td>
        </tr>
      </table>

      <h3>To Fetch (Traer/Recoger)</h3>
      <p>Se utiliza para pedir o hablar de traer algo.</p>
      <table border="1">
        <tr>
          <td>
            <strong>Can you get</strong> me some water? (¿Puedes traerme un poco
            de agua?)
          </td>
        </tr>
        <tr>
          <td>
            <strong>He got</strong> his friend from the airport. (Recogió a su
            amigo del aeropuerto.)
          </td>
        </tr>
      </table>

      <h2>“To get” + Expresión de Lugar</h2>
      <p>
        Cuando "<strong>to get</strong>" se usa con una expresión de lugar,
        indica llegar o alcanzar.
      </p>

      <h3>To Arrive (Llegar)</h3>
      <p>Utilizado para hablar de la llegada a algún lugar.</p>
      <table border="1">
        <tr>
          <td>
            <strong>What time did you get</strong> to the meeting? (¿A qué hora
            llegaste a la reunión?)
          </td>
        </tr>
        <tr>
          <td>
            <strong>She got</strong> to the office early today. (Llegó a la
            oficina temprano hoy.)
          </td>
        </tr>
      </table>

      <h3>To Reach (Alcanzar)</h3>
      <p>Este uso se refiere a alcanzar algo o algún lugar.</p>
      <table border="1">
        <tr>
          <td>
            <strong>I can't get</strong> the book from the top shelf. (No puedo
            alcanzar el libro del estante superior.)
          </td>
        </tr>
        <tr>
          <td>
            <strong>Did you get</strong> to the mountain peak? (¿Alcanzaste la
            cima de la montaña?)
          </td>
        </tr>
      </table>

      <h2>“To get” + Adjetivo</h2>
      <p>
        Cuando "<strong>to get</strong>" va seguido de un adjetivo, indica un
        cambio de estado o condición.
      </p>

      <h3>To Become (Ponerse/hacerse/volverse)</h3>
      <p>
        Este uso indica que alguien se transforma o experimenta un cambio en sus
        emociones o estado físico.
      </p>
      <table border="1">
        <tr>
          <td>
            <strong>Don't get</strong> upset, it's just a game. (No te pongas
            molesto, es solo un juego.)
          </td>
        </tr>
        <tr>
          <td>
            <strong>I'm getting</strong> sleepy. (Me estoy poniendo soñoliento.)
          </td>
        </tr>
        <tr>
          <td>
            <strong>It’s getting</strong> late; let’s go home. (Se está haciendo
            tarde; vamos a casa.)
          </td>
        </tr>
      </table>

      <h2>“To get” + Preposición o Adverbio</h2>
      <p>
        Existen muchos verbos frasales con "<strong>to get</strong>" que tienen
        significados variados. Aquí algunos de los más comunes.
      </p>

      <h3>To get on/in (Subir)</h3>
      <p>
        Cuando hablamos de subir a un medio de transporte, usamos "<strong
          >to get on</strong
        >" para autobuses, trenes y aviones; y "<strong>to get in</strong>" para
        autos.
      </p>
      <table border="1">
        <tr>
          <td>
            <strong>She got on</strong> the bus at the main stop. (Se subió al
            autobús en la parada principal.)
          </td>
        </tr>
        <tr>
          <td>
            <strong>Get in</strong> the car; it's too cold outside. (Sube al
            auto; hace demasiado frío afuera.)
          </td>
        </tr>
      </table>

      <h3>To get off/out of (Bajar)</h3>
      <p>
        Utilizado para descender de algún medio de transporte o salir de un
        lugar.
      </p>
      <table border="1">
        <tr>
          <td>
            <strong>They got off</strong> the train at the last station.
            (Bajaron del tren en la última estación.)
          </td>
        </tr>
        <tr>
          <td>
            <strong>Get out</strong> of the room, please. (Sal de la habitación,
            por favor.)
          </td>
        </tr>
      </table>

      <h3>To get by (Sobrevivir)</h3>
      <p>
        Este uso se refiere a cómo alguien logra sobrevivir o arreglárselas.
      </p>
      <table border="1">
        <tr>
          <td>
            <strong>She is getting by</strong> with a part-time job. (Está
            sobreviviendo con un trabajo de medio tiempo.)
          </td>
        </tr>
        <tr>
          <td>
            <strong>We can get by</strong> if we are careful with money.
            (Podemos arreglárnoslas si somos cuidadosos con el dinero.)
          </td>
        </tr>
      </table>

      <h3>To get over (Recuperar/Superar)</h3>
      <p>
        Se usa para hablar de superar una situación difícil o recuperarse de una
        enfermedad.
      </p>
      <table border="1">
        <tr>
          <td>
            <strong>He finally got over</strong> his cold. (Finalmente superó su
            resfriado.)
          </td>
        </tr>
        <tr>
          <td>
            <strong>It took her a while to get over</strong> the breakup. (Le
            tomó un tiempo superar la ruptura.)
          </td>
        </tr>
      </table>

      <h3>To get up (Levantarse)</h3>
      <p>Utilizado para hablar de levantarse, generalmente en la mañana.</p>
      <table border="1">
        <tr>
          <td>
            <strong>I get up</strong> at 6:00 a.m. every day. (Me levanto a las
            6:00 a.m. todos los días.)
          </td>
        </tr>
        <tr>
          <td>
            <strong>She gets up</strong> early for work. (Se levanta temprano
            para trabajar.)
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
