import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-indicaciones',
  templateUrl: './indicaciones.component.html',
  styleUrl: './indicaciones.component.scss',
})
export class IndicacionesComponent {
  constructor(public router: Router) {}

  showItem = false;
  onShowItem(showItem) {
    this.showItem = showItem;
  }
  goHome() {
    this.router.navigate(['']);
  }

  goNext() {
    this.router.navigate(['/profile']);
  }
}
