<div class="even">
  <p>
    En esta clase, aprenderás a usar <strong>even</strong>, una palabra que
    tiene varios significados y usos en inglés. También exploraremos expresiones
    comunes como <strong>even though</strong>, <strong>even if</strong>,
    <strong>even when</strong>, <strong>even so</strong>, y cómo se usan para
    añadir énfasis o expresar contradicciones y condiciones. A continuación, se
    detallan los diferentes casos en los que se utiliza "even" y sus
    combinaciones con otras palabras.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Uso de "Even" (Adverbio)</h2>

      <p>
        <strong>Even</strong> se utiliza como un adverbio de énfasis, para
        indicar sorpresa o para enfatizar algo que es inusual o inesperado. Es
        comúnmente utilizado para hablar de situaciones que son extremas o que
        están fuera de lo común.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          Se usa para mostrar que algo es sorprendente o más allá de lo
          esperado.
        </li>
        <li>
          Normalmente, se coloca antes de un adjetivo, adverbio o sustantivo en
          una oración.
        </li>
        <li>
          Se utiliza para indicar una comparación que va más allá de lo
          esperado.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          <strong>Even</strong> the children were tired after the long trip.
          (Incluso los niños estaban cansados después del largo viaje.)
        </li>
        <li>
          He <strong>even</strong> finished his homework before dinner. (Incluso
          terminó su tarea antes de la cena.)
        </li>
        <li>
          She can <strong>even</strong> speak three languages fluently. (Ella
          incluso puede hablar tres idiomas con fluidez.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>even</strong> enfatiza que la situación es
        inesperada o sorprendente, más allá de lo habitual o esperado.
      </p>
    </div>
    <div class="section-blanco">
      <h2>2. Uso de "Even though" (Aunque)</h2>

      <p>
        <strong>Even though</strong> es una conjunción utilizada para mostrar
        contradicción o para introducir una idea que es contraria a lo que se
        esperaría. Se traduce como "aunque" o "a pesar de que". Es útil cuando
        se quiere expresar que algo ocurre a pesar de las circunstancias que
        indicarían lo contrario.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Even though</strong> introduce una oración subordinada que
          refleja una contradicción o algo que es inesperado.
        </li>
        <li>
          Se usa para mostrar que, a pesar de que algo debería haber impedido
          una acción, esa acción todavía ocurre.
        </li>
        <li>
          Se coloca antes de una oración que da detalles sobre una condición
          contraria o inesperada.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          <strong>Even though</strong> it was raining, they went for a walk.
          (Aunque estaba lloviendo, salieron a caminar.)
        </li>
        <li>
          <strong>Even though</strong> she was tired, she finished her work.
          (Aunque estaba cansada, terminó su trabajo.)
        </li>
        <li>
          He didn't help <strong>even though</strong> he knew it was important.
          (No ayudó aunque sabía que era importante.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>even though</strong> introduce una situación
        contraria a la expectativa. La oración principal muestra lo que
        realmente sucedió, a pesar de que la situación mencionada en la oración
        subordinada era contraria.
      </p>
    </div>
    <div class="section-blanco">
      <h2>3. Uso de "Even if" (Incluso si)</h2>

      <p>
        <strong>Even if</strong> es una expresión similar a "even though", pero
        se usa en contextos condicionales. Se utiliza para expresar una
        situación hipotética, es decir, para hablar de algo que podría ocurrir
        en el futuro, aunque no se está seguro de que suceda.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Even if</strong> introduce una condición que podría no
          suceder, pero en la que una acción seguiría ocurriendo sin importar lo
          que pase.
        </li>
        <li>
          Se usa para expresar una acción o decisión que no cambia, incluso si
          la situación es incierta o hipotética.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          I will go to the park <strong>even if</strong> it rains. (Iré al
          parque incluso si llueve.)
        </li>
        <li>
          She will help you <strong>even if</strong> she is very busy. (Ella te
          ayudará aunque esté muy ocupada.)
        </li>
        <li>
          They will stay at the hotel <strong>even if</strong> the weather is
          bad. (Ellos se quedarán en el hotel aunque el clima sea malo.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>even if</strong> se utiliza para señalar que
        la acción principal no cambiará independientemente de la condición que
        se menciona.
      </p>
    </div>
    <div class="section-blanco">
      <h2>4. Uso de "Even when" (Incluso cuando)</h2>

      <p>
        <strong>Even when</strong> se usa para hablar de situaciones que son
        sorprendentes o inusuales en un contexto temporal. Se refiere a un
        momento específico en el tiempo cuando algo sucede a pesar de lo que
        normalmente se esperaría en esa situación.
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Even when</strong> introduce un momento específico en el
          tiempo que es sorprendente o inesperado.
        </li>
        <li>
          Se utiliza para referirse a una situación temporal en la que algo
          sucede, aunque las circunstancias sean inusuales.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          She smiles <strong>even when</strong> she is upset. (Ella sonríe
          incluso cuando está molesta.)
        </li>
        <li>
          He works hard <strong>even when</strong> no one is watching. (Él
          trabaja duro incluso cuando nadie lo está mirando.)
        </li>
        <li>
          They are happy <strong>even when</strong> they face difficulties. (Son
          felices incluso cuando enfrentan dificultades.)
        </li>
      </ul>

      <p>
        En estos ejemplos, <strong>even when</strong> se usa para mostrar que
        algo ocurre en circunstancias sorprendentes o inusuales, en momentos
        específicos que normalmente se esperaría que no sucedieran.
      </p>
    </div>
    <div class="section-blanco">
      <h2>5. Uso de "Even so" (Aún así)</h2>

      <p>
        <strong>Even so</strong> se utiliza para introducir una oración que
        muestra que, a pesar de una situación contraria o inesperada, la acción
        o el resultado sigue siendo el mismo. Se traduce como "aún así" o "sin
        embargo".
      </p>

      <h3>Reglas:</h3>
      <ul>
        <li>
          <strong>Even so</strong> introduce una acción que sigue ocurriendo a
          pesar de una circunstancia inesperada.
        </li>
        <li>
          Es similar a "however" y se usa cuando la situación parece ir en
          contra de la lógica, pero aún así algo ocurre.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          She didn't study for the exam, <strong>even so</strong>, she passed.
          (Ella no estudió para el examen, aún así, aprobó.)
        </li>
        <li>
          It was raining heavily, <strong>even so</strong>, they went hiking.
          (Estaba lloviendo mucho, aún así, fueron de excursión.)
        </li>
        <li>
          He was tired, <strong>even so</strong>, he kept working. (Estaba
          cansado, aún así, siguió trabajando.)
        </li>
      </ul>
    </div>
  </div>
</div>
