<div class="already-just-still-yet-for-since-ago">
  <p>
    Estas palabras ayudan a expresar cuándo ocurre o ha ocurrido una acción, y
    cada una tiene un uso específico que se adapta al contexto.
  </p>
  <div class="section">
    <div class="section-blanco">
      <table>
        <tr>
          <th>Palabra</th>
          <th>Significado</th>
          <th>Uso</th>
          <th>Ejemplo</th>
        </tr>
        <tr>
          <td><strong>Already</strong></td>
          <td>Ya</td>
          <td>
            Se usa en afirmaciones para indicar que algo ha sucedido antes de lo
            esperado.
          </td>
          <td>
            "I have <strong>already</strong> finished my homework." (Ya he
            terminado mi tarea)
          </td>
        </tr>
        <tr>
          <td><strong>Just</strong></td>
          <td>Justo, recién</td>
          <td>
            Se usa en afirmaciones para indicar que algo ha ocurrido hace muy
            poco.
          </td>
          <td>
            "She has <strong>just</strong> arrived." (Ella acaba de llegar)
          </td>
        </tr>
        <tr>
          <td><strong>Still</strong></td>
          <td>Todavía, aún</td>
          <td>
            Se usa en afirmaciones y preguntas para mostrar que algo continúa
            sucediendo.
          </td>
          <td>
            "He <strong>still</strong> lives with his parents." (Él todavía vive
            con sus padres)
          </td>
        </tr>
        <tr>
          <td><strong>Yet</strong></td>
          <td>Todavía, aún</td>
          <td>
            Se usa en preguntas y negativas para indicar que algo aún no ha
            sucedido, pero se espera que suceda.
          </td>
          <td>
            "I haven’t finished <strong>yet</strong>." (No he terminado aún)
          </td>
        </tr>
        <tr>
          <td><strong>For</strong></td>
          <td>Durante, por</td>
          <td>Se usa para expresar la duración de un período de tiempo.</td>
          <td>
            "I have lived here <strong>for</strong> five years." (He vivido aquí
            durante cinco años)
          </td>
        </tr>
        <tr>
          <td><strong>Since</strong></td>
          <td>Desde</td>
          <td>
            Se usa para indicar el punto de inicio de una acción que continúa
            hasta el presente.
          </td>
          <td>
            "She has worked here <strong>since</strong> 2010." (Ella ha
            trabajado aquí desde 2010)
          </td>
        </tr>
        <tr>
          <td><strong>Ago</strong></td>
          <td>Hace</td>
          <td>
            Se usa para expresar un momento en el pasado en relación con el
            presente.
          </td>
          <td>
            "I moved here two years <strong>ago</strong>." (Me mudé aquí hace
            dos años)
          </td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h3>Ejemplos de Already, Just, Still, Yet, For, Since y Ago</h3>
      <table border="1">
        <tr>
          <th>Ejemplo en Inglés</th>
          <th>Traducción al Español</th>
        </tr>
        <tr>
          <td>I have <strong>already</strong> seen that movie.</td>
          <td>Ya he visto esa película.</td>
        </tr>
        <tr>
          <td>She has <strong>just</strong> finished her report.</td>
          <td>Ella acaba de terminar su informe.</td>
        </tr>
        <tr>
          <td>He is <strong>still</strong> waiting for the bus.</td>
          <td>Él todavía está esperando el autobús.</td>
        </tr>
        <tr>
          <td>Have you eaten <strong>yet</strong>?</td>
          <td>¿Has comido ya?</td>
        </tr>
        <tr>
          <td>I have lived here <strong>for</strong> three years.</td>
          <td>He vivido aquí durante tres años.</td>
        </tr>
        <tr>
          <td>They have known each other <strong>since</strong> childhood.</td>
          <td>Se conocen desde la infancia.</td>
        </tr>
        <tr>
          <td>She graduated five years <strong>ago</strong>.</td>
          <td>Ella se graduó hace cinco años.</td>
        </tr>
        <tr>
          <td>He has <strong>already</strong> answered the question.</td>
          <td>Él ya ha respondido la pregunta.</td>
        </tr>
        <tr>
          <td>We have <strong>just</strong> arrived at the hotel.</td>
          <td>Acabamos de llegar al hotel.</td>
        </tr>
        <tr>
          <td>Are you <strong>still</strong> working on that project?</td>
          <td>¿Todavía estás trabajando en ese proyecto?</td>
        </tr>
        <tr>
          <td>I haven't finished my homework <strong>yet</strong>.</td>
          <td>No he terminado mi tarea aún.</td>
        </tr>
        <tr>
          <td>He has lived in this city <strong>for</strong> two decades.</td>
          <td>Él ha vivido en esta ciudad durante dos décadas.</td>
        </tr>
        <tr>
          <td>They have been friends <strong>since</strong> high school.</td>
          <td>Han sido amigos desde la secundaria.</td>
        </tr>
        <tr>
          <td>We moved here three months <strong>ago</strong>.</td>
          <td>Nos mudamos aquí hace tres meses.</td>
        </tr>
      </table>
    </div>
  </div>
</div>
