<div class="terms-of-use">
  <h1>Condiciones de Uso</h1>
  <p>Última actualización: 17 de noviembre de 2024</p>

  <section>
    <h2>Introducción</h2>
    <p>
      Bienvenido a <strong>Cursos de Inglés online</strong>. Al acceder a este
      sitio, aceptas las siguientes condiciones de uso. Si no estás de acuerdo
      con estos términos, por favor, no utilices este sitio web.
    </p>
  </section>

  <section>
    <h2>Propósito Educativo</h2>
    <p>
      El contenido de este sitio es exclusivo para fines educativos. Todos los
      textos, imágenes y recursos presentados son propiedad de
      <strong>Cursos de Inglés online</strong> y están protegidos por derechos
      de autor.
    </p>
  </section>

  <section>
    <h2>Prohibición de Copia y Reproducción</h2>
    <p>
      Queda estrictamente prohibida la copia, distribución, modificación o
      cualquier uso no autorizado del contenido de este sitio web sin el
      consentimiento previo por escrito de
      <strong>Cursos de Inglés online</strong>. Esto incluye, pero no se limita
      a, textos, gráficos, diseños y cualquier material disponible en esta
      página.
    </p>
  </section>

  <section>
    <h2>Acceso Responsable</h2>
    <p>
      Al utilizar este sitio, te comprometes a acceder al contenido de forma
      ética y a no realizar actividades que puedan comprometer la seguridad,
      integridad o disponibilidad de nuestra plataforma.
    </p>
  </section>

  <section>
    <h2>Exclusión de Responsabilidad</h2>
    <p>
      El contenido de este sitio se ofrece "tal cual" y no garantizamos la
      exactitud, relevancia o utilidad del material proporcionado.
      <strong>Cursos de Inglés online</strong> no se hace responsable de
      posibles errores, omisiones o interpretaciones derivadas del uso de este
      contenido.
    </p>
  </section>

  <section>
    <h2>Modificaciones</h2>
    <p>
      Nos reservamos el derecho de modificar estas condiciones de uso en
      cualquier momento. Te recomendamos revisarlas periódicamente para estar
      informado de los cambios.
    </p>
  </section>

  <section>
    <h2>Contacto</h2>
    <p>
      Si tienes preguntas o inquietudes sobre estas condiciones de uso, puedes
      contactarnos a través de nuestra página de contacto.
    </p>
  </section>
</div>
