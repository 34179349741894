<footer>
  <nav>
    <a routerLink="/indicaciones/contacto" routerLinkActive="active"
      >Contacto</a
    >
    <a
      routerLink="/indicaciones/politica-de-privacidad"
      routerLinkActive="active"
      >Políticas de Privacidad</a
    >
    <a routerLink="/indicaciones/condiciones-de-uso" routerLinkActive="active"
      >Términos y Condiciones</a
    >
  </nav>
  <p>&copy; 2024 Curso de inglés online. Todos los derechos reservados.</p>
</footer>
