<div class="future-perfect-continuous">
  <p>
    El <strong>Future Perfect Continuous</strong> en inglés se usa para expresar
    que una acción estará en progreso y se habrá completado hasta cierto punto
    en el futuro. Este tiempo verbal se enfoca tanto en la duración de la acción
    como en el hecho de que continuará hasta ese momento específico.
  </p>
  <div class="section">
    <div class="section-blanco">
      <table>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Estructura</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Afirmativa</td>
            <td>Sujeto + <strong>will have been</strong> + verbo en -ing</td>
            <td>
              By next year, <strong>I will have been studying</strong> English
              for three years.<br />(Para el próximo año, habré estado
              estudiando inglés por tres años).
            </td>
          </tr>
          <tr>
            <td>Negativa</td>
            <td>
              Sujeto + <strong>will not have been</strong> + verbo en -ing
            </td>
            <td>
              By next week, <strong>she will not have been working</strong> here
              for long.<br />(Para la próxima semana, ella no habrá estado
              trabajando aquí por mucho tiempo).
            </td>
          </tr>
          <tr>
            <td>Interrogativa</td>
            <td>
              <strong>Will</strong> + sujeto + <strong>have been</strong> +
              verbo en -ing?
            </td>
            <td>
              Will <strong>you have been living</strong> in this city for a year
              by December?<br />(¿Habrás estado viviendo en esta ciudad por un
              año para diciembre?)
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Usos</h2>
      <table>
        <thead>
          <tr>
            <th>Uso</th>
            <th>Ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong
                >Para indicar la duración de una acción hasta un momento
                específico en el futuro.</strong
              >
            </td>
            <td>
              By next year, <strong>I will have been studying</strong> English
              for three years.<br />(Para el próximo año, habré estado
              estudiando inglés por tres años).
            </td>
          </tr>
          <tr>
            <td>
              <strong
                >Para enfatizar que una acción estará en progreso hasta un
                momento específico en el futuro.</strong
              >
            </td>
            <td>
              When you arrive, <strong>they will have been cooking</strong> for
              two hours.<br />(Cuando llegues, ellos habrán estado cocinando
              durante dos horas).
            </td>
          </tr>
        </tbody>
      </table>

      <h2>Ejemplos de Oraciones en Afirmativa, Negativa e Interrogativa</h2>
      <table>
        <thead>
          <tr>
            <th>Tipo de oración</th>
            <th>Ejemplo en Future Perfect Continuous</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Afirmativa</td>
            <td>
              By next June, <strong>I will have been working</strong> at this
              company for ten years.<br />(Para el próximo junio, habré estado
              trabajando en esta empresa por diez años).
            </td>
          </tr>
          <tr>
            <td>Negativa</td>
            <td>
              By the time he returns,
              <strong>he will not have been driving</strong> all night.<br />(Para
              cuando él regrese, no habrá estado conduciendo toda la noche).
            </td>
          </tr>
          <tr>
            <td>Interrogativa</td>
            <td>
              Will <strong>you have been studying</strong> for five hours
              straight by the time I arrive?<br />(¿Habrás estado estudiando
              cinco horas seguidas para cuando yo llegue?)
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Ejemplos</h3>
      <table>
        <thead>
          <tr>
            <th>Oración en Inglés</th>
            <th>Traducción al Español</th>
          </tr>
        </thead>
        <tbody>
          <!-- Afirmativas -->
          <tr>
            <td>
              By next June, <strong>I will have been working</strong> at this
              company for ten years.
            </td>
            <td>
              Para el próximo junio, habré estado trabajando en esta empresa por
              diez años.
            </td>
          </tr>
          <tr>
            <td>
              At five o'clock, <strong>you will have been studying</strong> for
              three hours straight.
            </td>
            <td>
              A las cinco en punto, habrás estado estudiando por tres horas
              seguidas.
            </td>
          </tr>
          <tr>
            <td>
              When the guests arrive,
              <strong>we will have been preparing</strong> the meal for over an
              hour.
            </td>
            <td>
              Cuando lleguen los invitados, habremos estado preparando la comida
              por más de una hora.
            </td>
          </tr>
          <tr>
            <td>
              By the time the holidays come,
              <strong>they will have been saving</strong> money for six months.
            </td>
            <td>
              Para las vacaciones, ellos habrán estado ahorrando dinero durante
              seis meses.
            </td>
          </tr>

          <!-- Negativas -->
          <tr>
            <td>
              By tomorrow, <strong>she will not have been working</strong> here
              for a year.
            </td>
            <td>
              Para mañana, ella no habrá estado trabajando aquí por un año.
            </td>
          </tr>
          <tr>
            <td>
              By 8 p.m., <strong>I will not have been waiting</strong> for long.
            </td>
            <td>Para las 8 p.m., no habré estado esperando mucho tiempo.</td>
          </tr>
          <tr>
            <td>
              By the time they arrive,
              <strong>we will not have been discussing</strong> this topic
              anymore.
            </td>
            <td>
              Para cuando ellos lleguen, ya no habremos estado discutiendo este
              tema.
            </td>
          </tr>

          <!-- Interrogativas -->
          <tr>
            <td>
              <strong>Will you have been living</strong> here for five years by
              next March?
            </td>
            <td>
              ¿Habrás estado viviendo aquí por cinco años para el próximo marzo?
            </td>
          </tr>
          <tr>
            <td>
              <strong>Will they have been practicing</strong> for hours when we
              get there?
            </td>
            <td>¿Habrán estado practicando por horas cuando lleguemos?</td>
          </tr>
          <tr>
            <td>
              <strong>Will she have been studying</strong> enough to pass the
              test?
            </td>
            <td>
              ¿Habrá estado estudiando lo suficiente para pasar el examen?
            </td>
          </tr>
          <tr>
            <td>
              <strong>Will he not have been training</strong> for six months by
              then?
            </td>
            <td>
              ¿No habrá estado entrenando durante seis meses para entonces?
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
