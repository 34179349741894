<div class="phrasal-and-prepositional-verbs">
  <p>
    Un <strong>verbo frasal</strong> es una combinación de un verbo y una
    preposición o adverbio que, en conjunto,
    <strong>crea un nuevo significado</strong>. Por ejemplo, en el verbo
    <em>"give up"</em> (rendirse), "give" significa "dar", pero "give up"
    significa "dejar de intentar".
  </p>

  <p>
    Un <strong>verbo preposicional</strong> es un verbo que siempre va
    acompañado de una preposición. A diferencia de los verbos frasales, la
    preposición es esencial para el significado del verbo. Ejemplo:
    <em>"look after"</em> (cuidar), donde "look" significa "mirar", pero "look
    after" significa "cuidar de alguien o algo".
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Diferencias entre verbos frasales y preposicionales</h2>
      <ul>
        <li>
          <strong>Preposición vs Partícula Adverbial:</strong> La preposición
          está unida a un sustantivo o pronombre, mientras que la partícula
          adverbial depende solo del verbo.
        </li>
        <li>
          <strong>Verbos frasales:</strong> Los verbos frasales pueden tener un
          significado diferente al del verbo original, como en "give up"
          (rendirse), donde "give" y "give up" tienen significados distintos.
        </li>
        <li>
          <strong>Verbos preposicionales:</strong> Son verbos que siempre van
          acompañados de una preposición que forma parte del verbo, y el objeto
          siempre va después de la preposición.
        </li>
      </ul>

      <h2>Los 4 Tipos de Verbos Frasales</h2>

      <h3>Tipo 1: Intransitivo</h3>
      <p>
        Los verbos frasales intransitivos no requieren un objeto. Su acción está
        completa sin necesidad de uno.
      </p>
      <ul>
        <li>
          <strong>Ejemplo:</strong> <em>"come back"</em> (volver):
          <em>The cat <strong>came back</strong> home.</em> (El gato volvió a
          casa)
        </li>
      </ul>

      <h4>Ejemplos de Verbos Intransitivos</h4>
      <table border="1">
        <thead>
          <tr>
            <th>Verbo</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>break away</td>
            <td>She tried to <em>break away</em> from the crowd.</td>
            <td>Desprenderse</td>
          </tr>
          <tr>
            <td>come to</td>
            <td>He <em>came to</em> after fainting.</td>
            <td>Recuperar la conciencia</td>
          </tr>
          <tr>
            <td>get up</td>
            <td>She <em>got up</em> early today.</td>
            <td>Levantarse</td>
          </tr>
        </tbody>
      </table>

      <h3>Tipo 2: Transitivo e Inseparable</h3>
      <p>
        Los verbos frasales transitivos e inseparables requieren un objeto, que
        siempre debe ir después del verbo y la partícula. No se pueden separar.
      </p>
      <ul>
        <li>
          <strong>Ejemplo:</strong> <em>"look after"</em> (cuidar):
          <em>She <strong>looks after</strong> her younger siblings.</em> (Ella
          cuida de sus hermanos menores)
        </li>
      </ul>

      <h4>Ejemplos de Verbos Transitivos e Inseparables</h4>
      <table border="1">
        <thead>
          <tr>
            <th>Verbo</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>count on</td>
            <td>We can <em>count on</em> her support.</td>
            <td>Contar con</td>
          </tr>
          <tr>
            <td>run into</td>
            <td>I <em>ran into</em> an old friend yesterday.</td>
            <td>Encontrarse con</td>
          </tr>
        </tbody>
      </table>

      <h3>Tipo 3: Transitivo y Separable</h3>
      <p>
        Estos verbos pueden llevar un objeto después del verbo y la partícula, o
        el objeto puede insertarse entre ambos si es un pronombre.
      </p>
      <ul>
        <li>
          <strong>Ejemplo:</strong> <em>"bring up"</em> (mencionar):
          <em>She <strong>brought up</strong> a new topic.</em> (Ella mencionó
          un nuevo tema)
        </li>
      </ul>

      <h4>Ejemplos de Verbos Transitivos y Separables</h4>
      <table border="1">
        <thead>
          <tr>
            <th>Verbo</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>put off</td>
            <td>We <em>put off</em> the meeting.</td>
            <td>Posponer</td>
          </tr>
          <tr>
            <td>take back</td>
            <td>He <em>took back</em> the statement.</td>
            <td>Retractarse</td>
          </tr>
        </tbody>
      </table>

      <h3>
        Tipo 4: Verbos Preposicionales (Transitivos, dos partículas e
        inseparables)
      </h3>
      <p>
        Estos verbos llevan dos partículas: un adverbio y una preposición. Son
        inseparables y siempre requieren un objeto que sigue a las dos
        partículas.
      </p>
      <ul>
        <li>
          <strong>Ejemplo:</strong> <em>"put up with"</em> (soportar):
          <em>They <strong>put up with</strong> the noise.</em> (Ellos soportan
          el ruido)
        </li>
      </ul>

      <h4>Ejemplos de Verbos Preposicionales</h4>
      <table border="1">
        <thead>
          <tr>
            <th>Verbo</th>
            <th>Ejemplo en Inglés</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>come down with</td>
            <td>He <em>came down with</em> a cold.</td>
            <td>Enfermarse de</td>
          </tr>
          <tr>
            <td>look forward to</td>
            <td>She <em>looks forward to</em> the holidays.</td>
            <td>Esperar con ansias</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
