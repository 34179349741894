<div class="tobe-container">
  <h1>Las Wh- Questions en Presente Simple</h1>

  <p>
    Las <strong>Wh- questions</strong> son preguntas en inglés que comienzan con
    palabras interrogativas como "What", "Where", "When", "Why", entre otras.
    Estas preguntas se utilizan para obtener información específica en lugar de
    una simple respuesta de sí o no.
  </p>

  <div class="section">
    <div class="section-blanco">
      <h2>¿Cuándo se usan las Wh- questions?</h2>

      <p>
        Las Wh- questions se usan en diversas situaciones para preguntar sobre:
      </p>
      <ul>
        <li>
          <strong>Personas</strong>: ¿Quién está haciendo algo? (Who) - Quien?
        </li>
        <li><strong>Cosas</strong>: ¿Qué está ocurriendo? (What) - Que?</li>
        <li><strong>Lugares</strong>: ¿Dónde ocurre algo? (Where) - Donde?</li>
        <li><strong>Tiempo</strong>: ¿Cuándo sucede algo? (When) - Cuando?</li>
        <li>
          <strong>Razones</strong>: ¿Por qué sucede algo? (Why) - Por qué?
        </li>
        <li><strong>Manera</strong>: ¿Cómo ocurre algo? (How) - Como?</li>
      </ul>

      <h2>Significado de cada Wh- word</h2>

      <table>
        <thead>
          <tr>
            <th>Wh- word</th>
            <th>Significado</th>
            <th>Ejemplo en español</th>
            <th>Pregunta en inglés</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>What</strong></td>
            <td>Qué</td>
            <td>¿Qué haces?</td>
            <td>What do you do?</td>
          </tr>
          <tr>
            <td><strong>Where</strong></td>
            <td>Dónde</td>
            <td>¿Dónde vives?</td>
            <td>Where do you live?</td>
          </tr>
          <tr>
            <td><strong>When</strong></td>
            <td>Cuándo</td>
            <td>¿Cuándo estudias?</td>
            <td>When do you study?</td>
          </tr>
          <tr>
            <td><strong>Why</strong></td>
            <td>Por qué</td>
            <td>¿Por qué trabajas?</td>
            <td>Why do you work?</td>
          </tr>
          <tr>
            <td><strong>Who</strong></td>
            <td>Quién</td>
            <td>¿Quién juega?</td>
            <td>Who plays?</td>
          </tr>
          <tr>
            <td><strong>Which</strong></td>
            <td>Cuál/Cuáles</td>
            <td>¿Cuál prefieres?</td>
            <td>Which do you prefer?</td>
          </tr>
          <tr>
            <td><strong>How</strong></td>
            <td>Cómo</td>
            <td>¿Cómo cocinas?</td>
            <td>How do you cook?</td>
          </tr>
        </tbody>
      </table>

      <h2>Ejemplos de preguntas Wh- en presente simple</h2>

      <table>
        <thead>
          <tr>
            <th>Wh- Question</th>
            <th>Auxiliar</th>
            <th>Sujeto</th>
            <th>Verbo (forma base)</th>
            <th>Complemento</th>
            <th>Traducción</th>
            <th>¿Por qué se usa "do" o "does"?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>What</td>
            <td>do</td>
            <td>you</td>
            <td>do</td>
            <td>every day?</td>
            <td>¿Qué haces todos los días?</td>
            <td>
              Se usa "do" porque el sujeto es "you" (segunda persona
              singular/plural).
            </td>
          </tr>
          <tr>
            <td>Where</td>
            <td>does</td>
            <td>he</td>
            <td>live</td>
            <td>?</td>
            <td>¿Dónde vive él?</td>
            <td>
              Se usa "does" porque el sujeto es "he" (tercera persona singular).
            </td>
          </tr>
          <tr>
            <td>When</td>
            <td>do</td>
            <td>they</td>
            <td>work</td>
            <td>in the office?</td>
            <td>¿Cuándo trabajan ellos en la oficina?</td>
            <td>
              Se usa "do" porque el sujeto es "they" (tercera persona plural).
            </td>
          </tr>
          <tr>
            <td>Why</td>
            <td>does</td>
            <td>she</td>
            <td>study</td>
            <td>English?</td>
            <td>¿Por qué estudia ella inglés?</td>
            <td>
              Se usa "does" porque el sujeto es "she" (tercera persona
              singular).
            </td>
          </tr>
          <tr>
            <td>Who</td>
            <td>does</td>
            <td>she</td>
            <td>meet</td>
            <td>on Fridays?</td>
            <td>¿Con quién se encuentra ella los viernes?</td>
            <td>
              Se usa "does" porque el sujeto es "she" (tercera persona
              singular).
            </td>
          </tr>
          <tr>
            <td>How</td>
            <td>do</td>
            <td>you</td>
            <td>cook</td>
            <td>pasta?</td>
            <td>¿Cómo cocinas pasta?</td>
            <td>
              Se usa "do" porque el sujeto es "you" (segunda persona
              singular/plural).
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="section-blanco">
      <h2>Variantes de "How"</h2>
      <p>
        La palabra <strong>how</strong> cambia su significado según el adjetivo
        o sustantivo que la acompañe. A continuación se presentan las variantes
        más comunes con ejemplos y traducciones.
      </p>

      <h2>Tabla de Variantes de "How"</h2>

      <table>
        <thead>
          <tr>
            <th>Variante</th>
            <th>Traducción</th>
            <th>Ejemplo en inglés</th>
            <th>Traducción del ejemplo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>How much</strong></td>
            <td>¿Cuánto? (para cosas incontables)</td>
            <td>How much is this t-shirt?</td>
            <td>¿Cuánto cuesta esta camiseta?</td>
          </tr>
          <tr>
            <td><strong>How much</strong></td>
            <td>¿Cuánto? (para líquidos o sustancias)</td>
            <td>How much water do you drink?</td>
            <td>¿Cuánta agua bebes?</td>
          </tr>
          <tr>
            <td><strong>How many</strong></td>
            <td>¿Cuántos? (para cosas contables)</td>
            <td>How many friends do you have?</td>
            <td>¿Cuántos amigos tienes?</td>
          </tr>
          <tr>
            <td><strong>How many</strong></td>
            <td>¿Cuántos? (para libros, objetos, etc.)</td>
            <td>How many books have you read?</td>
            <td>¿Cuántos libros has leído?</td>
          </tr>
          <tr>
            <td><strong>How often</strong></td>
            <td>¿Con qué frecuencia? / ¿Cuántas veces?</td>
            <td>How often do you go to the gym?</td>
            <td>¿Con qué frecuencia vas al gimnasio?</td>
          </tr>
          <tr>
            <td><strong>How often</strong></td>
            <td>¿Cada cuánto tiempo?</td>
            <td>How often does she go to the cinema?</td>
            <td>¿Cada cuánto tiempo va ella al cine?</td>
          </tr>
          <tr>
            <td><strong>How + adjetivo</strong></td>
            <td>¿Qué tan...? / ¿Cuán...? (para tamaño)</td>
            <td>How big is your car?</td>
            <td>¿Qué tamaño tiene tu coche?</td>
          </tr>
          <tr>
            <td><strong>How + adjetivo</strong></td>
            <td>¿Qué tan...? / ¿Cuán...? (para altura)</td>
            <td>How tall is he?</td>
            <td>¿Cuánto mide él?</td>
          </tr>
          <tr>
            <td><strong>How far</strong></td>
            <td>¿Qué distancia...? / ¿Cuán lejos...?</td>
            <td>How far is it from here to the airport?</td>
            <td>¿Qué distancia hay de aquí al aeropuerto?</td>
          </tr>
          <tr>
            <td><strong>How old</strong></td>
            <td>¿Cuántos años tienes?</td>
            <td>How old are you?</td>
            <td>¿Cuántos años tienes?</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
