<div class="enough-and-too">
  <p>
    En esta clase, aprenderás a usar **"enough"** y **"too"**. Ambas palabras se
    utilizan para indicar cantidad o grado, pero se emplean de manera diferente.
    Te explico cómo utilizarlas correctamente y cómo puedes distinguir su uso en
    distintas situaciones.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Uso de "Enough"</h2>

      <p>
        "Enough" se usa para indicar que algo es **suficiente**. Dependiendo de
        su ubicación en la oración, puede modificar un **sustantivo**, un
        **adjetivo** o un **adverbio**.
      </p>

      <h3>Reglas de "Enough":</h3>
      <ul>
        <li>
          Se usa con **sustantivos** para indicar que la cantidad o el número es
          suficiente.
        </li>
        <li>
          Se usa con **adjetivos** para indicar que algo tiene el grado adecuado
          o suficiente.
        </li>
        <li>
          Se usa con **adverbios** para indicar que algo ocurre con la cantidad
          suficiente.
        </li>
        <li>
          La estructura básica es:
          <strong>enough + sustantivo/adjetivo/adverbio</strong>.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          We don't have <strong>enough</strong> time to finish the project. (No
          tenemos suficiente tiempo para terminar el proyecto.)
        </li>
        <li>
          He isn't <strong>enough</strong> tall to reach the shelf. (Él no es lo
          suficientemente alto para alcanzar la estantería.)
        </li>
        <li>
          She speaks <strong>enough</strong> clearly to be understood. (Ella
          habla lo suficientemente claro para ser entendida.)
        </li>
        <li>
          Do you have <strong>enough</strong> money to buy the ticket? (¿Tienes
          suficiente dinero para comprar el boleto?)
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h2>2. Uso de "Too"</h2>

      <p>
        "Too" se utiliza para indicar que algo es **demasiado**. Se usa para
        expresar una cantidad o grado excesivo, y a menudo tiene una connotación
        negativa.
      </p>

      <h3>Reglas de "Too":</h3>
      <ul>
        <li>
          Se usa con **adjetivos** y **adverbios** para indicar que algo es
          excesivo o más de lo que se considera adecuado o aceptable.
        </li>
        <li>
          La estructura básica es: <strong>too + adjetivo/adverbio</strong>.
        </li>
        <li>
          Se usa con **sustantivos** cuando el sustantivo está acompañado de un
          artículo, para indicar que hay una cantidad excesiva de algo.
        </li>
      </ul>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          The coffee is <strong>too</strong> hot to drink. (El café está
          demasiado caliente para beberlo.)
        </li>
        <li>
          She talks <strong>too</strong> fast for me to understand. (Ella habla
          demasiado rápido para que yo la entienda.)
        </li>
        <li>
          The box is <strong>too</strong> heavy to carry. (La caja está
          demasiado pesada para cargarla.)
        </li>
        <li>
          There is <strong>too</strong> much noise in the room. (Hay demasiado
          ruido en la habitación.)
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h2>3. Diferencias entre "Enough" y "Too"</h2>

      <p>
        La diferencia entre "enough" y "too" radica en el grado de cantidad o
        calidad que indican:
      </p>

      <h3>1. "Enough" indica que algo es suficiente:</h3>
      <ul>
        <li>
          "Enough" significa que la cantidad o el grado es adecuado, suficiente
          para lo que se necesita.
        </li>
        <li>
          Ejemplo: "I have <strong>enough</strong> food for everyone." (Tengo
          suficiente comida para todos.)
        </li>
      </ul>

      <h3>2. "Too" indica que algo es excesivo:</h3>
      <ul>
        <li>
          "Too" indica que algo es más de lo necesario o adecuado, lo cual suele
          tener una connotación negativa, sugiriendo que el exceso es
          problemático o no deseado.
        </li>
        <li>
          Ejemplo: "The movie was <strong>too</strong> long." (La película fue
          demasiado larga.)
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h2>4. Uso de "Enough" y "Too" con adjetivos y adverbios</h2>

      <p>
        Cuando "enough" y "too" se usan con **adjetivos** o **adverbios**, la
        posición en la oración cambia según la palabra que modifican.
      </p>

      <h3>Uso de "Enough" con adjetivos y adverbios:</h3>
      <ul>
        <li>
          Cuando "enough" modifica un adjetivo o un adverbio, generalmente se
          coloca después del adjetivo o adverbio.
        </li>
        <li>
          Ejemplo con adjetivo: "She is strong
          <strong>enough</strong> to lift the box." (Ella es lo suficientemente
          fuerte como para levantar la caja.)
        </li>
        <li>
          Ejemplo con adverbio: "He runs
          <strong>enough</strong> fast to win the race." (Él corre lo
          suficientemente rápido para ganar la carrera.)
        </li>
      </ul>

      <h3>Uso de "Too" con adjetivos y adverbios:</h3>
      <ul>
        <li>
          Cuando "too" modifica un adjetivo o un adverbio, se coloca antes del
          adjetivo o adverbio.
        </li>
        <li>
          Ejemplo con adjetivo: "The movie was
          <strong>too</strong> long." (La película fue demasiado larga.)
        </li>
        <li>
          Ejemplo con adverbio: "She sings
          <strong>too</strong> loudly." (Ella canta demasiado fuerte.)
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h2>5. Uso de "Enough" y "Too" con sustantivos</h2>

      <h3>Uso de "Enough" con sustantivos:</h3>
      <p>
        Cuando "enough" se usa con un sustantivo, se coloca **antes del
        sustantivo**.
      </p>
      <ul>
        <li>
          Ejemplo: "We don't have <strong>enough</strong> chairs for everyone.
          (No tenemos suficientes sillas para todos.)
        </li>
        <li>
          Ejemplo: "Do you have
          <strong>enough</strong> information? (¿Tienes suficiente información?)
        </li>
      </ul>

      <h3>Uso de "Too" con sustantivos:</h3>
      <p>
        Cuando "too" se usa con sustantivos, también se coloca **antes del
        sustantivo** y a menudo se utiliza con artículos (a, an, the) o
        posesivos.
      </p>
      <ul>
        <li>
          Ejemplo: "There are <strong>too</strong> many people in the room. (Hay
          demasiadas personas en la habitación.)
        </li>
        <li>
          Ejemplo: "He ate <strong>too</strong> much cake. (Él comió demasiado
          pastel.)
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h2>
        6. Estructura de "Enough" con "To" (para expresar suficiente para hacer
        algo)
      </h2>

      <p>
        Cuando usas "enough" con un verbo, la estructura es:
        <strong>enough + sustantivo + to + verbo</strong>. Esta construcción se
        utiliza para expresar que algo es lo suficientemente adecuado como para
        realizar una acción.
      </p>

      <h3>Ejemplos:</h3>
      <ul>
        <li>
          She is <strong>strong enough</strong> to carry the box. (Ella es lo
          suficientemente fuerte para cargar la caja.)
        </li>
        <li>
          He has <strong>enough money</strong> to buy the car. (Él tiene
          suficiente dinero para comprar el coche.)
        </li>
      </ul>
    </div>
  </div>
</div>
