import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-past-perfect-continuous',
  standalone: true,
  imports: [],
  templateUrl: './past-perfect-continuous.component.html',
  styleUrl: './past-perfect-continuous.component.scss',
})
export class PastPerfectContinuousComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Past perfect continuous';
  override title = 'Past perfect continuous';
}
