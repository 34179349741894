<div>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #drawer
      class="sidenav"
      fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="showBar ? (isHandset$ | async) === false : showBar"
    >
      <mat-nav-list>
        <h4
          class="header-temas header-temas--{{ buttonMenuActivado }}"
          (click)="goTo(buttonMenuActivado)"
        >
          {{
            buttonMenuActivado == "ejercicio"
              ? "Ejercicios"
              : "Temas Nivel  " + buttonMenuActivado
          }}
        </h4>

        <a
          mat-list-item
          *ngFor="let item of itemsMenuPorNivel"
          [ngClass]="{ 'buttonLink--active': item.path === pathActivo }"
          [href]="item.path"
          class="buttonLink buttonLink--{{ buttonMenuActivado }}"
        >
          {{ item.descripcionMenu }}
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar class="toolbar">
        @if (isHandset$ | async) {
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        }
        <ng-container *ngIf="!!buttonMenuActivado">
          <button
            mat-button
            class="navigation-button"
            (click)="setActive(ButtonMenuType.Principiante)"
            [ngClass]="{
              'navigation-button--small': (isHandset$ | async),
              'navigation-button--active':
                ButtonMenuType.Principiante == buttonMenuActivado,
              'navigation-button--small--active':
                (isHandset$ | async) &&
                ButtonMenuType.Principiante == buttonMenuActivado
            }"
          >
            Principiante
          </button>
          <button
            mat-button
            class="navigation-button"
            (click)="setActive(ButtonMenuType.Intermedio)"
            [ngClass]="{
              'navigation-button--small': (isHandset$ | async),
              'navigation-button--active':
                ButtonMenuType.Intermedio == buttonMenuActivado,
              'navigation-button--small--active':
                (isHandset$ | async) &&
                ButtonMenuType.Intermedio == buttonMenuActivado
            }"
          >
            Intermedio
          </button>
          <button
            mat-button
            class="navigation-button"
            (click)="setActive(ButtonMenuType.Avanzado)"
            [ngClass]="{
              'navigation-button--small': (isHandset$ | async),
              'navigation-button--active':
                ButtonMenuType.Avanzado == buttonMenuActivado,
              'navigation-button--small--active':
                (isHandset$ | async) &&
                ButtonMenuType.Avanzado == buttonMenuActivado
            }"
          >
            Avanzado
          </button></ng-container
        >
        <ng-container *ngIf="!buttonMenuActivado">
          <button
            mat-button
            class="navigation-button"
            (click)="goHome()"
            [ngClass]="{
              'navigation-button--small': (isHandset$ | async)
            }"
          >
            Ir a Home
          </button>
          <button
            mat-button
            class="navigation-button"
            (click)="goLecciones(leccionesService.lastNivelActivo)"
            [ngClass]="{
              'navigation-button--small': (isHandset$ | async),
              'navigation-button--aviso': true
            }"
          >
            Volver a Menu de temas
            {{ leccionesService.lastNivelActivo }}
          </button>
        </ng-container>
      </mat-toolbar>
      <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
