
<div class="preposicions">
   
    <div >
        <p>En inglés, el uso más común de las preposiciones <strong>in</strong>, <strong>on</strong> y <strong>at</strong> se puede dividir en tres áreas principales: lugar, tiempo y estado o condición.</p>
    </div>

    <div class="section">
        <h2>1. Uso en Lugar</h2>        <div class="section-blanco">

        <p>Estas preposiciones se utilizan para describir la ubicación o posición de algo o alguien. Cada una tiene su propio matiz dependiendo de si hablamos de un área cerrada, una superficie o un punto específico.</p>
        <ul>
            <li><strong>In</strong>: Se usa para áreas cerradas o limitadas, como ciudades, países o habitaciones.</li>
            <li><strong>On</strong>: Se utiliza cuando algo está sobre una superficie, como una mesa o una calle.</li>
            <li><strong>At</strong>: Se emplea para señalar un punto concreto o una ubicación específica, como una dirección o un evento.</li>
        </ul>
    </div></div>

    <div class="section">
        <h2>2. Uso en Tiempo</h2><div class="section-blanco">
        <p>Cuando hablamos del tiempo, estas preposiciones se usan para indicar cuándo ocurre algo. La elección de "in", "on" o "at" depende del tipo de referencia temporal que hagamos.</p>
        <ul>
            <li><strong>In</strong>: Se usa para periodos largos de tiempo como meses, años o partes del día.</li>
            <li><strong>On</strong>: Se emplea para días específicos o fechas, como los días de la semana.</li>
            <li><strong>At</strong>: Se usa para momentos específicos o puntuales, como las horas exactas.</li>
        </ul>
    </div></div>

    <div class="section">
        <h2>3. Uso en Estado o Condición</h2>
        <div class="section-blanco">
        <p>Estas preposiciones también pueden indicar el estado o condición de algo o alguien. Son útiles para describir situaciones o posiciones en las que alguien o algo se encuentra.</p>
        <ul>
            <li><strong>In</strong>: Se usa para hablar de un estado, como "in love" (enamorado) o "in danger" (en peligro).</li>
            <li><strong>On</strong>: Puede indicar una condición temporal, como "on vacation" (de vacaciones) o "on duty" (de servicio).</li>
            <li><strong>At</strong>: Se utiliza para señalar situaciones o condiciones particulares, como "at work" (en el trabajo) o "at rest" (en reposo).</li>
        </ul>
    <div >
        <p>En las próximas lecciones veremos en detalle cómo usar <strong>in</strong>, <strong>on</strong>, y <strong>at</strong> en estos contextos, con ejemplos específicos y tips para aprender a diferenciarlos.</p>
    </div></div>    </div>
</div>