<div class="this-that-these-those">
    <p>Los demostrativos en inglés (demonstratives) son palabras que utilizamos para señalar o hacer referencia a algo o alguien. Se dividen en <strong>singular</strong> y <strong>plural</strong>, y en <strong>cercanos</strong> o <strong>lejanos</strong>.</p>
    <div class="section">
        <div class="section-blanco">
    <h2>Demostrativos en Singular</h2>
    <ul>
        <li>
            <strong>This</strong> (esto, esta, este): Se usa para hablar de algo cercano en tiempo o distancia.
            <p class="example">Ejemplo: <em>This book is interesting</em> (Este libro es interesante).</p>
            <p class="example">Ejemplo: <em>This is my phone</em> (Este es mi teléfono).</p>
            <p class="example">Ejemplo: <em>This cake tastes delicious</em> (Este pastel sabe delicioso).</p>
        </li>
        <li>
            <strong>That</strong> (eso, esa, ese, aquello, aquella, aquel): Se usa para hablar de algo que está más lejos.
            <p class="example">Ejemplo: <em>That car is expensive</em> (Aquel coche es caro).</p>
            <p class="example">Ejemplo: <em>That is my friend's house</em> (Aquella es la casa de mi amigo).</p>
            <p class="example">Ejemplo: <em>That mountain is very tall</em> (Aquella montaña es muy alta).</p>
        </li>
    </ul>
</div>
<div class="section-blanco">
    <h2>Demostrativos en Plural</h2>
    <ul>
        <li>
            <strong>These</strong> (estos, estas): Se usa para hablar de cosas cercanas en plural.
            <p class="example">Ejemplo: <em>These shoes are comfortable</em> (Estos zapatos son cómodos).</p>
            <p class="example">Ejemplo: <em>These are my keys</em> (Estas son mis llaves).</p>
            <p class="example">Ejemplo: <em>These flowers smell beautiful</em> (Estas flores huelen hermoso).</p>
        </li>
        <li>
            <strong>Those</strong> (esos, esas, aquellos, aquellas): Se usa para hablar de cosas que están más lejos en plural.
            <p class="example">Ejemplo: <em>Those houses are big</em> (Aquellas casas son grandes).</p>
            <p class="example">Ejemplo: <em>Those are my brother's books</em> (Esos son los libros de mi hermano).</p>
            <p class="example">Ejemplo: <em>Those birds are flying very high</em> (Aquellos pájaros están volando muy alto).</p>
        </li>
    </ul>

    <table>
        <thead>
            <tr>
                <th>Demostrativo</th>
                <th>Singular/Plural</th>
                <th>Cercanía</th>
                <th>Ejemplo</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><strong>This</strong></td>
                <td>Singular</td>
                <td>Cerca</td>
                <td class="example">This coffee is hot (Este café está caliente).</td>
            </tr>
            <tr>
                <td><strong>These</strong></td>
                <td>Plural</td>
                <td>Cerca</td>
                <td class="example">These books are interesting (Estos libros son interesantes).</td>
            </tr>
            <tr>
                <td><strong>That</strong></td>
                <td>Singular</td>
                <td>Lejos</td>
                <td class="example">That building is very tall (Ese edificio es muy alto).</td>
            </tr>
            <tr>
                <td><strong>Those</strong></td>
                <td>Plural</td>
                <td>Lejos</td>
                <td class="example">Those mountains are beautiful (Esas montañas son hermosas).</td>
            </tr>
        </tbody>
    </table>
    <h2>Ejemplos adicionales</h2>
    <ul>
        <li>
            <strong>This</strong> (cerca, singular): <em>This pen is mine.</em> (Este bolígrafo es mío).
        </li>
        <li>
            <strong>That</strong> (lejos, singular): <em>That tree is tall.</em> (Ese árbol es alto).
        </li>
        <li>
            <strong>These</strong> (cerca, plural): <em>These apples are fresh.</em> (Estas manzanas están frescas).
        </li>
        <li>
            <strong>Those</strong> (lejos, plural): <em>Those birds are flying.</em> (Esos pájaros están volando).
        </li>
        <li>
            <strong>These</strong> (cerca, plural): <em>These children are playing in the park.</em> (Estos niños están jugando en el parque).
        </li>
        <li>
            <strong>Those</strong> (lejos, plural): <em>Those people are waiting for the bus.</em> (Aquellas personas están esperando el autobús).
        </li>
    </ul>

</div></div></div>