<div class="sustantivos-container">
    <p>Un sustantivo(noun) es un objeto o cosa, en el diccionario se identifican así: (n)</p>
<div>
    <table>
        <thead>
            <tr>
                <th>Sustantivo</th>
                <th>Pronunciación</th>
                <th>Traducción</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Book</td>
                <td>(buk)</td>
                <td>libro</td>
            </tr>
            <tr>
                <td>Apple</td>
                <td>(apul)</td>
                <td>manzana</td>
            </tr>
            <tr>
                <td>Car</td>
                <td>(kar)</td>
                <td>coche</td>
            </tr>
            <tr>
                <td>Table</td>
                <td>(teibl)</td>
                <td>mesa</td>
            </tr>
            <tr>
                <td>Cat</td>
                <td>(kat)</td>
                <td>gato</td>
            </tr>
            <tr>
                <td>Flower</td>
                <td>(flauar)</td>
                <td>flor</td>
            </tr>
            <tr>
                <td>Bird</td>
                <td>(berd)</td>
                <td>pájaro</td>
            </tr>
            <tr>
                <td>Window</td>
                <td>(windo)</td>
                <td>ventana</td>
            </tr>
            <tr>
                <td>City</td>
                <td>(siti)</td>
                <td>ciudad</td>
            </tr>
            <tr>
                <td>Pizza</td>
                <td>(pitsa)</td>
                <td>pizza</td>
            </tr>
        </tbody>
    </table>
</div> <div class="section">
    <div class="section-blanco">
    <h2>Reglas para la Pluralización</h2>
    <p>Las cosas u objetos se pluralizan con las siguientes reglas:</p>

    <h3>A) Añadir -s a la mayoría de los sustantivos:</h3>
    <table>
        <thead>
            <tr>
                <th>Sustantivo</th>
                <th>Plural</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>apple</td>
                <td>apples</td>
            </tr>
            <tr>
                <td>book</td>
                <td>books</td>
            </tr>
        </tbody>
    </table>
   <div>
    <h3>A1) Terminaciones "s, x, o, ch, sh"</h3>
    <p>Si la palabra termina en "s, x, o, ch, sh", entonces para pluralizar, se agrega "es" al final de la palabra.</p>
    <table>
        <thead>
            <tr>
                <th>Sustantivo</th>
                <th>Plural</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>kiss</td>
                <td>kisses</td>
            </tr>
            <tr>
                <td>tomato</td>
                <td>tomatoes</td>
            </tr>
        </tbody>
    </table>
</div><div>
    <h3>A2) Terminación "y"</h3>
    <p>Si la palabra termina en "y", pero una consonante va antes, entonces la "y" se sustituye por "ies".</p>
    <table>
        <thead>
            <tr>
                <th>Sustantivo</th>
                <th>Plural</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>puppy</td>
                <td>puppies</td>
            </tr>
            <tr>
                <td>lady</td>
                <td>ladies</td>
            </tr>
        </tbody>
    </table>
</div><div>
    <h3>A3) Terminaciones "f" o "fe"</h3>
    <p>Si la palabra termina en "f" o "fe", entonces para pluralizar, se sustituye la "f" o la "fe" por "ves".</p>
    <table>
        <thead>
            <tr>
                <th>Sustantivo</th>
                <th>Plural</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>leaf</td>
                <td>leaves</td>
            </tr>
            <tr>
                <td>wife</td>
                <td>wives</td>
            </tr>
        </tbody>
    </table>
</div><div>
    <h3>A4) Sustantivos Irregulares</h3>
    <p>Son los que en plural se escriben totalmente diferente o igual a como se escriben en singular:</p>
    <table>
        <thead>
            <tr>
                <th>Sustantivo</th>
                <th>Plural</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>man</td>
                <td>men</td>
            </tr>
            <tr>
                <td>woman</td>
                <td>women</td>
            </tr>
            <tr>
                <td>child</td>
                <td>children</td>
            </tr>
            <tr>
                <td>tooth</td>
                <td>teeth</td>
            </tr>
        </tbody>
    </table>
</div><div>
    <h3>A5) Sustantivos Compuestos</h3>
    <p>Es una palabra formada de 1, 2 o más palabras, que comúnmente tienen un solo significado y solo se pluraliza la última palabra.</p>
    <table>
        <thead>
            <tr>
                <th>Sustantivo</th>
                <th>Plural</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>toothbrush</td>
                <td>toothbrushes</td>
            </tr>
            <tr>
                <td>swimming pool</td>
                <td>swimming pools</td>
            </tr>
        </tbody>
    </table>
</div>
<div>
    <h3>B) Añadir "es"</h3>
    <p>Cuando el sonido de la palabra termina en "s", entonces en plural se pronuncia con terminación "es".</p>
    <table>
        <thead>
            <tr>
                <th>Sustantivo</th>
                <th>Plural</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>bus</td>
                <td>buses</td>
            </tr>
            <tr>
                <td>box</td>
                <td>boxes</td>
            </tr>
        </tbody>
    </table>
</div>
</div>
</div></div>