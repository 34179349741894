<div class="quantifier">
  <p>
    Los cuantificadores son palabras que se utilizan para expresar la cantidad o
    la cantidad de algo en inglés. Se usan para modificar sustantivos y pueden
    indicar cantidad exacta, una cantidad imprecisa o una relación de cantidad.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>Tipos de cuantificadores</h2>

      <h3>1. Cuantificadores de cantidad indefinida</h3>
      <p>
        Estos cuantificadores se utilizan para referirse a cantidades no
        específicas.
      </p>
      <ul>
        <li>
          <strong>Some</strong>: Se usa en oraciones afirmativas y en preguntas
          cuando se espera una respuesta afirmativa.
          <ul>
            <li>
              Ejemplo: <em>There are some apples on the table.</em> (Hay algunas
              manzanas sobre la mesa.)
            </li>
            <li>
              Ejemplo en pregunta: <em>Is there some coffee left?</em> (¿Hay
              algo de café?)
            </li>
          </ul>
        </li>
        <li>
          <strong>Any</strong>: Se utiliza en oraciones negativas y en
          preguntas.
          <ul>
            <li>
              Ejemplo: <em>I don’t have any money.</em> (No tengo dinero.)
            </li>
            <li>
              Ejemplo en pregunta: <em>Do you have any questions?</em> (¿Tienes
              alguna pregunta?)
            </li>
          </ul>
        </li>
        <li>
          <strong>Much</strong>: Se usa en oraciones negativas y en preguntas
          con sustantivos incontables.
          <ul>
            <li>
              Ejemplo: <em>There isn’t much milk in the fridge.</em> (No hay
              mucha leche en el refrigerador.)
            </li>
            <li>
              Ejemplo en pregunta: <em>How much water do you need?</em> (¿Cuánta
              agua necesitas?)
            </li>
          </ul>
        </li>
        <li>
          <strong>Many</strong>: Se usa en oraciones negativas y en preguntas
          con sustantivos contables.
          <ul>
            <li>
              Ejemplo: <em>There aren’t many students in the class.</em> (No hay
              muchos estudiantes en la clase.)
            </li>
            <li>
              Ejemplo en pregunta:
              <em>How many friends do you have?</em> (¿Cuántos amigos tienes?)
            </li>
          </ul>
        </li>
        <li>
          <strong>A lot of</strong>: Se puede usar con sustantivos contables e
          incontables en oraciones afirmativas y negativas. Indica una cantidad
          grande.
          <ul>
            <li>
              Ejemplo: <em>I have a lot of books.</em> (Tengo muchos libros.)
            </li>
            <li>
              Ejemplo: <em>There are a lot of cars in the parking lot.</em> (Hay
              muchos autos en el estacionamiento.)
            </li>
          </ul>
        </li>
      </ul>

      <h3>2. Cuantificadores de cantidad precisa</h3>
      <p>Estos cuantificadores indican una cantidad específica.</p>
      <ul>
        <li>
          <strong>One, two, three, etc.</strong>: Números cardinales que indican
          una cantidad exacta.
          <ul>
            <li>Ejemplo: <em>I have two dogs.</em> (Tengo dos perros.)</li>
          </ul>
        </li>
        <li>
          <strong>Half</strong>: Se utiliza para indicar una cantidad igual a la
          mitad de algo.
          <ul>
            <li>
              Ejemplo: <em>I want half of the cake.</em> (Quiero la mitad del
              pastel.)
            </li>
          </ul>
        </li>
        <li>
          <strong>All</strong>: Se refiere a la totalidad de algo.
          <ul>
            <li>
              Ejemplo: <em>All students must attend the meeting.</em> (Todos los
              estudiantes deben asistir a la reunión.)
            </li>
          </ul>
        </li>
        <li>
          <strong>Most</strong>: Se utiliza para indicar la mayoría de algo.
          <ul>
            <li>
              Ejemplo: <em>Most people enjoy music.</em> (La mayoría de las
              personas disfrutan de la música.)
            </li>
          </ul>
        </li>
        <li>
          <strong>None</strong>: Indica la ausencia total de algo.
          <ul>
            <li>Ejemplo: <em>There are none left.</em> (No quedan ninguno.)</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="section-blanco">
      <h2>Ejemplos : Tipos de cuantificadores</h2>

      <h3>1. Cuantificadores de cantidad indefinida</h3>
      <p>
        Estos cuantificadores se utilizan para referirse a cantidades no
        específicas.
      </p>

      <table>
        <thead>
          <tr>
            <th>Cuantificador</th>
            <th>Ejemplo</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Some</td>
            <td>There are some apples on the table.</td>
            <td>Hay algunas manzanas sobre la mesa.</td>
          </tr>
          <tr>
            <td>Some</td>
            <td>There are some children in the park.</td>
            <td>Hay algunos niños en el parque.</td>
          </tr>
          <tr>
            <td>Some</td>
            <td>Would you like some tea?</td>
            <td>¿Te gustaría un poco de té?</td>
          </tr>
          <tr>
            <td>Some</td>
            <td>There are some interesting books on the shelf.</td>
            <td>Hay algunos libros interesantes en la estantería.</td>
          </tr>
          <tr>
            <td>Some</td>
            <td>I have some questions for you.</td>
            <td>Tengo algunas preguntas para ti.</td>
          </tr>
          <tr>
            <td>Any</td>
            <td>I don’t have any money.</td>
            <td>No tengo dinero.</td>
          </tr>
          <tr>
            <td>Any</td>
            <td>Are there any cookies left?</td>
            <td>¿Quedan algunas galletas?</td>
          </tr>
          <tr>
            <td>Any</td>
            <td>Do you have any friends here?</td>
            <td>¿Tienes amigos aquí?</td>
          </tr>
          <tr>
            <td>Any</td>
            <td>We don't have any time to waste.</td>
            <td>No tenemos tiempo que perder.</td>
          </tr>
          <tr>
            <td>Any</td>
            <td>Is there any food in the fridge?</td>
            <td>¿Hay comida en el refrigerador?</td>
          </tr>
          <tr>
            <td>Much</td>
            <td>There isn’t much milk in the fridge.</td>
            <td>No hay mucha leche en el refrigerador.</td>
          </tr>
          <tr>
            <td>Much</td>
            <td>How much time do we have?</td>
            <td>¿Cuánto tiempo tenemos?</td>
          </tr>
          <tr>
            <td>Much</td>
            <td>I don’t have much experience in this field.</td>
            <td>No tengo mucha experiencia en este campo.</td>
          </tr>
          <tr>
            <td>Much</td>
            <td>There isn't much sugar in the pantry.</td>
            <td>No hay mucho azúcar en la despensa.</td>
          </tr>
          <tr>
            <td>Much</td>
            <td>How much water do you need?</td>
            <td>¿Cuánta agua necesitas?</td>
          </tr>
          <tr>
            <td>Many</td>
            <td>There aren’t many students in the class.</td>
            <td>No hay muchos estudiantes en la clase.</td>
          </tr>
          <tr>
            <td>Many</td>
            <td>How many books do you read in a month?</td>
            <td>¿Cuántos libros lees en un mes?</td>
          </tr>
          <tr>
            <td>Many</td>
            <td>I don't have many friends in this city.</td>
            <td>No tengo muchos amigos en esta ciudad.</td>
          </tr>
          <tr>
            <td>Many</td>
            <td>Are there many options available?</td>
            <td>¿Hay muchas opciones disponibles?</td>
          </tr>
          <tr>
            <td>Many</td>
            <td>She has many talents.</td>
            <td>Ella tiene muchos talentos.</td>
          </tr>
          <tr>
            <td>A lot of</td>
            <td>I have a lot of books.</td>
            <td>Tengo muchos libros.</td>
          </tr>
          <tr>
            <td>A lot of</td>
            <td>There are a lot of cars in the parking lot.</td>
            <td>Hay muchos autos en el estacionamiento.</td>
          </tr>
          <tr>
            <td>A lot of</td>
            <td>She drinks a lot of water every day.</td>
            <td>Ella bebe mucha agua cada día.</td>
          </tr>
          <tr>
            <td>A lot of</td>
            <td>We have a lot of work to do.</td>
            <td>Tenemos mucho trabajo que hacer.</td>
          </tr>
          <tr>
            <td>A lot of</td>
            <td>There are a lot of people at the concert.</td>
            <td>Hay muchas personas en el concierto.</td>
          </tr>
        </tbody>
      </table>

      <h3>2. Cuantificadores de cantidad precisa</h3>
      <p>Estos cuantificadores indican una cantidad específica.</p>

      <table>
        <thead>
          <tr>
            <th>Cuantificador</th>
            <th>Ejemplo</th>
            <th>Traducción</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>One, two, three, etc.</td>
            <td>I have two dogs.</td>
            <td>Tengo dos perros.</td>
          </tr>
          <tr>
            <td>One, two, three, etc.</td>
            <td>She bought one dress.</td>
            <td>Ella compró un vestido.</td>
          </tr>
          <tr>
            <td>One, two, three, etc.</td>
            <td>There are three cats in the garden.</td>
            <td>Hay tres gatos en el jardín.</td>
          </tr>
          <tr>
            <td>One, two, three, etc.</td>
            <td>He has five brothers.</td>
            <td>Él tiene cinco hermanos.</td>
          </tr>
          <tr>
            <td>One, two, three, etc.</td>
            <td>I read one book last week.</td>
            <td>Leí un libro la semana pasada.</td>
          </tr>
          <tr>
            <td>Half</td>
            <td>I want half of the cake.</td>
            <td>Quiero la mitad del pastel.</td>
          </tr>
          <tr>
            <td>Half</td>
            <td>She cut the pizza in half.</td>
            <td>Ella cortó la pizza por la mitad.</td>
          </tr>
          <tr>
            <td>Half</td>
            <td>He drank half of the juice.</td>
            <td>Él bebió la mitad del jugo.</td>
          </tr>
          <tr>
            <td>Half</td>
            <td>We split the bill in half.</td>
            <td>Dividimos la cuenta por la mitad.</td>
          </tr>
          <tr>
            <td>Half</td>
            <td>Half of the students passed the exam.</td>
            <td>La mitad de los estudiantes aprobó el examen.</td>
          </tr>
          <tr>
            <td>All</td>
            <td>All students must attend the meeting.</td>
            <td>Todos los estudiantes deben asistir a la reunión.</td>
          </tr>
          <tr>
            <td>All</td>
            <td>She gave all her money to charity.</td>
            <td>Ella dio todo su dinero a la caridad.</td>
          </tr>
          <tr>
            <td>All</td>
            <td>All the cookies were eaten.</td>
            <td>Se comieron todas las galletas.</td>
          </tr>
          <tr>
            <td>All</td>
            <td>We watched all the movies in the series.</td>
            <td>Vimos todas las películas de la serie.</td>
          </tr>
          <tr>
            <td>All</td>
            <td>All of the answers were correct.</td>
            <td>Todas las respuestas eran correctas.</td>
          </tr>
          <tr>
            <td>Most</td>
            <td>Most people like ice cream.</td>
            <td>A la mayoría de las personas les gusta el helado.</td>
          </tr>
          <tr>
            <td>Most</td>
            <td>She finished most of her homework.</td>
            <td>Ella terminó la mayoría de su tarea.</td>
          </tr>
          <tr>
            <td>Most</td>
            <td>Most of the food was delicious.</td>
            <td>La mayoría de la comida estaba deliciosa.</td>
          </tr>
          <tr>
            <td>Most</td>
            <td>Most students passed the exam.</td>
            <td>La mayoría de los estudiantes aprobaron el examen.</td>
          </tr>
          <tr>
            <td>Most</td>
            <td>Most animals need water to survive.</td>
            <td>La mayoría de los animales necesitan agua para sobrevivir.</td>
          </tr>
          <tr>
            <td>None</td>
            <td>None of the cookies are left.</td>
            <td>No queda ninguna galleta.</td>
          </tr>
          <tr>
            <td>None</td>
            <td>I have none of the materials needed.</td>
            <td>No tengo ninguno de los materiales necesarios.</td>
          </tr>
          <tr>
            <td>None</td>
            <td>None of the students failed the exam.</td>
            <td>Ninguno de los estudiantes reprobó el examen.</td>
          </tr>
          <tr>
            <td>None</td>
            <td>There were none available at the store.</td>
            <td>No había ninguno disponible en la tienda.</td>
          </tr>
          <tr>
            <td>None</td>
            <td>None of the answers are correct.</td>
            <td>Ninguna de las respuestas es correcta.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
