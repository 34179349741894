import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-sustantivos',
  templateUrl: './sustantivos.component.html',
  styleUrl: './sustantivos.component.scss',
})
export class SustantivosComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Sustantivos';
  override title = 'Sustantivos - “Nouns”';
}
