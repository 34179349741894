import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-conjunctions',
  standalone: true,
  imports: [],
  templateUrl: './conjunctions.component.html',
  styleUrl: './conjunctions.component.scss',
})
export class ConjunctionsComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Conjunciones';
  override title = 'Conjunciones - “Conjunctions”';
}
