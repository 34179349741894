<div class="preposicions-of-time-in-at-on">

    <p>Las preposiciones de tiempo en inglés son fundamentales para indicar cuándo ocurren las acciones. En esta lección, aprenderemos sobre tres de las más comunes: <strong>in</strong>, <strong>at</strong> y <strong>on</strong>.</p>

    <div class="section"> 
        <div class="section-blanco"> 
        <h2>1. <strong>In</strong></h2>
        <p>La preposición <strong>in</strong> se utiliza para indicar períodos de tiempo más largos, como meses, años, estaciones o partes del día.</p>

        <h3>Ejemplos:</h3>
        <ul>
            <li>She was born <strong>in</strong> 1995. (Ella nació en 1995.)</li>
            <li>We will travel <strong>in</strong> the summer. (Viajaremos en el verano.)</li>
            <li>The flowers bloom <strong>in</strong> spring. (Las flores florecen en primavera.)</li>
        </ul>

        <h3>Consejos para recordar:</h3>
        <ul>
            <li>Utiliza <strong>in</strong> para referirte a meses, años y estaciones.</li>
            <li>Recuerda que también se usa para partes del día, como <strong>in</strong> the morning.</li>
        </ul>
        <h2>Tabla de Ejemplos</h2>
        <table>
            <tr>
                <th>Contexto</th>
                <th>Ejemplo</th>
            </tr>
            <tr>
                <td>Meses</td>
                <td>in January</td>
            </tr>
            <tr>
                <td>Meses</td>
                <td>in February</td>
            </tr>
            <tr>
                <td>Meses</td>
                <td>in March</td>
            </tr>
            <tr>
                <td>Años</td>
                <td>in 1990</td>
            </tr>
            <tr>
                <td>Años</td>
                <td>in 2000</td>
            </tr>
            <tr>
                <td>Momentos del Día</td>
                <td>in the morning</td>
            </tr>
            <tr>
                <td>Momentos del Día</td>
                <td>in the afternoon</td>
            </tr>
            <tr>
                <td>Momentos del Día</td>
                <td>in the evening</td>
            </tr>
            <tr>
                <td>Estaciones</td>
                <td>in spring</td>
            </tr>
            <tr>
                <td>Estaciones</td>
                <td>in summer</td>
            </tr>
            <tr>
                <td>Estaciones</td>
                <td>in autumn</td>
            </tr>
            <tr>
                <td>Estaciones</td>
                <td>in winter</td>
            </tr>
        </table>
    </div>
        <div class="section-blanco"> 
    <h2>2. <strong>At</strong></h2>
    <p>La preposición <strong>at</strong> se usa para indicar momentos específicos, como horas y fechas. También se utiliza para referirse a eventos y lugares.</p>

    <h3>Ejemplos:</h3>
    <ul>
        <li>We will meet <strong>at</strong> 5 PM. (Nos reuniremos a las 5 PM.)</li>
        <li>The party is <strong>at</strong> my house. (La fiesta es en mi casa.)</li>
        <li>She was born <strong>at</strong> midnight. (Ella nació a la medianoche.)</li>
    </ul>

    <h3>Consejos para recordar:</h3>
    <ul>
        <li>Utiliza <strong>at</strong> para horas y momentos específicos.</li>
        <li>Recuerda que también se usa para eventos, como <strong>at</strong> the concert.</li>
    </ul>
</div>   <div class="section-blanco"> 
    <h2>3. <strong>On</strong></h2>
    <p>La preposición <strong>on</strong> se utiliza para fechas y días de la semana. También se puede usar para indicar eventos programados.</p>

    <h3>Ejemplos:</h3>
    <ul>
        <li>The meeting is <strong>on</strong> Monday. (La reunión es el lunes.)</li>
        <li>My birthday is <strong>on</strong> July 20th. (Mi cumpleaños es el 20 de julio.)</li>
        <li>We will have a picnic <strong>on</strong> Christmas Day. (Tendremos un picnic el día de Navidad.)</li>
    </ul>

    <h3>Consejos para recordar:</h3>
    <ul>
        <li>Utiliza <strong>on</strong> para referirte a días de la semana y fechas.</li>
        <li>Recuerda que también se usa para indicar eventos específicos, como <strong>on</strong> your wedding day.</li>
    </ul>
</div>   <div class="section-blanco"> 
    <h2>Tabla de Ejemplos</h2>
    <table>
        <tr>
            <th>Preposición</th>
            <th>Ejemplo en inglés</th>
            <th>Traducción al español</th>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>We will go on vacation in July.</td>
            <td>Nos iremos de vacaciones en julio.</td>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>She was born in 1990.</td>
            <td>Ella nació en 1990.</td>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>He will finish his project in a week.</td>
            <td>Él terminará su proyecto en una semana.</td>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>The flowers bloom in spring.</td>
            <td>Las flores florecen en primavera.</td>
        </tr>
        <tr>
            <td><strong>In</strong></td>
            <td>We met in the morning.</td>
            <td>Nos encontramos por la mañana.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>We will meet at 3 PM.</td>
            <td>Nos reuniremos a las 3 PM.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>The meeting starts at noon.</td>
            <td>La reunión comienza al mediodía.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>She usually goes to bed at 10 PM.</td>
            <td>Ella generalmente se va a la cama a las 10 PM.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>We have a party at the weekend.</td>
            <td>Tenemos una fiesta el fin de semana.</td>
        </tr>
        <tr>
            <td><strong>At</strong></td>
            <td>I like to study at night.</td>
            <td>Me gusta estudiar por la noche.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>Her birthday is on March 5th.</td>
            <td>Su cumpleaños es el 5 de marzo.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>We will have a meeting on Friday.</td>
            <td>Tendremos una reunión el viernes.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>He likes to go for a walk on Sundays.</td>
            <td>Le gusta salir a caminar los domingos.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>The concert is on July 10th.</td>
            <td>El concierto es el 10 de julio.</td>
        </tr>
        <tr>
            <td><strong>On</strong></td>
            <td>We will travel on Christmas Day.</td>
            <td>Viajaremos el día de Navidad.</td>
        </tr>
    </table>
</div></div></div>