<div class="adverbs">
  <p>
    Los adverbios en inglés son palabras que modifican o describen verbos,
    adjetivos, otros adverbios o incluso una oración completa. Cumplen la
    función de expresar cómo, cuándo, dónde, con qué frecuencia o en qué grado
    ocurre una acción. Vamos a explorar sus reglas gramaticales y cómo se forman
    en inglés, con ejemplos y más detalles.
  </p>
  <div class="section">
    <div class="section-blanco">
      <h2>1. Formación de Adverbios</h2>
      <p>
        En la mayoría de los casos, los adverbios en inglés se forman añadiendo
        <strong>-ly</strong> al adjetivo. Por ejemplo:
      </p>

      <h3>Reglas generales</h3>
      <table>
        <tr>
          <th>Adjetivo</th>
          <th>Adverbio</th>
          <th>Ejemplo en oraciones</th>
        </tr>
        <tr>
          <td>Quick</td>
          <td>Quickly</td>
          <td>She runs quickly. (Ella corre rápidamente.)</td>
        </tr>
        <tr>
          <td>Happy</td>
          <td>Happily</td>
          <td>They danced happily. (Ellos bailaron felices.)</td>
        </tr>
        <tr>
          <td>Beautiful</td>
          <td>Beautifully</td>
          <td>She sings beautifully. (Ella canta hermosamente.)</td>
        </tr>
        <tr>
          <td>Loud</td>
          <td>Loudly</td>
          <td>He spoke loudly. (Él habló en voz alta.)</td>
        </tr>
        <tr>
          <td>Careful</td>
          <td>Carefully</td>
          <td>He drives carefully. (Él conduce con cuidado.)</td>
        </tr>
      </table>

      <h3>Reglas especiales:</h3>
      <ul>
        <li>
          Si el adjetivo termina en <strong>-y</strong>, se cambia la
          <strong>-y</strong> por <strong>-i</strong> y luego se añade
          <strong>-ly</strong>: <br />
          Easy (fácil) → Easily (fácilmente)
        </li>
        <li>
          Algunos adjetivos que terminan en <strong>-le</strong> cambian esa
          terminación a <strong>-ly</strong>: <br />
          Comfortable (cómodo) → Comfortably (cómodamente)
        </li>
        <li>
          Adjetivos que terminan en <strong>-ic</strong> añaden
          <strong>-ally</strong>: <br />
          Basic (básico) → Basically (básicamente)
        </li>
      </ul>

      <table>
        <tr>
          <th>Adjetivo</th>
          <th>Adverbio</th>
          <th>Ejemplo en oraciones</th>
        </tr>
        <tr>
          <td>Easy</td>
          <td>Easily</td>
          <td>This task is done easily. (Esta tarea se hace fácilmente.)</td>
        </tr>
        <tr>
          <td>Comfortable</td>
          <td>Comfortably</td>
          <td>They sat comfortably. (Se sentaron cómodamente.)</td>
        </tr>
        <tr>
          <td>Basic</td>
          <td>Basically</td>
          <td>
            Basically, we need more time. (Básicamente, necesitamos más tiempo.)
          </td>
        </tr>
        <tr>
          <td>Automatic</td>
          <td>Automatically</td>
          <td>
            The doors open automatically. (Las puertas se abren
            automáticamente.)
          </td>
        </tr>
        <tr>
          <td>Traffic</td>
          <td>Trafficly</td>
          <td>He drives trafficly. (Él conduce de manera fluida.)</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>2. Uso de los Adverbios</h2>
      <h3>a. Adverbios de Modo (How?)</h3>
      <p>
        Indican cómo ocurre una acción y suelen colocarse después del verbo:
      </p>
      <ul>
        <li>
          She speaks <strong>quickly</strong>. (Ella habla
          <strong>rápidamente</strong>.)
        </li>
        <li>
          He drives <strong>carefully</strong>. (Él maneja
          <strong>con cuidado</strong>.)
        </li>
      </ul>
      <table>
        <tr>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td>She dances <strong>gracefully</strong>.</td>
          <td>Ella baila <strong>con gracia</strong>.</td>
        </tr>
        <tr>
          <td>They solved the problem <strong>quickly</strong>.</td>
          <td>Ellos resolvieron el problema <strong>rápidamente</strong>.</td>
        </tr>
        <tr>
          <td>He spoke <strong>softly</strong>.</td>
          <td>Él habló <strong>suavemente</strong>.</td>
        </tr>
        <tr>
          <td>She wrote the letter <strong>carefully</strong>.</td>
          <td>Ella escribió la carta <strong>cuidadosamente</strong>.</td>
        </tr>
        <tr>
          <td>We walked <strong>slowly</strong>.</td>
          <td>Nosotros caminamos <strong>despacio</strong>.</td>
        </tr>
      </table>

      <h3>b. Adverbios de Tiempo (When?)</h3>
      <p>
        Indican cuándo ocurre una acción. Se colocan al principio o al final de
        la oración:
      </p>
      <ul>
        <li>
          They arrived <strong>yesterday</strong>. (Llegaron
          <strong>ayer</strong>.)
        </li>
        <li>
          I will call you <strong>later</strong>. (Te llamaré
          <strong>más tarde</strong>.)
        </li>
      </ul>
      <table>
        <tr>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td>She will come <strong>tomorrow</strong>.</td>
          <td>Ella vendrá <strong>mañana</strong>.</td>
        </tr>
        <tr>
          <td>We met <strong>last week</strong>.</td>
          <td>Nos encontramos <strong>la semana pasada</strong>.</td>
        </tr>
        <tr>
          <td>He always wakes up <strong>early</strong>.</td>
          <td>Él siempre se despierta <strong>temprano</strong>.</td>
        </tr>
        <tr>
          <td>I visited my grandparents <strong>recently</strong>.</td>
          <td>Visité a mis abuelos <strong>recientemente</strong>.</td>
        </tr>
        <tr>
          <td>They left <strong>just now</strong>.</td>
          <td>Ellos se fueron <strong>hace un momento</strong>.</td>
        </tr>
      </table>

      <h3>c. Adverbios de Lugar (Where?)</h3>
      <p>
        Indican dónde ocurre una acción. También se colocan al final de la
        oración:
      </p>
      <ul>
        <li>
          We met <strong>outside</strong>. (Nos encontramos
          <strong>afuera</strong>.)
        </li>
        <li>
          She lives <strong>nearby</strong>. (Ella vive <strong>cerca</strong>.)
        </li>
      </ul>
      <table>
        <tr>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td>He is waiting <strong>inside</strong>.</td>
          <td>Él está esperando <strong>adentro</strong>.</td>
        </tr>
        <tr>
          <td>They walked <strong>everywhere</strong>.</td>
          <td>Ellos caminaron <strong>por todas partes</strong>.</td>
        </tr>
        <tr>
          <td>She looked <strong>upstairs</strong>.</td>
          <td>Ella miró <strong>arriba</strong>.</td>
        </tr>
        <tr>
          <td>We sat <strong>here</strong>.</td>
          <td>Nosotros nos sentamos <strong>aquí</strong>.</td>
        </tr>
        <tr>
          <td>He went <strong>home</strong>.</td>
          <td>Él se fue <strong>a casa</strong>.</td>
        </tr>
      </table>

      <h3>d. Adverbios de Frecuencia (How often?)</h3>
      <p>
        Indican con qué frecuencia ocurre una acción. Suelen colocarse antes del
        verbo principal o después del verbo <strong>to be</strong>:
      </p>
      <ul>
        <li>
          She <strong>always</strong> arrives early. (Ella
          <strong>siempre</strong> llega temprano.)
        </li>
        <li>
          They are <strong>usually</strong> busy. (Ellos
          <strong>suelen</strong> estar ocupados.)
        </li>
      </ul>
      <table>
        <tr>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td>He <strong>often</strong> plays tennis.</td>
          <td>Él <strong>frecuentemente</strong> juega al tenis.</td>
        </tr>
        <tr>
          <td>We <strong>rarely</strong> eat out.</td>
          <td>Nosotros <strong>raramente</strong> comemos fuera.</td>
        </tr>
        <tr>
          <td>She <strong>sometimes</strong> goes jogging.</td>
          <td>Ella <strong>a veces</strong> sale a correr.</td>
        </tr>
        <tr>
          <td>They are <strong>never</strong> late.</td>
          <td>Ellos <strong>nunca</strong> llegan tarde.</td>
        </tr>
        <tr>
          <td>I <strong>usually</strong> wake up early.</td>
          <td>Yo <strong>usualmente</strong> me despierto temprano.</td>
        </tr>
      </table>

      <h3>e. Adverbios de Grado (To what extent?)</h3>
      <p>
        Indican en qué medida o grado ocurre una acción o se aplica un adjetivo.
        Se colocan antes del adjetivo o adverbio que están modificando:
      </p>
      <ul>
        <li>
          He is <strong>extremely</strong> happy. (Él está
          <strong>extremadamente</strong> feliz.)
        </li>
        <li>
          She sings <strong>quite</strong> beautifully. (Ella canta
          <strong>bastante</strong> bien.)
        </li>
      </ul>
      <table>
        <tr>
          <th>Ejemplo</th>
          <th>Traducción</th>
        </tr>
        <tr>
          <td>They are <strong>very</strong> tall.</td>
          <td>Ellos son <strong>muy</strong> altos.</td>
        </tr>
        <tr>
          <td>He runs <strong>incredibly</strong> fast.</td>
          <td>Él corre <strong>increíblemente</strong> rápido.</td>
        </tr>
        <tr>
          <td>She is <strong>fairly</strong> new to this.</td>
          <td>Ella es <strong>bastante</strong> nueva en esto.</td>
        </tr>
        <tr>
          <td>We are <strong>somewhat</strong> tired.</td>
          <td>Nosotros estamos <strong>algo</strong> cansados.</td>
        </tr>
        <tr>
          <td>He speaks <strong>too</strong> quickly.</td>
          <td>Él habla <strong>demasiado</strong> rápido.</td>
        </tr>
      </table>
    </div>
    <div class="section-blanco">
      <h2>3. Orden de los Adverbios</h2>
      <p>
        El orden de los adverbios sigue generalmente esta secuencia en una
        oración cuando usamos varios:
      </p>
      <ul>
        <li>Modo (How)</li>
        <li>Lugar (Where)</li>
        <li>Tiempo (When)</li>
      </ul>
      <p>Ejemplo:</p>
      <p>He worked quickly (modo) at the office (lugar) yesterday (tiempo).</p>

      <h2>4. Adverbios que no siguen la regla de -ly</h2>
      <p>
        Algunos adverbios no terminan en -ly y no tienen una forma derivada de
        adjetivos:
      </p>
      <ul>
        <li>Hard (difícilmente) – Ejemplo: He works hard.</li>
        <li>Fast (rápidamente) – Ejemplo: She runs fast.</li>
      </ul>

      <h2>5. Adverbios que cambian el significado del verbo</h2>
      <p>
        Algunos adverbios pueden cambiar el significado del verbo al colocarse
        antes o después del mismo:
      </p>
      <p>Only cambia el significado dependiendo de dónde se coloca:</p>
      <ul>
        <li>Only she saw the accident. (Solo ella vio el accidente.)</li>
        <li>
          She only saw the accident. (Ella solo vio el accidente, no participó.)
        </li>
      </ul>

      <h2>6. Cómo Formar Oraciones con Adverbios</h2>
      <p>
        Para formar oraciones con adverbios, debes seguir esta estructura
        básica:
      </p>
      <p>
        <strong>Sujeto + Verbo + Adverbio</strong> (en el caso de adverbios de
        modo, lugar, o tiempo).
      </p>
      <ul>
        <li>He sings beautifully.</li>
      </ul>
      <p>
        En el caso de adverbios de frecuencia, coloca el adverbio antes del
        verbo principal.
      </p>
      <ul>
        <li>She often goes to the gym.</li>
      </ul>

      <h3>Ejemplos adicionales:</h3>
      <ul>
        <li>I often read books. (Frecuencia)</li>
        <li>They danced beautifully. (Modo)</li>
        <li>She went home early. (Tiempo)</li>
        <li>He lives far away. (Lugar)</li>
        <li>We almost missed the bus. (Grado)</li>
      </ul>
    </div>
  </div>
</div>
