import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-alfabeto',
  templateUrl: './alfabeto.component.html',
  styleUrl: './alfabeto.component.scss',
})
export class AlfabetoComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Abecedario';
  override title = 'Aprende el abecedario en inglés y su pronunciación';

  letters = [
    { letter: 'A', pronunciacion: 'ei', fonetica: '/eɪ/' },
    { letter: 'B', pronunciacion: 'bi', fonetica: '/bi/' },
    { letter: 'C', pronunciacion: 'ci', fonetica: '/si/' },
    { letter: 'D', pronunciacion: 'di', fonetica: '/di/' },
    { letter: 'E', pronunciacion: 'i', fonetica: '/i/' },
    { letter: 'F', pronunciacion: 'ef', fonetica: '/ef/' },
    { letter: 'G', pronunciacion: 'yi', fonetica: '/dʒi/' },
    { letter: 'H', pronunciacion: 'eich', fonetica: '/eɪtʃ/' },
    { letter: 'I', pronunciacion: 'ai', fonetica: '/aɪ/' },
    { letter: 'J', pronunciacion: 'yei', fonetica: '/dʒeɪ/' },
    { letter: 'K', pronunciacion: 'kei', fonetica: '/keɪ/' },
    { letter: 'L', pronunciacion: 'el', fonetica: '/el/' },
    { letter: 'M', pronunciacion: 'em', fonetica: '/em/' },
    { letter: 'N', pronunciacion: 'en', fonetica: '/en/' },
    { letter: 'O', pronunciacion: 'ou', fonetica: '/əʊ/' },
    { letter: 'P', pronunciacion: 'pi', fonetica: '/pi/' },
    { letter: 'Q', pronunciacion: 'kiu', fonetica: '/kju/' },
    { letter: 'R', pronunciacion: 'ar', fonetica: '/ɑ(r)/' },
    { letter: 'S', pronunciacion: 'es', fonetica: '/es/' },
    { letter: 'T', pronunciacion: 'ti', fonetica: '/ti/' },
    { letter: 'U', pronunciacion: 'iu', fonetica: '/iu/' },
    { letter: 'V', pronunciacion: 'uvi', fonetica: '/vi/' },
    { letter: 'W', pronunciacion: 'doubel-iu', fonetica: '/ˈdʌb(ə)l ju/' },
    { letter: 'X', pronunciacion: 'ex', fonetica: '/eks/' },
    { letter: 'Y', pronunciacion: 'wai', fonetica: '/waɪ/' },
    { letter: 'Z', pronunciacion: 'set', fonetica: '/zed/' },
  ];
}
