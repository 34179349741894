import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-direct-and-reported-speech',
  standalone: true,
  imports: [],
  templateUrl: './direct-and-reported-speech.component.html',
  styleUrl: './direct-and-reported-speech.component.scss',
})
export class DirectAndReportedSpeechComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Direct and reported speech';
  override title = 'Direct and reported speech';
}
