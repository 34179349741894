<div class="verbos-irregulares">
  <table>
    <thead>
      <tr>
        <th>Infinitivo</th>
        <th>Pasado Simple</th>
        <th>Participio</th>
        <th>Español</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Arise</td>
        <td>Arose</td>
        <td>Arisen</td>
        <td>Surgir, Levantarse</td>
      </tr>
      <tr>
        <td>Awake</td>
        <td>Awoke</td>
        <td>Awoken</td>
        <td>Despertarse</td>
      </tr>
      <tr>
        <td>Be (am, are, is)</td>
        <td>Was / Were</td>
        <td>Been</td>
        <td>Ser / Estar</td>
      </tr>
      <tr>
        <td>Bear</td>
        <td>Bore</td>
        <td>Born / Borne</td>
        <td>Soportar, dar a luz</td>
      </tr>
      <tr>
        <td>Beat</td>
        <td>Beat</td>
        <td>Beaten</td>
        <td>Golpear</td>
      </tr>
      <tr>
        <td>Become</td>
        <td>Became</td>
        <td>Become</td>
        <td>Llegar a Ser</td>
      </tr>
      <tr>
        <td>Begin</td>
        <td>Began</td>
        <td>Begun</td>
        <td>Empezar</td>
      </tr>
      <tr>
        <td>Bend</td>
        <td>Bent</td>
        <td>Bent</td>
        <td>Doblar</td>
      </tr>
      <tr>
        <td>Bet</td>
        <td>Bet</td>
        <td>Bet</td>
        <td>Apostar</td>
      </tr>
      <tr>
        <td>Bind</td>
        <td>Bound</td>
        <td>Bound</td>
        <td>Atar, encuadernar</td>
      </tr>
      <tr>
        <td>Bid</td>
        <td>Bid</td>
        <td>Bid</td>
        <td>Pujar</td>
      </tr>
      <tr>
        <td>Bite</td>
        <td>Bit</td>
        <td>Bitten</td>
        <td>Morder</td>
      </tr>
      <tr>
        <td>Bleed</td>
        <td>Bled</td>
        <td>Bled</td>
        <td>Sangrar</td>
      </tr>
      <tr>
        <td>Blow</td>
        <td>Blew</td>
        <td>Blown</td>
        <td>Soplar</td>
      </tr>
      <tr>
        <td>Break</td>
        <td>Broke</td>
        <td>Broken</td>
        <td>Romper</td>
      </tr>
      <tr>
        <td>Breed</td>
        <td>Bred</td>
        <td>Bred</td>
        <td>Criar</td>
      </tr>
      <tr>
        <td>Bring</td>
        <td>Brought</td>
        <td>Brought</td>
        <td>Traer, Llevar</td>
      </tr>
      <tr>
        <td>Broadcast</td>
        <td>Broadcast</td>
        <td>Broadcast</td>
        <td>Radiar</td>
      </tr>
      <tr>
        <td>Build</td>
        <td>Built</td>
        <td>Built</td>
        <td>Edificar</td>
      </tr>
      <tr>
        <td>Burn</td>
        <td>Burnt / Burned</td>
        <td>Burnt / Burned</td>
        <td>Quemar</td>
      </tr>
      <tr>
        <td>Burst</td>
        <td>Burst</td>
        <td>Burst</td>
        <td>Reventar</td>
      </tr>
      <tr>
        <td>Buy</td>
        <td>Bought</td>
        <td>Bought</td>
        <td>Comprar</td>
      </tr>
      <tr>
        <td>Cast</td>
        <td>Cast</td>
        <td>Cast</td>
        <td>Arrojar</td>
      </tr>
      <tr>
        <td>Catch</td>
        <td>Caught</td>
        <td>Caught</td>
        <td>Coger</td>
      </tr>
      <tr>
        <td>Come</td>
        <td>Came</td>
        <td>Come</td>
        <td>Venir</td>
      </tr>
      <tr>
        <td>Cost</td>
        <td>Cost</td>
        <td>Cost</td>
        <td>Costar</td>
      </tr>
      <tr>
        <td>Cut</td>
        <td>Cut</td>
        <td>Cut</td>
        <td>Cortar</td>
      </tr>
      <tr>
        <td>Choose</td>
        <td>Chose</td>
        <td>Chosen</td>
        <td>Elegir</td>
      </tr>
      <tr>
        <td>Cling</td>
        <td>Clung</td>
        <td>Clung</td>
        <td>Agarrarse</td>
      </tr>
      <tr>
        <td>Creep</td>
        <td>Crept</td>
        <td>Crept</td>
        <td>Arrastrarse</td>
      </tr>
      <tr>
        <td>Deal</td>
        <td>Dealt</td>
        <td>Dealt</td>
        <td>Tratar</td>
      </tr>
      <tr>
        <td>Dig</td>
        <td>Dug</td>
        <td>Dug</td>
        <td>Cavar</td>
      </tr>
      <tr>
        <td>Do (Does)</td>
        <td>Did</td>
        <td>Done</td>
        <td>Hacer</td>
      </tr>
      <tr>
        <td>Draw</td>
        <td>Drew</td>
        <td>Drawn</td>
        <td>Dibujar</td>
      </tr>
      <tr>
        <td>Dream</td>
        <td>Dreamt / Dreamed</td>
        <td>Dreamt / Dreamed</td>
        <td>Soñar</td>
      </tr>
      <tr>
        <td>Drink</td>
        <td>Drank</td>
        <td>Drunk</td>
        <td>Beber</td>
      </tr>
      <tr>
        <td>Drive</td>
        <td>Drove</td>
        <td>Driven</td>
        <td>Conducir</td>
      </tr>
      <tr>
        <td>Eat</td>
        <td>Ate</td>
        <td>Eaten</td>
        <td>Comer</td>
      </tr>
      <tr>
        <td>Fall</td>
        <td>Fell</td>
        <td>Fallen</td>
        <td>Caer</td>
      </tr>
      <tr>
        <td>Feed</td>
        <td>Fed</td>
        <td>Fed</td>
        <td>Alimentar</td>
      </tr>
      <tr>
        <td>Feel</td>
        <td>Felt</td>
        <td>Felt</td>
        <td>Sentir</td>
      </tr>
      <tr>
        <td>Fight</td>
        <td>Fought</td>
        <td>Fought</td>
        <td>Luchar</td>
      </tr>
      <tr>
        <td>Find</td>
        <td>Found</td>
        <td>Found</td>
        <td>Encontrar</td>
      </tr>
      <tr>
        <td>Flee</td>
        <td>Fled</td>
        <td>Fled</td>
        <td>Huir</td>
      </tr>
      <tr>
        <td>Fly</td>
        <td>Flew</td>
        <td>Flown</td>
        <td>Volar</td>
      </tr>
      <tr>
        <td>Forbid</td>
        <td>Forbade</td>
        <td>Forbidden</td>
        <td>Prohibir</td>
      </tr>
      <tr>
        <td>Forget</td>
        <td>Forgot</td>
        <td>Forgotten</td>
        <td>Olvidar</td>
      </tr>
      <tr>
        <td>Forgive</td>
        <td>Forgave</td>
        <td>Forgiven</td>
        <td>Perdonar</td>
      </tr>
      <tr>
        <td>Freeze</td>
        <td>Froze</td>
        <td>Frozen</td>
        <td>Helar</td>
      </tr>
      <tr>
        <td>Get</td>
        <td>Got</td>
        <td>Gotten / Got</td>
        <td>Obtener</td>
      </tr>
      <tr>
        <td>Give</td>
        <td>Gave</td>
        <td>Given</td>
        <td>Dar</td>
      </tr>
      <tr>
        <td>Go (Goes)</td>
        <td>Went</td>
        <td>Gone</td>
        <td>Ir</td>
      </tr>
      <tr>
        <td>Grow</td>
        <td>Grew</td>
        <td>Grown</td>
        <td>Crecer</td>
      </tr>
      <tr>
        <td>Grind</td>
        <td>Ground</td>
        <td>Ground</td>
        <td>Moler</td>
      </tr>
      <tr>
        <td>Hang</td>
        <td>Hung</td>
        <td>Hung</td>
        <td>Colgar</td>
      </tr>
      <tr>
        <td>Have</td>
        <td>Had</td>
        <td>Had</td>
        <td>Haber o Tener</td>
      </tr>
      <tr>
        <td>Hear</td>
        <td>Heard</td>
        <td>Heard</td>
        <td>Oir</td>
      </tr>
      <tr>
        <td>Hide</td>
        <td>Hid</td>
        <td>Hidden</td>
        <td>Ocultar</td>
      </tr>
      <tr>
        <td>Hit</td>
        <td>Hit</td>
        <td>Hit</td>
        <td>Golpear</td>
      </tr>
      <tr>
        <td>Hold</td>
        <td>Held</td>
        <td>Held</td>
        <td>Agarrar, Celebrar</td>
      </tr>
      <tr>
        <td>Hurt</td>
        <td>Hurt</td>
        <td>Hurt</td>
        <td>Herir</td>
      </tr>
      <tr>
        <td>Keep</td>
        <td>Kept</td>
        <td>Kept</td>
        <td>Conservar</td>
      </tr>
      <tr>
        <td>Know</td>
        <td>Knew</td>
        <td>Known</td>
        <td>Saber, Conocer</td>
      </tr>
      <tr>
        <td>Kneel</td>
        <td>Knelt</td>
        <td>Knelt</td>
        <td>Arrodillarse</td>
      </tr>
      <tr>
        <td>Knit</td>
        <td>Knit</td>
        <td>Knit</td>
        <td>Hacer punto</td>
      </tr>
      <tr>
        <td>Lay</td>
        <td>Laid</td>
        <td>Laid</td>
        <td>Poner</td>
      </tr>
      <tr>
        <td>Lead</td>
        <td>Led</td>
        <td>Led</td>
        <td>Conducir</td>
      </tr>
      <tr>
        <td>Lean</td>
        <td>Leant</td>
        <td>Leant</td>
        <td>Apoyarse</td>
      </tr>
      <tr>
        <td>Leap</td>
        <td>Leapt</td>
        <td>Leapt</td>
        <td>Brincar</td>
      </tr>
      <tr>
        <td>Learn</td>
        <td>Learnt / Learned</td>
        <td>Learnt / Learned</td>
        <td>Aprender</td>
      </tr>
      <tr>
        <td>Leave</td>
        <td>Left</td>
        <td>Left</td>
        <td>Dejar, Salir</td>
      </tr>
      <tr>
        <td>Lend</td>
        <td>Lent</td>
        <td>Lent</td>
        <td>Prestar</td>
      </tr>
      <tr>
        <td>Let</td>
        <td>Let</td>
        <td>Let</td>
        <td>Permitir</td>
      </tr>
      <tr>
        <td>Lie</td>
        <td>Lay</td>
        <td>Lain</td>
        <td>Yacer</td>
      </tr>
      <tr>
        <td>Light</td>
        <td>Lit</td>
        <td>Lit</td>
        <td>Encender</td>
      </tr>
      <tr>
        <td>Lose</td>
        <td>Lost</td>
        <td>Lost</td>
        <td>Perder</td>
      </tr>
      <tr>
        <td>Make</td>
        <td>Made</td>
        <td>Made</td>
        <td>Hacer</td>
      </tr>
      <tr>
        <td>Mean</td>
        <td>Meant</td>
        <td>Meant</td>
        <td>Significar</td>
      </tr>
      <tr>
        <td>Meet</td>
        <td>Met</td>
        <td>Met</td>
        <td>Encontrar</td>
      </tr>
      <tr>
        <td>Mistake</td>
        <td>Mistook</td>
        <td>Mistaken</td>
        <td>Equivocar</td>
      </tr>
      <tr>
        <td>Overcome</td>
        <td>Overcame</td>
        <td>Overcome</td>
        <td>Vencer</td>
      </tr>
      <tr>
        <td>Pay</td>
        <td>Paid</td>
        <td>Paid</td>
        <td>Pagar</td>
      </tr>
      <tr>
        <td>Put</td>
        <td>Put</td>
        <td>Put</td>
        <td>Poner</td>
      </tr>
      <tr>
        <td>Read</td>
        <td>Read</td>
        <td>Read</td>
        <td>Leer</td>
      </tr>
      <tr>
        <td>Ride</td>
        <td>Rode</td>
        <td>Ridden</td>
        <td>Montar</td>
      </tr>
      <tr>
        <td>Ring</td>
        <td>Rang</td>
        <td>Rung</td>
        <td>Llamar</td>
      </tr>
      <tr>
        <td>Rise</td>
        <td>Rose</td>
        <td>Risen</td>
        <td>Levantarse</td>
      </tr>
      <tr>
        <td>Run</td>
        <td>Ran</td>
        <td>Run</td>
        <td>Correr</td>
      </tr>
      <tr>
        <td>Say</td>
        <td>Said</td>
        <td>Said</td>
        <td>Decir</td>
      </tr>
      <tr>
        <td>See</td>
        <td>Saw</td>
        <td>Seen</td>
        <td>Ver</td>
      </tr>
      <tr>
        <td>Seek</td>
        <td>Sought</td>
        <td>Sought</td>
        <td>Buscar</td>
      </tr>
      <tr>
        <td>Sell</td>
        <td>Sold</td>
        <td>Sold</td>
        <td>Vender</td>
      </tr>
      <tr>
        <td>Send</td>
        <td>Sent</td>
        <td>Sent</td>
        <td>Enviar</td>
      </tr>
      <tr>
        <td>Set</td>
        <td>Set</td>
        <td>Set</td>
        <td>Poner(se)</td>
      </tr>
      <tr>
        <td>Sew</td>
        <td>Sewed</td>
        <td>Sewn</td>
        <td>Coser</td>
      </tr>
      <tr>
        <td>Shake</td>
        <td>Shook</td>
        <td>Shaken</td>
        <td>Sacudir</td>
      </tr>
      <tr>
        <td>Shear</td>
        <td>Shore</td>
        <td>Shorn</td>
        <td>Esquilar</td>
      </tr>
      <tr>
        <td>Shine</td>
        <td>Shone</td>
        <td>Shone</td>
        <td>Brillar</td>
      </tr>
      <tr>
        <td>Shoot</td>
        <td>Shot</td>
        <td>Shot</td>
        <td>Disparar</td>
      </tr>
      <tr>
        <td>Show</td>
        <td>Showed</td>
        <td>Shown</td>
        <td>Mostrar</td>
      </tr>
      <tr>
        <td>Shrink</td>
        <td>Shrank</td>
        <td>Shrunk</td>
        <td>Encogerse</td>
      </tr>
      <tr>
        <td>Shut</td>
        <td>Shut</td>
        <td>Shut</td>
        <td>Cerrar</td>
      </tr>
      <tr>
        <td>Sing</td>
        <td>Sang</td>
        <td>Sung</td>
        <td>Cantar</td>
      </tr>
      <tr>
        <td>Sink</td>
        <td>Sank</td>
        <td>Sunk</td>
        <td>Hundir</td>
      </tr>
      <tr>
        <td>Sit</td>
        <td>Sat</td>
        <td>Sat</td>
        <td>Sentarse</td>
      </tr>
      <tr>
        <td>Sleep</td>
        <td>Slept</td>
        <td>Slept</td>
        <td>Dormir</td>
      </tr>
      <tr>
        <td>Slide</td>
        <td>Slid</td>
        <td>Slid</td>
        <td>Resbalar</td>
      </tr>
      <tr>
        <td>Smell</td>
        <td>Smelt</td>
        <td>Smelt</td>
        <td>Oler</td>
      </tr>
      <tr>
        <td>Sow</td>
        <td>Sowed</td>
        <td>Sown</td>
        <td>Sembrar</td>
      </tr>
      <tr>
        <td>Speak</td>
        <td>Spoke</td>
        <td>Spoken</td>
        <td>Hablar</td>
      </tr>
      <tr>
        <td>Speed</td>
        <td>Sped</td>
        <td>Sped</td>
        <td>Acelerar</td>
      </tr>
      <tr>
        <td>Spell</td>
        <td>Spelt</td>
        <td>Spelt</td>
        <td>Deletrear</td>
      </tr>
      <tr>
        <td>Spend</td>
        <td>Spent</td>
        <td>Spent</td>
        <td>Gastar</td>
      </tr>
      <tr>
        <td>Spill</td>
        <td>Spilt / Spilled</td>
        <td>Spilt / Spilled</td>
        <td>Derramar</td>
      </tr>
      <tr>
        <td>Spin</td>
        <td>Spun</td>
        <td>Spun</td>
        <td>Hilar</td>
      </tr>
      <tr>
        <td>Spit</td>
        <td>Spat</td>
        <td>Spat</td>
        <td>Escupir</td>
      </tr>
      <tr>
        <td>Split</td>
        <td>Split</td>
        <td>Split</td>
        <td>Hender / Partir / Rajar</td>
      </tr>
      <tr>
        <td>Spoil</td>
        <td>Spoilt / Spoiled</td>
        <td>Spoilt / Spoiled</td>
        <td>Estropear</td>
      </tr>
      <tr>
        <td>Spread</td>
        <td>Spread</td>
        <td>Spread</td>
        <td>Extender</td>
      </tr>
      <tr>
        <td>Spring</td>
        <td>Sprang</td>
        <td>Sprung</td>
        <td>Saltar</td>
      </tr>
      <tr>
        <td>Stand</td>
        <td>Stood</td>
        <td>Stood</td>
        <td>Estar en pie</td>
      </tr>
      <tr>
        <td>Steal</td>
        <td>Stole</td>
        <td>Stolen</td>
        <td>Robar</td>
      </tr>
      <tr>
        <td>Stick</td>
        <td>Stuck</td>
        <td>Stuck</td>
        <td>Pegar / Engomar</td>
      </tr>
      <tr>
        <td>Sting</td>
        <td>Stung</td>
        <td>Stung</td>
        <td>Picar</td>
      </tr>
      <tr>
        <td>Stink</td>
        <td>Stank / Stunk</td>
        <td>Stunk</td>
        <td>Apestar</td>
      </tr>
      <tr>
        <td>Stride</td>
        <td>Strode</td>
        <td>Stridden</td>
        <td>Dar zancadas</td>
      </tr>
      <tr>
        <td>Strike</td>
        <td>Struck</td>
        <td>Struck</td>
        <td>Golpear</td>
      </tr>
      <tr>
        <td>Swear</td>
        <td>Swore</td>
        <td>Sworn</td>
        <td>Jurar</td>
      </tr>
      <tr>
        <td>Sweat</td>
        <td>Sweat</td>
        <td>Sweat</td>
        <td>Sudar</td>
      </tr>
      <tr>
        <td>Sweep</td>
        <td>Swept</td>
        <td>Swept</td>
        <td>Barrer</td>
      </tr>
      <tr>
        <td>Swell</td>
        <td>Swelled</td>
        <td>Swollen</td>
        <td>Hinchar</td>
      </tr>
      <tr>
        <td>Swim</td>
        <td>Swam</td>
        <td>Swum</td>
        <td>Nadar</td>
      </tr>
      <tr>
        <td>Swing</td>
        <td>Swung</td>
        <td>Swung</td>
        <td>Columpiarse</td>
      </tr>
      <tr>
        <td>Take</td>
        <td>Took</td>
        <td>Taken</td>
        <td>Coger</td>
      </tr>
      <tr>
        <td>Teach</td>
        <td>Taught</td>
        <td>Taught</td>
        <td>Enseñar</td>
      </tr>
      <tr>
        <td>Tear</td>
        <td>Tore</td>
        <td>Torn</td>
        <td>Rasgar</td>
      </tr>
      <tr>
        <td>Tell</td>
        <td>Told</td>
        <td>Told</td>
        <td>Decir</td>
      </tr>
      <tr>
        <td>Think</td>
        <td>Thought</td>
        <td>Thought</td>
        <td>Pensar</td>
      </tr>
      <tr>
        <td>Throw</td>
        <td>Threw</td>
        <td>Thrown</td>
        <td>Arrojar / Tirar</td>
      </tr>
      <tr>
        <td>Thrust</td>
        <td>Thrust</td>
        <td>Thrust</td>
        <td>Introducir</td>
      </tr>
      <tr>
        <td>Tread</td>
        <td>Trod</td>
        <td>Trodden</td>
        <td>Pisar, hollar</td>
      </tr>
      <tr>
        <td>Understand</td>
        <td>Understood</td>
        <td>Understood</td>
        <td>Entender</td>
      </tr>
      <tr>
        <td>Undergo</td>
        <td>Underwent</td>
        <td>Underwent</td>
        <td>Sufrir</td>
      </tr>
      <tr>
        <td>Undertake</td>
        <td>Undertook</td>
        <td>Undertaken</td>
        <td>Emprender</td>
      </tr>
      <tr>
        <td>Wake</td>
        <td>Woke</td>
        <td>Woken</td>
        <td>Despertarse</td>
      </tr>
      <tr>
        <td>Wear</td>
        <td>Wore</td>
        <td>Worn</td>
        <td>Llevar puesto</td>
      </tr>
      <tr>
        <td>Weave</td>
        <td>Wove</td>
        <td>Woven</td>
        <td>Tejer</td>
      </tr>
      <tr>
        <td>Weep</td>
        <td>Wept</td>
        <td>Wept</td>
        <td>Llorar</td>
      </tr>
      <tr>
        <td>Wet</td>
        <td>Wet</td>
        <td>Wet</td>
        <td>Mojar</td>
      </tr>
      <tr>
        <td>Win</td>
        <td>Won</td>
        <td>Won</td>
        <td>Ganar</td>
      </tr>
      <tr>
        <td>Wind</td>
        <td>Wound</td>
        <td>Wound</td>
        <td>Enrollar</td>
      </tr>
      <tr>
        <td>Withdraw</td>
        <td>Withdrew</td>
        <td>Withdrawn</td>
        <td>Retirarse</td>
      </tr>
      <tr>
        <td>Wring</td>
        <td>Wrung</td>
        <td>Wrung</td>
        <td>Torcer</td>
      </tr>
      <tr>
        <td>Write</td>
        <td>Wrote</td>
        <td>Written</td>
        <td>Escribir</td>
      </tr>
    </tbody>
  </table>
</div>
