import { Component } from '@angular/core';
import { LeccionBaseComponent } from '../../leccion/leccion-base.component';

@Component({
  selector: 'app-else',
  standalone: true,
  imports: [],
  templateUrl: './else.component.html',
  styleUrl: './else.component.scss',
})
export class ElseComponent extends LeccionBaseComponent {
  override descripcionMenu = 'Else';
  override title = 'Else';
}
